<template>
	<v-container fluid>
		<v-row justify="center" align="center">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<v-card>
					<v-card-title>
						<v-btn v-if="doc.role !== 2" @click="createquestionnarie" color="#6374dd" dark class="ml-4">Пройти опрос</v-btn>
					</v-card-title>

					<v-data-table no-results-text="Поиск не дал результатов"
						sort-by="questdate"
						:sort-desc="true"
						:headers="headers"
						:items="list"
						:loading="loading"
						no-data-text="Опросники отсутствуют..."
						hide-default-footer
						loading-text="Загрузка... Пожалуйста подождите..."
					>

						<template v-slot:[`item.action`]="{ item }">
							<v-btn v-if="user.role === 1 || user.role === 3" @click="editItems(item)" color="#6374dd" x-small icon>
								<v-icon class="w-20">mdi-pencil</v-icon>
							</v-btn>
							
							<v-btn v-if="doc.role !== 2 && (new Date().getTime() - item.creation_date < 86400000)" @click="deleteItems(item, list)" color="error" x-small icon>
								<v-icon class="w-20">mdi-delete</v-icon>
							</v-btn>
							
							<v-btn v-if="doc.role === 2 || checkRole" @click="editItems(item, list)" color="#6374dd" x-small icon>
								<v-icon class="w-20">mdi-eye</v-icon>
							</v-btn>
						</template>
						
						<template v-slot:[`item.questdate`]="{ item }">
							{{new Date(item.questdate).toLocaleDateString()}}
						</template>
					</v-data-table>


					<v-dialog v-model="dialog" fullscreen>
						<v-card>
							<v-toolbar dark color="#6374dd">
								<v-btn icon dark @click="close()">
									<v-icon>mdi-close</v-icon>
								</v-btn>
							</v-toolbar>

							<questionnaire class="mt-6 pb-4" :idRow="idRow" @visibleEmit="visible" :dataEdit="dataEdit"/>
						</v-card>
					</v-dialog>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Questionnaire from './Questionnaire.vue'

export default {
	components: {Questionnaire},
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
            user: 'user/patient',
            doc: 'user/user',
        }),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
	data() {
		return {
			idRow: '',
			dataEdit: {},
			obj: {
				id: null,
				comment: '',
				creation_date: null,
				date_start: null,
				diagnosis: '',
				end_date: {date: null, checkbox: null},
				id: null,
				patients_id: null
			},
			dialog: false,
			list: [],
			loading: true,
			headers: [
				{ text: "Дата", value: "questdate", align: 'center' },
				{ text: "Индекс ФН", value: "result", align: 'center', sortable: false },
				// { text: "Оценка боли", value: "pain_asses", align: 'center', sortable: false },
                // { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		...mapActions({
            deleteQuest: 'qualityOfLifeQuestionnaire/deleteQuest',
            getQuest: 'qualityOfLifeQuestionnaire/getQuest',
            visibleOn: 'qualityOfLifeQuestionnaire/visibleOn',
            activeOff: 'qualityOfLifeQuestionnaire/activeOff',
            newDataNull: 'qualityOfLifeQuestionnaire/newDataNull',
            saveOn: 'qualityOfLifeQuestionnaire/saveOn',
            saveOff: 'qualityOfLifeQuestionnaire/saveOff',
            savePutBtnOn: 'qualityOfLifeQuestionnaire/savePutBtnOn',
            savePutBtnOff: 'qualityOfLifeQuestionnaire/savePutBtnOff',
		}),
		close() {
			this.dialog = false
		},
		async apiMethods(url, name) {
			this.loading = false
			let res = (await this.api.get(`${url}/${name}`)).data
			
			return res
		},
		editItems(row) {
			this.saveOff();
            this.dialog = true
            this.savePutBtnOn();
            this.dataEdit = row
            this.activeOff();
		},
		visible(data) {
			this.loading = true
            this.dialog = data.visibleQ

			this.apiMethods(this.apiUrl, `/patients/${this.user.patients_id}/questionnaire`)
			.then(res => {
				this.list = res
				this.loading = false
			})
        },
		createquestionnarie() {
            this.saveOn();
            this.dataEdit = {}
            this.newDataNull();
            this.activeOff();
            this.dialog = true;
        },
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/patients/${this.user.patients_id}/questionnaire/${row.id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)
					}
				})
			}
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `/patients/${this.user.patients_id}/questionnaire`)

		if(this.doc.role === 2)
			this.headers.push({ text: "Оценка боли", value: "pain_asses", align: 'center', sortable: false })
		this.headers.push({ text: "", value: "action", sortable: false })
	}
};
</script>