export default {
    namespaced: true,
    state: {
        global_review: [],
        local_review: []
    },
    getters: {
        globalReview: state => state.global_review,
        localReview: state => state.local_review
    },
    mutations: {
        //Общая оценка состояния здоровья пациентом
        setLocalReview(state, payload) {
            state.local_review = payload
        },
        setCreateLocalReview(state, payload) {
            state.local_review.push(payload)
        },
        //Глобальная оценка активности болезни врачом
        setGlobalReview(state, payload) {
            state.global_review = payload
        },
        setCreateGlobalReview(state, payload) {
            state.global_review.push(payload)
        },
        setLineUpdate(state, payload) {
            state.global_review.Scale = payload.Scale
            state.global_review.ScaleDate = payload.ScaleDate
        },
    },
    actions: {
        //Общая оценка состояния здоровья пациентом
        getLocalReview({commit, rootState}) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/patients/${id}/patientscales`).then(res => {
				commit('setLocalReview', res.data);
			})
        },
        createLocalReview({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/patients/${id}/patientscales`, payload).then(res => {
                commit('setCreateLocalReview', res.data)
			})
        },
        deleteLocalReview({rootState}, payload) {
            //console.log('rootState',payload)
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/patients/${id}/patientscales/${payload.Id}`, payload);
        },


        //Глобальная оценка активности болезни врачом
        getGlobalReview({commit, rootState}) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/patients/${id}/doctorsscales`).then(res => {
				commit('setGlobalReview', res.data);
			})
        },
        createGlobalReview({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/patients/${id}/doctorsscales`, payload).then(res => {
                commit('setCreateGlobalReview', res.data)
			})
        },
        deleteGlobalReview({rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/patients/${id}/doctorsscales/${payload.Id}`, payload);
        },
        lineUpdate({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.put(`${rootState.apiUrl}/patients/${id}/doctorsscales/${payload.Id}`, payload).then(res => {
                commit('setLineUpdate', res.data)
			})
		},
    }
}