<template>
	<v-card>
		<v-card-title>
			<v-btn v-if="user.role !== 2" @click="newRow" color="#6374dd" dark>Добавить нежелательное явление</v-btn>
			
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:search="search"
			:items="list"
			:loading="loading"
			sort-by="date"
        	:sort-desc="true"
			no-data-text="Нежелательные явления отсутствуют..."
			loading-text="Загрузка... Пожалуйста подождите..."
		>
			<!-- кнопки редактирование/просмотра/удаления -->
			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="checkRole" @click="onEditingStart(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn v-if="user.role !== 2 && new Date().getTime() - item.creation_date < 86400000" @click="deleteItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>

		<!-- Окно создания/редактирования -->
		<v-dialog persistent v-model="dialog" max-width="1000px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ !editeRow ? 'Добавить' : 'Редактировать' }} нежелательное явление</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="!editeRow ? save(obj) : onRowUpdating(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>
						<v-form ref="form" v-model="valid" lazy-validation class="mt-2">
                            <v-row>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense hide-details outlined :rules="[rules.required]" v-model="dateFormatted" label="Дата начала нежелательного явления *" readonly v-bind="attrs" v-on="on" />
                                        </template>

                                        <v-date-picker :max="obj.date_end.date ? toJSONLocal(obj.date_end.date) : toJSONLocal(null)" :min="toJSONLocal(bday.birth_date)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.date" @change="saveDate" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
									<v-row>
										<v-col cols="12" sm="12" md="6" xl="6">
											<v-menu ref="menuDOP" v-model="menuDOP" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
												<template v-slot:activator="{ on, attrs }">
													<v-text-field :disabled="obj.date_end.checkbox" dense hide-details outlined ref="refDOP" v-model="dateFormattedOP" label="Дата окончания нежелательного явления" readonly v-bind="attrs" v-on="on" />
												</template>

												<v-date-picker :min="obj.date ? toJSONLocal(obj.date) : toJSONLocal(bday.birth_date)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.date_end.date" @change="saveDateDOP" />
											</v-menu>
										</v-col>
										<v-col cols="12" sm="12" md="6" xl="6">
											<v-checkbox color="#6b59c7" dense hide-details v-model="obj.date_end.checkbox" @change="objDopCheckbox(obj.date_end)" label="По настоящее время" />
										</v-col>
									</v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-select dense hide-details label="Нежелательное явление *" :rules="[rules.required]" item-text="name" item-value="name" outlined :items="diagnosysSelect" v-model="obj.ny" />
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-select dense hide-details label="Исход лечения *" :rules="[rules.required]" item-text="name" item-value="name" outlined :items="ish" v-model="obj.treat_out" />
                                </v-col>

                                <v-col cols="12" sm="12" md="12" xl="12">
                                    <v-text-field dense hide-details outlined label="Лечение нежелательного явления" v-model="obj.treat_adv_ev" />
                                </v-col>
                                
                                <v-col cols="12" sm="12" md="12" xl="12">
                                    <v-textarea dense hide-details outlined label="Комментарий *" rows="2" v-model="obj.comment" />
                                </v-col>
                            </v-row>
                        </v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
//остальное
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			bday: 'passportPart/patientData',
			user: 'user/user',
			patient: 'user/patient',
            listMedPreparat: 'treatment/listMedPreparat'
        }),
		dateFormatted() {
            if(this.obj.date)
                return new Date(this.obj.date).toLocaleDateString("ru-RU");
            return null;
        },
		dateFormattedOP() {
            if(this.obj.date_end.date)
                return new Date(this.obj.date_end.date).toLocaleDateString("ru-RU");
            return null;
        },
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? false : this.user.role !== 2)
        }
	},
	data() {
		return {
			diagnosysSelect: [],
            ish: [],
			editeRow: false,
			showSysData: [],
            selectedPoint: '',
			file: null,
			search: '',
			maxDate: new Date().toISOString().substr(0, 10),
			menu: false,
			menuDOP: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				comment: '',
                creation_date: null,
                date: null,
                date_end: {date: null, checkbox: null},
                id: null,
                ny: '',
                treat_adv_ev: '',
                treat_out: ''
			},
			dialog: false,
			list: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.'
			},
			headers: [
				{ text: "Дата начала нежелательного явления", value: "dateStart", align: 'center' },
				{ text: "Дата окончания нежелательного явления", value: "dateEnd", align: 'center', sortable: false },
				{ text: "Нежелательное явление", value: "ny", align: 'center', sortable: false },
				{ text: "Исход лечения", value: "treat_out", align: 'center', sortable: false },
				{ text: "Лечение нежелательного явления", value: "treat_adv_ev", align: 'center', sortable: false },
				{ text: "Комментарий", value: "comment", align: 'center', sortable: false },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		close() {
			this.obj = {
				comment: '',
                creation_date: null,
                date: null,
                date_end: {date: null, checkbox: null},
                id: null,
                ny: '',
                treat_adv_ev: '',
                treat_out: ''
			}
			this.editeRow = false
			this.dialog = false
            this.$refs.form.resetValidation()
		},
		async apiMethods(url, name, flag) {
			this.loading = false			
			let res = [];

			if(flag) {
				res = (await this.api.get(`${url}/${name}`)).data
				await res.forEach(el => {
					el.dateStart = new Date(el.date).toLocaleDateString()
					el.dateEnd = el.date_end.checkbox ? 'По настоящее время' : new Date(el.date_end.date).toLocaleDateString()
				})
			} else {
				res = (await this.api.get(`${url}/${name}`)).data.filter(f => !f.IsHidden)
			}

			return res
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/patients/${this.patient.patients_id}/side_effects/${row.id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)

						this.snackBarFunc(true, 'Запись успешно удалена!!!', 'success')
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				this.api.post(`${this.apiUrl}/api/patients/${this.patient.patients_id}/side_effects`, {
					creation_date: new Date().getTime(),
					date: new Date(obj.date).getTime(),
					date_end: {date: obj.date_end.date ? new Date(obj.date_end.date).getTime() : null, checkbox: obj.date_end.checkbox},
					comment: obj.comment,
                    ny: obj.ny,
                    treat_adv_ev: obj.treat_adv_ev,
                    treat_out: obj.treat_out
				})
				.then(res => {
					if(res.status == 200) {
						res.data.dateStart = new Date(res.data.date).toLocaleDateString()
						res.data.dateEnd = res.data.date_end.checkbox ? 'По настоящее время' : new Date(res.data.date_end.date).toLocaleDateString()
						this.list.unshift(res.data)

						this.snackBarFunc(true, 'Нежелательное явление успешно создано!!!', 'success')
						this.close()
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		saveDate(date) {
			this.$refs.menu.save(date)
		},
		saveDateDOP(date) {
			this.$refs.menuDOP.save(date)
		},
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
        newRow() {
            this.editeRow = false
            this.dialog = true
        },
		onEditingStart(row) {
			row.date = this.toJSONLocal(row.date)
			if(row.date_end.date) row.date_end.date = this.toJSONLocal(row.date_end.date)
			
			this.editeRow = true
			Object.assign(this.obj, row)
			this.dialog = true
		},
		onRowUpdating(obj) {
            if(this.$refs.form.validate()) {
				this.api.put(`${this.apiUrl}/api/patients/${this.patient.patients_id}/side_effects/${obj.id}`, {
					creation_date: obj.creation_date,
					date: new Date(obj.date).getTime(),
					date_end: {date: obj.date_end.date ? new Date(obj.date_end.date).getTime() : null, checkbox: obj.date_end.checkbox},
					comment: obj.comment,
                    ny: obj.ny,
                    treat_adv_ev: obj.treat_adv_ev,
                    treat_out: obj.treat_out
				})
				.then(res => {
					if(res.status == 200) {
						res.data.dateStart = new Date(res.data.date).toLocaleDateString()
						res.data.dateEnd = res.data.date_end.checkbox ? 'По настоящее время' : new Date(res.data.date_end.date).toLocaleDateString()

						this.list.forEach(el => {
							if(el.id == res.data.id) Object.assign(el, res.data)
						})

						this.snackBarFunc(true, 'Нежелательное явление успешно обновлено!!!', 'success')
						this.close()
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		objDopCheckbox(date_end) {
			if(date_end.checkbox) date_end.date = null
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/patients/${this.patient.patients_id}/side_effects`, true)
		
		this.diagnosysSelect = await this.apiMethods(this.apiUrl, `api/lookups/side-effect-types?lookupName=side-effect-types`, false)
		this.ish = await this.apiMethods(this.apiUrl, `api/lookups/treatment-results?lookupName=treatment-results`, false)
	}
};
</script>