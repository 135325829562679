import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import RU from 'vuetify/es5/locale/ru'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
	lang: { locales: { RU }, current: 'RU' },
	icons: { iconfont: 'mdi' }
});
