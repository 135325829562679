var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[(_vm.user.role !== 2)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"#6374dd","dark":""},on:{"click":function($event){_vm.dialog=true}}},[_vm._v("Добавить рентген")]):_vm._e(),_c('v-spacer'),_c('v-text-field',{attrs:{"color":"#6b59c7","append-icon":"mdi-magnify","label":"Поиск...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"no-results-text":"Поиск не дал результатов","headers":_vm.headers,"items":_vm.list,"search":_vm.search,"loading":_vm.loading,"sort-by":"date","sort-desc":true,"no-data-text":"Нет данных...","loading-text":"Загрузка... Пожалуйста подождите..."},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.user.role !== 2 || _vm.checkRole)?_c('v-btn',{attrs:{"color":"#6374dd","small":"","icon":""},on:{"click":function($event){return _vm.editeItems(item)}}},[_c('v-icon',{staticClass:"w-20"},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.user.role !== 2 && (new Date().getTime() - item.creation_date < 86400000))?_c('v-btn',{attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.deleteItems(item, _vm.list)}}},[_c('v-icon',{staticClass:"w-20"},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.file_ids",fn:function(ref){
var item = ref.item;
return [(item.file_ids && item.file_ids.length > 0)?_c('v-btn',{staticClass:"ml-4",attrs:{"small":"","color":"#6374dd","dark":""},on:{"click":function($event){return _vm.downloadFile(item.file_ids)}}},[_vm._v("Скачать прикрепленный файл")]):_c('span',[_vm._v("---")])]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"#6374dd"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.editeOn ? 'Редактировать' : 'Добавить')+" исследование (рентген)")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.save(_vm.obj)}}},[_vm._v("Сохранить")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#6b59c7"}})],1),_c('v-form',{ref:"form",staticClass:"mt-2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"tf0",attrs:{"outlined":"","rules":[_vm.rules.required],"value":_vm.dateFormatted,"label":"Дата *","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":_vm.toJSONLocal(),"min":_vm.toJSONLocal(_vm.bday.birth_date),"color":"#6b59c7","first-day-of-week":1},on:{"change":_vm.saveDate},model:{value:(_vm.obj.date),callback:function ($$v) {_vm.$set(_vm.obj, "date", $$v)},expression:"obj.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-text-field',{ref:"tf1",attrs:{"outlined":"","rules":[_vm.rules.required],"color":"#6b59c7","label":"Исследование *"},model:{value:(_vm.obj.name),callback:function ($$v) {_vm.$set(_vm.obj, "name", $$v)},expression:"obj.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(!_vm.editeOn)?_c('v-file-input',{attrs:{"label":"Прикрепить файл","outlined":"","hide-details":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"hide-details":"","outlined":"","rows":"4","color":"#6b59c7","label":"Комментарий"},model:{value:(_vm.obj.comment),callback:function ($$v) {_vm.$set(_vm.obj, "comment", $$v)},expression:"obj.comment"}})],1)],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"rounded":"pill","elevation":"24","color":_vm.snackbarColor,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }