<template>
	<v-card>
		<v-data-table no-results-text="Поиск не дал результатов"
            hide-default-footer
            :headers="headers"
            :items="showData"
        >
            <!-- Изображение -->
			<template v-slot:[`item.id`]="{ item }">
                <img class="mt-2 mb-2 rounded-xl w-20" v-img="{src: `${apiUrl}/files/${item.id}/download`}" :src="apiUrl + '/files/' + item.id + '/download-thumb'"/>
			</template>

            <!-- Дата -->
			<template v-slot:[`item.date`]="{ item }">
                <span v-if="item.date">
                    {{new Date(item.date).toLocaleDateString()}}
                </span>
			</template>

            <!-- Кнопки управления строкой -->
			<template v-slot:[`item.action`]="{ item }">
                <!-- v-if="user.role !== 2 && new Date().getTime() - item.creation_date < 86400000" -->
                <v-btn  @click="editeItems(item)" color="info" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
                
                <v-btn v-if="user.role !== 2 && new Date().getTime() - item.creation_date < 86400000" @click="deleteItems(item)" color="error" x-small icon>
					<v-icon class="w-20">mdi-trash</v-icon>
				</v-btn>
			</template>
        </v-data-table>

        <!-- Окно просмотра -->
		<v-dialog persistent v-model="dialog" max-width="700px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Редактирование углов</v-toolbar-title>
                    <div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
                        <v-form ref="form" v-model="valid" lazy-validation class="mt-2">
                            <v-row class="mt-2">
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field :rules="[rules.maxAngels]" outlined color="#6374dd" v-model="obj.angle1" label="Угол 1" />
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field :rules="[rules.maxAngels]" :readonly="obj.angle2 == null" outlined color="#6374dd" v-model="obj.angle2" label="Угол 2" />
                                </v-col>
                            </v-row>
                        </v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-card>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        valid: true,
        dialog: false,
        isInitiated: false,
        headers: [
            { text: "Измерение", value: "id", align: 'center', sortable: false },
            { text: "Дата", value: "date", align: 'center' },
            { text: "Угол 1", value: "angle1", align: 'center', sortable: false },
            { text: "Угол 2", value: "angle2", align: 'center', sortable: false },
            { text: "", value: "action", sortable: false },
        ],
        obj: {
            angle1: null,
            angle2: null
        },
        rules: {
            maxAngels: value => !!value ? value <= 360 || 'Больше 360 не может быть!!!' : true
        },
    }),
	computed: {
		...mapGetters({
			activeJoint: 'manifestationOfDisease/activeJoint',
			allJointPhoto: 'manifestationOfDisease/allJointPhoto',
            apiUrl: 'apiUrl',
            user: 'user/user',
		}),
		showData() {
			let res = [];
			res = !this.activeJoint.items ? this.allJointPhoto : this.activeJoint.items;
			return res || []; 
		},
    },
    watch: {
        showData: {
            deep: true,
            handler: function(arr) {
                if(arr.length && !this.isInitiated) {
                    let joint = localStorage.getItem('subtype_item')

                    if(joint) {
                        console.log('Going to init with', arr.length )
                        this.setActiveJoint(joint)
                    }
                    this.isInitiated = true                            
                }

            } 
        }
    },
    methods: {
        ...mapActions({
            setActiveJoint: 'manifestationOfDisease/setActiveJoint',
            removePicture: 'manifestationOfDisease/removePicture',
            updatePhoto: 'manifestationOfDisease/updatePhoto'
		}),
        editeItems(row) {
            this.obj = Object.assign({}, row)
			this.dialog = true
		},
        save(obj) {
            if(this.$refs.form.validate()) {
                this.updatePhoto(obj).then(res => {
                    this.activeJoint.items.forEach(el => {
                        if(el.id == res.data.id) Object.assign(el, res.data)
                    })
                    this.dialog = false
                })
            }
        },
        deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
                row.flag = !this.activeJoint.items ? true : false;
				this.removePicture(row)
				.then(res => {
					if(res.data.Success) {
                        const index = this.showData.indexOf(row);
						this.showData.splice(index, 1)
					}
				})
			}
		},
    }
}
</script>

<style scoped>
.purp-row {
    background: #6374dd;
    color: white;
}
</style>