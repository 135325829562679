<template>
	<v-card>
		<v-data-table no-results-text="Поиск не дал результатов"
            dense
            item-key="key"
            v-model="selectedRows"
            :headers="headers"
            :items="showData"
            :items-per-page="-1"
        >
            <template v-slot:item="{ item }">
                <tr :class="selectedRows.indexOf(item.key) > -1 ? 'purp-row' : ''">
                    <td>{{item.name}}</td>
                    <td>{{item.isA}}</td>
                    <td>{{item.isB}}</td>
                    <td>{{item.isC}}</td>
                    <td>{{item.isActive}}</td>
                </tr>
            </template>
        </v-data-table>
	</v-card>
</template>


<script>
import { mapGetters } from 'vuex';

export default {
	props: ['sys', 'selected'],
    data: () => ({
        selectedRows: [],
        headers: [
            { text: "Суставы", value: "name", sortable: false },
            { text: "Болезненный сустав", value: "isA", sortable: false },
            { text: "Припухший сустав", value: "isB", sortable: false },
            { text: "Ограниченный в движениях сустав", value: "isC", sortable: false },
            { text: "Суставы с активным артритом", value: "isActive", sortable: false }
        ]
    }),
	computed: {
		...mapGetters({
			joints: 'manifestationOfDisease/joints',
		}),
		showData() {
            this.selectedRows = [];
            this.toggleSelection(this.selected);
            
            // <el-checkbox label="1">Болезненный сустав</el-checkbox><br>
            // <el-checkbox label="2">Припухший сустав</el-checkbox><br>
            // <el-checkbox label="3">Ограниченный в движениях сустав</el-checkbox>
            
            // console.log(this.sys.filter(i => i.check.length))

            return this.sys.filter(i => i.check.length).map(i => {
                return {
                    n: i.n,
                    key: i.n,
                    name: i.name, 
                    isA: i.check.find(i => i == 1) ? 'Да' : 'Нет', 
                    isB: i.check.find(i => i == 2) ? 'Да' : 'Нет', 
                    isC: i.check.find(i => i == 3) ? 'Да' : 'Нет',
                    isActive: i.check.length == 2 || i.check.indexOf('2') > -1 ? 'Да' : 'Нет'
                }
            })
        }
    },
    methods: {
        toggleSelection(keyID) {
            if (this.selectedRows.includes(keyID)) {
                this.selectedRows = this.selectedRows.filter(selectedKeyID => selectedKeyID !== keyID)
            } else {
                this.selectedRows.push(keyID)
            }
        },
    }
}
</script>

<style scoped>
.purp-row {
    background: #6374dd;
    color: white;
}
</style>