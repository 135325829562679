<template>
	<el-tabs :value="dtt" @tab-click="tabClick" type="border-card">
		<el-tab-pane name="1" label="УЗИ">
			<table-uzi v-if="tabClickVal == 1" />
		</el-tab-pane>
		<el-tab-pane name="2" label="Рентген">
			<table-xray v-if="tabClickVal == 2"/>
		</el-tab-pane>
		<el-tab-pane name="3" label="КТ">
			<table-kt v-if="tabClickVal == 3"/>
		</el-tab-pane>
		<el-tab-pane name="4" label="МРТ">
			<table-mrt v-if="tabClickVal == 4"/>
		</el-tab-pane>
	</el-tabs>
</template>

<script>
import { mapActions } from 'vuex'
import tableUzi from './tableUzi.vue';
import tableXray from './tableXRAY.vue';
import tableKt from './tableKT.vue';

import tableMrt from './tableMRT.vue';

export default {
	components: { tableXray, tableKt, tableMrt, tableUzi },
	data: () => ({
		tabClickVal: '1'
	}),
	computed: {
		dtt(){
			return localStorage.dtt || '1'
		}
	},
	methods: {
		...mapActions({
			getData: 'data/getData'
		}),
		tabClick(e){
			this.tabClickVal = e.name

            if(e.name == 1) {
                localStorage.setItem('dtt', '1');
            } else if(e.name == 2){
                localStorage.setItem('dtt', '2');
            } else if(e.name == 3){
                localStorage.setItem('dtt', '3');
            } else if(e.name == 4){
                localStorage.setItem('dtt', '4');
            }
        }
	},
	created() {
		this.tabClickVal = localStorage.getItem('dtt') || '1'
	}
}
</script>
