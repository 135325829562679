<template>
	<v-card>
		<v-card-title v-if="doc.role !== 2">
			<v-btn @click="dialog=true" color="#6374dd" dark class="ml-4">Добавить оценку</v-btn>
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			sort-by="ScaleDate"
			:sort-desc="true"
			:headers="headers"
			:items="list"
			:loading="loading"
			no-data-text="Оценки отсутствуют..."
			hide-default-footer
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="doc.role !== 2 && (new Date().getTime() - item.creation_date < 86400000)" @click="deleteItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>

			<template v-slot:[`item.ScaleDate`]="{ item }">
				{{new Date(item.ScaleDate).toLocaleDateString()}}
			</template>
		</v-data-table>


		<v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Оценить боль по шкале от 0 до 100</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text class="mt-12">
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation >
							<v-row>
								<v-col cols="12" sm="12" md="12">
									<!-- <v-text-field outlined ref="tf0" :rules="[rules.required]" color="#6b59c7" v-model="obj.diagnosis" label="Название заболевания *" /> -->

									<v-slider v-model="obj.Scale" label="Оценка" color="#6b59c7" thumb-color="#6b59c7" thumb-label="always" />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			patient: 'user/patient',
            doc: 'user/user',
        })
	},
	data() {
		return {
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				Scale: null,
				ScaleDate: null,
				creation_date: null
			},
			dialog: false,
			list: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.'
			},
			headers: [
				{ text: "Дата прохождения", value: "ScaleDate", align: 'center' }
			],
		};
	},
	methods: {
		close() {
			this.obj = {
				Scale: null,
				ScaleDate: null,
				creation_date: null
			}
			this.dialog = false
		},
		async apiMethods(url, name) {
			this.loading = false
			let res = (await this.api.get(`${url}/${name}`)).data
			
			return res
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/patients/${this.patient.patients_id}/patientscales/${row.Id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)

						this.snackBarFunc(true, 'Оценка успешно удалена!!!', 'success')
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			let url = `${this.apiUrl}/patients/${this.patient.patients_id}/patientscales`
			this.overlay = true

			obj.creation_date = new Date().getTime()
			obj.ScaleDate = this.toJSONLocal()

			this.api.post(url, obj)
			.then(res => {
				//Добавление в массив новой оценки
				this.list.unshift(res.data)
				
				this.snackBarFunc(true, 'Оценка успешно добавлена!!!', 'success')

				this.overlay = false
				this.dialog = false
				this.close()
			})
			.catch(error => {
				if(error) {
					this.snackBarFunc(true, 'Ошибка, обновите страницу и попробуйте снова!!!', 'error')
					this.overlay = false
				}
			})
		},
		toJSONLocal() {
			let local = new Date();
			local.setMinutes(new Date().getMinutes() - new Date().getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `patients/${this.patient.patients_id}/patientscales`)

		if(this.doc.role === 2)
			this.headers.push({ text: "Оценка", value: "Scale", align: 'center' })
		this.headers.push({ text: "", value: "action", sortable: false })
	}
};
</script>