<template>
	<v-container fluid>
		<v-row justify="center" align="center" class="tabs-wrap">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<el-tabs :value="al" @tab-click="tabClick" style="margin: 20px 0 0 31px; width: 96%;" type="border-card">
					<el-tab-pane name="1" label="Клинический анализ крови">
						<ClinicalBloodCount />
					</el-tab-pane>
					<el-tab-pane name="2" label="Биохимический анализ крови">
						<BiochemicalBloodTest />
							<el-collapse style="margin-top:20px;" accordion>
								<el-collapse-item name="1">
									<template slot="title">
										<span style="margin-left: 45%;font-size:15px;"><i class="header-icon el-icon-caret-bottom"></i>Иные анализы<i class="header-icon el-icon-caret-bottom"></i></span>
									</template>
									<OtherAnalyses />
								</el-collapse-item>
							</el-collapse>
					</el-tab-pane>
					<el-tab-pane name="3" label="Иммунология">
						<Immunology />
					</el-tab-pane>
				</el-tabs>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import ClinicalBloodCount from './grid/ClinicalBloodCount.vue'
import BiochemicalBloodTest from './grid/BiochemicalBloodTest.vue'
import Immunology from './grid/Immunology.vue'
import OtherAnalyses from './grid/OtherAnalyses.vue'

export default {
    components: {
        ClinicalBloodCount,
        BiochemicalBloodTest,
        Immunology,
        OtherAnalyses
    },
    computed: {
        al(){
            return localStorage.al || '1'
        }
    },
    methods: {
        tabClick(e){
            if(e.name == 1) {
                localStorage.setItem('al', '1');
            } else if(e.name == 2){
                localStorage.setItem('al', '2');
            } else if(e.name == 3){
                localStorage.setItem('al', '3');
            }
        }
    }
}
</script>
