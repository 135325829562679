import Vue from 'vue'
import VueRouter from 'vue-router'
import PassportPart from '@/views/PassportPart/PassportPart'
import Authorization from '@/views/Auth/Authorization'
import Analyses from '@/views/Analyses/Analyses'
import Analytics from '@/views/Analytics/Analytics'
import Scale from '@/views/Scale/Scale'
import Data from '@/views/Data/Data'
import ListDoc from '@/views/AdminModule/element/ListDoc'
import Guide from '@/views/AdminModule/element/Guide'
import Report from '@/views/AdminModule/element/Report'
import Role from '@/views/AdminModule/element/Role'
import ListPatient from '@/views/AdminModule/element/ListPatient'
import Treatment from '@/views/Treatment/Treatment'
import ManifestationOfDisease from '@/views/ManifestationOfDisease/ManifestationOfDisease'
import QualityOfLifeQuestionnaire from '@/views/QualityOfLifeQuestionnaire/QualityOfLifeQuestionnaire'
import PersonalDoctorsOffice from '@/views/PersonalDoctorsOffice/PersonalDoctorsOffice'
import ResetPass from '@/views/ResetPassword/ResPass'

Vue.use(VueRouter)

const routes = [
    // {
    // 	path: '/about',
    // 	name: 'About',
    // 	component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
    {
        path: '/',
        name: 'Authorization',
        component: Authorization
    },
    {
        path: '/user/reset_pswd/:token',
        name: 'ResetPass',
        component: ResetPass
    },
    {
        path: '/PersonalDoctorsOffice',
        name: 'PersonalDoctorsOffice',
        component: PersonalDoctorsOffice
    },
    {
        path: '/PassportPart',
        name: 'PassportPart',
        component: PassportPart
    },
    {
        path: '/ListDoc',
        name: 'ListDoc',
        component: ListDoc
    },
    {
        path: '/ListPatient',
        name: 'ListPatient',
        component: ListPatient
    },
    {
        path: '/Guide',
        name: 'Guide',
        component: Guide
    },
    {
        path: '/Report',
        name: 'Report',
        component: Report
    },
    {
        path: '/Role',
        name: 'Role',
        component: Role
    },
    {
        path: '/Analyses',
        name: 'Analyses',
        component: Analyses
    },
    {
        path: '/Analytics',
        name: 'Analytics',
        component: Analytics
    },
    {
        path: '/Scale',
        name: 'Scale',
        component: Scale
    },
    {
        path: '/Data',
        name: 'Data',
        component: Data
    },
    {
        path: '/Treatment',
        name: 'Treatment',
        component: Treatment
    },
    {
        path: '/ManifestationOfDisease',
        name: 'ManifestationOfDisease',
        component: ManifestationOfDisease
    },
    {
        path: '/QualityOfLifeQuestionnaire',
        name: 'QualityOfLifeQuestionnaire',
        component: QualityOfLifeQuestionnaire
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router