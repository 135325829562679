var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[(_vm.user.role !== 2)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"#6374dd","dark":""},on:{"click":function($event){_vm.dialog=true}}},[_vm._v("Добавить сопутствующее заболевание")]):_vm._e()],1),_c('v-data-table',{attrs:{"no-results-text":"Поиск не дал результатов","headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"no-data-text":"Cопутствующее заболевание отсутствует...","hide-default-footer":"","loading-text":"Загрузка... Пожалуйста подождите..."},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.checkRole)?_c('v-btn',{attrs:{"color":"#6374dd","x-small":"","icon":""},on:{"click":function($event){return _vm.editItems(item)}}},[_c('v-icon',{staticClass:"w-20"},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.user.role !== 2 && (new Date().getTime() - item.creation_date < 86400000))?_c('v-btn',{attrs:{"color":"error","x-small":"","icon":""},on:{"click":function($event){return _vm.deleteItems(item, _vm.list)}}},[_c('v-icon',{staticClass:"w-20"},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.date_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date_start).toLocaleDateString())+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"#6374dd"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Сопутствующее заболевание")]),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.save(_vm.obj)}}},[_vm._v("Сохранить")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#6b59c7"}})],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{ref:"tf0",attrs:{"outlined":"","rules":[_vm.rules.required],"color":"#6b59c7","label":"Название заболевания *"},model:{value:(_vm.obj.diagnosis),callback:function ($$v) {_vm.$set(_vm.obj, "diagnosis", $$v)},expression:"obj.diagnosis"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({ref:"tf1",attrs:{"outlined":"","rules":[_vm.rules.required],"label":"Дата начала *","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFormattedStart),callback:function ($$v) {_vm.dateFormattedStart=$$v},expression:"dateFormattedStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",attrs:{"color":"#6b59c7","first-day-of-week":1,"max":_vm.checkMaxDateStart},on:{"change":_vm.saveDate},model:{value:(_vm.obj.date_start),callback:function ($$v) {_vm.$set(_vm.obj, "date_start", $$v)},expression:"obj.date_start"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-menu',{ref:"menuEnd",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"disabled":_vm.obj.end_date.checkbox,"outlined":"","label":"Дата окончания","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFormattedEnd),callback:function ($$v) {_vm.dateFormattedEnd=$$v},expression:"dateFormattedEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuEnd),callback:function ($$v) {_vm.menuEnd=$$v},expression:"menuEnd"}},[_c('v-date-picker',{ref:"picker",attrs:{"color":"#6b59c7","first-day-of-week":1,"min":_vm.checkMinDateEnd,"max":_vm.maxDate},on:{"change":_vm.saveDateEnd},model:{value:(_vm.obj.end_date.date),callback:function ($$v) {_vm.$set(_vm.obj.end_date, "date", $$v)},expression:"obj.end_date.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-checkbox',{attrs:{"outlined":"","color":"#6b59c7","label":"По настоящее время"},on:{"change":function($event){return _vm.resetDateEnd(_vm.obj.end_date.checkbox)}},model:{value:(_vm.obj.end_date.checkbox),callback:function ($$v) {_vm.$set(_vm.obj.end_date, "checkbox", $$v)},expression:"obj.end_date.checkbox"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"outlined":"","rows":"4","color":"#6b59c7","label":"Комментарий"},model:{value:(_vm.obj.comment),callback:function ($$v) {_vm.$set(_vm.obj, "comment", $$v)},expression:"obj.comment"}})],1)],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"rounded":"pill","elevation":"24","color":_vm.snackbarColor,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }