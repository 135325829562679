import _ from 'lodash'
export default {
    namespaced: true,
    state: {
        uzi: [],
        rengen: [],
        kt: [],
        mrt: [],
        statements: [],
    },
    getters: {
        dataUzi: state => state.uzi,
        dataRengen: state => state.rengen,
        dataKt: state => state.kt,
        dataMrt: state => state.mrt,
        dataStatements: state => state.statements,
    },
    mutations: {
        setData(state, data){
            let dat = _.groupBy(data, 'type_id')
            state.uzi = dat[1] ? dat[1] : []
            state.rengen = dat[2] ? dat[2] : []
            state.kt = dat[3] ? dat[3] : []
            state.mrt = dat[4] ? dat[4] : []
        },
        setDataStat(state, data){
            state.statements = data
        },
        save(state, data){
            if(data.type_id === 1){
                state.uzi.push(data)
            } else if(data.type_id === 2) {
                state.rengen.push(data)
            } else if(data.type_id === 3) {
                state.kt.push(data)
            } else if(data.type_id === 4) {
                state.mrt.push(data)
            }
        },
        saveStat(state, data){
            state.statements.push(data)
        },
        del(state, data){
            let index;
            if(data.type_id === 1){
                index = state.uzi.findIndex(uzi => uzi.id == data.id)
                state.uzi.splice(index, 1)
            } else if(data.type_id === 2) {
                index = state.rengen.findIndex(rengen => rengen.id == data.id)
                state.rengen.splice(index, 1)
            } else if(data.type_id === 3) {
                index = state.kt.findIndex(kt => kt.id == data.id)
                state.kt.splice(index, 1)
            } else if(data.type_id === 4) {
                index = state.mrt.findIndex(mrt => mrt.id == data.id)
                state.mrt.splice(index, 1)
            }
        },
        delStat(state, data){
            let index = state.statements.findIndex(statements => statements.id == data.id)
            state.statements.splice(index, 1)
        },
    },
    actions: {
        //Получение списка всех методов
        getData({commit, rootState}) {
            let dat_us = rootState.user.patient;
            this.api.get(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/researches`).then(res => {
                commit('setData', res.data)
            })
        },
        //Общий метод загрузки/удаления Инструментальных
        create({commit, rootState}, payload) {
            //console.log(payload)
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/researches`, payload).then(res=>{
                commit('save', res.data)
            })
        },
        delete({commit, rootState}, payload) {
            let id_stroke = payload.id || payload.__KEY__
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/researches/${id_stroke}`, payload).then(res=>{
                commit('del', payload)
            })
        },
        //Выписка
        getDataStat({commit, rootState}) {
            let dat_us = rootState.user.patient;
            this.api.get(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/research-records`).then(res => {
                commit('setDataStat', res.data)
            })
        },
        createStat({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/research-records`, payload).then(res=>{
                commit('saveStat', res.data)
            })
        },
        deleteStat({commit, rootState}, payload) {
            let id_stroke = payload.id || payload.__KEY__
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/research-records/${id_stroke}`, payload).then(res=>{
                commit('delStat', payload)
            })
        },
    }
}