let passportPartLocal = JSON.parse(localStorage.getItem('passportPart')) || {};
let state =  {
	patient_data: {},
    information_about_parents: {},
    family_history: {},
    anamnez : {},
    diagnosys: {},
    diagsynonim: {},
    soputzab: {},
    key_mobile: '',
    key_update_component: 0,
    notificationsPatient: []
};
Object.keys(state).forEach(key => state[key] = passportPartLocal[key] ? passportPartLocal[key] : state[key]);
export default {
    namespaced: true,
    state,
    getters: {
        soputzab: state => state.soputzab,
        patientData: state => state.patient_data,
        infoAboutParent: state => state.information_about_parents,
        familyHistory: state => state.family_history,
        anamnez: state => state.anamnez,
        diagnosys: state => state.diagnosys,
        diagsynonim: state => state.diagsynonim,
        keyMobile: state => state.key_mobile,
        keyUpdateComponent: state => state.key_update_component,
        notificationsPatient: state => state.notificationsPatient,
    },
    mutations: {
        setNotificationsPatient(state, payload) {
            state.notificationsPatient = payload
        },

        //Данные о пациенте
        //Данные о пациенте
        //Данные о пациенте
        setPatData(state, payload) {
            state.patient_data = payload
        },
        savePatData(state, payload) {
            state.patient_data = payload
        },
        //Анамнез
        //Анамнез
        //Анамнез
        setAnamnez(state, payload) {
            if(payload === null) {
                state.anamnez = {
                    date_disease: '',
                    date_diagnosis: ''
                }
            } else {
                state.anamnez = payload
            }
        },
        saveAnamnez(state, payload) {
            state.anamnez = payload
        },
        //Данные о родителе (опекуне)
        //Данные о родителе (опекуне)
        //Данные о родителе (опекуне)
        setInfoAboutPar(state, payload) {
            state.information_about_parents = payload;
        },
        saveInfoAbPar(state, payload) {
            state.information_about_parents = payload
        },
        //Семейный Анамнез
        //Семейный Анамнез
        //Семейный Анамнез
        setGetFamilyHis(state, payload) {
            state.family_history = payload
        },
        setFamilyHis(state, payload) {
            state.family_history.radioart = payload.radioart,
            state.family_history.radiobobolrey = payload.radiobobolrey,
            state.family_history.radiobolbeh = payload.radiobolbeh,
            state.family_history.radiobouveit = payload.radiobouveit,
            state.family_history.radiokron = payload.radiokron,
            state.family_history.radiopsor = payload.radiopsor,
            state.family_history.radioyazkol = payload.radioyazkol,
            state.family_history.valueart = payload.valueart,
            state.family_history.valuebobolrey = payload.valuebobolrey,
            state.family_history.valuebolbeh = payload.valuebolbeh,
            state.family_history.valuebouveit = payload.valuebouveit,
            state.family_history.valuepsor = payload.valuepsor,
            state.family_history.valueyazkol = payload.valueyazkol,
            state.family_history.valuekron = payload.valuekron
        },
        // Диагноз по заключению врача
        // Диагноз по заключению врача
        // Диагноз по заключению врача
        setDiagnosys(state, payload) {
            state.diagnosys = payload;
        },
        setDiagSinonym(state, payload) {
            function compare( a, b ) {
                if ( a.synonym < b.synonym ){
                    return -1;
                }
                if ( a.synonym > b.synonym ){
                    return 1;
                }
                return 0;
            }
            payload.sort(compare);
            state.diagsynonim = payload;
        },
        updatePutDiagnosys(state, payload) {
            state.diagnosys.diagnosis_id = payload.diagnosis_id
            state.diagnosys.comment = payload.comment
        },
        setPostDiagnosys(state, payload) {
            state.diagnosys.push(payload)
        },
        //Сопутствующие заболевания
        //Сопутствующие заболевания
        //Сопутствующие заболевания
        setSoputzab(state, payload) {
            state.soputzab = payload;
        },
        updatePutSoputzab(state, payload) {
            state.soputzab.comment = payload.comment
            state.soputzab.date_start = payload.date_start
            state.soputzab.diagnosis = payload.diagnosis
            state.soputzab.end_date = payload.end_date
        },
        setPostSoputzab(state, payload) {
            state.soputzab.push(payload)
        },
        setKey(state, payload) {
            state.key_mobile = payload
        },
        //Обновление компонента при переходе на пациента в уведомлениях
        setkeyUpdateComponent(state, payload) {
            state.key_update_component++;
        }
    },
    actions: {
        getNotificationsPatient({commit, rootState}) {  
            let dat_us = rootState.user.patient;          
			this.api.get(`${rootState.apiUrl}/api/schedulednotification/notificationToPatients/${dat_us.patients_id}`).then(res => {
				commit('setNotificationsPatient', res.data);
			})
        },

        deleteNotificationsPatient({commit, rootState}, payload) {  
            let dat_us = rootState.user.patient;          
			this.api.delete(`${rootState.apiUrl}/api/schedulednotification/notificationToPatients/${payload}`)
        },

        //Информация авторизован пользователь или нет
        //Информация авторизован пользователь или нет
        //Информация авторизован пользователь или нет
        getUserInfo({rootState}) {
            return new Promise((resolve, reject)=>{
                this.api.get(`${rootState.apiUrl}/api/auth/user`).then(res => {
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        // Диагноз по заключению врача
        // Диагноз по заключению врача
        // Диагноз по заключению врача
        getDiagnosys ({commit, rootState}) {
            let dat_us = rootState.user.patient;
            return new Promise((resolve, reject)=>{
                this.api.get(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/diagnoses`).then(res => {
                    commit('setDiagnosys', res.data)
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        getDiagSinonym ({commit, rootState}) {
            return new Promise((resolve, reject)=>{
                this.api.get(`${rootState.apiUrl}/api/diagnoses`).then(res => {
                    commit('setDiagSinonym', res.data)
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },
        updatePutDiagnosys ({commit, rootState}, payload) {
            let dat_us = rootState.user.patient;
            this.api.put(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/diagnoses/${payload.id}`, payload).then(res => {
                commit('updatePutDiagnosys', res.data)
            })
        },
        deleteDiagnosys ({commit, rootState}, payload) {
            let dat_us = rootState.user.patient;
            this.api.delete(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/diagnoses/${payload.id}`, payload);
        },
        savePostDiagnosys ({commit, rootState}, payload) {
            let dat_us = rootState.user.patient;
            this.api.post(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/diagnoses`, payload).then(res => {
                commit('setPostDiagnosys', res.data)
            })
        },
        //Анамнез
        //Анамнез
        //Анамнез
        getAnamnez ({commit, rootState}) {
            let dat_us = rootState.user.patient;
            this.api.get(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/anamnesis`).then(res => {
                commit('setAnamnez', res.data)
            })
        },
        saveAnamnez ({commit, rootState}, payload) {
            let dat_us = rootState.user.patient;
            this.api.put(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/anamnesis`, payload).then(res => {
                commit('saveAnamnez', res.data)
            })
        },
        //Данные о пациенте
        //Данные о пациенте
        //Данные о пациенте
        getPatData ({commit, rootState}) {
            let dat_us = rootState.user.patient;
            return new Promise((resolve, reject)=>{
                this.api.get(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}`).then(res => {
                    commit('setPatData', res.data)
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },
        savPatData ({commit, rootState}, payload) {
            //console.log(payload)
            let dat_us = rootState.user.patient;
            this.api.put(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}`, payload).then(res => {
                commit('savePatData', res.data)
            })
        },
        //Данные о родителе (опекуне)
        //Данные о родителе (опекуне)
        //Данные о родителе (опекуне)
        getInformAboutPar({commit, rootState}) {
            let dat_us = rootState.user.patient;
            this.api.get(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/parent`).then(res => {
                commit('setInfoAboutPar', res.data)
            })
        },
        savInformAboutPar ({commit, rootState}, payload) {
            let dat_us = rootState.user.patient;
            this.api.put(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/parent`, payload).then(res => {
                commit('saveInfoAbPar', res.data)
            })
        },
        //Семейный Анамнез
        //Семейный Анамнез
        //Семейный Анамнез
        getFamilyHis({commit, rootState}) {
            let dat_us = rootState.user.patient;
            this.api.get(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/famhistory`).then(res => {
                commit('setGetFamilyHis', res.data)
            })
        },
        savFamilyHis ({commit, rootState}, payload) {
            //console.log(payload)
            let dat_us = rootState.user.patient;
            this.api.put(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/famhistory`, payload).then(res => {
                commit('setFamilyHis', res.data)
            })
        },
        //Сопутствующие заболевания
        //Сопутствующие заболевания
        //Сопутствующие заболевания
        getSoputzab ({commit, rootState}) {
            let dat_us = rootState.user.patient;
            this.api.get(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/concomitants`).then(res => {
                commit('setSoputzab', res.data)
            })
        },
        updatePutSoputzab ({commit, rootState}, payload) {
            let dat_us = rootState.user.patient;
            this.api.put(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/concomitants/${payload.id}`, payload).then(res => {
                commit('updatePutSoputzab', res.data)
            })
        },
        deleteSoputzab ({rootState}, payload) {
            let dat_us = rootState.user.patient;
            this.api.delete(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/concomitants/${payload.id}`, payload)
        },
        savePostSoputzab ({commit, rootState}, payload) {
            let dat_us = rootState.user.patient;
            this.api.post(`${rootState.apiUrl}/api/patients/${dat_us.patients_id}/concomitants`, payload).then(res => {
                commit('setPostSoputzab', res.data)
            })
        },
        //Обновление компонента при переходе на пациента в уведомлениях
        updateComponent({commit}, payload){
            commit('setkeyUpdateComponent', payload);
        },
        //Смена пароля
        //Смена пароля
        //Смена пароля
        changePass ({commit, rootState}, payload) {
            return new Promise((resolve, reject)=>{
                this.api.post(`${rootState.apiUrl}/changepassword`, payload).then(res => {
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        //Генерация кода для привязки к телефону
        //Генерация кода для привязки к телефону
        //Генерация кода для привязки к телефону
        getKey ({commit, rootState}) {            
            this.api.get(`${rootState.apiUrl}/mobilekey`).then(res => {
                commit('setKey', res.data)
            })
        },
        //Выход со всех устройств
        //Выход со всех устройств
        //Выход со всех устройств
        outDevice ({rootState}) {
            return new Promise((resolve, reject)=>{
                this.api.post(`${rootState.apiUrl}/changemobilekey`).then(res => {
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
    }
}
