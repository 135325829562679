<template>
	<v-container fluid>
		<v-row justify="center" align="center">
			<v-col class="" xs="12" sm="12" md="12" lg="12" xl="12">

                <v-tabs v-model="tab" background-color="#5D4CB8" centered dark icons-and-text >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab v-for="item in arrayTabs" :key="item.id" :href="'#tab-'+item.id">
                        {{item.name}}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">

                    <!-- КОНСТРУКТОР РОЛЕЙ -->
                    <v-tab-item value="tab-1" >
                        <v-card class="mt-10 ml-1 mr-1 mb-1">
                            <v-card-title>
                                <v-btn color="#6374dd" @click="dialog = !dialog" dark class="ml-4">Добавить</v-btn>
                                
                                <v-spacer></v-spacer>

                                <v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
                            </v-card-title>

                            <v-data-table
                                no-results-text="Поиск не дал результатов"
                                :headers="headers"
                                :search="search"
                                :items="list"
                                :loading="loadingTable"
                                sort-by="date_new"
                                :sort-desc="true"
                                no-data-text="Данные отсутствуют..."
                                loading-text="Загрузка... Пожалуйста подождите..."
                            >
                                <template v-slot:[`item.action`]="{ item }">
                                    <v-btn @click="editeItem(item)" color="#6374dd" x-small icon>
                                        <v-icon class="w-20">mdi-pencil</v-icon>
                                    </v-btn>
                                </template>

                                <!-- РЕГИОНЫ -->
                                <template v-slot:[`item.Regions`]="{ item }">
                                    <v-menu v-model="item.flagReg" :close-on-content-click="false" :nudge-width="200" offset-x >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-show="item.RegionCount > 0" small text color="indigo" dark v-bind="attrs" v-on="on" >{{item.RegionCount}} регион(а/ов)</v-btn>
                                        </template>

                                        <v-card>
                                            <v-data-table
                                                hide-default-footer
                                                sort-by="Name"
                                                :sort-ask="true"
                                                :headers="[{text: 'Список регионов', value: 'Name', align: 'center' }]"
                                                :items="item.Regions"
                                            />
                                        </v-card>
                                    </v-menu>
                                </template>
                                
                                <!-- ОРГАНИЗАЦИИ -->
                                <template v-slot:[`item.Hospitals`]="{ item }">
                                    <v-menu v-model="item.flagHosp" :close-on-content-click="false" :nudge-width="200" offset-x >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-show="item.HospitalCount > 0" small text color="indigo" dark v-bind="attrs" v-on="on" >{{item.HospitalCount}} организаци(и/я)</v-btn>
                                        </template>

                                        <v-card>
                                            <v-data-table
                                                hide-default-footer
                                                sort-by="Name"
                                                :sort-ask="true"
                                                :headers="[{text: 'Организация', value: 'Name', align: 'center' }]"
                                                :items="item.Hospitals"
                                            />
                                        </v-card>
                                    </v-menu>
                                </template>
                                
                                <!-- Врачи -->
                                <template v-slot:[`item.Doctors`]="{ item }">
                                    <v-menu v-model="item.flagDoc" :close-on-content-click="false" :nudge-width="200" offset-x >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-show="item.DoctorCount > 0" small text color="indigo" dark v-bind="attrs" v-on="on" >{{item.DoctorCount}} врач(а)</v-btn>
                                        </template>

                                        <v-card>
                                            <v-data-table
                                                hide-default-footer
                                                sort-by="Name"
                                                :sort-ask="true"
                                                :headers="[{text: 'ФИО', value: 'Name', align: 'center' }]"
                                                :items="item.Doctors"
                                            />
                                        </v-card>
                                    </v-menu>
                                </template>

                                <template v-slot:[`item.PersonalInformation`]="{ item }">
                                    {{ item.PersonalInformation ? 'Открыты' : 'Скрыты' }}
                                </template>
                                
                                <template v-slot:[`item.CanSet`]="{ item }">
                                    {{ item.CanRead && item.CanSet ? 'Просмотр, Редактирование' : ( item.CanSet ? 'Просмотр' : (item.CanRead ? 'Редактирование' : '') ) }}
                                </template>


                            </v-data-table>
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>
			</v-col>
		</v-row>

        <v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()" :disabled="loading">
						<v-icon>mdi-close</v-icon>
					</v-btn>

					<v-toolbar-title> {{ editeOn ? 'Редактировать' : 'Добавить' }}</v-toolbar-title>
					
                    <div class="flex-grow-1"></div>

					<v-toolbar-items>
						<v-btn @click="save(obj)" :loading="loading" dark text>Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>				
					<v-container>
						<v-form ref="form" v-model="valid" lazy-validation>
							<v-row class="mt-2">
								<v-col :cols="12" :sm="12" :md="12">
                                    <v-text-field color="#5D4CB8" v-model="obj.Name" dense hide-details label="Роль *" outlined />
								</v-col>
                                <v-col class="pb-0" :cols="12" :sm="12" :md="12">
                                    <h3>Доступ к пациентам, которые относятся к:</h3>
                                </v-col>
                                <v-col :cols="12" :sm="12" :md="12">
                                    <v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/regions?lookupName=regions', 'regionsItems', false, 'isLoadingR')"
                                        :loading="isLoadingR"
                                        v-model="obj.Regions"
                                        item-text="Name"
                                        item-value="id"
                                        hide-no-data
                                        hide-selected
                                        clearable
                                        multiple
                                        hide-details
                                        dense
                                        :items="regionsItems"
                                        label="Регионы"
                                        outlined
                                        :search-input.sync="searchInputRegions"
                                        @change="searchInputRegions = '' "
                                    />
                                </v-col>
                                <v-col :cols="12" :sm="12" :md="12">
                                    <v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/hospitals?lookupName=hospitals', 'hospitalsItems', false, 'isLoadingH')"
                                        :loading="isLoadingH"
                                        v-model="obj.Hospitals"
                                        item-text="Name"
                                        item-value="id"
                                        hide-no-data
                                        hide-selected
                                        clearable
                                        multiple
                                        hide-details
                                        dense
                                        :items="hospitalsItems"
                                        label="Организация"
                                        outlined
                                        :search-input.sync="searchInputHospitals"
                                        @change="searchInputHospitals = '' "
                                    />
                                </v-col>
                                <v-col :cols="12" :sm="12" :md="12">
                                    <v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/doctors?lookupName=doctors', 'doctorsItems', false, 'isLoadingD')"
                                        :loading="isLoadingD"
                                        v-model="obj.Doctors"
                                        item-text="Name"
                                        item-value="Id"
                                        hide-no-data
                                        hide-selected
                                        clearable
                                        multiple
                                        hide-details
                                        dense
                                        :items="doctorsItems"
                                        label="Врач"
                                        outlined
                                        :search-input.sync="searchInputDoctors"
                                        @change="searchInputDoctors = '' "
                                    />
                                </v-col>
                                <v-col :cols="12" :sm="12" :md="12">
                                    <v-radio-group label="Персональные данные:" class="pt-0" dense hide-details v-model="obj.PersonalInformation" row>
                                        <v-radio color="#5D4CB8" label="Скрыты" :value="false" />
                                        <v-radio color="#5D4CB8" label="Открыты" :value="true" />
                                    </v-radio-group>
                                </v-col>
                                <v-col :cols="12" :sm="12" :md="12">
                                    <v-row>
                                        <v-col md="auto">
                                            <legend class="v-label theme--light pt-1">
                                                Доступ к данным:
                                            </legend>
                                        </v-col>
                                        <v-col md="auto">
                                            <v-checkbox class="mt-0 pt-0" color="#5D4CB8" dense hide-details v-model="obj.CanSet" label="Просмотр" />
                                        </v-col>
                                        <v-col md="auto">
                                            <v-checkbox class="mt-0 pt-0" color="#5D4CB8" dense hide-details v-model="obj.CanRead" label="Редактирование" />
                                        </v-col>
                                    </v-row>
                                </v-col>
							</v-row>
						</v-form> 
					</v-container>				
				</v-card-text>
			</v-card>
		</v-dialog>

        <v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        searchInputDoctors: null,
        searchInputHospitals: null,
        searchInputRegions: null,
        headers: [
            { text: "Роль", value: "Name", align: 'center' },
            { text: "Кол-во регионов", value: "Regions", align: 'center', sortable: false },
            { text: "Организация", value: "Hospitals", align: 'center', sortable: false },
            { text: "Врач", value: "Doctors", align: 'center', sortable: false },
            { text: "Персональные данные", value: "PersonalInformation", align: 'center', sortable: false },
            { text: "Доступ к данным", value: "CanSet", align: 'center', sortable: false },
            { text: "", value: "action", align: 'center', sortable: false }
        ],
        isLoadingR: false,
        regionsItems: [],
        isLoadingH: false,
        hospitalsItems: [],
        isLoadingD: false,
        doctorsItems: [],
        obj: {
            Name: '',
            Regions: [],
            RegionCount: null,
            Hospitals: [],
            HospitalCount: null,
            Doctors: [],
            DoctorCount: null,
            PersonalInformation: false,
            CanSet: true,
            CanRead: false
        },
        editeOn: false,
        dialog: false,
        valid: false,
        loading: false,
        loadingTable: true,
        list: [],
        search: '',
        snackbar: false,
        snackbar_text: '',
        snackbarColor: '',
        tab: '#tab-1',
        arrayTabs: [
            {id:1, name: 'Конструктор ролей'},
            // {id:2, name: 'Шаблоны'}
        ]
    }),
    computed: {
        ...mapGetters({
            apiUrl: 'apiUrl'
        })
    },
    methods: {
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
        getList(url, name, flag, isLoading) {
            if(this[name].length > 0) return;

            this[isLoading] = true

            this.api.get(`${this.apiUrl}/${url}`)
            .then(res => {
                this[isLoading] = false
                
                this[name] = name == 'regionsItems' ? res.data.sort((a, b) => (a.Name < b.Name) ? -1 : ((b.Name > a.Name) ? 1 : 0)) : res.data
            })
        },
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
        get() {
            this.api.get(`${this.apiUrl}/api/roles/all`)
            .then(res => {
                res.data.forEach(el => {
                    el.flagReg = false
                    el.flagHosp = false
                    el.flagDoc = false
                })

                this.loadingTable = false

                this.list = res.data
            })
        },
        close() {
            this.obj = {
                Name: '',
                Regions: [],
                Hospitals: [],
                Doctors: [],
                PersonalInformation: false,
                CanSet: true,
                CanRead: false
            }
            this.dialog = false
            this.loading = false
            this.editeOn = false
        },
        save(obj) {
            if(!obj.Name) {
                this.snackBarFunc(true, 'Заполните название Роли!!!', 'error')

                return;
            }
            
            if(!obj.CanSet && !obj.CanRead) {
                this.snackBarFunc(true, 'Выберите доступ к данным!!!', 'error')

                return;
            }

            if(obj.Doctors.length !== 0 || obj.Regions.length !== 0 || obj.Hospitals.length !== 0) {
                this.loading = true

                this.api[this.editeOn ? 'put' : 'post'](`${this.apiUrl}/${this.editeOn ? 'api/roles/set/'+obj.Id : 'api/roles/create'}`, obj)
                .then(res => {
                    if(res.status == 200) {
                        this.get()
                        this.snackBarFunc(true, `Роль успешно ${this.editeOn ? 'отредактирована' : 'создана'}`, 'success')
                        this.close()
                    }
                })
            } else {
                this.snackBarFunc(true, 'Заполните одно из обязательных полей (Регион, Организация, Врач)', 'error')
            }
        },
        editeItem(item) {

            this.getList('api/lookups/regions?lookupName=regions', 'regionsItems', true, 'isLoadingR')
            this.getList('api/lookups/hospitals?lookupName=hospitals', 'hospitalsItems', false, 'isLoadingH')
            this.getList('api/lookups/doctors?lookupName=doctors', 'doctorsItems', false, 'isLoadingD')
            
            this.obj = Object.assign({}, item)

            this.obj.Regions = this.obj.Regions.map(m => m.Id)
            // this.obj.RegionCount = this.obj.Regions.length
            
            this.obj.Hospitals = this.obj.Hospitals.map(m => m.Id)
            // this.obj.HospitalCount = this.obj.Hospitals.length

            this.obj.Doctors = this.obj.Doctors.map(m => m.Id)
            // this.obj.DoctorCount = this.obj.Doctors.length

            this.editeOn = true
            this.dialog = true
        }
    },
    created() {
        this.get()
    }
}
</script>

<style>
    .v-input--radio-group legend.v-label {
        font-size: 17px!important
    }
</style>