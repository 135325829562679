<template>
	<v-card>
		<v-card-title>
			{{ headerName }}
			<v-btn @click="dialog=true" color="#6374dd" dark class="ml-4">Добавить пациента</v-btn>
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table
			no-results-text="Поиск не дал результатов"
			:headers="headers"
			:items="list"
			:search="search"
			:loading="loading"
			no-data-text="Данных нет"
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="item.myPatient || !user.roleId ? true : user.CanRead" @click="editItems(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn @click="openReportFunc(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-clipboard-list-outline</v-icon>
				</v-btn>
				
				<v-btn @click="goPatient(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-card-account-details-outline</v-icon>
				</v-btn>

				<v-btn @click="NotificationToPatient(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-bell</v-icon>
				</v-btn>
			</template>
		</v-data-table>


		<v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Данные пациента</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation >
							<v-row>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf0" v-model="obj.last_name" :rules="[rules.required]" label="Фамилия *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf1" v-model="obj.first_name" :rules="[rules.required]" label="Имя *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf2" v-model="obj.patronymic" :rules="[rules.required]" label="Отчество *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf3" v-model="obj.email" :rules="[rules.required, rules.email]" label="E-mail *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf4" v-model="obj.phone" :rules="[rules.required, rules.phone]" v-mask="'+7 (###) ###-####'" placeholder="+7 (___) ___-____" label="Телефон *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field dense outlined hide-details ref="tf5" :rules="[rules.required]" :value="dateFormatted" label="Дата рождения *" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
										</template>

										<v-date-picker color="#6374dd" ref="picker" :first-day-of-week="1" v-model="obj.birth_date" :max="new Date().toISOString().substr(0, 10)" @change="saveDate" />
									</v-menu>
								</v-col>
								<v-col md="6">
									<v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/regions?lookupName=regions', 'regionsItems', false, 'isLoadingR')"
                                        :loading="isLoadingR"
                                        v-model="obj.regionId"
                                        item-text="Name"
										ref="tf9"
                                        item-value="id"
										return-object
                                        hide-selected
                                        clearable
										:rules="[rules.required]"
                                        hide-details
                                        dense
                                        :items="regionsItems"
                                        label="Регион *"
                                        outlined
                                    />
								</v-col>
								<v-col md="6">
									<v-select :rules="[rules.required]" v-model="obj.gender" :items="['Мужчина', 'Женщина']" label="Пол *" dense outlined hide-details />
								</v-col>
								<v-col md="12">
									<v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/hospitals?lookupName=hospitals', 'hospitalsItems', false, 'isLoadingH')"
                                        :loading="isLoadingH"
                                        v-model="obj.hospitalId"
                                        item-text="Name"
                                        item-value="id"
										ref="tf10"
                                        hide-selected
										return-object
										:rules="[rules.required]"
                                        clearable
                                        hide-details
                                        dense
                                        :items="hospitalsItems"
                                        label="Организация *"
                                        outlined
                                    />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog persistent v-model="NotificationToPatientDialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="closeNotificationToPatientDialog()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{NotificationToPatientDialogTitle}}</v-toolbar-title>
				</v-toolbar>
				<v-card-text class="pt-0 pl-0 pr-0 pb-0">
					<table-notification-to-patient :notificationToPatientId="NotificationToPatientId" :randomChangedValue="randomChangedValue"/>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Окно формирование отчета -->
		<modal-generate-report
			:openReport="openReport"
			v-on:update:openReport="openReport = $event"
			:user="patient"
			:birthDate="new Date(objPatient.birth_date).getTime()"
			:apiUrl="apiUrl"
			:doctor="patientData.doctor"
			:doctorFlag="true"
			:objPatient="objPatient"
		/>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import {mask} from 'vue-the-mask'
import TableNotificationToPatient from './tableNotificationToPatient.vue'
import modalGenerateReport from '../../Header/modalGenerateReport.vue'

export default {
	components: { TableNotificationToPatient, modalGenerateReport },
	directives: {mask},
	props: {
		headerName: {type: String, default: () => '', require: true}
	},
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			user: 'user/user',
			patient: 'user/patient',
			patientData: 'passportPart/patientData'
        }),
		dateFormatted() {
            if(this.obj.birth_date)
                return new Date(this.obj.birth_date).toLocaleDateString("ru-RU");
            return null;
        }
	},
	watch: {
		menu (val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
		},
    },
	data() {
		return {
			objPatient: {id: null, fio: ''},
			openReport: false,
			isLoadingR: false,
			isLoadingH: false,
			regionsItems: [],
			hospitalsItems: [],
			menu: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				regionId: null,
				hospitalId: null,
				DefaultLabProfileId: null,
				address: '',
				birth_date: null,
				diag: '',
				doctor: '',
				doctor_fio: null,
				email: '',
				first_name: '',
				full_name: null,
				gender: '',
				id: null,
				invalid: true,
				last_name: '',
				not_invalid_reason: '',
				patronymic: '',
				phone: ''
			},
			dialog: false,
			NotificationToPatientDialog: false,
			NotificationToPatientDialogTitle: null,
			NotificationToPatientId: '',
			list: [],
			loading: true,
			search: "",
			rules: {
				required: value => !!value || 'Обязательно для заполнения.',
				phone: val => val && val.length == 17 || 'Некорректный формат телефона',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Некорректный e-mail.'
				}
			},
			headers: [
				{ text: "Регион", value: "regionName", align: 'center' },
				{ text: "Организация", value: "hospitalName", align: 'center' },
				{ text: "ФИО пациента", value: "fio", align: 'center' },
				{ text: "Код МКБ-10", value: "diag", align: 'center' },
                { text: "", value: "action", sortable: false }
			],
			randomChangedValue: new Date()
		};
	},
	methods: {
		openReportFunc(item) {
			// console.log(item)
			this.objPatient = item
			this.openReport = true
		},
		NotificationToPatientId1()
		{
			return this.NotificationToPatientId
		},
        ...mapMutations({
            setSaveUrlBack: 'user/setSaveUrlBack'
        }),
        ...mapActions({
            setPatient: 'user/setPatient'
        }),
		close() {
			this.$refs.form.resetValidation()
			for (let i = 0; i < 6; i++) if(this.$refs[`tf${i}`]) this.$refs[`tf${i}`].reset()
			this.obj = {
				DefaultLabProfileId: null,
				address: '',
				birth_date: null,
				diag: '',
				doctor: '',
				doctor_fio: null,
				email: '',
				first_name: '',
				full_name: null,
				gender: '',
				id: null,
				invalid: true,
				last_name: '',
				not_invalid_reason: '',
				patronymic: '',
				phone: ''
			}
			this.dialog = false
		},
		closeNotificationToPatientDialog(){
			this.NotificationToPatientDialog = false
		},
        getList(url, name, flag, isLoading) {
            if(this[name].length > 0) return;

            this[isLoading] = true

            this.api.get(`${this.apiUrl}/${url}`)
            .then(res => {
                this[isLoading] = false

				if(flag) res.data.filter(f => !f.IsHidden)
                
                this[name] = res.data
            })
        },
		async apiMethods(url, name) {
			this.loading = false
			let res = (await this.api.get(`${url}/${name}`)).data
			await res.sort((a,b) => (a.last_name > b.last_name) ? 1 : ((b.last_name > a.last_name) ? -1 : 0))

			res.forEach(el => {
				el.birth_date = new Date(el.birth_date).toISOString().substr(0, 10)

				if(this.user.roleId){
					
					// console.log(el.doctor, this.user.id)

					if(el.doctor !== this.user.id) {
						// console.log(1);
						el.last_name = this.user.PersonalInformation ? el.last_name : (el.last_name ? this.hideData(el.last_name) : '')
						el.patronymic = this.user.PersonalInformation ? el.patronymic :  (el.patronymic ? this.hideData(el.patronymic) : '')
						el.myPatient = false
					} else {
						el.myPatient = true
					}
				}
				el.fio = `${el.last_name} ${el.first_name} ${el.patronymic}`
			})
			
			// console.log(res)
			
			return res
		},
		editItems(row) {
			this.getList('api/lookups/hospitals?lookupName=hospitals', 'hospitalsItems', false, 'isLoadingH')
			this.getList('api/lookups/regions?lookupName=regions', 'regionsItems', false, 'isLoadingR')
			
			
			this.obj = Object.assign({}, row)
			this.dialog = true
			
			console.log(this.obj)
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				let url = obj.id ? `${this.apiUrl}/doctors/${this.user.id}/patients/${obj.id}` : `${this.apiUrl}/patients`
				this.overlay = true

				obj.birth_date = new Date(obj.birth_date).getTime()
				
				obj.regionName = typeof obj.regionId == 'string' ? obj.regionName : obj.regionId.Name
				obj.regionId = typeof obj.regionId == 'string' ? obj.regionId : obj.regionId.id
				
				obj.hospitalName = typeof obj.hospitalId == 'string' ? obj.hospitalName : obj.hospitalId.Name
				obj.hospitalId = typeof obj.hospitalId == 'string' ? obj.hospitalId : obj.hospitalId.id
				
				if(!obj.id) delete obj['id']

				this.api[obj.id ? 'put' : 'post'](url, obj)
				.then(res => {
					if(!obj.id) {
						//Добавление в массив нового пациента
						this.list.unshift({
							fio: `${obj.last_name} ${obj.first_name} ${obj.patronymic}`,
							id : res.data.id,
							birth_date: new Date(obj.birth_date).toISOString().substr(0, 10),
							email: obj.email,
							phone: obj.phone,
							first_name: obj.first_name,
							last_name: obj.last_name,
							patronymic: obj.patronymic,
							regionName: obj.regionName,
							hospitalName: obj.hospitalName,
							regionId: obj.regionId,
							hospitalId: obj.hospitalId
						})
						
						//привязка нового пациента к доктору
						this.linkToADoctor(res.data.id, {id: res.data.id, doctor: this.user.id})

						this.snackBarFunc(true, 'Пациент успешно создан!!!', 'success')
					} else {
						this.list.forEach(el => {
							if(el.id == res.data.id) {
								Object.assign(el, res.data)
								el.fio = `${res.data.last_name} ${res.data.first_name} ${res.data.patronymic}`
							}
						})
						this.snackBarFunc(true, 'Пациент успешно обновлен!!!', 'success')
					}
					this.overlay = false
					this.dialog = false
					this.close()
				})
				.catch(error => {
					if(error) {
						this.snackBarFunc(true, 'Указанный email уже используется в системе!!!', 'error')
						this.overlay = false
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		saveDate(date) {
			this.$refs.menu.save(date)
		},
		linkToADoctor(idNewUser, obj) {
			this.api.post(`${this.apiUrl}/patients/${idNewUser}/doctors`, obj)
		},
        goPatient(e) {
            this.setSaveUrlBack(this.$route.fullPath);

			this.setPatient({ query_fio: this.$route.query.fio ? this.$route.query.fio : undefined, patients_id: e.id, fio: e.last_name + ' ' +  e.first_name + ' ' +  e.patronymic});
			this.$router.push(`/PassportPart${e.myPatient || e.myPatient == false ? '?myPatient=' + e.myPatient : ''}`);
        },
		NotificationToPatient(item)
		{			
			this.randomChangedValue = new Date()
			this.NotificationToPatientId = item.id
			this.NotificationToPatientDialogTitle = "Уведомления "+item.last_name + " " + item.first_name + " " + item.patronymic
			this.NotificationToPatientDialog = true
		},
        hideData(str) {
            let res = ''

            for (let i = 0; i < str.length; i++) {
                res += '*'
            }

            return res
        }
	},
	async created() {
		if(this.$route.query.id) {
			this.list = await this.apiMethods(this.apiUrl, `doctors/${this.$route.query.id}/patients`)
        } else {
			this.list = await this.apiMethods(this.apiUrl, `api/patients/ByRole`)
        }
	}
};
</script>


<style scoped>

.fs-16 {
	font-size: 16px!important;
}

</style>