<template>
	<v-container fluid>
		<v-row justify="center" align="center" class="tabs-wrap">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<el-tabs :value="tm" @tab-click="tabClick" type="border-card">
					<el-tab-pane name="1" label="Лекарственные препараты, которые получал пациент">
						<medicines-product-preparations />
					</el-tab-pane>
					<el-tab-pane name="2" label="Нежелательные явления препаратов">
						<h4 style="text-align:center;">
							«Любое состояние или заболевание, возникшее на фоне лечения и не относящееся к основному заболеванию»
						</h4>
						<an-undesirable-phenomenon-of-the-drug />
					</el-tab-pane>
					<el-tab-pane name="3" label="Реабилитация">
						<!-- <Rehabilitation /> -->
						<rehabilitations />
					</el-tab-pane>
				</el-tabs>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Rehabilitation from "./grid/Rehabilitation.vue"

import MedicinesProductPreparations from './grid/MedicinesProductPreparations.vue'
import AnUndesirablePhenomenonOfTheDrug from './grid/AnUndesirablePhenomenonOfTheDrug.vue'
import Rehabilitations from './grid/Rehabilitations.vue'

export default {
    components: { Rehabilitation, MedicinesProductPreparations, AnUndesirablePhenomenonOfTheDrug, Rehabilitations },
    computed: {
        tm(){
            return localStorage.tm || '1'
        }
    },
    methods: {
        tabClick(e){
            if(e.name == 1) {
                localStorage.setItem('tm', '1');
            } else if(e.name == 2){
                localStorage.setItem('tm', '2');
            } else if(e.name == 3){
                localStorage.setItem('tm', '3');
            }
        }
    },
}
</script>
