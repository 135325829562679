<template>
	<v-card>
		<v-toolbar flat color="#6b59c7" dark>
			<v-toolbar-title>Справочник</v-toolbar-title>
		</v-toolbar>
		<v-tabs color="#6b59c7" vertical>
			<v-tab v-for="(item,ind) in list" @click="getData(apiUrl, `api/lookups/${item.technicalName}?lookupName=${item.technicalName}`, item)" :key="ind">
				{{item.name}}
			</v-tab>

			<v-tab-item v-for="(item, ind) in list" :key="ind">
				<v-card class="mt-2 mb-2 mr-2 ml-2">
					<v-card-title>
						<div style="color:#6b59c7">{{ item.name }}</div>
						<v-btn @click="addBtn(analysisOn)" rounded color="#6374dd" dark class="ml-4">Добавить параметр</v-btn>
						<v-spacer></v-spacer>

						<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
					</v-card-title>
						<v-data-table no-results-text="Поиск не дал результатов"
							:headers="headers"
							:items="item.children"
							sort-by="groupName"
							:sort-desc="true"
							:search="search"
							no-data-text="Данных нет"
							loading-text="Загрузка... Пожалуйста подождите..."
						>
							<template v-slot:[`item.action`]="{ item }">
								<v-btn @click="editItems(item)" color="#6374dd" x-small icon>
									<v-icon class="w-20">mdi-pencil</v-icon>
								</v-btn>
							</template>
						</v-data-table>
				</v-card>
			</v-tab-item>
		</v-tabs>


		<v-dialog persistent v-model="dialog" max-width="1100px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close(obj)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{editParams ? 'Редактирование' : 'Добавление'}} параметра</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj, analysisOn)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6374dd" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation >
							<v-row>
								<v-col cols="12" sm="8" md="10">
									<v-text-field :rules="[rules.required]" hide-details outlined color="#6b59c7" v-model="obj.name" label="Название параметра" />
								</v-col>
								<v-col cols="12" sm="2" md="2">
									<v-checkbox v-model="obj.IsHidden" color="#6b59c7" label="Спрятать"/>
								</v-col>

								<v-col v-if="analysisOn" class="mt-0 mb-0 pt-0 pb-0" cols="12" sm="12" md="12">
									<v-radio-group v-model="obj.AnalysisGroupId" row>
										<v-radio v-for="item in groupNameAnalusis" :key="item.Id" :label="item.name" :value="item.Id"/>
									</v-radio-group>
								</v-col>

								<v-expand-transition>
									<v-col v-show="analysisOn && obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146'" cols="12" sm="12" md="12">
										<v-card class="mt-2 mb-2">
											<v-data-table no-results-text="Поиск не дал результатов"
												dense
												:items-per-page="-1"
												hide-default-footer
												:headers="headers_referense"
												:items="obj.analysValueList"
												no-data-text="Данных нет"
												loading-text="Загрузка... Пожалуйста подождите..."
											>
												<template v-slot:item.add="{ item, index }">
													<v-btn v-if="obj.analysValueList.length-1 == index" @click="addRowRefParams" color="#6374dd" small icon>
														<v-icon class="w-20">mdi-plus-circle-outline</v-icon>
													</v-btn>
												</template>
												
												<template v-slot:[`item.UnitId`]="{ item }">
													<v-select autofocus :rules="[obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' ? rules.required : true]" hide-details class="mt-2 mb-2" label="Ед. Изм." item-text="name" item-value="id" dense outlined :items="listUnits" v-model="item.UnitId" color="#6b59c7" />
												</template>
												
												<template v-slot:[`item.dz`]="{ item }">
													<v-row justify="center">
														<v-col md="6" lg="6" xl="6">
															<v-text-field :rules="[obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.dz.minValue && item.dz.maxValue ? rules.minNOTmax(item.dz.minValue, item.dz.maxValue) : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.dz.minValue ? rules.requiredNool : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' ? (item.dz.minValue > 0 || item.dz.minValue == 0 ? true : rules.required) : true]" placeholder="От" v-model="item.dz.minValue" dense hide-details outlined color="#6b59c7" />
														</v-col>
														<v-col md="6" lg="6" xl="6">
															<v-text-field :rules="[obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.dz.minValue && item.dz.maxValue ? rules.minNOTmax(item.dz.minValue, item.dz.maxValue) : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.dz.maxValue ? rules.requiredNool : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' ? (item.dz.maxValue > 0 || item.dz.maxValue == 0 ? true : rules.required) : true]" placeholder="До" v-model="item.dz.maxValue" type="number" dense hide-details outlined color="#6b59c7" />
														</v-col>
													</v-row>
												</template>
												
												<template v-slot:[`item.rz`]="{ item }">
													<v-row justify="center">
														<v-col md="6" lg="6" xl="6">
															<v-text-field :rules="[obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.dz.minValue && item.rz.refMin ? rules.minNOTmax(item.dz.minValue, item.rz.refMin) : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.rz.refMin && item.rz.refMax ? rules.minNOTmax(item.rz.refMin, item.rz.refMax) : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.rz.refMin ? rules.requiredNool : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' ? (item.rz.refMin > 0 || item.rz.refMin == 0 ? true : rules.required) : true]" placeholder="От" v-model="item.rz.refMin" dense hide-details outlined color="#6b59c7" />
														</v-col>
														<v-col md="6" lg="6" xl="6">
															<v-text-field :rules="[obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.dz.maxValue && item.rz.refMax ? rules.minNOTmax(item.rz.refMax, item.dz.maxValue) : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.rz.refMin && item.rz.refMax ? rules.minNOTmax(item.rz.refMin, item.rz.refMax) : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.rz.refMax ? rules.requiredNool : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' ? (item.rz.refMax > 0 || item.rz.refMax == 0 ? true : rules.required) : true]" placeholder="До" v-model="item.rz.refMax" type="number" dense hide-details outlined color="#6b59c7" />
														</v-col>
													</v-row>
												</template>
												
												<template v-slot:[`item.age`]="{ item }">
													<v-row>
														<v-col md="6" lg="6" xl="6">
															<v-text-field :rules="[obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.age.ageMin && item.age.ageMax ? rules.minNOTmax(item.age.ageMin, item.age.ageMax) : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.age.ageMin ? rules.requiredNool : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' ? (item.age.ageMin > 0 || item.age.ageMin == 0 ? true : rules.required) : true]" placeholder="От" v-model="item.age.ageMin" dense hide-details outlined color="#6b59c7" />
														</v-col>
														<v-col md="6" lg="6" xl="6">
															<v-text-field :rules="[obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.age.ageMin && item.age.ageMax ? rules.minNOTmax(item.age.ageMin, item.age.ageMax) : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' && item.age.ageMax ? rules.requiredNool : true, obj.AnalysisGroupId !== '16eb8dae-3ccc-46b1-97c5-658ed47f5146' ? (item.age.ageMax > 0 || item.age.ageMax == 0 ? true : rules.required) : true]" placeholder="До" v-model="item.age.ageMax" type="number" dense hide-details outlined color="#6b59c7" />
														</v-col>
													</v-row>
												</template>

												<template v-slot:item.action="{ item, index }">
													<v-btn v-if="index !== 0" color="error" @click="delRef(index,item.id)" x-small icon>
														<v-icon class="w-20">mdi-delete</v-icon>
													</v-btn>
												</template>
											</v-data-table>
										</v-card>
									</v-col>
								</v-expand-transition>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			apiUrlCheckValue: 'apiUrlCheckValue',
			apiUrl: 'apiUrl',
        }),
		swatchStyle() {
			const { color, menu } = this
			return {
				backgroundColor: color,
				cursor: 'pointer',
				height: '25px',
				width: '30px',
				borderRadius: menu ? '50%' : '4px',
				transition: 'border-radius 200ms ease-in-out'
			}
		}
	},
	data() {
		return {
			checkItems: [],
			technicalName: null,
			listUnits: [],
			analysisOn: false,
			menu: false,
			editParams: false,
			overlay: true,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			list_ref_default: [
				{
					id: null,
					AnalysisGroupId: null,
					AnalysisId: null,
					UnitId: null,
					age: {ageMax: null, ageMin: null},
					dz: {maxValue: null, minValue: null},
					rz: {refMax: null, refMin: null},
					name: ''
				}
			],
			obj: {
				name: null,
				IsHidden: false,
				KeyName: null,
				groupName: null,
				AnalysisGroupId: '7b9be5ce-1b6e-4262-bb96-9491d624d7c5',
				analysValueList: [
					{
						id: null,
						AnalysisGroupId: null,
						AnalysisId: null,
						UnitId: null,
						age: {ageMax: null, ageMin: null},
						dz: {maxValue: null, minValue: null},
						rz: {refMax: null, refMin: null},
						name: ''
					}
				]
			},
			dialog: false,
			list: [],
			listDoc: [],
			loading: true,
			search: "",
			headers: [],
			headers_referense: [
				{ text: "", sortable: false, value: "add", align: 'center' },
				{ text: "Ед. изм.", width: 220, sortable: false, value: "UnitId", align: 'center' },
				{ text: "Допустимые значения", sortable: false, value: "dz", align: 'center' },
				{ text: "Референсные значения", sortable: false, value: "rz", align: 'center' },
				{ text: "Возраст", with: 100, sortable: false, value: "age", align: 'center' },
				{ text: "", value: "action", sortable: false, align: 'left' }
			],
			groupNameAnalusis: [],
            rules: {
				required: v => !!v || 'Обязательно для заполнения.',
				requiredNool: v => +v >= 0 || 'Обязательно для заполнения.',
				minNOTmax: (min, max) => +min < +max || 'Минимальное не должно быть больше максимального!'
			}
		};
	},
	methods: {
		addBtn(analysisOn) {
			if(analysisOn) {
				this.obj = {
					name: null,
					IsHidden: false,
					KeyName: null,
					groupName: null,
					AnalysisGroupId: '68827c5b-2686-48ff-a0d4-086b54167dcc',
					analysValueList: [
						{
							id: null,
							AnalysisGroupId: null,
							AnalysisId: null,
							UnitId: null,
							age: {ageMax: null, ageMin: null},
							dz: {maxValue: null, minValue: null},
							rz: {refMax: null, refMin: null},
							name: ''
						}
					]
				}
				console.log(this.obj);
				this.dialog = true

				return;
			}
			this.obj = {
				name: null,
				IsHidden: false
			}
			console.log(this.obj);
			this.dialog = true
		},
		close(obj) {
			for (let key in obj) {
				obj[key] = key == 'analysValueList' ?
				obj[key] = this.list_ref_default :
				(key == 'AnalysisGroupId' ? '68827c5b-2686-48ff-a0d4-086b54167dcc' : null)
			}
			this.$refs.form.resetValidation()
			this.dialog = false
			this.editParams = false
		},
		async apiMethods(url, name) {
			this.loading = false
			this.overlay = false
			let res = (await this.api.get(`${url}/${name}`)).data
			await res.forEach(el => { el.children = [] })

			return res
		},
		async getData(url, name, item) {
			this.technicalName = item.technicalName
			
			if(name.indexOf('analysis') !== -1) {
				this.analysisOn = true
				this.headers = [
					{ text: "Название параметра", sortable: false, value: "name", align: 'center' },
					{ text: "Группа", sortable: true, value: "groupName", align: 'center' },
                	{ text: "", value: "action", sortable: false }
				]
				
				this.api.get(`${this.apiUrl}/api/lookups/units?lookupName=units`)
				.then(res => { this.listUnits = res.data })

				this.groupNameAnalusis = await this.apiMethods(this.apiUrl, 'api/lookups/analysisgroup?lookupName=analysisgroup')

			} else {
				this.analysisOn = false
				this.headers = [
					{ text: "Название параметра", sortable: false, value: "name", align: 'center' },
                	{ text: "", value: "action", sortable: false }
				]
				this.listUnits = []
			}
			
			if(item) {
				let res = (await this.api.get(`${url}/${name}`)).data
        if(name.indexOf("research-items") > -1 || name.indexOf("research-type") > -1){
          for (let n = 0; n < res.length; n++) {
            const el = res[n];
            el.name = el.Name;
          }
        }
				await (this.checkItems = item.children = res)
			}
		},
		editItems(item) {
			//если при редактировании анализа решили поменять c ИННЫЕ АНАЛИЗЫ на другую группу то добавим в массив 1ин обьект,
			//при сохранении если вернется на инные анализы то в функц сохранения там почистится этот массив
			if(item.AnalysisGroupId == '16eb8dae-3ccc-46b1-97c5-658ed47f5146') {
				item.analysValueList = [
					{
						id: null,
						AnalysisGroupId: null,
						AnalysisId: null,
						UnitId: null,
						age: {ageMax: null, ageMin: null},
						dz: {maxValue: null, minValue: null},
						rz: {refMax: null, refMin: null},
						name: ''
					}
				]
			}

			if(item.analysValueList && item.analysValueList.length > 0) {
				item.analysValueList.forEach(el => {
					el.age = {ageMax: el.ageMax, ageMin: el.ageMin}
					el.dz = {maxValue: el.maxValue, minValue: el.minValue}
					el.rz = {refMax: el.refMax, refMin: el.refMin}
				})
			}
			
			this.editParams = true
			this.obj = Object.assign({}, item)

			console.log('editItems',this.obj)
			this.dialog = true
		},
		autoCorrect(str) {
			let replacer = {
				"а":"a", "б":"b", "в":"v", "г":"g", "д":"d", "e":"e",
				"ё":"yo", "ж":"zh", "з":"z", "и":"i", "й":"j", "к":"k",
				"л":"l", "м":"m", "н":"n", "о":"o", "п":"p", "р":"r",
				"с":"s", "т":"t", "у":"u", "ф":"f", "х":"h", "ц":"ts",
				"ч":"ch", "ш":"sh", "щ":"sch", "ь":"'", "ъ":"''", "ы":"y",
				"э":"e", "ю":"yu", "я":"ya"
			}, replace = null;
			
			for(let i = 0; i < str.length; i++){                        
				if( replacer[ str[i].toLowerCase() ] != undefined){
									
					if(str[i] == str[i].toLowerCase()){
						replace = replacer[ str[i].toLowerCase() ];    
					} else if(str[i] == str[i].toUpperCase()){
						replace = replacer[ str[i].toLowerCase() ].toUpperCase();
					} 
					
					str = str.replace(str[i], replace);
				}
			}
			
			return str;
		},
		save(obj, analysisOn) {
			if(this.$refs.form.validate()) {
				this.overlay = true

				if(this.editParams) {
					if(analysisOn) {
						//если при редактировании анализа решили поменять на ИННЫЕ АНАЛИЗЫ то чистим массив в ноль
						if(obj.AnalysisGroupId == '16eb8dae-3ccc-46b1-97c5-658ed47f5146') {
							obj.analysValueList = []
						}

						
						obj.groupName = this.groupNameAnalusis.find(f => f.Id == obj.AnalysisGroupId).name

						//добавить цикл для очистки лишних обьектов AnalysisValueList
						if(obj.analysValueList.length > 0) {
							obj.analysValueList.forEach(el => {
								el.name = this.listUnits.find(f => f.id == el.UnitId) ? this.listUnits.find(f => f.id == el.UnitId).name : ''
								el.ageMin = +el.age.ageMin
								el.ageMax = +el.age.ageMax
								delete el.age
								el.refMin = +el.rz.refMin
								el.refMax = +el.rz.refMax
								delete el.rz
								el.minValue = +el.dz.minValue
								el.maxValue = +el.dz.maxValue
								delete el.dz
								delete el.AnalysisGroupId
							})
						}
						//если это инные анализы то мы обычный id преобразуем в AnalysisId
						if(obj.groupName == 'Иные анализы' && obj.id) obj.AnalysisId = obj.id

						this.api.put(`${this.apiUrl}/api/lookups/updateanalysisvalues`, obj)
						.then(res => {
							if(res.status == 200) {
								if(res.data.groupName == 'Иные анализы') res.data.id = res.data.AnalysisId

								this.checkItems.forEach(el => {
									if((el.AnalysisId || el.id) == res.data.AnalysisId) {
										console.log(el, res.data);
										Object.assign(el, res.data)
									}
								})
								this.overlay = false
								this.close(this.obj)
							}
						})

						return;
					}

					this.api.put(`${this.apiUrl}/api/lookups/${this.technicalName}/${obj.id}?name=${obj.name}&IsHidden=${obj.IsHidden}&lookupName=${this.technicalName}`, {})
					.then(res => {
						if(res.status == 200) {
							this.checkItems.forEach(el => { if(el.id == res.data[0].id) Object.assign(el, res.data[0]) })
							this.overlay = false
							this.close(this.obj)
						}
					})
				} else {
					if(analysisOn) {
						//если при создании анализа решили поменять на ИННЫЕ АНАЛИЗЫ то чистим массив в ноль
						if(obj.AnalysisGroupId == '16eb8dae-3ccc-46b1-97c5-658ed47f5146') {
							obj.analysValueList = []
						}

						obj.KeyName = this.autoCorrect(obj.name)
						obj.groupName = this.groupNameAnalusis.find(f => f.Id == obj.AnalysisGroupId).name
						
						//добавить цикл для очистки лишних обьектов AnalysisValueList
						if(obj.analysValueList.length > 0) {
							obj.analysValueList.forEach(el => {
								el.name = this.listUnits.find(f => f.id == el.UnitId) ? this.listUnits.find(f => f.id == el.UnitId).name : ''
								el.ageMin = +el.age.ageMin
								el.ageMax = +el.age.ageMax
								delete el.age
								el.refMin = +el.rz.refMin
								el.refMax = +el.rz.refMax
								delete el.rz
								el.minValue = +el.dz.minValue
								el.maxValue = +el.dz.maxValue
								delete el.dz
								delete el.id
								delete el.AnalysisId
								
							})
						}

						this.api.post(`${this.apiUrl}/api/lookups/addanalysis`, obj)
						.then(res => {
							if(res.status == 200) {
								this.checkItems.push(res.data)
								this.overlay = false
								this.close(this.obj)
							}
						})
						return;
					}

					this.api.post(`${this.apiUrl}/api/lookups/${this.technicalName}/${obj.name}?lookupName=${this.technicalName}&IsHidden=${obj.IsHidden}`, {})
					.then(res => {
						if(res.status == 200) {
							this.checkItems.push(res.data[0])
							this.overlay = false
							this.close(this.obj)
						}
					})
				}
			}
		},
		addRowRefParams() {
			this.obj.analysValueList.push({
				UnitId: null,
				age: {ageMax: null, ageMin: null},
				dz: {maxValue: null, minValue: null},
				rz: {refMax: null, refMin: null},
				name: ''
			})
		},
		delRef(index, id) {
			if(id) {
				if(confirm('Вы уверены что хотите удалить референсное значение?')) {
					this.api.delete(`${this.apiUrl}/api/lookups/deletevalues?id=${id}`)
					.then(res => {
						if(res.status == 200) this.obj.analysValueList.splice(index, 1);
					})
				}
				return;
			}

			this.obj.analysValueList.splice(index, 1);
		}
	},
	async created() {
		let name = 'medicine-skipping-reasons';
		this.list = await this.apiMethods(this.apiUrl, 'api/lookups/all')
		await this.getData(this.apiUrl, `api/lookups/${name}?lookupName=${name}`, this.list.find(f=>f.technicalName == name))
	}
};
</script>