<template>
	<v-container fluid>
		<v-row justify="center" align="center">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<!-- <h1>Справочник</h1> -->
				<!-- <guide /> -->
				<guide-new />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Guide from '../grid/Guide'
import GuideNew from '../grid/GuideNew'
export default {
    components: { Guide, GuideNew }
}
</script>