let localUser = JSON.parse(localStorage.getItem('user')) || {};
let state = {
    url_back: '',
    auth: {},
    error: '',
    user_copie: '',
    user: {
        id: '',
        login: '',
        patients_id: '',
        role: '',
        ssid: '',
        fio: '',
        CanRead: null,
        CanSet: null,
        PersonalInformation: null,
        roleId: null
    },
    patient: {
        id: '',
        login: '',
        patients_id: '',
        role: '',
        ssid: '',
        fio: '',
        query_fio: '',
        CanRead: null,
        CanSet: null,
        PersonalInformation: null,
        roleId: null
    },
};
Object.keys(state).forEach(key => state[key] = localUser[key] ? localUser[key] : state[key]);

export default {
    namespaced: true,
    state,
    getters: {
        user: state => state.user,
        urlBack: state => state.url_back,
        patient: state => state.patient,
        error: state => state.error,
        copy: state => state.user_copie,
    },
    mutations: {
        setSaveUrlBack(state, url) {
            state.url_back = url
        },
        setAuthorization(state, payload) {
            Object.assign(state.user, payload)
        },
        setError(state, payload) {
            state.error = payload
        },
        setUser(state, payload) {
            state.user.id = payload.id;
            state.user.login = payload.login;
            state.user.patients_id = payload.patients_id;
            state.user.role = payload.role;
            state.user.ssid = payload.ssid;
            state.user.fio = payload.fio;
        },
        setUserCopie(state, payload) {
            state.user_copie = payload
        },
        patient(state, payload) {
            Object.assign(state.patient, payload)
        },
        setResetAuth(state) {
            state.user.id = ''
            state.user.login = ''
            state.user.patients_id = ''
            state.user.role = ''
            state.user.ssid = ''
            state.user.fio = ''
            state.patient.id = ''
            state.patient.login = ''
            state.patient.patients_id = ''
            state.patient.role = ''
            state.patient.ssid = ''
            state.patient.fio = ''
        }
    },
    actions: {
        changePassword({ rootState }, payload) {
            return new Promise((resolve, reject) => {
                this.api.put(`${rootState.apiUrl}/api/user/reset-pswd`, payload).then(res => {
                    resolve(res)
                }, error => {
                    reject(error)
                })
            })
        },
        chekCode({ rootState }, payload) {
            return new Promise((resolve, reject) => {
                this.api.put(`${rootState.apiUrl}/api/user/check-sms-token`, payload).then(res => {
                    resolve(res)
                }, error => {
                    reject(error)
                })
            })
        },
        checkEmailInSystem({ rootState }, payload) {
            return new Promise((resolve, reject) => {
                this.api.put(`${rootState.apiUrl}/api/user/send-pswd-token`, payload).then(res => {
                    resolve(res)
                }, error => {
                    reject(error)
                })
            })
        },
        changeAgr({ rootState }) {
            this.api.put(`${rootState.apiUrl}/api/user/agreement`)
        },
        setPatient({ commit }, payload) {
            commit('patient', payload);
        },
        authorization({ commit, rootState }, payload) {
            return new Promise((resolve, reject) => {
                this.api.post(`${rootState.apiUrl}/api/auth/login`, payload).then(res => {
                    if (res.data.success === false) {
                        resolve(res)
                    } else {
                        if (res.data.role == 1) {
                            commit('patient', res.data);
                        } else {
                            commit('setAuthorization', res.data);
                        }
                        resolve(res)
                    }
                }, error => {
                    reject(error)
                })
            })
        },
        logout({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                this.api.post(`${rootState.apiUrl}/api/auth/logout`).then(res => {
                    if (state.user.id) {
                        commit('setAuthorization', {
                            id: '',
                            login: '',
                            patients_id: '',
                            role: '',
                            ssid: '',
                            fio: '',
                            agreement: '',
                            CanRead: null,
                            CanSet: null,
                            PersonalInformation: null,
                            roleId: null
                        });
                        commit('patient', {
                            id: '',
                            login: '',
                            patients_id: '',
                            role: '',
                            ssid: '',
                            fio: '',
                            agreement: '',
                            CanRead: null,
                            CanSet: null,
                            PersonalInformation: null,
                            roleId: null
                        });
                    } else {
                        commit('patient', {
                            id: '',
                            login: '',
                            patients_id: '',
                            role: '',
                            ssid: '',
                            fio: '',
                            agreement: '',
                            CanRead: null,
                            CanSet: null,
                            PersonalInformation: null,
                            roleId: null
                        });
                    }
                    resolve(res)
                }, error => {
                    reject(error)
                })
            })
        },
        errorPasport({ commit }, payload) {
            commit('setError', payload)
        },
        getUser({ commit }, payload) {
            commit('setUser', payload)
        },
        getUserCopie({ commit }, payload) {
            commit('setUserCopie', payload)
        },
        resetAuth({ commit }) {
            commit('setResetAuth')
        },
    }
}