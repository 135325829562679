<template>
	<v-card>
		<v-dialog persistent v-model="dialog" max-width="1000px">
			<v-card :loading="loading">
				<template slot="progress">
					<v-progress-linear color="red" indeterminate></v-progress-linear>
				</template>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title> {{ (isCreate ? "Добавить анализ" : "Редактировать анализ") + (loading ? " ( Загрузка\\обновление... )" : "") }}</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text :disabled="loading">Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>				
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>
						
						<v-form class="mt-4" ref="form" v-model="valid" lazy-validation :disabled="loading" >
							<v-row>
								<v-col cols="6" sm="6" md="6">
									<v-menu ref="menuED" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field   outlined dense label="Дата" ref="tf5" :rules="[rules.required]"  :value="dateFormatted" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
										</template>

										<v-date-picker color="#6374dd" ref="pickerED" :first-day-of-week="1" v-model="date" :max="maxDate" />
									</v-menu>
								</v-col>

								<v-col cols="6" sm="6" md="6">
									<v-row align="center">
										<v-col  cols="5" sm="5" md="5">
											<v-text-field   label="Мочевина" dense outlined type="number" color="#6b59c7" v-model="obj.Mochevina.AnalysisPatientValue" :rules="[rules.required, rules.requiredMinMax(obj.Mochevina, minmax)]" />
										</v-col>
										<v-col md="auto" class="mb-6">X</v-col>
										<v-col class="mb-6">
											<span style="font-size:18px">( ммоль/л )</span>
										</v-col>
									</v-row>
								</v-col>

								<v-col cols="6" sm="6" md="6">
									<v-row align="center">
										<v-col  cols="5" sm="5" md="5">
											<v-text-field   label="АЛТ" dense outlined type="number" color="#6b59c7" v-model="obj.ALT.AnalysisPatientValue" :rules="[rules.required, rules.requiredMinMax(obj.ALT, minmax, true)]" />
										</v-col>
										<v-col md="auto" class="mb-6">X</v-col>
										<v-col cols="5" sm="5" md="5">
											<v-select   label="Ед. измерения" :rules="[rules.required]" item-text="UnitName" item-value="UnitId" dense outlined :items="units.ALT" v-model="obj.ALT.AnalysisPatientUnitId" />
										</v-col>
									</v-row>
								</v-col>

								<v-col cols="6" sm="6" md="6">
									<v-row align="center">
										<v-col  cols="5" sm="5" md="5">
											<v-text-field   label="АСТ" dense outlined type="number" color="#6b59c7" v-model="obj.AST.AnalysisPatientValue" :rules="[rules.required, rules.requiredMinMax(obj.AST, minmax, true)]" />
										</v-col>
										<v-col md="auto" class="mb-6">X</v-col>
										<v-col cols="5" sm="5" md="5">
											<v-select   label="Ед. измерения" :rules="[rules.required]" item-text="UnitName" item-value="UnitId" dense outlined :items="units.AST" v-model="obj.AST.AnalysisPatientUnitId" />
										</v-col>
									</v-row>
								</v-col>

								<v-col cols="6" sm="6" md="6">
									<v-row align="center">
										<v-col  cols="5" sm="5" md="5">
											<v-text-field   label="Общий билирубин" dense outlined type="number" color="#6b59c7" v-model="obj.BilirubinTotal.AnalysisPatientValue" :rules="[rules.required, rules.requiredMinMax(obj.BilirubinTotal, minmax, true)]" />
										</v-col>
										<v-col md="auto" class="mb-6">X</v-col>
										<v-col cols="5" sm="5" md="5">
											<v-select   label="Ед. измерения" :rules="[rules.required]" item-text="UnitName" item-value="UnitId" dense outlined :items="units.BilirubinTotal" v-model="obj.BilirubinTotal.AnalysisPatientUnitId" />
										</v-col>
									</v-row>
								</v-col>
								
								<v-col cols="6" sm="6" md="6">
									<v-row align="center">
										<v-col  cols="5" sm="5" md="5">
											<v-text-field   label="Креатинин" dense outlined type="number" color="#6b59c7" v-model="obj.Creatinine.AnalysisPatientValue" :rules="[rules.required, rules.requiredMinMax(obj.Creatinine, minmax, true)]" />
										</v-col>
										<v-col md="auto" class="mb-6">X</v-col>
										<v-col cols="5" sm="5" md="5">
											<v-select   label="Ед. измерения" :rules="[rules.required]" item-text="UnitName" item-value="UnitId" dense outlined :items="units.Creatinine" v-model="obj.Creatinine.AnalysisPatientUnitId" />
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-form> 
					</v-container>				
				</v-card-text>
			</v-card>
		</v-dialog>	

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
//остальное
import { mapGetters, mapMutations, mapActions} from 'vuex';

export default {
	props: {
		id: String,	
		isCreate: Boolean,
		itemRow: Object,	
		list: Array,		
	},

	computed: {	
		...mapGetters({
			apiUrl: 'apiUrl',
			user: 'user/user',
			patient: 'user/patient',
			dialog: 'analyses/biochemicalBloodTestModal',
			patient_data: 'passportPart/patientData'
        }),
		dateFormatted() {
            if(this.date)
                return new Date(this.date).toLocaleDateString("ru-RU");
            return null;
        },		
	},
	data() {
		return {
			style:"max-height:90px;",
			maincol: 6,
			cols: 9,
			colsName: 3,
			cols2: 9,
			colsName2: 3,	
			colsInt: 1,
			colsInt2: 1,			
			search: '',

			maxDate: new Date().toISOString().substr(0, 10),
			menu: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			date: null,		
			obj: {				
				BilirubinTotal: { AnalysisPatientValue : null, AnalysisPatientUnitId: null},
				Mochevina: 		{ AnalysisPatientValue : null, AnalysisPatientUnitId: null},
				AST: 			{ AnalysisPatientValue : null, AnalysisPatientUnitId: null},
				ALT: 			{ AnalysisPatientValue : null, AnalysisPatientUnitId: null},
				Creatinine: 	{ AnalysisPatientValue : null, AnalysisPatientUnitId: null},				
			},					
			loading: true,
			rules: {
				required: value => true || 'Обязательно для заполнения.',
				requiredMinMax: (value, minmax, isMulti) => {
						if (isMulti){
							let v = value.AnalysisPatientValue ?? ""
							let unit = value.AnalysisPatientUnitId ?? ""
							if (v === "" && unit !== "" || v !== "" && unit === "")							
							{
								if (v === "")
									return 'Заполните значение'
								else if (unit === "")
									return 'Заполните ед. измерения'
							}
						}
						let item = null;
						let items = [];

						if (isMulti)
						{
							if (value.AnalysisPatientUnitId)
								items = minmax.filter(x=> x.UnitId == value.AnalysisPatientUnitId && x.AnalysisId == value.AnalysisId)
						}
						else
						{
							items = minmax.filter(x=> x.AnalysisId == value.AnalysisId)	
							if (items.length > 0)
							{
								value.AnalysisPatientUnitId = items[0].UnitId //проставим ЕИ
							}							
						}
						if (items.length > 0)
							item = items[0];

						if (item && (item.minValue > value.AnalysisPatientValue || item.maxValue < value.AnalysisPatientValue))
							return `Должно быть от ${item.minValue} до ${item.maxValue}`

						//проставим сразу норму, хоть и нехорошо это делать в валидации
						if (item && value.AnalysisPatientValue != null) {
						   value.norma = item.refMIn <= value.AnalysisPatientValue && item.refMax >= value.AnalysisPatientValue
						   value.minmax = item
						}		
						else value.norma = true				
						
						return true 
					},
			},
			units:{},
			minmax: [],			
		};
	},
	methods: {
		...mapActions({
            updatebiochemicalBloodTestModal: 'analyses/updatebiochemicalBloodTestModal'
        }),
		close() {			
			this.updatebiochemicalBloodTestModal(false)			
		},
		async apiMethods(url, name) {
			return  (await this.api.get(`${url}/${name}`)).data			
		},		
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		validate()
		{
			let allIsEmpty = true
			for (const property in this.obj) {
				if ( (this.obj[property].AnalysisPatientValue ?? "") !== "")
				{
				 	allIsEmpty = false
					break
				}
			}
			
			if (allIsEmpty) {
				return "Заполните хотя бы одно поле"
			}
				
			for (const property in this.obj) {				
				let value = this.obj[property]
				if (value.AnalysisKeyName === "Mochevina")
					continue;

				let v = value.AnalysisPatientValue ?? ""
				let unit = value.AnalysisPatientUnitId ?? ""
				if (v === "" && unit !== "" || v !== "" && unit === "")
				{
					return "Заполните значение и ед.измерения " + value.AnalysisName
				}
			}
			
			return "";
		},
		save(obj) {			
			let val = this.validate()
			if(this.$refs.form.validate()) {
				if(val === "") {				
					let request = { 
						items: this.obj,
						patientId: this.patientId,
						date: new Date(this.date).getTime(),
						isCreate: this.isCreate
					}
					let that = this
					that.loading = true
					this.api.post(`${this.apiUrl}/api/analysispatient/SaveBiochemicalBloodTest`, request)
						.then(res => {																		
							if(that.isCreate) {
								this.snackBarFunc(true, 'Уведомление успешно создано!', 'success')
								//Добавление в массив нового уведомления
								if (that.list)
									that.list.unshift(res.data)							
							} else {	
								//обновление объекта
								if (that.list)
									Object.assign(that.list.filter(x=> x.creation_date === res.data.creation_date)[0], res.data)					
								that.snackBarFunc(true, 'Уведомление успешно обновлено!', 'success')
							}
							that.overlay = false
							that.loading = false						
							that.close()					
						})
						.catch(error => {
							if(error) {
								let msg = error.response.data.Message;						
								that.snackBarFunc(true, 'Ошибка при обновлении данных: '+ msg, 'error')
								that.overlay = false
								that.loading = false							 
							}
						})			
				} else {
					this.snackBarFunc(true, val, 'warning')
				}
			} else {
				this.snackBarFunc(true, 'Некорректно заполнены данные', 'warning')
			}
		},
		
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());
			return local.toJSON().slice(0, 10);
		},		
		
		toMilliSeconds(d)
        {
            if (d.toString().length <= 10)
                return d*1000;
            return d;
        },
        getFullYear(birthData){
            let diff = new Date()-birthData
            let age = Math.floor(diff/31557600000);
        	let count = age % 100;
            return count
        },
	},
	async created() {
		let result = await this.apiMethods(this.apiUrl, `api/analysispatient/GetBiochemicalBloodTestByDate${this.isCreate ? "New" : ""}/${this.patient.patients_id}/${this.isCreate ? 0 : this.itemRow.date_new}`, true)
		for (const property in result.items) {
			Object.assign(this.obj[property], result.items[property])			
		}
		
		this.source_date = result.date
		this.date = this.toJSONLocal(result.date);		
		this.patientId = result.patientId
		let that = this;
		result.units.map(x=> that.units[x.analyseName] = x.items)			
		
		let fullyears = this.getFullYear(this.toMilliSeconds(this.patient_data.birth_date))
		this.minmax = await this.apiMethods(this.apiUrl, `api/analysispatient/GetMinMax/${fullyears}`)
		this.loading = false
	}
};
</script>

<style scoped>

.not_reference {
    color: #ff4500;
}
</style>