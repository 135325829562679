<template>
    <v-row>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <svg-human-pok  viewBox="0 0 1040 800" />
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div v-if="user.role !== 2 || checkRole" class="upload-label">Для того чтобы загрузить фото, выберите часть тела на схематичном изображении человека и нажмите на кнопку Добавить фото</div>
                    <el-popover
                        style="text-align: center;"
                        placement="bottom"
                        width="400"
                        trigger="manual"
                        :value="getIntegumentFlag"
                    >
                        <span @click="changeFlag({name: 'integumentFlag', val: false})" style="font-size: 17px;float: right;color: black;cursor: pointer;">X</span>
                        <div class="settings-form add-popup skin-photo-form">
                            <span>Выберите дату снимка</span>
                            <el-date-picker
                            v-model="datedownpic"
                            value-format="timestamp"
                            format="dd.MM.yyyy"
                            type="date"
                            placeholder="Выберите дату"
							:validationRules="{ type: 'required',message: 'Заполните поле!'}"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                        <span :class="{'not-valid-text ': fileNotValid}">Загрузите изображение</span>
                            <dx-file-uploader
                            :ref="'uploaderFiles'"
                            style="margin:0"
                            width="45%"
                            selectButtonText="Загрузить"
                            labelText=""
                            accept="image/*"
                            uploadMode="useForm"
                            name="file"
							:validationRules="{ type: 'required',message: 'Заполните поле!'}"
                        	/>
							<span style="word-break: break-word" class="file-info">Загрузка больше одной фотографии невозможна</span>

                            <span>Описание</span>
                            <dx-text-area
                            height="100"
                            v-model="commentsys"
                            />
							<el-button slot="reference" :disabled="disabledBtnSave" style="float:right;margin: 15px 0px 15px 0px;" @click="uploadfiles" type="primary" icon="el-icon-check">Сохранить введенные данные</el-button>
                        </div>
					    <el-button v-if="user.role !== 2 || checkRole" slot="reference" type="primary" icon="el-icon-plus" @click="changeFlag({name: 'integumentFlag', val: true})" class="def-btn" :disabled="!activeJoint.id">Добавить фото</el-button>
                    </el-popover>
                </v-col>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <picture-sys-table />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { DxFileUploader, DxTextArea } from "devextreme-vue"
import SvgHumanPok from '../human/SvgHumanPok.vue'
import PictureSysTable from '../grid/PictureSysTable.vue'
import { mapGetters, mapActions, mapMutations } from 'vuex';
import $ from 'jquery';
export default {
    props: ['photo'],
    components: {
        SvgHumanPok,
        DxFileUploader,
        DxTextArea,
        PictureSysTable
	},
	computed: {
		...mapGetters({
			user: 'user/user',
			patient: 'user/patient',
			activeJoint: 'manifestationOfDisease/activeJoint',
			getIntegumentFlag: 'manifestationOfDisease/getIntegumentFlag',
			apiUrl: 'apiUrl'
		}),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
    },
    data() {
        return {
			disabledBtnSave: false,
			fileNotValid: false,
            datedownpic: new Date().getTime(),
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            commentsys: ''
        }
	},
    watch: {
        photo(val) {
            if(val) this.changeFlag({name: 'integumentFlag', val: false})
        }
    },
	methods: {
        ...mapMutations({
            changeFlag: 'manifestationOfDisease/changeFlag'
        }),
		...mapActions({
			getJoints: 'manifestationOfDisease/getJoints',
			saveJoint: 'manifestationOfDisease/saveJoint',
			setActiveSkin: 'manifestationOfDisease/setActiveSkin',
			getAllJointPhoto: 'manifestationOfDisease/getAllJointPhoto'
		}),
		 uploadfiles: function() {
            this.disabledBtnSave = true
			let filelist = $(".skin-photo-form .dx-fileuploader-input");
			let formData = new FormData()
			if (!filelist[0].files[0]) {
				this.fileNotValid = true;
				return;
			}
			formData.append('file', filelist[0].files[0]);
			this.api.post(this.apiUrl + '/files/send', formData, { headers: { 'Content-type': 'multipart/form-data' } })
            .then(r => {
				let picture = {
					id: r.data.id,
					patients_id: this.patient.patients_id,
					joints_id: this.activeJoint.id,
					date: this.datedownpic,
                    comments: this.commentsys,
                    creation_date: new Date().getTime()
				};
				this.api.post(this.apiUrl + '/api/patients/' + this.patient.patients_id + '/pictures', picture).then(r => {
                    this.setActiveSkin(this.activeJoint.id);
                    this.changeFlag({name: 'integumentFlag', val: false})
                    this.commentsys = ''
                    this.datedownpic = new Date().getTime()
                    this.$refs['uploaderFiles'].instance.reset()
                    this.disabledBtnSave = false
				})
			})
        },
	}
}
</script>
