export default {
    namespaced: true,
    state: {
        joints: [],
        inspection: [],
        allJointPhoto: [],
        skins: [],
        activeSkin: 0,
        activeSip: [], // Массив объектов вида {id:<id осмотра>, sipList:<массив по сыпи>}
        jointsFlag: false,
        integumentFlag: false,
        generalFlag: false
    },
    getters: {
        inspection: state => state.inspection,
        joints: state => state.joints,
        skins: state => state.skins,
        activeJoint: state => state.joints.find(i => i.active) || {},
        allJointPhoto: state => state.allJointPhoto,
        activeSkin: state => state.skins.find(i => i.id == state.activeSkin),
        activeSipList: state => (id) => {
            const item = state.activeSip.filter(i => i.id === id)

            return (item.length && item[0].sipList) ? item[0].sipList : []
        },
        getJointsFlag: state => state.jointsFlag,
        getIntegumentFlag: state => state.integumentFlag,
        getGeneralFlag: state => state.generalFlag
    },
    mutations: {
        changeFlag(state, obj) {
            if(!obj) {
                state.jointsFlag = false
                state.integumentFlag = false
                state.generalFlag = false

                return;
            }
            
            state[obj.name] = obj.val
        },
        // Осмотр
        // Осмотр
        // Осмотр
        setInspection(state, payload) {
            state.inspection = payload;
        },
        updatePutInspection(state, data) {
            let inspection = state.inspection.find(i => i.id == data.id);
            if (inspection) {
                for (let key in inspection) {
                    inspection[key] = data[key];
                }
            }
        },
        savePostInspection(state, data) {
            state.inspection.push(data);
        },
        skins(state, data) {
            state.activeSkin = 0;
            state.skins = data;
        },
        joins(state, data) {
            state.joints = data;
        },
        activeJoint(state, id) {
            state.joints.forEach(i => i.active = false)
            let jointById = state.joints.find(i => i.id == id)

            if (jointById) state.joints.find(i => i.id == id).active = true
            state.joints.forEach(item => {
                if (item.items) {
                    if (item.items.length == 0) {
                        item.HasPictures = 0
                        item.PicturesCount = 0
                    }
                }
            })
        },
        jointPhoto(state, data) {
            state.joints.forEach(item => {
                if (item.id == data.id) {
                    item.PicturesCount = data.data.length
                }
            })
            let joint = state.joints.find(i => i.id == data.id);
            if (joint) {
                if (data.data.length > 0) {
                    joint.PicturesCount = data.data.length
                }
                joint.items = data.data;
                state.joints.push({});
                state.joints.slice(-1);
            }
        },
        allJointPhoto(state, data) {
            state.allJointPhoto = data;
        },
        skinPhoto(state, data) {
            let skin = state.skins.find(i => i.id == data.id);
            if (skin) {
                skin.items = data.data;
                skin.joints.push({});
                skin.joints.slice(-1);
            }
        },
        activeSkin(state, id) {
            state.skins.forEach(i => i.isActive = false);
            state.skins.find(i => i.id === id).isActive = true;
            state.activeSkin = id;
            state.skins.push({});
            state.skins.slice(-1);
        },
        setSavePokSelected(state, data) { // data => {id:<id осмотра>, data:<массив по сыпи>}
            let num = -1

            state.activeSip.forEach((item, k) => {
                if (item.id === data.id) num = k
            })
            if (num !== -1) {
                state.activeSip.splice(num, 1, data)
            } else {
                state.activeSip.push(data)
            }
        },
        setResetPokSelected(state, id) { // id текущего осмотра
            let num = -1

            state.activeSip.forEach((item, k) => {
                if (item.id === id) num = k
            })
            if (num !== -1) state.activeSip.splice(num, 1, { id: id, sipList: [] })
        },
        cleanSipListForNew(state) {
            let num = -1

            state.activeSip.forEach((item, k) => {
                if (item.id === 'new_item') num = k
            })
            if (num !== -1) state.activeSip.splice(num, 1)
        },
        updateSipListForNew(state, obj) {
            let arr = [...state.activeSip]

            arr.push(obj)
            state.activeSip = [...arr]
        },
        setActiveSip(state, data) {
            let arr = data.map(item => {
                return {
                    id: item.id,
                    sipList: JSON.parse(item.siplist)
                }
            })
            state.activeSip = [...arr]
        },
        setResetMass(state) {
            state.allJointPhoto = []
        },
        resetDeleteStr(state, data) {
            let index = state.joints.findIndex(photo => photo.id === data.joints_id)
            if (index) {
                state.joints.forEach(item => {
                    if (item.items) {
                        let ind = item.items.findIndex(ph => ph.id === data.id)
                        item.items.splice(ind, 1)
                        item.PicturesCount -= 1
                    }
                })
            }
        },
    },
    actions: {
        //api/patients/<patient_id>/pictures/<picture_id>
        deleteStr({ commit, rootState }, payload) {
            let id = rootState.user.patient.patients_id;
            this.api.delete(`${rootState.apiUrl}/api/patients/${id}/pictures/${payload.id}`, payload).then(res => {
                commit('resetDeleteStr', payload)
            })
        },
        // Осмотр
        // Осмотр
        // Осмотр
        getInspection({ commit, rootState }) {
            return new Promise((resolve, rejects) => {
                let id = rootState.user.patient.patients_id;
                this.api.get(`${rootState.apiUrl}/api/patients/${id}/inspections`).then(res => {
                    commit('setInspection', res.data)
                    commit('setActiveSip', res.data)
                    resolve(res)
                }, error => {
                    rejects(error)
                })
            })
        },
        updatePutInspection({ commit, rootState }, payload) {
            return new Promise((resolve, rejects) => {
                let id = rootState.user.patient.patients_id;
                this.api.put(`${rootState.apiUrl}/api/patients/${id}/inspections/${payload.id}`, payload).then(res => {
                    commit('updatePutInspection', res.data)

                    resolve(res)
                }, error => {
                    rejects(error)
                })
            })
        },
        deleteInspection({ commit, rootState }, payload) {
            let id = rootState.user.patient.patients_id;
            this.api.delete(`${rootState.apiUrl}/api/patients/${id}/inspections/${payload.id}`, payload)
        },
        savePostInspection({ commit, rootState }, payload) {
            return new Promise((resolve, rejects) => {
                let id = rootState.user.patient.patients_id;
                this.api.post(`${rootState.apiUrl}/api/patients/${id}/inspections`, payload).then(res => {
                    commit('cleanSipListForNew')
                    commit('updateSipListForNew', {
                        id: res.data.id,
                        sipList: JSON.parse(res.data.siplist)
                    })
                    res.data.sip = JSON.parse(res.data.siplist).length ? JSON.parse(res.data.siplist).length : 0
                    commit('savePostInspection', res.data)

                    resolve(res)
                }, error => {
                    rejects(error)
                })
            })
        },
        getSkins({ commit, rootState }) {
            let id = rootState.user.patient.patients_id;
            this.api.get(`${rootState.apiUrl}/api/patients/${id}/joints`, { params: { type: 'skin' } }).then(res => {
                commit('joints', res.data)
            })
        },
        getJoints({ commit, dispatch, rootState }, type) {
            let id = rootState.user.patient.patients_id;
            this.api.get(`${rootState.apiUrl}/api/patients/${id}/joints`, {
                params: {
                    type
                }
            }).then(res => {
                commit('joins', res.data)
            })
        },
        getPhoto({ commit, rootState }, jointId) {
            let id = rootState.user.patient.patients_id;
            this.api.get(`${rootState.apiUrl}/api/patients/${id}/pictures`, {
                params: {
                    'joint': jointId
                }
            }).then(res => {
                commit('jointPhoto', { data: res.data, id: jointId })
            })
        },
        updatePhoto({ rootState }, payload) {
            return new Promise((resolve, rejects) => {
                let id = rootState.user.patient.patients_id;
                this.api.put(`${rootState.apiUrl}/api/patients/${id}/pictures/${payload.id}`, payload).then(res => {
                    resolve(res)
                }, error => {
                    rejects(error)
                })
            })
        },
        getAllJointPhoto({ commit, rootState }, type) {
            let id = rootState.user.patient.patients_id;

            this.api.get(`${rootState.apiUrl}/api/patients/${id}/pictures`, {
                params: {
                    type
                }
            }).then(res => {
                commit('allJointPhoto', res.data);
            })
        },
        saveSkin({ commit, rootState }, payload) {
            let id = rootState.user.patient.patients_id;
            this.api.post(`${rootState.apiUrl}/api/patients/${id}/pictures`, payload).then(res => {

            })
        },

        getSkinPhoto({ commit, rootState }, jointId) {
            let id = rootState.user.patient.patients_id;
            this.api.get(`${rootState.apiUrl}/api/patients/${id}/pictures`, {
                params: {
                    'joint': jointId
                }
            }).then(res => {
                commit('skinPhoto', { data: res.data, id: jointId })
            })
        },
        removePicture({ commit, rootState, dispatch }, data) {
            return new Promise((resolve, rejects) => {
                let id = rootState.user.patient.patients_id;
                this.api.delete(`${rootState.apiUrl}/api/patients/${id}/pictures/${data.id}`).then(res => {
                    commit('activeJoint', data.joints_id);
                    if (data.flag == false) {
                        dispatch('getPhoto', data.joints_id)
                    } else {
                        dispatch('getJoints', data.type)
                    }

                    resolve(res)
                }, error => {
                    rejects(error)
                })
            })
        },
        setActiveSkin({ commit, dispatch }, id) {
            commit('activeJoint', id);
            dispatch('getPhoto', id)
        },
        setActiveJoint({ commit, dispatch }, id) {
            commit('activeJoint', id);
            dispatch('getPhoto', id)
        },
        savePokSelected({ commit }, data) {
            commit('setSavePokSelected', data)
        },
        resetPokSelected({ commit }) {
            commit('setResetPokSelected')
        },
        resetMass({ commit }) {
            commit('setResetMass')
        },
        cleanSipList({ commit }) {
            commit('cleanSipListForNew')
        },
    }
}