<template>
	<v-card>
		<v-card-title>
			{{ headerName }}
			<v-btn @click="dialog=true" color="#6374dd" dark class="ml-4">Регистрация врача</v-btn>
			
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:items="list"
			:search="search"
			:loading="loading"
			no-data-text="Данных нет"
			sort-by="fio"
        	:sort-desc="true"
			loading-text="Загрузка... Пожалуйста подождите..."
		>
			<template v-slot:[`item.patients_count`]="{ item }">
				<v-menu v-if="item.patients_count > 0" v-model="item.menu" :close-on-content-click="false" :nudge-width="200" offset-x >
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" color="#6374dd" outlined elevation="4" small>
							{{item.patients_count}}
							<v-icon class="fs-16">mdi-eye</v-icon>
						</v-btn>
					</template>

					<v-card>
						<v-card-subtitle>Прикрепленные пациенты</v-card-subtitle>
						<v-list>
          					<v-list-item>
								<v-data-table no-results-text="Поиск не дал результатов"
									:headers="header_patients"
									:items-per-page="5"
									:items="item.patients_items"
									no-data-text="Данных нет"
								>
									<template v-slot:[`item.last_name`]="{ item }">
										{{`${item.last_name} ${item.first_name} ${item.patronymic}`}}
									</template>
								</v-data-table>
							</v-list-item>
						</v-list>
					</v-card>
				</v-menu>

                <span v-else>Нет пациентов</span>
			</template>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn @click="editItems(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				<v-btn @click="deletItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-trash-can-outline</v-icon>
				</v-btn>
				
			</template>
		</v-data-table>


		<v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Данные врача</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation >
							<v-row>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf0" v-model="obj.last_name" :rules="[rules.required]" label="Фамилия *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf1" v-model="obj.first_name" :rules="[rules.required]" label="Имя *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf2" v-model="obj.patronymic" :rules="[rules.required]" label="Отчество *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf3" v-model="obj.email" :rules="[rules.required, rules.email]" label="E-mail *" />
								</v-col>
								<v-col cols="4" sm="4" md="4">
									<v-text-field color="#6b59c7" dense outlined hide-details ref="tf4" v-model="obj.phone" :rules="[rules.required, rules.phone]" v-mask="'+7 (###) ###-####'" placeholder="+7(___)___-____" label="Телефон *" />
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/departments?lookupName=departments', 'listDolj', true, 'isLoadingD')"
                                        :loading="isLoadingD"
                                        v-model="obj.positionId"
                                        item-text="name"
                                        item-value="id"
										ref="tf5"
                                        hide-selected
                                        clearable
                                        hide-details
                                        dense
                                        :items="listDolj"
                                        label="Должность"
                                        outlined
                                    />
								</v-col>
								<v-col md="12">
									<v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/roles?lookupName=roles', 'roleItems', false, 'isLoadingRole')"
                                        :loading="isLoadingRole"
                                        v-model="obj.roleId"
										ref="tf8"
                                        item-text="Name"
                                        item-value="Id"
										return-object
                                        hide-selected
                                        clearable
                                        hide-details
                                        dense
                                        :items="roleItems"
                                        label="Роль"
                                        outlined
                                    />
								</v-col>
								<v-col md="12">
									<v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/regions?lookupName=regions', 'regionsItems', false, 'isLoadingR')"
                                        :loading="isLoadingR"
                                        v-model="obj.regionId"
                                        item-text="Name"
										ref="tf9"
                                        item-value="id"
										return-object
                                        hide-selected
                                        clearable
										:rules="[rules.required]"
                                        hide-details
                                        dense
                                        :items="regionsItems"
                                        label="Регионы *"
                                        outlined
                                    />
								</v-col>
								<v-col md="12">
									<v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/hospitals?lookupName=hospitals', 'hospitalsItems', false, 'isLoadingH')"
                                        :loading="isLoadingH"
                                        v-model="obj.hospitalId"
                                        item-text="Name"
                                        item-value="id"
										return-object
										ref="tf10"
                                        hide-selected
										:rules="[rules.required]"
                                        clearable
                                        hide-details
                                        dense
                                        :items="hospitalsItems"
                                        label="Организация *"
                                        outlined
                                    />
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<v-autocomplete
										ref="tf6"
                                        color="#5D4CB8"
                                        v-model="obj.substitutional_Id"
                                        item-text="fio"
                                        item-value="id"
                                        hide-selected
                                        clearable
                                        hide-details
                                        dense
                                        :items="listDoc"
                                        label="Замещающий врач"
                                        outlined
                                    />
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/lookups/departments?lookupName=departments', 'listPodr', true, 'isLoadingDep')"
                                        :loading="isLoadingDep"
                                        v-model="obj.departmentId"
                                        item-text="name"
                                        item-value="id"
										ref="tf7"
                                        hide-selected
                                        clearable
                                        hide-details
                                        dense
                                        :items="listPodr"
                                        label="Подразделение"
                                        outlined
                                    />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {mask} from 'vue-the-mask'

export default {
	directives: {mask},
	props: {
		headerName: {type: String, default: () => '', require: true}
	},
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl'
        })
	},
	data() {
		return {
			isLoadingDep: false,
			isLoadingD: false,
			isLoadingR: false,
			regionsItems: [],
			isLoadingRole: false,
			roleItems: [],
			isLoadingH: false,
			hospitalsItems: [],
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				departmentId: "",
				email: "",
				first_name: "",
				id: null,
				last_name: "",
				patients_count: null,
				patients_items: [],
				patronymic: "",
				phone: "",
				positionId: "",
				substitutional_Id: null,
				regionId: null,
				hospitalId: null,
				roleId: null
			},
			dialog: false,
			list: [],
			listDoc: [],
			listDocZam: [],
			loading: true,
			search: "",
			rules: {
				required: value => !!value || 'Обязательно для заполнения.',
				phone: val => val && val.length == 17 || 'Некорректный формат телефона',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Некорректный e-mail.'
				}
			},
			headers: [
				{ text: "Роль", value: "roleName", align: 'center' },
				{ text: "Регион", value: "regionName", align: 'center' },
				{ text: "Организация", value: "hospitalName", align: 'center' },
				{ text: "Подразделение", value: "departmentName", align: 'center' },
				{ text: "ФИО врача", value: "fio", align: 'center' },
				{ text: "Замещающий врач", value: "substitutional_name", align: 'center' },
				{ text: "Кол-во пациентов", value: "patients_count", align: 'center' },
				{ text: "E-mail", value: "email", align: 'center' },
				{ text: "Телефон", sortable: false, value: "phone", align: 'center' },
				{ text: "Должность", value: "positionName", align: 'center' },
                { text: "", value: "action", sortable: false }
			],
			header_patients: [
				{ text: "ФИО пациента", sortable: false, value: "last_name", align: 'center' },
				{ text: "E-mail", sortable: false, value: "email", align: 'center' },
				{ text: "Телефон", sortable: false, value: "phone", align: 'center' }
			],
			listDolj: [],
            listPodr: [],
		};
	},
	methods: {
		async close() {
			for (let i = 0; i < 11; i++) this.$refs[`tf${i}`].reset()
			this.dialog = false

			this.list = await this.apiMethods(this.apiUrl, 'doctors')
		},
		async apiMethods(url, name) {
			this.loading = false
			let res = (await this.api.get(`${url}/${name}`)).data
			if(name == 'doctors') {
				res.forEach((el, indx, array) => {
					el.menu = false
					el.fio = `${el.last_name} ${el.first_name} ${el.patronymic}`

					if(el.substitutional_Id) {
						let f = array.find(f=>f.id == el.substitutional_Id);
						el.substitutional_name = `${f.last_name} ${f.first_name} ${f.patronymic}`
					}
				})
				this.listDoc = res.sort((a,b) => (a.fio > b.fio) ? 1 : ((b.fio > a.fio) ? -1 : 0))
			}
			return res
		},
        getList(url, name, flag, isLoading) {
            if(this[name].length > 0) return;

            this[isLoading] = true

            this.api.get(`${this.apiUrl}/${url}`)
            .then(res => {
                this[isLoading] = false

				if(flag) res.data.filter(f => !f.IsHidden)
                
                this[name] = res.data
            })
        },
		editItems(row) {
			this.getList('api/lookups/regions?lookupName=regions', 'regionsItems', false, 'isLoadingR')
			this.getList('api/lookups/roles?lookupName=roles', 'roleItems', false, 'isLoadingRole')
			this.getList('api/lookups/hospitals?lookupName=hospitals', 'hospitalsItems', false, 'isLoadingH')
			this.getList('api/lookups/departments?lookupName=departments', 'listPodr', false, 'isLoadingDep')

			this.obj = Object.assign({}, row)

			this.dialog = true
		},
		deletItems(row, array) {
			let f = array.find(f=> f.substitutional_Id == row.id)
			
			if(row.patients_count > 0 || f) {
				this.snackbar = true
				this.snackbar_text = `Удаление врача невозможно ${row.patients_count > 0 ? 'из-за прикрепленных пациентов' : (f ? 'он является замещающий врачом' : '')}!!!`
				this.snackbarColor = 'warning'
			} else {
				if(confirm('Вы уверены что хотите удалить врача ?')) {
					this.api.delete(`${this.apiUrl}/doctors/${row.id}`, row)
					.then(res => {
						if(res.status == 200) {
							this.snackbar = true
							this.snackbar_text = 'Врач удален успешно!!!'
							this.snackbarColor = 'success'

							const index = this.list.indexOf(row);
							this.list.splice(index, 1)
						}
					})
				}
			}
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				this.overlay = true
				this.api[obj.id ? 'put' : 'post'](`${this.apiUrl}/doctors${obj.id ? '/'+obj.id : ''}`, {
					last_name: obj.last_name,
                    first_name: obj.first_name,
                    patronymic: obj.patronymic,
                    substitutional_Id: obj.substitutional_Id,
                    positionId: obj.positionId,
                    email: obj.email,
					patients_count: obj.id ? obj.patients_count : undefined,
                    phone: obj.phone,
                    departmentId: obj.departmentId,
                    regionId: typeof obj.regionId == 'string' ? obj.regionId : obj.regionId.id,
                    regionName: typeof obj.regionId == 'string' ? obj.regionName : obj.regionId.Name,
                    hospitalId: typeof obj.hospitalId == 'string' ? obj.hospitalId : obj.hospitalId.id,
                    hospitalName: typeof obj.hospitalId == 'string' ? obj.hospitalName : obj.hospitalId.Name,
                    roleId: typeof obj.roleId == 'string' ? obj.roleId : (obj.roleId ? obj.roleId.Id: null),
                    roleName: typeof obj.roleId == 'string' ? obj.roleName : (obj.roleId ? obj.roleId.Name: null)
				})
				.then(res => {
					if(res.status == 201) {
						res.data.Doctor.fio = `${res.data.Doctor.last_name} ${res.data.Doctor.first_name} ${res.data.Doctor.patronymic}`
						
						if(res.data.Doctor.substitutional_Id) {
							let f = this.list.find(f=>f.id == res.data.Doctor.substitutional_Id);
							res.data.Doctor.substitutional_name = `${f.last_name} ${f.first_name} ${f.patronymic}`
						}

						this.list.push(res.data.Doctor)
						this.snackbar = true
						this.snackbar_text = 'Врач успешно создан!!!'
						this.snackbarColor = 'success'
						this.dialog = false
						this.overlay = false
						this.close()
					}
					if(res.status == 200) {
						this.list.forEach(el => {
							if(el.id == res.data.id) {
								Object.assign(el, res.data)
								el.fio = `${res.data.last_name} ${res.data.first_name} ${res.data.patronymic}`
								
								if(el.substitutional_Id) {
									let f = this.list.find(f=>f.id == res.data.substitutional_Id);
									el.substitutional_name = `${f.last_name} ${f.first_name} ${f.patronymic}`
								}
							}
						})
						this.overlay = false
						this.snackbar = true
						this.snackbar_text = 'Врач успешно обновлен!!!'
						this.snackbarColor = 'success'
						this.dialog = false
						this.close()
					}
				})
				.catch(error => {
					this.overlay = false
					this.snackbar = true
					this.snackbar_text = error.response ? error.response.data.Message : 'Ошибка!!!'
					this.snackbarColor = 'error'
				})
			} else {
				this.snackbar = true
				this.snackbar_text = 'Заполните обязательные поля!!!'
				this.snackbarColor = 'warning'
			}
		}
	},
	async created() {
		// this.api.get(`${this.apiUrl}/api/lookups/departments?lookupName=departments`)
        // .then(res => { this.listPodr = res.data.filter(f => !f.IsHidden) })

		this.api.get(`${this.apiUrl}/api/lookups/positions?lookupName=positions`)
        .then(res => { this.listDolj = res.data.filter(f => !f.IsHidden) })

		this.list = await this.apiMethods(this.apiUrl, 'doctors')
	}
};
</script>


<style scoped>

.fs-16 {
	font-size: 16px!important;
}

</style>