<template>
	<v-card>
		<v-card-title>
			{{ headerName }}
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:items="list"
        	:sort-desc="true"
			:search="search"
			:loading="loading"
			no-data-text="Данных нет"
			sort-by="fio"
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn @click="editItems(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				
			</template>
		</v-data-table>


		<v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close(obj)">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Замена врача</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="primary" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation >
							<v-row>
								<v-col cols="4" sm="6" md="6">
									<v-text-field color="#6b59c7" v-model="obj.fio" readonly label="ФИО пациента" />
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<v-select color="#6b59c7" menu-props="auto" v-model="obj.doctor" item-text="fio" item-value="id" :items="listDoc" label="ФИО врача" />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		headerName: {type: String, default: () => '', require: true}
	},
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl'
        })
	},
	data() {
		return {
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				DoctorFIO: "",
				doctor: '',
				first_name: '',
				id: null,
				last_name: '',
				patronymic: ''
			},
			dialog: false,
			list: [],
			listDoc: [],
			loading: true,
			search: "",
			headers: [
				{ text: "Регион", value: "regionName", align: 'center' },
				{ text: "Организация", value: "hospitalName", align: 'center' },
				{ text: "ФИО пациента", value: "fio", align: 'center' },
				{ text: "ФИО врача", value: "DoctorFIO", align: 'center' },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		close(obj) {
			for (let key in obj) obj[key] = null
			this.dialog = false
		},
		async apiMethods(url, name) {
			this.loading = false
			let res = (await this.api.get(`${url}/${name}`)).data
			res.forEach(el => { el.fio = `${el.last_name} ${el.first_name} ${el.patronymic}` })

			return name == 'doctors' ? res.sort((a,b) => (a.fio > b.fio) ? 1 : ((b.fio > a.fio) ? -1 : 0)) : res
		},
		editItems(row) {
			Object.assign(this.obj, row)
			this.dialog = true
		},
		save(obj) {
			this.overlay = true
			this.api.put(`${this.apiUrl}/patients/${obj.id}/doctors/`, {
				id: obj.id,
				doctor: obj.doctor
			})
			.then(res => {
				if(res.status == 200) {
					let f = this.listDoc.find(f=> f.id == res.data.DoctorId)
					
					console.log('f',f)
					
					this.list.forEach(el => {
						if(el.id == res.data.Id) {
							
							console.log(el, res.data)

							el.doctor = res.data.DoctorId
							el.DoctorFIO = `${f.last_name} ${f.first_name} ${f.patronymic}`
						}
					})
					this.overlay = false
					this.snackbar = true
					this.snackbar_text = 'Врач успешно обновлен!!!'
					this.snackbarColor = 'success'
					this.dialog = false
					this.close()
				}
			})
			.catch(error => {
				if(error) {
					this.overlay = false

					this.snackbar = true
					this.snackbar_text = 'Ошибка обновления данных, обратитесь к администратору!!!'
					this.snackbarColor = 'error'
				}
			})
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, 'api/patients')
		this.listDoc = await this.apiMethods(this.apiUrl, 'doctors')
	}
};
</script>


<style scoped>

.fs-16 {
	font-size: 16px!important;
}

</style>