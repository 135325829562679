<template>
    <div id="osn">
        <el-row type="flex" justify="space-between" class="about-parents">
            <el-col :xs="24" class="otst opekun">
                <el-row class="preview-header">
                    <h3>Данные родителя/опекуна</h3>
                    <el-button id="pp_11" v-if="this.disabledinfoabpat === true && checkRole" @click="disabledinfoabpatient" type="primary" icon="el-icon-edit" circle></el-button>
                    <el-button id="pp_12" v-if="this.disabledinfoabpat === false" @click="submit_information_about_parents('infoAboutParent')" type="primary">Сохранить</el-button>
                </el-row>

                <el-row class="preview" v-if="this.disabledinfoabpat === true">
                    <el-col class="fio">
                        {{infoAboutParent.whoYouAreToThePatient}}:
                        {{ checkRolePI ? (infoAboutParent.last_name ? hideData(infoAboutParent.last_name) : '') : (infoAboutParent.last_name !== null ? infoAboutParent.last_name : '-') }}
                        {{ infoAboutParent.first_name !== null ? infoAboutParent.first_name : '-' }}
                        {{ checkRolePI ? (infoAboutParent.patronymic ? hideData(infoAboutParent.patronymic) : '') : (infoAboutParent.patronymic !== null ? infoAboutParent.patronymic : '-') }}
                    </el-col>
                    <el-col>E-mail: {{ checkRolePI ? hideData(infoAboutParent.email, 'email') : (infoAboutParent.email !== null ? infoAboutParent.email : '-') }}</el-col>
                    <el-col>Телефон: {{ checkRolePI ? hideData(infoAboutParent.phone, 'phone') : (infoAboutParent.phone !== null ? infoAboutParent.phone : '-') }}</el-col>
                </el-row>

                <el-form v-if="this.disabledinfoabpat === false" :label-position="labelPosition" :model="infoAboutParent" :rules="information_about_parents_val" ref="infoAboutParent" class="demo-ruleForm">
                    <el-form-item label="Фамилия" prop="last_name">
                        <el-input id="pp_13" :disabled="disabledinfoabpat" clearable v-model="infoAboutParent.last_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Имя" prop="first_name">
                        <el-input id="pp_14" :disabled="disabledinfoabpat" clearable v-model="infoAboutParent.first_name"></el-input>
                    </el-form-item>
                    <el-form-item label="Отчество" prop="patronymic">
                        <el-input id="pp_15" :disabled="disabledinfoabpat" clearable v-model="infoAboutParent.patronymic"></el-input>
                    </el-form-item>
                    <el-form-item
                        prop="email"
                        label="E-mail"
                        :rules="[
                        { required: true, message: 'Пожалуйста введите Ваш e-mail', trigger: 'blur' },
                        { type: 'email', message: 'Пожалуйста введите E-mail адрес корректно', trigger: ['blur', 'change'] }
                        ]"
                    >
                        <el-input id="pp_16" :disabled="disabledinfoabpat" clearable v-model="infoAboutParent.email"></el-input>
                    </el-form-item>
                    <el-form-item label="Телефон" prop="phone" class="el-form-item is-required">
                        <the-mask id="pp_17" :disabled="disabledinfoabpat" v-model="infoAboutParent.phone" class="idmask el-input__inner" placeholder="+7 (___) ___-__-__" :masked="true" :mask="['+7 (###) ###-##-##']" />
                    </el-form-item>

                    <el-form-item prop="whoYouAreToThePatient" label="Кем Вы приходитесь пациенту?">
                        <!-- <el-input
                        v-model="infoAboutParent.whoYouAreToThePatient"
                        v-if="this.disabledinfoabpat === false"
                        :disabled="disabledinfoabpat"
                        type="textarea"
                        :rows="2"
                        placeholder="Введите текст">
                        </el-input> -->
                        <el-select id="pp_18" v-if="this.disabledinfoabpat === false" :disabled="disabledinfoabpat" v-model="infoAboutParent.relationshipDegreeId">
                            <el-option v-for="item in relationshipDegrees" :key="item.id" :label="item.name" :value="item.id" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {TheMask} from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        TheMask
    },
    data() {
        return {
            relationshipDegrees: [],
            labelPosition: 'left',
            disabledinfoabpat: true,
            optionsart: [
                {value: 'Сестра',label: 'Сестра'},
                {value: 'Брат',label: 'Брат'},
                {value: 'Мама',label: 'Мама'},
                {value: 'Папа',label: 'Папа'},
                {value: 'Дедушка по линии отца',label: 'Дедушка по линии отца'},
                {value: 'Дедушка по линии матери',label: 'Дедушка по линии матери'},
                {value: 'Бабушка по линии отца',label: 'Бабушка по линии отца'},
                {value: 'Бабушка по линии матери',label: 'Бабушка по линии матери'}
            ],
            information_about_parents_val: {
                last_name: [
                    { required: true, message: 'Пожалуйста заполните Фамилию', trigger: 'blur' },
                    { min: 3, message: 'Длина должна быть не менее 3 символов', trigger: 'blur' }
                ],
                first_name: [
                    { required: true, message: 'Пожалуйста заполните Имя', trigger: 'blur' },
                    { min: 3, message: 'Длина должна быть не менее 3 символов', trigger: 'blur' }
                ],
                patronymic: [
                    { required: true, message: 'Пожалуйста заполните Отчество', trigger: 'blur' },
                    { min: 3, message: 'Длина должна быть не менее 3 символов', trigger: 'blur' }
                ],
                whoYouAreToThePatient: [
                    { required: true, message: 'Пожалуйста введите текст', trigger: 'change' }
                ],
                phone: [
                    { required: true, message: 'Пожалуйста введите номер телефона', trigger: 'change' }
                ],
            }
        }
    },
    computed: {
		...mapGetters({
			infoAboutParent: 'passportPart/infoAboutParent',
			uss: 'user/user',
            apiUrl: 'apiUrl'
		}),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.uss.CanRead : (this.$route.query.myPatient == 'true' ? true : this.uss.role !== 2)
        },
        checkRolePI() {
            return this.$route.query.myPatient == 'false' ? !this.uss.PersonalInformation : false
        }
	},
    methods: {
        ...mapActions({
            getInformAboutPar: 'passportPart/getInformAboutPar',
            savInformAboutPar(dispatch) {
               dispatch('passportPart/savInformAboutPar', this.infoAboutParent)
            }
		}),
        submit_information_about_parents(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.disabledinfoabpat = true
                    this.savInformAboutPar();
                    this.$message({
                        message: 'Данные успешно сохранены!',
                        type: 'success'
                    });
                } else {
                    this.$message.error('Заполните обязательные поля!!!');
                    return false;
                }
            });
        },
        disabledinfoabpatient: function() {
            this.disabledinfoabpat = false
        },
        hideData(str, name) {
            let res = '', i = 0, i_length = str.length, secondPart = '';

            //если email
            if(name == 'email') {
                secondPart = `@${str.split('@')[1]}`
                str = str.split('@')[0]
                i_length = i_length - secondPart.length
            }

            //если телефон
            if(name == 'phone') {
                secondPart = `-${str.split('-')[1]}`
                str = str.split('-')[0]
                i_length = i_length - secondPart.length
            }

            for (i = 0; i < i_length; i++) {
                if(name == 'email' && i == 0) {
                    res += str[0]
                } else if (name == 'email' && i == (i_length-1)){
                    res += str[(i_length-1)]
                } else if (name == 'phone') {
                    if(str[i] !== '+' && str[i] !== '(' && str[i] !== ')' && str[i] !== ' ') {
                        res += '*'
                    } else {
                        res += str[i]
                    }
                } else {
                    res += '*'
                }
            }

            if(name == 'email' || name == 'phone') res += secondPart

            return res
        }
    },
    created() {
        this.getInformAboutPar();
        this.api.get(`${this.apiUrl}/api/lookups/relationship-degrees?lookupName=relationship-degrees`)
        .then(res => { this.relationshipDegrees = res.data.filter(f => !f.IsHidden) })
    }
}
</script>
