var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"table"}},[(_vm.user.role !== 2)?_c('el-button',{staticStyle:{"margin-top":"10px","margin-bottom":"15px"},attrs:{"type":"primary","icon":"el-icon-plus","round":""},on:{"click":_vm.newpr}},[_vm._v("Новая реабилитация")]):_vm._e(),(false)?_c('span',[_vm._v("Загрузка...")]):_vm._e(),_c('dx-data-grid',{ref:'grid',attrs:{"data-source":_vm.dataReability,"remote-operations":false,"allow-column-reordering":true,"hover-state-enabled":true,"wordWrapEnabled":true,"row-alternation-enabled":false,"showColumnLines":false,"showRowLines":true,"showBorders":false},on:{"editing-start":_vm.onEditingStart,"init-new-row":_vm.initNewRow,"row-inserting":_vm.onRowInserting,"row-updating":_vm.onRowUpdating,"row-removed":_vm.onRowRemoved,"content-ready":_vm.onContentReady},scopedSlots:_vm._u([{key:"vidRehabil",fn:function(data){return _c('div',{},[(data.data.value.type === 'Физиотерапия')?_c('span',[_vm._v(_vm._s(data.data.value.type +" "+ (data.data.value.fizcomment ? "("+ data.data.value.fizcomment +")" : '')))]):_vm._e(),(data.data.value.type !== 'Физиотерапия')?_c('span',[_vm._v(_vm._s(data.data.value.type))]):_vm._e()])}},{key:"vidRehabilitation",fn:function(data){return _c('div',{},[(!data.data.value)?_c('dx-select-box',{attrs:{"items":_vm.vr,"displayExpr":"name","valueExpr":"name","searchEnabled":true,"onValueChanged":function(e) {
                data.data.setValue({type:e.value, fizcomment: data.data.fizcomment})}},on:{"selectionChanged":_vm.selectionChanged}}):_vm._e(),(_vm.fizter === 3 && !data.data.value)?_c('dx-text-area',{staticStyle:{"margin-top":"10px"},attrs:{"height":"70","value":data.data.fizcomment,"onValueChanged":function(e) {
                data.data.setValue({fizcomment:e.value, type: data.data.type})},"placeholder":"Уточните название процедуры (Обязательно для заполнения)"}}):_vm._e(),(data.data.value)?_c('dx-select-box',{attrs:{"items":_vm.vr,"displayExpr":"name","valueExpr":"name","value":data.data.value.type,"onValueChanged":function(e) {
                data.data.setValue({type:e.value, fizcomment: data.data.fizcomment})}},on:{"selectionChanged":_vm.selectionChanged}}):_vm._e(),(_vm.fizter === 3 && data.data.value)?_c('dx-text-area',{staticStyle:{"margin-top":"10px"},attrs:{"height":"70","value":data.data.value.fizcomment,"onValueChanged":function(e) {
                data.data.setValue({fizcomment:e.value, type: data.data.type})},"placeholder":"Уточните название процедуры (Обязательно для заполнения)"}}):_vm._e()],1)}},{key:"date_start_edit",fn:function(data){return _c('div',{},[(!data.data.value)?_c('dx-date-box',{staticStyle:{"width":"100%"},attrs:{"display-format":"dd.MM.yyyy","min":new Date(_vm.patDate.birth_date),"max":new Date(),"type":"date","onValueChanged":function(e) {
                data.data.setValue(e.value)}},on:{"valueChanged":_vm.valueChanged}}):_vm._e(),(data.data.value)?_c('dx-date-box',{staticStyle:{"width":"100%"},attrs:{"value":data.data.value,"display-format":"dd.MM.yyyy","min":new Date(_vm.patDate.birth_date),"max":new Date(),"type":"date","onValueChanged":function(e) {
                data.data.setValue(e.value)}},on:{"valueChanged":_vm.valueChanged}}):_vm._e()],1)}},{key:"date_end_cell",fn:function(data){return _c('div',{},[(data.data.value === null)?_c('span',[_vm._v("По настоящее время")]):_c('span',[_vm._v(_vm._s(new Date(data.data.value).toLocaleDateString("ru-RU")))])])}},{key:"editCellTemplate",fn:function(data){return _c('div',{},[(data.data.value)?_c('dx-date-box',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.checkEvent === true,"type":"date","dateSerializationFormat":"yyyy-MM-ddTHH:mm:ss","display-format":"dd.MM.yyyy","value":_vm.checkEvent === true ? null : data.data.value,"onValueChanged":function(e) {
                if(_vm.checkEvent === true){
                    data.data.setValue(null)
                }else{
                    data.data.setValue(e.value)
                }}}}):_vm._e(),(!data.data.value)?_c('dx-date-box',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.checkEvent === true,"type":"date","dateSerializationFormat":"yyyy-MM-ddTHH:mm:ss","display-format":"dd.MM.yyyy","onValueChanged":function(e) {
                if(_vm.checkEvent === true){
                    data.data.setValue(null)
                }else{
                    data.data.setValue(e.value)
                }}}}):_vm._e()],1)}},{key:"cell-city_checkbox-edit",fn:function(data){return _c('div',{},[(data.data.value)?_c('dx-check-box',{attrs:{"text":"По настоящее время","value":data.data.value,"onValueChanged":function(e) {
                    data.data.setValue(e.value)}},on:{"valueChanged":_vm.valueChangedCheck}}):_vm._e(),(!data.data.value)?_c('dx-check-box',{attrs:{"text":"По настоящее время","onValueChanged":function(e) {
                    data.data.setValue(e.value)}},on:{"valueChanged":_vm.valueChangedCheck}}):_vm._e()],1)}}])},[_c('dx-column',{attrs:{"data-field":"date_start","caption":"Дата начала","alignment":"center","sort-order":"desc","validationRules":_vm.validationRul,"data-type":"date","edit-cell-template":"date_start_edit"}}),_c('dx-column',{attrs:{"data-field":"date_end","caption":"Дата окончания","alignment":"center","validationRules":_vm.validationEndDate,"data-type":"date","edit-cell-template":"editCellTemplate","cell-template":"date_end_cell","cssClass":"no_outline_cell","header-filter":{dataSource: _vm.headerFilterEndDate}}}),_c('dx-column',{attrs:{"data-field":"date_end_checkbox","edit-cell-template":"cell-city_checkbox-edit","visible":false}}),_c('dx-column',{attrs:{"data-field":"type_rehabil","caption":"Вид реабилитации","alignment":"center","validationRules":_vm.validationRul,"edit-cell-template":"vidRehabilitation","cell-template":"vidRehabil","dataType":"string","cssClass":"no_outline_cell"}}),_c('dx-filter-row',{attrs:{"visible":true}}),_c('dx-header-filter',{attrs:{"visible":true}}),_c('dx-editing',{attrs:{"mode":"popup","allowUpdating":_vm.user.role !== 2,"allowDeleting":true,"useIcons":true,"form":_vm.form}},[_c('DxPopup',{attrs:{"width":_vm.getWindowWidth,"showTitle":true,"title":_vm.edit == 1 ? 'Добавить реабилитацию' : 'Редактировать реабилитацию',"height":"auto"}})],1),_c('dx-column',{attrs:{"data-field":"edit_but","width":130,"caption":"","buttons":_vm.editButtons,"type":"buttons"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }