<template>
    <div id="table">
        <span v-if="false">Загрузка...</span>
            <dx-data-grid
                :ref="'grid'"
                :data-source="numberOfNotifications"
                :remote-operations="false"
                :allow-column-reordering="true" 
                :hover-state-enabled="true"
                :wordWrapEnabled="true"
                :row-alternation-enabled="false"
                :showColumnLines="false"
                :showRowLines="true"
                :showBorders="false"
                >
            <dx-column
            data-field="created"
            caption="Дата"
            alignment="center"
            data-type="date"
            />
            <dx-column
            data-field="patient_fio"
            caption="ФИО пациента"
            alignment="center"
            />
            <dx-column
            data-field="parameter"
            caption="Показатель"
            alignment="center"
            />
            <dx-column
            data-field="value"
            caption="Текущее значение"
            alignment="center"
            />
            <dx-column
            data-field="limits"
            caption="Норма анализа"
            alignment="center"
            :allowFiltering="false"
            />
            <dx-paging :page-size="6"/>
            <dx-filter-row 
            :visible="true"
            />
            <dx-header-filter 
            :visible="true"
            />
            </dx-data-grid>
    </div>
</template>

<script>
import 'devextreme-intl';
import { DxDataGrid, DxColumn, DxPaging, DxEditing, DxHeaderFilter, DxFilterRow } from "devextreme-vue/ui/data-grid";
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages } from 'devextreme/localization';
import axios from 'axios'
loadMessages(ruMessages);
locale(navigator.language || navigator.browserLanguage);
import { mapGetters, mapActions } from 'vuex';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import $ from 'jquery';
export default {
    components: {
        DxDataGrid, 
        DxColumn, 
        DxPaging,
        DxHeaderFilter,
        DxFilterRow,
        DxEditing
    },
    computed: {
        ...mapGetters({
            notifications: 'personalDoctorsOffice/notifications',
        }),
        numberOfNotifications() {
            this.notificFalse = []
            for(let i=0;i<this.notifications.length;i++) {
                if(this.notifications[i].seen === true) {
                    this.notificFalse.push(this.notifications[i])
                }
            }
            return this.notificFalse
        },
    },
    methods: {
        ...mapActions({
            // savePutDiagnosys(dispatch) {
            //    dispatch('personalDoctorsOffice/savePutDiagnosys', this.diagnosys)
            // },
        }),
    },
    data() {
        return {
            notificFalse: [],
            onToolbarPreparing:(e)=> {
                e.toolbarOptions.items.unshift({
                    location: 'after',
                    widget: 'dxButton',
                    options: {
                        icon: 'add',
                        text: 'Добавить пациента',
                        onClick:()=> {
                            this.$refs["grid"].instance.addRow();
                        }
                    }
                });
            },
        }
    }
}
</script>

