<template>
	<v-card>
		<v-card-title>
			<v-btn v-if="user.role !== 2" @click="newRow" color="#6374dd" dark>Новый препарат</v-btn>
			
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:search="search"
			:items="list"
			:loading="loading"
			sort-by="dnp"
        	:sort-desc="true"
			no-data-text="Лекарственные препараты отсутствуют..."
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<!-- Причина пропуска-->
			<template v-slot:[`item.medicineSkippingReason`]="{ item }">
				{{ item.medicineSkippingReason ? item.medicineSkippingReason.name : '' }}
			</template>

			<!-- кнопки редактирование/просмотра/удаления -->
			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="checkRole" @click="onEditingStart(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn v-if="user.role !== 2 && new Date().getTime() - item.creation_date < 86400000" @click="deleteItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>

		<!-- Окно создания/редактирования -->
		<v-dialog persistent v-model="dialog" max-width="1200px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ !editeRow ? 'Добавить' : 'Редактировать' }} препарат</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="!editeRow ? save(obj) : onRowUpdating(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>
						<v-form ref="form" v-model="valid" lazy-validation class="mt-2">
                            <v-row>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-combobox
										:rules="[rules.required]"
										append-icon=""
                                        v-model="obj.tnp"
                                        :items="listMedPreparat"
                                        label="Название лекарства *"
                                        placeholder="Начните вводить для поиска..."
                                        outlined
										dense
										hide-details
                                    />
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-select :menu-props="{ bottom: true, offsetY: true }" dense hide-details label="Форма выпуска *" :rules="[rules.required]" item-text="name" item-value="name" outlined :items="tlf" v-model="obj.tlf" />
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-text-field dense hide-details outlined label="МНН" v-model="obj.mnn" />
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-select :menu-props="{ bottom: true, offsetY: true }" dense hide-details label="Обеспечение лекарствами *" :rules="[rules.required]" item-text="name" item-value="name" outlined :items="oblek" v-model="obj.obesplek" />
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-select @change="getList(`api/lookups/units/${obj.pv.id}`, 'ei')" :menu-props="{ bottom: true, offsetY: true }" dense hide-details label="Путь введения *" :rules="[rules.required]" item-text="name" item-value="name" return-object outlined :items="pv" v-model="obj.pv" />
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense hide-details outlined :rules="[rules.required]" v-model="dateFormatted" label="Дата начала приема *" readonly v-bind="attrs" v-on="on" />
                                        </template>

                                        <v-date-picker :max="obj.dop.date ? toJSONLocal(obj.dop.date) : toJSONLocal(null)" :min="toJSONLocal(bday.birth_date)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.dnp" @change="saveDate" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-select :disabled="!obj.pv" :hide-details="obj.pv ? true : false" persistent-hint hint="Выберите путь введения, что бы разблокировать это поле..." :menu-props="{ bottom: true, offsetY: true }" dense label="Единицы измерения *" :rules="[rules.required]" item-text="name" item-value="name" outlined :items="ei" v-model="obj.ei" />
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
									<v-row>
										<v-col cols="12" sm="12" md="6" xl="6">
											<v-menu ref="menuDOP" v-model="menuDOP" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
												<template v-slot:activator="{ on, attrs }">
													<v-text-field :disabled="obj.dop.checkbox" dense hide-details outlined :rules="[obj.dop.checkbox ? true : rules.required]" v-model="dateFormattedOP" label="Дата окончания приема *" readonly v-bind="attrs" v-on="on" />
												</template>

												<v-date-picker :min="obj.dnp ? toJSONLocal(obj.dnp) : toJSONLocal(bday.birth_date)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.dop.date" @change="saveDateDOP" />
											</v-menu>
										</v-col>
										<v-col cols="12" sm="12" md="6" xl="6">
											<v-checkbox color="#6b59c7" dense hide-details v-model="obj.dop.checkbox" @change="objDopCheckbox(obj.dop)" label="По настоящее время" />
										</v-col>
									</v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-text-field dense hide-details type="number" outlined :rules="[rules.required]" label="Средняя разовая доза *" v-model="obj.srd" />
                                </v-col>
                                <v-col cols="12" sm="12" md="6" xl="6">
                                    <v-select :menu-props="{ bottom: true, offsetY: true }" dense hide-details label="Кратность *" :rules="[rules.required]" item-text="name" item-value="name" outlined :items="kr" v-model="obj.krat" />
                                </v-col>

                                <v-col cols="12" sm="12" md="6" xl="6">
									<v-row>
										<v-col cols="12" sm="12" md="6" xl="6">
											<v-menu ref="menuMedSkipStart" v-model="menuMedSkipStart" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
												<template v-slot:activator="{ on, attrs }">
													<v-text-field dense hide-details outlined :rules="[!obj.medicineSkippingEndDate ? true : rules.required]" v-model="dateFormattedMedicineSkippingStartDate" label="Пропуск препарата ОТ" readonly v-bind="attrs" v-on="on" />
												</template>

												<v-date-picker :min="toJSONLocal(bday.birth_date)" :max="obj.medicineSkippingEndDate ? toJSONLocal(obj.medicineSkippingEndDate) : toJSONLocal(null)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.medicineSkippingStartDate" @change="saveDateMedSkipStart" />
											</v-menu>
										</v-col>


										<v-col cols="12" sm="12" md="6" xl="6">
											<v-menu ref="menuMedSkipEnd" v-model="menuMedSkipEnd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
												<template v-slot:activator="{ on, attrs }">
													<v-text-field dense hide-details outlined :rules="[!obj.medicineSkippingStartDate ? true : rules.required]" v-model="dateFormattedMedicineSkippingEndDate" label="Пропуск препарата ДО" readonly v-bind="attrs" v-on="on" />
												</template>

												<v-date-picker :min="obj.medicineSkippingStartDate ? toJSONLocal(obj.medicineSkippingStartDate) : toJSONLocal(bday.birth_date)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.medicineSkippingEndDate" @change="saveDateMedSkipEnd" />
											</v-menu>
										</v-col>
									</v-row>
                                </v-col>
								
								<v-col cols="12" sm="12" md="6" xl="6">
                                    <v-select :menu-props="{ bottom: true, offsetY: true }" dense hide-details label="Причина пропуска" :rules="[obj.medicineSkippingStartDate || obj.medicineSkippingEndDate ? rules.required : true]" item-text="name" item-value="id" outlined :items="medicineSkippingReasons" v-model="obj.medicineSkippingReasonId" />
                                </v-col>


                                <v-col cols="12" sm="12" md="12" xl="12">
                                    <v-textarea dense hide-details outlined :rules="[!obj.dop.checkbox ? rules.required : true]" :label="`Причина окончания приема ${!obj.dop.checkbox ? '*' : ''}`" rows="2" v-model="obj.pop" />
                                </v-col>
                            </v-row>
                        </v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
//остальное
import { mapGetters, mapActions } from 'vuex';

export default {
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			bday: 'passportPart/patientData',
			user: 'user/user',
			patient: 'user/patient',
            listMedPreparat: 'treatment/listMedPreparat'
        }),
		dateFormatted() {
            if(this.obj.dnp)
                return new Date(this.obj.dnp).toLocaleDateString("ru-RU");
            return null;
        },
		dateFormattedOP() {
            if(this.obj.dop.date)
                return new Date(this.obj.dop.date).toLocaleDateString("ru-RU");
            return null;
        },
		dateFormattedMedicineSkippingEndDate() {
            if(this.obj.medicineSkippingEndDate)
                return new Date(this.obj.medicineSkippingEndDate).toLocaleDateString("ru-RU");
            return null;
        },
		dateFormattedMedicineSkippingStartDate() {
            if(this.obj.medicineSkippingStartDate)
                return new Date(this.obj.medicineSkippingStartDate).toLocaleDateString("ru-RU");
            return null;
        },
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? false : this.user.role !== 2)
        }
	},
	data() {
		return {
			isLoadingEI: false,
			medicineSkippingReasons: [],
			tlf : [],
			oblek: [],
			pv: [],
			ei: [],
			kr: [],
			editeRow: false,
			showSysData: [],
            selectedPoint: '',
			file: null,
			search: '',
			maxDate: new Date().toISOString().substr(0, 10),
			menu: false,
			menuDOP: false,
			menuMedSkipStart: false,
			menuMedSkipEnd: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				creation_date: null,
                dnp: null,
                dop: {date: null, checkbox: null},
                ei: null,
                id: null,
                krat: null,
                medicineSkippingEndDate: null,
                medicineSkippingReasonId: null,
                medicineSkippingStartDate: null,
                mnn: null,
                obesplek: null,
                pop: null,
                pv: null,
                srd: null,
                tlf: null,
                tnp: null
			},
			dialog: false,
			list: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.'
			},
			headers: [
				{ text: "Дата начала приема", value: "dnpDate", align: 'center' },
				{ text: "Дата окончания приема", value: "dopDate", align: 'center', sortable: false },
				{ text: "МНН", value: "mnn", align: 'center', sortable: false },
				{ text: "Название лекарства", value: "tnp", align: 'center', sortable: false },
				{ text: "Путь введения", value: "pv", align: 'center', sortable: false },
				{ text: "Форма выпуска", value: "tlf", align: 'center', sortable: false },
				{ text: "Единицы измерения", value: "ei", align: 'center', sortable: false },
				{ text: "Средняя разовая доза", value: "srd", align: 'center', sortable: false },
				{ text: "Кратность", value: "krat", align: 'center', sortable: false },
				{ text: "Причина окончания приема", value: "pop", align: 'center', sortable: false },
				{ text: "Срок пропуска препарата", value: "medicineSkippingStartDateAndEndDate", align: 'center', sortable: false },
				{ text: "Причина пропуска", value: "medicineSkippingReason", align: 'center', sortable: false },
				{ text: "Обеспечение лекарствами", value: "obesplek", align: 'center', sortable: false },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		...mapActions({
			getListMedPreparat: 'treatment/getListMedPreparat'
		}),
		close() {
			this.obj = {
				creation_date: null,
                dnp: null,
                dop: {date: null, checkbox: null},
                ei: null,
                id: null,
                krat: null,
                medicineSkippingEndDate: null,
                medicineSkippingReasonId: null,
                medicineSkippingStartDate: null,
                mnn: null,
                obesplek: null,
                pop: null,
                pv: null,
                srd: null,
                tlf: null,
                tnp: null
			}
			this.$refs.form.resetValidation()
			this.editeRow = false
			this.dialog = false
		},
        getList(url, name) {
            this.api.get(`${this.apiUrl}/${url}`)
            .then(res => {
                this[name] = res.data
            })
        },
		async apiMethods(url, name, flag) {
			this.loading = false			
			let res = [];
			
			if(flag){
				res = (await this.api.get(`${url}/${name}`)).data
				await res.forEach(el => {
					el.dnpDate = new Date(el.dnp).toLocaleDateString()
					el.dopDate = el.dop.checkbox ? 'По настоящее время' : new Date(el.dop.date).toLocaleDateString()
					if(el.medicineSkippingStartDate && el.medicineSkippingEndDate) {
						el.medicineSkippingStartDateAndEndDate = `${new Date(el.medicineSkippingStartDate).toLocaleDateString()} - ${new Date(el.medicineSkippingEndDate).toLocaleDateString()}`
					}
				})
			} else {
				res = (await this.api.get(`${url}/${name}`)).data.filter(f => !f.IsHidden)
			}

			return res
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/patients/${this.patient.patients_id}/treatments/${row.id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)

						this.snackBarFunc(true, 'Запись успешно удалена!!!', 'success')
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				this.api.post(`${this.apiUrl}/api/patients/${this.patient.patients_id}/treatments`, {
					creation_date: new Date().getTime(),
					dnp: new Date(obj.dnp).getTime(),
					dop: {date: obj.dop.date ? new Date(obj.dop.date).getTime() : null, checkbox: obj.dop.checkbox},
					ei: obj.ei,
					krat: obj.krat,
					medicineSkippingEndDate: obj.medicineSkippingEndDate,
					medicineSkippingReasonId: obj.medicineSkippingReasonId,
					medicineSkippingStartDate: obj.medicineSkippingStartDate,
					mnn: obj.mnn,
					obesplek: obj.obesplek,
					pop: obj.pop,
					pv: typeof obj.name == 'string' ? obj.name : obj.pv.name,
					srd: obj.srd,
					tlf: obj.tlf,
					tnp: obj.tnp
				})
				.then(res => {
					if(res.status == 200) {
						res.data.dnpDate = new Date(res.data.dnp).toLocaleDateString()
						res.data.dopDate = res.data.dop.checkbox ? 'По настоящее время' : new Date(res.data.dop.date).toLocaleDateString()
						if(res.data.medicineSkippingStartDate && res.data.medicineSkippingEndDate) {
							res.data.medicineSkippingStartDateAndEndDate = `${new Date(res.data.medicineSkippingStartDate).toLocaleDateString()} - ${new Date(res.data.medicineSkippingEndDate).toLocaleDateString()}`
						}

						this.list.unshift(res.data)

						this.snackBarFunc(true, 'Лекарственный препарат успешно создан!!!', 'success')
						this.close()
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		saveDate(date) {
			this.$refs.menu.save(date)
		},
		saveDateDOP(date) {
			this.$refs.menuDOP.save(date)
		},
		saveDateMedSkipStart(date) {
			this.$refs.menuMedSkipStart.save(date)
		},
		saveDateMedSkipEnd(date) {
			this.$refs.menuMedSkipEnd.save(date)
		},
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
        newRow() {
            this.editeRow = false
            this.dialog = true
        },
		onEditingStart(row) {
			if(row.pv) this.getList(`api/lookups/units/${this.pv.find(f => f.name == row.pv).id}`, 'ei')

			if(row.medicineSkippingReason && row.medicineSkippingReason.id) row.medicineSkippingReasonId = row.medicineSkippingReason.id
			row.dnp = this.toJSONLocal(row.dnp)
			
			if(row.dop.date) row.dop.date = this.toJSONLocal(row.dop.date)
			if(row.medicineSkippingStartDate) row.medicineSkippingStartDate = this.toJSONLocal(row.medicineSkippingStartDate)
			if(row.medicineSkippingEndDate) row.medicineSkippingEndDate = this.toJSONLocal(row.medicineSkippingEndDate)
			
			this.editeRow = true
			this.obj = Object.assign({}, row)
			this.dialog = true
		},
		onRowUpdating(obj) {
            if(this.$refs.form.validate()) {
				this.api.put(`${this.apiUrl}/api/patients/${this.patient.patients_id}/treatments/${obj.id}`, {
					creation_date: obj.creation_date,
					dnp: new Date(obj.dnp).getTime(),
					dop: {date: obj.dop.date ? new Date(obj.dop.date).getTime() : null, checkbox: obj.dop.checkbox},
					ei: obj.ei,
					krat: obj.krat,
					medicineSkippingEndDate: obj.medicineSkippingEndDate,
					medicineSkippingReasonId: obj.medicineSkippingReasonId,
					medicineSkippingStartDate: obj.medicineSkippingStartDate,
					mnn: obj.mnn,
					obesplek: obj.obesplek,
					pop: obj.pop,
					pv: typeof obj.name == 'string' ? obj.name : obj.pv.name,
					srd: obj.srd,
					tlf: obj.tlf,
					tnp: obj.tnp
				})
				.then(res => {
					if(res.status == 200) {
						res.data.dnpDate = new Date(res.data.dnp).toLocaleDateString()
						res.data.dopDate = res.data.dop.checkbox ? 'По настоящее время' : new Date(res.data.dop.date).toLocaleDateString()
						if(res.data.medicineSkippingStartDate && res.data.medicineSkippingEndDate) {
							res.data.medicineSkippingStartDateAndEndDate = `${new Date(res.data.medicineSkippingStartDate).toLocaleDateString()} - ${new Date(res.data.medicineSkippingEndDate).toLocaleDateString()}`
						}

						this.list.forEach(el => {
							if(el.id == res.data.id) Object.assign(el, res.data)
						})

						this.snackBarFunc(true, 'Лекарственный препарат успешно обновлен!!!', 'success')
						this.close()
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		objDopCheckbox(dop) {
			if(dop.checkbox) dop.date = null
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/patients/${this.patient.patients_id}/treatments`, true)

		this.medicineSkippingReasons = await this.apiMethods(this.apiUrl, `api/lookups/medicine-skipping-reasons?lookupName=medicine-skipping-reasons`, false)
		this.tlf = await this.apiMethods(this.apiUrl, `api/lookups/drug-release-forms?lookupName=drug-release-forms`, false)
		this.oblek = await this.apiMethods(this.apiUrl, `api/lookups/drug-provision-types?lookupName=drug-provision-types`, false)
		this.pv = await this.apiMethods(this.apiUrl, `api/lookups/drug-using-methods?lookupName=drug-using-methods`, false)
		// this.ei = await this.apiMethods(this.apiUrl, `api/lookups/units?lookupName=units`, false)
		this.kr = await this.apiMethods(this.apiUrl, `api/lookups/drug-use-rates?lookupName=drug-use-rates`, false)
        
		this.getListMedPreparat()
	}
};
</script>