<template>
    <div id="osn">
        <el-row type="flex" justify="space-between" class="about-parents">
            <el-col :xs="24" class="otst semanam">
                <el-row class="preview-header">
                    <h3>Семейный анамнез</h3>
                    <el-button id="pp_19" v-if="this.disabledsemanamnez === true && checkRole" @click="disabledsemana" type="primary" icon="el-icon-edit" circle></el-button>
                    <el-button id="pp_20" v-if="this.disabledsemanamnez === false" @click="submit_family_history('family_history')" type="primary">Сохранить</el-button>
                </el-row>

                <el-row class="preview" v-if="this.disabledsemanamnez === true">
                    <el-col :xs="24" :md="12">
                        <el-col>
                            <div>
                                <span :style="{'padding-left': '10px', 'padding-right': '10px'}"><b>Артрит:</b></span>
                                <span>{{family_history.radioart == 1? 'Да': 'Нет'}}</span>    
                            </div>
                            <div v-if="family_history.radioart == 1" :style="{'margin-top': '-5px','line-height': '1.2'}">
                                {{"("+ family_history.valueart.join(', ') +")"}}
                            </div>
                        </el-col>
                        <el-col>
                            <div>
                                <span :style="{'padding-left': '10px', 'padding-right': '10px'}"><b>Псориаз:</b></span>
                                <span>{{family_history.radiopsor == 1? 'Да': 'Нет'}}</span>    
                            </div>
                            <div v-if="family_history.radiopsor == 1" :style="{'margin-top': '-5px','line-height': '1.2'}">
                                {{"("+ family_history.valuepsor.join(', ') +")"}}
                            </div>
                        </el-col>
                        <el-col>
                            <div>
                                <span :style="{'padding-left': '10px', 'padding-right': '10px'}"><b>Болезнь Крона:</b></span>
                                <span>{{family_history.radiokron == 1? 'Да': 'Нет'}}</span>    
                            </div>
                            <div v-if="family_history.radiokron == 1" :style="{'margin-top': '-5px','line-height': '1.2'}">
                                {{"("+ family_history.valuekron.join(', ') +")"}}
                            </div>
                        </el-col>
                        <el-col>
                            <div>
                                <span :style="{'padding-left': '10px', 'padding-right': '10px'}"><b>Язвенный колит:</b></span>
                                <span>{{family_history.radioyazkol == 1? 'Да': 'Нет'}}</span>    
                            </div>
                            <div v-if="family_history.radioyazkol == 1" :style="{'margin-top': '-5px','line-height': '1.2'}">
                                {{"("+ family_history.valueyazkol.join(', ') +")"}}
                            </div>
                        </el-col>
                    </el-col>

                    <el-col :md="24" :lg="12">
                        <el-col>
                            <div>
                                <span :style="{'padding-left': '10px', 'padding-right': '10px'}"><b>Болезнь Бехтерева:</b></span>
                                <span>{{family_history.radiobolbeh == 1? 'Да': 'Нет'}}</span>    
                            </div>
                            <div v-if="family_history.radiobolbeh == 1" :style="{'margin-top': '-5px','line-height': '1.2'}">
                                {{"("+ family_history.valuebolbeh.join(', ') +")"}}
                            </div>
                        </el-col>
                        <el-col>
                            <div>
                                <span :style="{'padding-left': '10px', 'padding-right': '10px'}"><b>Увеит:</b></span>
                                <span>{{family_history.radiobouveit == 1? 'Да': 'Нет'}}</span>    
                            </div>
                            <div v-if="family_history.radiobouveit == 1" :style="{'margin-top': '-5px','line-height': '1.2'}">
                                {{"("+ family_history.valuebouveit.join(', ') +")"}}
                            </div>
                        </el-col>
                        <el-col>
                            <div>
                                <span :style="{'padding-left': '10px', 'padding-right': '10px'}"><b>Болезнь Рейтера:</b></span>
                                <span>{{family_history.radiobobolrey == 1? 'Да': 'Нет'}}</span>    
                            </div>
                            <div v-if="family_history.radiobobolrey == 1" :style="{'margin-top': '-5px','line-height': '1.2'}">
                                {{"("+ family_history.valuebobolrey.join(', ') +")"}}
                            </div>
                        </el-col>
                    </el-col>
                </el-row>

                <el-form v-if="this.disabledsemanamnez === false" :label-position="labelPosition" :model="family_history" :rules="family_history_val" ref="family_history" class="demo-ruleForm">
                    <el-row>
                        <el-col :md="24" :lg="11">
                            <el-form-item prop="radioart" style="width: 100%;" label="Артрит">
                                <el-radio-group id="pp_21" :disabled="disabledsemanamnez" v-model="family_history.radioart">
                                    <el-radio :label="'1'">Да</el-radio>
                                    <el-radio :label="'2'">Нет</el-radio>

                                    <el-select id="pp_22" :disabled="disabledsemanamnez" v-if="this.family_history.radioart == 1" v-model="family_history.valueart" multiple placeholder="Выбрать">
                                        <el-option
                                        v-for="item in optionsart"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item prop="radiopsor" style="width: 100%;" label="Псориаз">
                                <el-radio-group id="pp_23" :disabled="disabledsemanamnez" v-model="family_history.radiopsor">
                                    <el-radio :label="'1'">Да</el-radio>
                                    <el-radio :label="'2'">Нет</el-radio>

                                    <el-select id="pp_24" :disabled="disabledsemanamnez" v-if="this.family_history.radiopsor == 1" v-model="family_history.valuepsor" multiple placeholder="Выбрать">
                                        <el-option
                                        v-for="item in optionsart"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item prop="radiokron" style="width: 100%;" label="Болезнь Крона">
                                <el-radio-group id="pp_25" :disabled="disabledsemanamnez" v-model="family_history.radiokron">
                                    <el-radio :label="'1'">Да</el-radio>
                                    <el-radio :label="'2'">Нет</el-radio>

                                    <el-select id="pp_26" :disabled="disabledsemanamnez" v-if="this.family_history.radiokron == 1" v-model="family_history.valuekron" multiple placeholder="Выбрать">
                                        <el-option
                                        v-for="item in optionsart"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item prop="radioyazkol" style="width: 100%;" label="Язвенный колит">
                                <el-radio-group id="pp_27" :disabled="disabledsemanamnez" v-model="family_history.radioyazkol">
                                    <el-radio :label="'1'">Да</el-radio>
                                    <el-radio :label="'2'">Нет</el-radio>

                                    <el-select id="pp_28" :disabled="disabledsemanamnez" v-if="this.family_history.radioyazkol == 1" v-model="family_history.valueyazkol" multiple placeholder="Выбрать">
                                        <el-option
                                        v-for="item in optionsart"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :md="24" :lg="11" :offset="1">
                            <el-form-item prop="radiobolbeh" style="width: 100%;" label="Болезнь Бехтерева">
                                <el-radio-group id="pp_29" :disabled="disabledsemanamnez" v-model="family_history.radiobolbeh">
                                    <el-radio :label="'1'">Да</el-radio>
                                    <el-radio :label="'2'">Нет</el-radio>

                                    <el-select id="pp_30" :disabled="disabledsemanamnez" v-if="this.family_history.radiobolbeh == 1" v-model="family_history.valuebolbeh" multiple placeholder="Выбрать">
                                        <el-option
                                        v-for="item in optionsart"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item prop="radiobouveit" style="width: 100%;" label="Увеит">
                                <el-radio-group id="pp_31" :disabled="disabledsemanamnez" v-model="family_history.radiobouveit">
                                    <el-radio :label="'1'">Да</el-radio>
                                    <el-radio :label="'2'">Нет</el-radio>

                                    <el-select id="pp_32" :disabled="disabledsemanamnez" v-if="this.family_history.radiobouveit == 1" v-model="family_history.valuebouveit" multiple placeholder="Выбрать">
                                        <el-option
                                        v-for="item in optionsart"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item prop="radiobobolrey" style="width: 100%;" label="Болезнь Рейтера">
                                <el-radio-group id="pp_33" :disabled="disabledsemanamnez" v-model="family_history.radiobobolrey">
                                    <el-radio :label="'1'">Да</el-radio>
                                    <el-radio :label="'2'">Нет</el-radio>

                                    <el-select id="pp_34" :disabled="disabledsemanamnez" v-if="this.family_history.radiobobolrey == 1" v-model="family_history.valuebobolrey" multiple placeholder="Выбрать">
                                        <el-option
                                        v-for="item in optionsart"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.name">
                                        </el-option>
                                    </el-select>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    data() {
        return {
            labelPosition: 'left',
            disabledsemanamnez: true,
            optionsart: [],
            family_history_val: {
                radioart: [
                    { required: true, message: 'Пожалуйста выберите значение', trigger: 'change' }
                ],
                radiopsor: [
                    { required: true, message: 'Пожалуйста выберите значение', trigger: 'change' }
                ],
                radiokron: [
                    { required: true, message: 'Пожалуйста выберите значение', trigger: 'change' }
                ],
                radioyazkol: [
                    { required: true, message: 'Пожалуйста выберите значение', trigger: 'change' }
                ],
                radiobobolrey: [
                    { required: true, message: 'Пожалуйста выберите значение', trigger: 'change' }
                ],
                radiobolbeh: [
                    { required: true, message: 'Пожалуйста выберите значение', trigger: 'change' }
                ],
                radiobouveit: [
                    { required: true, message: 'Пожалуйста выберите значение', trigger: 'change' }
                ],
            }
        }
    },
    computed: {
		...mapGetters({
			family_history: 'passportPart/familyHistory',
			user: 'user/user',
            apiUrl: 'apiUrl'
		}),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
    methods: {
        disabledsemana() {
            this.disabledsemanamnez = false
        },
        ...mapActions({
            getFamilyHis: 'passportPart/getFamilyHis',
            savFamilyHis(dispatch, e) {
                for(let key in e) {
					if (key.indexOf('radio') > -1 && e[key] == 2) {
						e['value' + key.replace('radio', '')] = [];
					}
                }
                dispatch('passportPart/savFamilyHis', e)
            }
		}),
        checkRelatives() {
            const suffList = [
                { suffix: 'art', label: 'артрит'},
                { suffix: 'psor', label: 'псориаз'},
                { suffix: 'kron', label: 'болезнь Крона'},
                { suffix: 'yazkol', label: 'язвенный колит'},
                { suffix: 'bolbeh', label: 'болезнь Бехтерева'},
                { suffix: 'bouveit', label: 'увеит'},
                { suffix: 'bobolrey', label: 'болезнь Рейтера'},
            ]
            const wrongLabels = []

            suffList.forEach( item => {
                if( this.family_history[`radio${item.suffix}`] === '1') {
                    if( !this.family_history[`value${item.suffix}`].length ) {
                        console.log(`No relative for ${item.label}`)
                        wrongLabels.push(item.label)
                    }
                }
            }) 
            if (wrongLabels.length) {
                this.$message.error(`Заполните родственников для заболевани${wrongLabels.length>1?'й':'я'}: ${wrongLabels.join(', ')}`);
            }

            return wrongLabels.length
        },
        submit_family_history(formName) {
            if(this.checkRelatives()) return

            this.$refs[formName].validate((valid) => {
            if (valid) {
                this.disabledsemanamnez = true
                this.savFamilyHis(this.family_history);
                this.$message({
                    message: 'Данные успешно сохранены!',
                    type: 'success'
                });
            } else {
                this.$message.error('Заполните обязательные поля!!!');
                return false;
            }
            });
        },
    },
    created() {
        this.getFamilyHis();

        this.api.get(`${this.apiUrl}/api/lookups/relatives?lookupName=relatives`)
        .then(res => { this.optionsart = res.data.filter(f => !f.IsHidden) })
    }
}
</script>
