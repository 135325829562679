<template>
    <div class="login-page">
		<div class="form" v-loading="loading">
            <div v-if="routeHome == true">
				<p style="color:rgb(54, 156, 97)">Ваш пароль успешно изменен!</p>
				Вы будете перенаправлены на главную страницу через: 5 сек.
			</div>
			<h3 v-if="routeHome == false" class="h3">Придумайте пароль</h3>
			<el-form v-if="routeHome == false" :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" class="demo-ruleForm">
				<el-form-item label="Введите пароль" prop="pass">
					<el-input type="password" v-model="ruleForm2.pass" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="Повторите пароль" prop="checkPass">
					<el-input type="password" v-model="ruleForm2.checkPass" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm2')">Сохранить и перейти на главную страницу</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
import $ from 'jquery'
import { mapActions } from 'vuex'
export default {
    data() {
		var validatePass = (rule, value, callback) => {
            var s_letters = "qwertyuiopasdfghjklzxcvbnm"; // Буквы в нижнем регистре
            var b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"; // Буквы в верхнем регистре
            var digits = "0123456789"; // Цифры
            var specials = "!@#$%^&*()_-+=\|/.,:;[]{}"; // Спецсимволы
            var is_s = false; // Есть ли в пароле буквы в нижнем регистре
            var is_b = false; // Есть ли в пароле буквы в верхнем регистре
            var is_d = false; // Есть ли в пароле цифры
            var is_sp = false; // Есть ли в пароле спецсимволы
            for (var i = 0; i < value.length; i++) {
                if (!is_s && s_letters.indexOf(value[i]) != -1) is_s = true;
                else if (!is_b && b_letters.indexOf(value[i]) != -1) is_b = true;
                else if (!is_d && digits.indexOf(value[i]) != -1) is_d = true;
                else if (!is_sp && specials.indexOf(value[i]) != -1) is_sp = true;
            }
			if (value === '') {
				callback(new Error('Пожалуйста введите пароль'));
			} else if(value.length < 6){
                callback(new Error('Пароль должен состоять из 6 или более символов, содержать 1 латинскую букву и спецсимвол'));
			} else if((is_b && is_sp && is_d) || (is_s && is_sp && is_d)){
				callback();
            } else {
				callback(new Error('Пароль должен состоять из 6 или более символов, содержать 1 латинскую букву и спецсимвол'));
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('Пожалуйста, введите пароль еще раз'));
			} else if (value !== this.ruleForm2.pass) {
				callback(new Error('Ваши пароли не совпадают!'));
			} else {
				callback();
			}
		};
        return {
			timeRoute: 5,
			routeHome: false,
			loading: false,
            ruleForm2: {
				pass: '',
				checkPass: '',
			},
			rules2: {
				pass: [
					{ validator: validatePass, trigger: 'blur' }
				],
				checkPass: [
					{ validator: validatePass2, trigger: 'blur' }
				]
			}
        }
	},
    methods: {
		...mapActions({
			changePassword(dispatch, e){
                return dispatch('user/changePassword', e)
            }
		}),
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.loading = true
					let dp = {
						token: this.$route.params.token,
						pswd: this.ruleForm2.pass,
						pswd_repeat: this.ruleForm2.checkPass
					}
					this.changePassword(dp).then(res => {
						if(res.data.Success == true){
							this.loading = false
							this.routeHome = true
							setTimeout(()=>{
								window.location.href = '/'
							}, 5000)
						}
					})
					.catch(error => console.log(error));
				} else {
					this.$notify.error({
						title: 'Ошибка',
						message: 'Ваши пароли не совпадают, или не соблюдены правила создания пароля!'
					});
					return false;
				}
			});
		},
    }
}
</script>

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.h3 {
	text-align: center;
    font-family: serif;
    font-size: 21px;
	margin-bottom: 20px;
    margin-top: 0;
}
.login-page {
  width: 425px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  max-width: 425px;
  margin: 0 auto 100px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
	font-family: "Roboto", sans-serif;
	text-transform: uppercase;
	outline: 0;
	width: 100%;
	border: 0;
	margin-top: 20px;
	padding: 12px 20px!important;
	color: black;
	font-size: 14px;
	-webkit-transition: all 0.3 ease;
	transition: all 0.3 ease;
	cursor: pointer;
}
.form button:hover,.form button:active,.form button:focus {
  background: #ffec5d;
  color: black
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #4CAF50;
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}
body {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
</style>
