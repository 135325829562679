<template>
<div class="joint-status">
	<el-tabs :value="currentSubtype" @tab-click="setCurrentSubtype" tab-position="left" class="joints-status-style">
		<el-tab-pane v-for="( it, ind ) in typeList" :key="ind" :name="it.title" :label="it.count !== 0 ? it.title+' '+'('+it.count+')' : it.title">
			<div class="joint-status-numerics" v-if="!currentJoint">
				<div class="joint-status-numeric-item" v-for="( item, index ) in numericList" :key="index" @click="setJoint(item.id)">
					<span v-if="item.count" class="joint-status-numeric-item-count"> {{ item.count }} </span>
					<img :src="apiUrl + '//files/joints/img_' + item.num + '.jpg'"/>
				</div>
			</div>
			<!-- <grid-j-s v-if="currentJoint"/> -->
			<joint-status-table v-if="currentJoint"/>
		</el-tab-pane>
	</el-tabs>
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { DxPopup } from 'devextreme-vue'
import GridJS from './gridJointSt.vue'
import JointStatusTable from '../grid/JointStatusTable.vue';
export default {
	components: {
		DxPopup,
		GridJS,
		JointStatusTable,
	},
	computed: {
		...mapGetters({
			user: 'user/user',
			joints: 'manifestationOfDisease/joints',
			activeJoint: 'manifestationOfDisease/activeJoint',
			patient: 'user/patient',
			apiUrl: 'apiUrl'
		}),
		typeList() {
			let list = []
			let data = {}
			this.joints.forEach(i => {
				if (!list.find(it => it.title === i.subtype)) {
					list.push({ title: i.subtype, count: Number(i.PicturesCount)});
				} else {
					let item = list.find(it => it.title === i.subtype);
					item.count += Number(i.PicturesCount);
				}
			});
			if(list.length > 9)
				list.pop();
			return list
		},
		numericList() {
			return this.joints.filter(i => i.subtype === this.currentSubtype).map(i => { return { count: Number(i.PicturesCount), id: i.id, num: i.numeric_id } });
		}
	},
    data() {
        return {
			// jointStatusTabs: 'Тазобедренные',
			dataDel: {},
			popupVisible: false,
			visPopupSysStat: false,
			visible: false,
			fileNotValid: false,
            datedownpic: new Date().getTime(),
			tab_position: 'left',
			visiblepopup: false,
			pickUrl: '',
			currentSubtype: "Тазобедренные",
			currentJoint: false,
			editModeItem: {},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
			},
            editButtons: ['edit', {
                    hint: 'Посмотреть',
                    icon: 'search',
                    onClick: this.visibleViewDataMobileView
                }
            ],
			popup: {
                width: 1000,
                height: 'auto',
                position: {
                    my: "center",
                    at: "center",
                    of: window
                }
			},
            form: {
                customizeItem:(item)=> {
                    if (item.dataField === "measurement") {
                        item.visible = this.$refs["grid"].instance.__itemVisible;
                    } else if(item.dataField === "edit_but") {
                        item.visible = this.$refs["grid"].instance.__itemVisible;
                    }
                }
            },
            commentsys: '',
            ops: {
                bar: {
                    background: '#6374DD',
                }
            },
        }
	},
	mounted() {
		this.updateDataFromLocalStorage()
	},
	updated() {
		this.$nextTick(function () {
			this.updateDataFromLocalStorage()
	  	})
	},
    methods: {
    	updateDataFromLocalStorage() {
			let subtype = localStorage.getItem('subtype')
			let joint = localStorage.getItem('subtype_item')

			if( subtype ) {
				if(this.currentSubtype !== subtype) this.currentSubtype = subtype
			} else {
				this.currentSubtype = 'Тазобедренные'
			}
			if( joint ) {
				if (this.currentJoint !== joint) {
					this.currentJoint = joint
					this.setActiveJoint(joint)	
				}
			} else {
				this.currentJoint = false
			}
    	},
		delStroke(e, b){
			//console.log(this.joints)
			this.dataDel = e
			this.popupVisible = true
		},
		closePop(done){
			this.pickUrl = ''
			done()
		},
		...mapActions({
			setActiveJoint: 'manifestationOfDisease/setActiveJoint',
			updatePhoto: 'manifestationOfDisease/updatePhoto',
			deleteStr(dispatch, e) {
				this.popupVisible = false
				dispatch('manifestationOfDisease/deleteStr', e)
			}

		}),
		fileChange() {
			this.fileNotValid = false;
		},
		setActiveSys(n) {//console.log(n);
			this.activeSys = this.joints.find(i => i.NumericId == n);
		},
		// handleClick(e) {
		// 	this.setActiveJoint(this.joints[Number(e.index)].id);

		// },
		setCurrentSubtype(s) {
			localStorage.setItem('subtype', s.name)
			localStorage.removeItem('subtype_item')

			this.currentSubtype = s.name
			this.currentJoint = false;
		},
		setJoint(id) {
			localStorage.setItem('subtype_item', id)

			this.currentJoint = id;
			this.setActiveJoint(id);
		},
		setEditMode(item) {
			this.editModeItem = {
				id: item.id,
				angle1: item.angle1,
				angle2: item.angle2
			};
		},
		saveItem(item) {
			item.edit = false;
			item.angle1 = Number(this.editModeItem.angle1);
			item.angle2 = Number(this.editModeItem.angle2);
			this.updatePhoto(item);
			this.editModeItem = {};
		}
	}
}
</script>

<style>
.upload-label {
	text-align: left;
	width: 500px;
	float: left;
	padding-top: 10px;
}
.el-popover {
	padding-bottom: 10px;
}

.joint-status {
	display: flex;
	padding-left: 40px;
	padding-top: 40px;
}

.joint-status-table {
	display: flex;
	flex-direction: column;
	border-top: 1px solid #eee;
	width: calc(90% - 20px);
	margin-left: 20px;

}

.joint-status-table .row {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		border-bottom: 1px solid #eee;
}
.joint-status-table .row .column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	border-left: 1px solid #eee;
    font-size: 16px;
	padding: 20px;
	flex-grow: 1;
	width: 100px;

}


.joint-status-table .row .column input {
	width: 100%;
    height: 22px;
	font-size: 16px;
    border: none;
    border-bottom: 1px solid #999;
	outline: none;
}

.joint-status-table .row-header .column {
	font-weight: 500;
    font-size: 18px;
}
.joint-status-table .row .column:hover .joint-status-table-edit {
	display: block;
}

.type-list {
	display: flex;
	flex-direction: column;
	width: 200px;
}
.type-list .type-list-item {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	cursor: pointer;
}

.type-list .type-list-item:hover {
	background: #5D4CB8;
	color: #fff;
}

.type-list .type-list-item-active {
	background: #5D4CB8;
	color: #fff;
}

.joint-status-numerics {
	display: flex;
	flex-wrap: wrap;
	width: calc(90% - 40px);
	margin-left: 20px;
}

.joint-status-numeric-item {
	position: relative;
	display: flex;
	cursor: pointer;
    justify-content: center;
	width: 200px;
	height: 200px;
	border: 1px solid #5D4CB8;
	margin: 10px;
}
.joint-status-numeric-item img {
	height: 100%;
}

.image-view {
	position: fixed;
	z-index: 100;
	background: rgba(0,0,0,0.4);
	display: flex;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.image-view .image-view-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 80%;
	height: 100%;

}
.image-view-content {
	position: relative;
	background: #fff;
	display: flex;
	padding: 20px;
	max-height: 80%;
	overflow: hidden;
}
.image-view-content img {
	height: 50%;
	width: 50%;
}
.image-view-close {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 20px;
	height: 20px;
	transform: scaleX(1.3);
	font-size: 20px;
	cursor: pointer;
}

.joint-status-numeric-item-count {
	position: absolute;
	right: 10px;
	top: 10px;
}
.joint-status-table-edit {
	display: none;
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 10px;
	cursor: pointer;
}
.joint-status-table-save {
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 10px;
	cursor: pointer;
}
</style>
