export default {
    namespaced: true,
    state: {
        list_doc: [],
        list_doc_away: [],
        list_pat: [],
        list_doc_zam: []
    },
    getters: {
        listDoc: state => state.list_doc,
        listDocZam: state => state.list_doc_zam,
        listDocAway: state => state.list_doc_away,
        listPat: state => state.list_pat
    },
    mutations: {
        saveDocList(state, data) {
            state.list_doc_zam.forEach(f => {
                delete f['substitutional_active']
            })

            data.forEach(e => {
                e.fio = e.last_name+' '+e.first_name+' '+e.patronymic;
                state.list_doc_zam.push({
                    fio: e.last_name+' '+e.first_name+' '+e.patronymic,
                    id: e.id
                })
            })

            data.forEach(ee => {
                state.list_doc_zam.forEach(d => {
                    if(ee.substitutional_id == d.id) {
                        d.substitutional_active = true
                    }
                })
            })

            state.list_doc_zam.forEach(d => {
                data.forEach(el => {
                    if(el.id == d.id && d.substitutional_active) {
                        el.substitutional_active = true
                    }
                })
            })
            //console.log(data)
            state.list_doc = data
        },
        saveDocAwayList(state, data) {
            state.list_doc_away = data
        },
        saveDoc(state, payload) {
            state.list_doc.push(payload)
        },
        setUpdate(state, payload) {
            state.list_doc.zam_doc = payload.zam_doc
        },
        savePatList(state, data) {
            state.list_pat = data
        },
        delDoc(state, data) {
            let index = state.list_doc.findIndex(ld => ld.id == data.id)
            state.list_doc.splice(index, 1)
        },
        saveAwayItem(state, data) {
            state.list_doc_away.push(data)
        },
        updateAwayItem(state, data) {
            let index = state.list_doc_away.findIndex(item => item.Id == data.Id)

            state.list_doc_away.splice(index, 1, data)
        },
        deleteAwayItem(state, data) {
            let index = state.list_doc_away.findIndex(item => item.Id == data.Id)

            state.list_doc_away.splice(index, 1)
        },
        replaceDoc(state, data) {
            let id_sub_act = '';
            state.list_doc.forEach(el => {
                if(el.id == data.id) Object.assign(el, data)
                state.list_doc_zam.forEach(d => { if(el.substitutional_id == d.id) id_sub_act = d.id })
            });

            state.list_doc.forEach(el => {
                if(el.id == id_sub_act) {
                    el.substitutional_active = true
                }
            })
        }
    },
    actions: {
        //СПИСОК СПРАВОЧНИКА
        getParamsList({rootState}) {
            return new Promise((resolve, reject)=>{
                this.api.get(`${rootState.apiUrlCheckValue}/paramlist`).then(res => {
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        //СОХРАНЕНИЕ СПРАВОЧНИКА
        async saveParams({rootState}, data) {
            return new Promise((resolve, reject)=>{
                this.api.post(`${rootState.apiUrlCheckValue}/paramlist/${data.caption}`, data).then(res => {
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        //СОХРАНЕНИЕ НОВОГО СПРАВОЧНИКА
        async saveNewParams({rootState, dispatch}, data) {
            return new Promise((resolve, reject)=>{
                this.api.post(`${rootState.apiUrlCheckValue}/paramlist}`, data).then(res => {
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        //АВТОРИЗАЦИЯ СПРАВОЧНИКА
        authParams({rootState}) {
            this.api.post(`${rootState.apiUrlCheckValue}/login`, {login:'administrator',password:'123456'})
        },
        //СПИСОК ВРАЧЕЙ
        getDocList({commit, rootState}) {
            return new Promise((resolve, reject)=>{
                this.api.get(`${rootState.apiUrl}/doctors`).then(res => {
                    commit('saveDocList', res.data);
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        // СПИСОК ОТСУТСТВИЯ ВРАЧЕЙ
        getDocAwayList({commit, rootState}) {
            return new Promise((resolve, reject)=>{
                this.api.get(`${rootState.apiUrl}/doctors/away/current`).then(res => {
                    commit('saveDocAwayList', res.data);
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },
        // ОПЕРАЦИИ СО СПИСКОМ ВРАЧЕЙ
        createDoc({dispatch, rootState}, payload) {
            return new Promise((resolve, reject)=>{
                this.api.post(`${rootState.apiUrl}/doctors`, payload).then(res => {
                    //commit('saveDoc', res.data.Doctor);
                    dispatch('getDocList')
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        updateDoc({commit, dispatch, rootState}, payload) {
            return new Promise((resolve, reject)=>{
                this.api.put(`${rootState.apiUrl}/doctors/${payload.id}`, payload).then(res => {
                    //commit('replaceDoc', res.data)
                    dispatch('getDocList')
                    resolve(res)
                },error => {
                    if( error.response.data.Message === 'Указанный e-mail уже используется.') {
                        resolve({status: 'wrong_email'})
                    } else {
                        reject(error)
                    }
				})
            })
		},
        deleteDoc({commit, dispatch, rootState}, payload) {
            return new Promise((resolve, reject)=>{
                this.api.delete(`${rootState.apiUrl}/doctors/${payload.id}`, payload).then(res => {
                    commit('delDoc', payload);
                    dispatch('getDocList')
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        // ОПЕРАЦИИ СО СПИСКОМ ОТСУТСТВИЯ ВРАЧЕЙ
        createAwayItem({commit, rootState}, payload) {
            return new Promise((resolve, reject)=>{
                this.api.post(`${rootState.apiUrl}/doctors/${payload.DoctorId}/away`, payload).then(res => {
                    commit('saveAwayItem', res.data);
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },
        updateAwayItem({commit,rootState}, payload) {
            return new Promise((resolve, reject)=>{
                this.api.put(`${rootState.apiUrl}/doctors/${payload.DoctorId}/away/${payload.Id}`, payload).then(res => {
                    commit('updateAwayItem', res.data);
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },
        deleteAwayItem({commit,rootState}, payload) {
            return new Promise((resolve, reject)=>{
                this.api.delete(`${rootState.apiUrl}/doctors/${payload.DoctorId}/away/${payload.Id}`, payload).then(res => {
                    commit('deleteAwayItem', payload);
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },

        //СПИСОК ПАЦИЕНТОВ
        getPatList({commit, rootState}) {
            return new Promise((resolve, reject)=>{
                this.api.get(`${rootState.apiUrl}/api/patients`).then(res => {
                    commit('savePatList', res.data)
                    resolve(res)
                },error => {
                    reject(error)
				})
            })
        },
        //Сменить доктора у пациента
        updateDocPatient({rootState}, payload){
            this.api.put(`${rootState.apiUrl}/patients/${payload.id}/doctors/`, payload)
        }
    }
}