<template>
	<v-container fluid>
		<v-row justify="center" align="center">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<el-tabs :value="PersonalDoctorsOfficeTab" @tab-click="tabClick" type="border-card">
					<el-tab-pane name="1" label="Пациенты">
						<table-patient headerName=""/>
					</el-tab-pane>
					<el-tab-pane name="2" label="Уведомления пациентам">
						<table-notification-to-patient :randomChangedValue="randomChangedValue"/>
					</el-tab-pane>
					<!-- <el-tab-pane name="3" label="Конструктор отчетов">
						<reports-table :doctor="true" />
					</el-tab-pane> -->
				</el-tabs>
			</v-col>
		</v-row>
		<!-- <v-row justify="center" align="center">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<table-patient headerName="Пациенты"/>
			</v-col>
		</v-row>
		<v-row justify="center" align="center">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<table-notification-to-patient/>
			</v-col>
		</v-row> -->
		
	</v-container>
</template>

<script>
import tablePatient from './grid/tablePatient.vue'
import TableNotificationToPatient from './grid/tableNotificationToPatient.vue'
import reportsTable from '../AdminModule/element/Report.vue'

export default {
    components: { tablePatient, TableNotificationToPatient, reportsTable },
	computed: {
        PersonalDoctorsOfficeTab(){
            return localStorage.PersonalDoctorsOfficeTab || '1'
        }
    },
	data() {
		return {
			randomChangedValue: new Date()
		}	
	},
    methods: {
        tabClick(e){
            if(e.name == 1) {
                localStorage.setItem('PersonalDoctorsOfficeTab', '1');
            } else if(e.name == 2){
				this.randomChangedValue = new Date()
                localStorage.setItem('PersonalDoctorsOfficeTab', '2');
            } else if(e.name == 3){
                localStorage.setItem('PersonalDoctorsOfficeTab', '3');
            }
        }
    },
}
</script>