<template>
<div>
	<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		:viewBox="viewBox" style="enable-background:new 0 0 1000 1500;" xml:space="preserve">

		<g id="Слой_2_1_">
		</g>
		<g>
			<g v-for="( skin, index ) in skins" :key="index">
				<path :class="[skin.class, skin.bol == true ? 'st10': '']" :d="skin.d" />
			</g>
		</g>
	</svg>
</div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import skin from './humanPokView.json';

export default {
	props: ['viewBox', 'siplist'],
	data() {
		return {
			skins: JSON.parse(JSON.stringify(skin.skins)),
		}
	},
	mounted(){
		if(this.siplist.length > 0){
			this.skins.forEach(i => {
				let data = JSON.parse(this.siplist)
				data.forEach(j => {
					if(i.numeric_id == j.numeric_id){
						i.bol = j.bol
					}
				})
            })
        }
	}
}
</script>
<style type="text/css" scoped>
.st0{fill:#F7AD9A;stroke:#FFFFFF;stroke-miterlimit:10;stroke-dasharray:2;}
.st1{fill:#EA9C94;}
.st2{fill:#C6847B;}
.st3{fill:#6D4F4F;}
.st4{fill:#1A171B;}
.st5{fill:#FFFFFF;}
.st6{fill:#49494B;}
.st7{fill:#BFE5F7;}
.st8{opacity:0.27;fill:#F4CAC3;enable-background:new;}
.st9{opacity:0.2;fill:#560B0B;enable-background:new    ;}
.st0.st10{opacity:1;fill:#ca3232;enable-background:new    ;}
.st15{fill:#DD9080;stroke:#FFFFFF;stroke-miterlimit:10;stroke-dasharray:2;cursor: not-allowed}
.st0.st11{opacity:1;stroke:#ca3232;enable-background:new; stroke-width: 2;}
</style>

