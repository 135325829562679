<template>
    <v-row>
        <v-col xs="12" sm="12" md="4" lg="4" xl="4">
            <svg-sys type="photo" viewBox="450 0 520 800" />
        </v-col>
        <v-col xs="12" sm="12" md="8" lg="8" xl="8">
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div v-if="user.role !== 2 || checkRole" class="upload-label">Для того чтобы загрузить фото, выберите сустав на схематичном изображении человека и нажмите на кнопку Добавить фото</div>
                    <el-popover
                        placement="bottom"
                        width="400"
                        trigger="manual"
                        :value="getJointsFlag"
                    >
                        <span @click="changeFlag({name: 'jointsFlag', val: false})" style="font-size: 17px;float: right;color: black;cursor: pointer;">X</span>
                        <form id="form" class="settings-form add-popup">
                            <span>Выберите дату снимка</span>
                            <el-date-picker
                                v-model="datedownpic"
                                format="dd.MM.yyyy"
                                value-format="timestamp"
                                type="date"
                                placeholder="Выберите дату"
                                :validationRules="{ type: 'required',message: 'Заполните поле!'}"
                                :picker-options="pickerOptions">
                            </el-date-picker>
                            <span :class="{'not-valid-text ': fileNotValid}">Загрузите изображение</span>
                            <dx-file-uploader
                                :ref="'uploaderFiles'"
                                style="margin:0"
                                width="45%"
                                selectButtonText="Загрузить"
                                labelText=""
                                accept="image/*"
                                @change="fileChange"
                                uploadMode="useForm"
                                name="file"
                            />
                            <span style="word-break: break-word" class="file-info">Загрузка больше одной фотографии невозможна</span>

                            <span>Описание</span>
                            <dx-text-area
                                height="100"
                                v-model="commentsys"
                            />
                            <el-button slot="reference" :disabled="disabledBtnSave" @click="uploadfiles" type="primary" icon="el-icon-check">Сохранить введенные данные</el-button>
                        </form>
                        <el-button v-if="user.role !== 2 || checkRole" slot="reference" type="primary" icon="el-icon-plus" @click="changeFlag({name: 'jointsFlag', val: true})" class="def-btn" :disabled="!activeJoint.id">Добавить фото</el-button>
                    </el-popover>
                </v-col>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <picture-sys-table />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { DxFileUploader, DxTextArea } from "devextreme-vue";
import svgSys from '../human/SvgHumanSys.vue';
import PictureSysTable from '../grid/PictureSysTable.vue';
import $ from 'jquery';

import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    props: ['photo'],
    components: {
        svgSys,
        DxFileUploader,
        DxTextArea,
        PictureSysTable
	},
	computed: {
		...mapGetters({
			user: 'user/user',
			joints: 'manifestationOfDisease/joints',
			activeJoint: 'manifestationOfDisease/activeJoint',
			getJointsFlag: 'manifestationOfDisease/getJointsFlag',
			patient: 'user/patient',
			apiUrl: 'apiUrl'
		}),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
    data() {
        return {
			disabledBtnSave: false,
			fileNotValid: false,
            datedownpic: new Date().getTime(),
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
			},
            commentsys: '',
            ops: {
                bar: {
                    background: '#6374DD',
                }
            },
        }
	},
    watch: {
        photo(val) {
            if(val) this.changeFlag({name: 'jointsFlag', val: false})
        }
    },
	mounted() {
        let photo = localStorage.photo || ''
        if(photo == '11' && localStorage.mofd === '2'){
            this.getJoints('joints');
            this.getAllJointPhoto('joints');
        }
	},
    methods: {
        ...mapMutations({
            changeFlag: 'manifestationOfDisease/changeFlag'
        }),
		...mapActions({
			getJoints: 'manifestationOfDisease/getJoints',
			getAllJointPhoto: 'manifestationOfDisease/getAllJointPhoto',
			setActiveJoint: 'manifestationOfDisease/setActiveJoint'
		}),
		fileChange() {
			this.fileNotValid = false;
		},
		setActiveSys(n) {//console.log(n);
			this.activeSys = this.joints.find(i => i.NumericId == n);
		},
        uploadfiles: function() {
            this.disabledBtnSave = true

			let filelist = $("#form .dx-fileuploader-input");
			let formData = new FormData()
			if (!filelist[0].files[0]) {
				this.fileNotValid = true;
				return;
			}
			formData.append('file', filelist[0].files[0]);
			this.api.post(this.apiUrl + '/files/send', formData, {
            headers: {
                'Content-type': 'multipart/form-data',
            }
			}).then(r => {
				let picture = {
					id: r.data.id,
					patients_id: this.patient.patients_id,
					joints_id: this.activeJoint.id,
					date: this.datedownpic,
                    comments: this.commentsys,
                    creation_date: new Date().getTime()
				};
				this.api.post(this.apiUrl + '/api/patients/' + this.patient.patients_id + '/pictures', picture).then(r => {
					this.setActiveJoint(this.activeJoint.id);
                    this.changeFlag({name: 'jointsFlag', val: false})
                    this.commentsys = ''
                    this.datedownpic = new Date().getTime()
                    this.$refs['uploaderFiles'].instance.reset()
                    this.disabledBtnSave = false
				})
			}).catch(e => {
				
			});
        }
    },
}
</script>