<template>
    <div id="table">
        <span v-if="false">Загрузка...</span>
            <dx-data-grid
                style="margin-left:20px;max-height:500px"
                :ref="'grid'"
				v-if="showData"
                :data-source="showData"
                :remote-operations="false"
                :allow-column-reordering="true" 
                :hover-state-enabled="false"
                :wordWrapEnabled="true"
                :showColumnLines="false"
                :showRowLines="true"
                :showBorders="true"
                @row-removed="onRowRemoved"
                @row-updated="onRowUpdated"
				@content-ready="onContentReady"
                @editing-start="onEditingStart"
                >
            <dx-column
            data-field="id"
            caption="Измерение" 
            alignment="center"
            width="160px"
            cell-template="cellTemplatePicUrl"
            />
            <dx-column
            data-field="date"
            caption="Дата"
            alignment="center"
            dataType="date"
            width="100px"
            />
            <dx-column
            data-field="angle1"
            caption="Угол 1"
            alignment="center"
            :validationRules="validationAngle"
            edit-cell-template="edit-angle1"
            />
            <dx-column 
            data-field="angle2"
            caption="Угол 2"
            :validationRules="validationAngle"
            alignment="center"
            edit-cell-template="edit-angle2"
            />
            <div slot="edit-angle1" slot-scope="data">
                <dx-text-box
                    :value="String(data.value)"
                    v-filter="'[0-9.]'"
                    :onValueChanged="function(e) {
                    data.setValue(Number(e.value))}"
                />
            </div>
            <div slot="edit-angle2" slot-scope="data">
                <dx-text-box
                    :value="String(data.value)"
                    v-filter="'[0-9.]'"
                    :onValueChanged="function(e) {
                    data.setValue(Number(e.value))}"
                />
            </div>
			<div
            slot="cellTemplatePicUrl"
            slot-scope="data">
				<div class='pickture-sys-img'>
					<template>
						<img v-img="{src: `${apiUrl}/files/${data.data.value}/download`}" :src="apiUrl + '/files/' + data.data.value + '/download-thumb'"/>
					</template>
				</div>
            </div>
            <dx-editing
            mode="popup"
            :allowDeleting="true"
			:allow-updating="true"
            :useIcons="true"
            :form="form"
            >
                <dx-popup
                    :width="600"
                    :height="150"
                    :closeOnOutsideClick="true"
                />
            </dx-editing>
            <dx-column
                data-field="edit_but"
                :width="130"
                caption=""
                :buttons="editButtons"
                type="buttons"
            />
            </dx-data-grid>
    </div>
</template>

<script>
import 'devextreme-intl';
import { DxDataGrid, DxColumn, DxEditing, DxPaging, DxFilterRow, DxHeaderFilter, DxPopup} from "devextreme-vue/ui/data-grid";
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages } from 'devextreme/localization';
import { DxTextBox } from 'devextreme-vue';
import { DxValidator } from "devextreme-vue";
import axios from 'axios'
loadMessages(ruMessages);
locale(navigator.language || navigator.browserLanguage);
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import $ from 'jquery';
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        DxDataGrid, 
        DxColumn,
        DxPaging,
        DxFilterRow, 
		DxHeaderFilter,
        DxEditing,
        DxPopup,
        DxTextBox,
        DxValidator
	},
	computed: {
		...mapGetters({
			activeJoint: 'manifestationOfDisease/activeJoint',
			allJointPhoto: 'manifestationOfDisease/allJointPhoto',
            apiUrl: 'apiUrl',
            user: 'user/user',
		}),
		showData() {
			let res = []

            res = !this.activeJoint.items ? this.allJointPhoto : this.activeJoint.items
			
            return res || []
		},
    },
    watch: {
        showData: {
            deep: true,
            handler: function(arr) {
                if(arr.length && !this.isInitiated) {
                    let joint = localStorage.getItem('subtype_item')

                    if(joint) {
                        console.log('Going to init with', arr.length )
                        this.setActiveJoint(joint)
                    }
                    this.isInitiated = true                            
                }

            } 
        }
    },
    data() {
        return {
            isInitiated: false,
			picturedata: [],
			pickUrl: '',
            editPopuped: false,
            showAngle2: false,
            form: {
                customizeItem:(item)=> {
                    if(item.dataField === "edit_but") {
                        item.visible = this.$refs["grid"].instance.__itemVisible;
                    }
                    if(item.dataField === "id") {
                        item.visible = this.$refs["grid"].instance.__itemVisible;
                    }
                    if(item.dataField === "angle2") {
                        item.visible = this.showAngle2;
                    }
                    if(item.dataField === "date") {
                        item.visible = this.$refs["grid"].instance.__itemVisible;
                    }
                }
            },
            validationAngle: [{
                type: 'custom',
                message: 'Проверьте правильность данных',
                validationCallback: function(e){
                    if(e.value <= 360){
                        return true
                    }
                }
            }],
            editButtons: [
                'edit',
                {
                    hint: 'Удалить',
                    icon: 'trash',
                    onClick:(e)=> {
                        this.$refs["grid"].instance.deleteRow(e.row.rowIndex);
                        e.event.preventDefault();
                    },
                    visible:(e)=>{
                        return this.user.role !== 2 && new Date().getTime() - e.row.data.creation_date < 86400000
                    }
                }
            ],
        }
	},
	methods: {
		...mapActions({
            setActiveJoint: 'manifestationOfDisease/setActiveJoint',
            removePicture: 'manifestationOfDisease/removePicture',
            updatePhoto: 'manifestationOfDisease/updatePhoto'
		}),
		onRowRemoved(e) {
            e.data.flag = !this.activeJoint.items ? true : false;
			this.removePicture(e.data)
        },
        onRowUpdated(e){
            this.updatePhoto(e.key);
        },
		onContentReady(e) {
            e.component.__itemVisible = false;
        },
        onEditingStart(e){
            if(e.data.angle2 !== null)
                this.showAngle2 = true;
            else
                this.showAngle2 = false;
        }
	}
}
</script>
