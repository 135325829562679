<template>
	<v-card>
		<v-data-table no-results-text="Поиск не дал результатов"
            hide-default-footer
            :headers="headers"
            :items="showData"
        >
            <!-- Видео -->
			<template v-slot:[`item.id`]="{ item }">
                <video class="mt-2 mb-2 rounded-xl" style="width:350px" controls="controls">
                    <source :src="apiUrl + '/files/' + item.id + '/download'">
                </video>
			</template>
            <!-- Дата -->
			<template v-slot:[`item.date`]="{ item }">
                <span v-if="item.date">
                    {{new Date(item.date).toLocaleDateString()}}
                </span>
			</template>

            <!-- Кнопки управления строкой -->
			<template v-slot:[`item.action`]="{ item }">
                <v-btn v-if="user.role !== 2 && new Date().getTime() - item.creation_date < 86400000" @click="deleteItems(item)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>
        </v-data-table>
	</v-card>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        headers: [
            { text: "Видео", value: "id", align: 'center', sortable: false, width: 250 },
            { text: "Дата", value: "date", align: 'center' },
            { text: "Описание", value: "comments", align: 'center', sortable: false },
            { text: "", value: "action", sortable: false },
        ]
    }),
	computed: {
		...mapGetters({
			activeJoint: 'manifestationOfDisease/activeJoint',
			allJointPhoto: 'manifestationOfDisease/allJointPhoto',
            apiUrl: 'apiUrl',
            user: 'user/user',
		}),
		showData() {
			return this.allJointPhoto
		},
    },
    methods: {
        ...mapActions({
			removePicture: 'manifestationOfDisease/removePicture'
		}),
        deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
                row.flag = !this.activeJoint.items ? true : false;
				this.removePicture(row)
				.then(res => {
					if(res.data.Success) {
                        const index = this.showData.indexOf(row);
						this.showData.splice(index, 1)
					}
				})
			}
		},
    }
}
</script>

<style scoped>
.purp-row {
    background: #6374dd;
    color: white;
}
</style>