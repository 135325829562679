<template>
<div id="osn" class="Analytics">
<dx-popup
    :visible.sync="visiblePopupNotif"
    :drag-enabled="true"
    :close-on-outside-click="true"
    width="1200"
    height="auto"
    class="popupNotif"
    title="Архив уведомлений"
    >
    <ArhNotific />
</dx-popup>
</div>
</template>
<script>
import { DxPopup } from "devextreme-vue";
import ArhNotific from '../PersonalDoctorsOffice/grid/ArhNotific.vue'
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
        DxPopup,
        ArhNotific
    },
    data() {
        return {
            visiblePopupNotif: false,
            activeIndex: '8',
            imageUrl: '',
            mobileMenu: false
        }
    },
    computed: {
        ...mapGetters({
            notifications: 'personalDoctorsOffice/notifications',
			user: 'user/user',
			usNoCopie: 'user/copy'
        }),
        numberOfNotifications() {
            var intNot = 0
            for(let i=0;i<this.notifications.length;i++) {
                if(this.notifications[i].new === true) {
                    intNot++
                }
            }
            return intNot
        },
    },
    methods: {
        falseNotific(id) {
            this.notifications.forEach(i => {
                if(i.id === id) {
                    i.new = false
                }
            });
        },
        backPersonDoc() {
            this.getUser();
            window.location = "/PersonalDoctorsOffice"
        },
        arhNotif() {
            this.visiblePopupNotif = true
        },
        ...mapActions({
            logout(dispatch) {
               return dispatch('user/logout')
            },
            getUser(dispatch) {
                let dataEditCop = JSON.parse(localStorage.statusUserCopie)
                dispatch('auth/getUser', {
                    id: dataEditCop.id,
                    fio: dataEditCop.FullName,
                    role: dataEditCop.role,
                    ssid: dataEditCop.ssid,
                    patients_id: dataEditCop.patients_id,
                    login: dataEditCop.login
                })
            },
		}),
        loggout: function() {
            this.logout()
            .then(res => {
                if(res.status === 200 && res.data.success === true) {
                    this.$router.replace('/')
                }
            })
            .catch(error => console.log(error));
        },
        handleSelect(key, keyPath) {
        if(key === "2") {
            this.$router.replace('/Treatment')
        } else if(key === "3") {
            this.$router.replace('/Analyses')
        } else if(key === "4") {
            this.$router.replace('/ManifestationOfDisease')
        } else if(key === "5") {
            this.$router.replace('/QualityOfLifeQuestionnaire')
        } else if(key === "6") {
            this.$router.replace('/Scale')
        } else if(key === "7") {
            this.$router.replace('/Data')
        } else if(key === "1") {
            this.$router.replace('/PassportPart')
        }
      }
    },
}
</script>

