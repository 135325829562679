<template>
	<v-container fluid>
		<v-row justify="center" align="center" class="tabs-wrap">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<el-tabs class="tabs" type="border-card">
					<el-tab-pane v-if="user.role === 2 || user.role === 3" label="Глобальная оценка активности болезни врачом">
						<table-global-review />
					</el-tab-pane>
					<el-tab-pane label="Общая оценка состояния здоровья пациента">
						<table-local-review />
					</el-tab-pane>
				</el-tabs>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import tableLocalReview from './tableLocalReview'
import tableGlobalReview from './tableGlobalReview'

export default {
	components: { tableGlobalReview, tableLocalReview },
    computed: {
        ...mapGetters({
			user: 'user/user'
        })
    }
}
</script>
