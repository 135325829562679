<template>
	<v-card>
		<v-card-title>
			<v-btn v-if="user.role !== 2" @click="dialog=true" color="#6374dd" dark class="ml-4">Добавить кожную пробу</v-btn>
			
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:items="list"
			:search="search"
			:loading="loading"
			sort-by="Date"
        	:sort-desc="true"
			no-data-text="Туберкулиновые пробы отсутствуют..."
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="user.role !== 2 || checkRole" @click="editeItems(item)" color="#6374dd" small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn v-if="user.role !== 2 && (new Date().getTime() - item.create_date < 86400000)" @click="deleteItems(item, list)" color="error" small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>

			<template v-slot:[`item.Date`]="{ item }">
				{{item.Date ? new Date(item.Date).toLocaleDateString() : ''}}
			</template>
			
			<template v-slot:[`item.Result`]="{ item }">
				{{ item.Result ? (item.Result.Name !== 'Отрицательный' ? `${item.Result.Name} (${item.Value} мм)` : item.Result.Name) : '' }}
			</template>
			
			<template v-slot:[`item.ResearchItem`]="{ item }">
				{{ item.ResearchItem ? item.ResearchItem.Name : '' }}
			</template>
		</v-data-table>

		<!-- Окно создания/редактирования -->
		<v-dialog persistent v-model="dialog" max-width="1100px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ editeFlag ? 'Редактировать' : 'Добавить'}} кожную пробу</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="editeFlag ? update(obj) : save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation class="mt-2">
							<v-row>
								<v-col cols="12" sm="12" md="3">
									<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field outlined ref="tf0" :rules="[rules.required]" v-model="dateFormatted" label="Дата *" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
										</template>

										<v-date-picker :max="toJSONLocal(null)" :min="toJSONLocal(bday.birth_date)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.Date" @change="saveDate" />
									</v-menu>
								</v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-select label="Исследование *" :rules="[rules.required]" item-text="Name" item-value="id" outlined :items="researchItems" v-model="obj.researchItemId" />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-select label="Результат *" :rules="[rules.required]" @change="changeResult(obj)" item-text="name" item-value="id" outlined :items="resultItems" v-model="obj.resultId" />
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field
										:label="obj.resultId == 'e5579cf3-c9d8-4062-ac2f-6e32e3a7aeb4' ? 'Гиперемия в мм *' : 'Папула *' "
										:rules="[obj.resultId == 'e5579cf3-c9d8-4062-ac2f-6e32e3a7aeb4' || obj.resultId == 'e6167ee5-8b67-478b-9325-3f772cb2206b' ? rules.required : true]"
										:disabled="obj.resultId == '45ecc39c-eab0-4565-9437-adb1c6706069'"
										outlined
										type="number"
										v-model="obj.value"
									/>
                                </v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			uzi: 'data/dataUzi',
			apiUrl: 'apiUrl',
			bday: 'passportPart/patientData',
			user: 'user/user',
			patient: 'user/patient'
        }),
		dateFormatted() {
            if(this.obj.Date)
                return new Date(this.obj.Date).toLocaleDateString("ru-RU");
            return null;
        },
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
	data() {
		return {
			editeFlag: false,
			search: '',
			maxDate: new Date().toISOString().substr(0, 10),
			menu: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				create_date: null,
				Date: null,
				patientId: null,
				value: null,
				researchItemId: null,
				resultId: null
			},
			dialog: false,
			list: [],
			researchItems: [],
			resultItems: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.'
			},
			headers: [
				{ text: "Дата", value: "Date", align: 'center' },
				{ text: "Исследование", value: "ResearchItem", align: 'center', sortable: false },
				{ text: "Результат", value: "Result", align: 'center', sortable: false },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	watch: {
		dialog(val) {
			if(!val) this.editeFlag = false
		}
	},
	methods: {
		close() {
			this.obj = {
				create_date: null,
				Date: null,
				patientId: null,
				value: null,
				researchItemId: null,
				resultId: null
			}
			this.dialog = false
			this.overlay = false
			this.$refs.form.resetValidation()
		},
		async apiMethods(url, name) {
			this.loading = false
			let res = (await this.api.get(`${url}/${name}`)).data
			
			return res
		},
		editeItems(row) {
			this.editeFlag = true
			Object.assign(this.obj, {
				id: row.Id ? row.Id : undefined,
				value: row.Value,
				researchItemId: row.ResearchItem.Id,
				resultId: row.Result.Id,
				Date: row.Date,
				create_date: row.create_date
			})
			console.log(this.obj)
			this.dialog = true
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/tuberculinTests/delete/${row.Id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				this.overlay = true

				this.api.post(`${this.apiUrl}/api/tuberculinTests/add`, {
					create_date: new Date().getTime(),
					Date: obj.Date,
					patientId: this.patient.patients_id,
					value: obj.value,
					researchItemId: obj.researchItemId,
					resultId: obj.resultId
				})
				.then(response => {
					if(response.status == 200) {
						this.list.push(response.data[0])
						this.snackBarFunc(true, 'Тест успешно создан!!!', 'success')

						this.close()
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		changeResult(obj) {
			if(obj.resultId !== 'e5579cf3-c9d8-4062-ac2f-6e32e3a7aeb4') obj.value = 0
		},
		update(obj) {
			if(this.$refs.form.validate()) {
				this.overlay = true

				this.api.put(`${this.apiUrl}/api/tuberculinTests/update/${obj.id}`, {
					create_date: obj.create_date,
					Date: obj.Date,
					patientId: this.patient.patients_id,
					value: obj.value,
					researchItemId: obj.researchItemId,
					resultId: obj.resultId
				})
				.then(response => {
					console.log(response)
					if(response.status == 200) {
						this.list.forEach(el => {
							if(el.Id == response.data[0].Id) Object.assign(el, response.data[0])
						})
						this.snackBarFunc(true, 'Тест успешно обновлен!!!', 'success')

						this.close()
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		saveDate(date) {
			this.$refs.menu.save(date)
		},
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
		downloadFile(id_array) {
			window.open(`${this.apiUrl}/files/${id_array}/download`, '_blank');
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/tuberculinTests/byPatientId/${this.patient.patients_id}`)
		this.resultItems = await this.apiMethods(this.apiUrl, `api/lookups/results?lookupName=results`)
		this.researchItems = await this.apiMethods(this.apiUrl, `api/lookups/research-items?lookupName=research-items`)
	}
};
</script>