<template>
  <div>
	<el-tabs v-loading="loading" tab-position="left" @tab-click="search = ''" type="border-card" style="height: 650px">
	  <el-tab-pane v-for="(item, ind) in newArr" :key="ind" :label="item.caption">
		<el-button
		  type="primary"
		  @click="handleEdit(0, 0, defaultItems, item.type, item)"
		>Добавить параметр</el-button>
		<el-table
		  :data="item.params.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
		  style="width: 100%"
          height="600"
		  @selection-change="handleSelectionChange"
		>
		  <el-table-column type="selection" width="55" />

		  <el-table-column align="center" label="Название параметра" property="name" />

		  <el-table-column align="right">
			<template slot="header" slot-scope="scope">
			  <el-input
				v-model="search"
				prefix-icon="el-icon-search"
				clearable
				size="large"
				placeholder="Введите название для поиска..."
			  />
			</template>

			<template slot-scope="scope">
			  <el-button
				type="info"
				icon="el-icon-edit"
				size="mini"
				@click="handleEdit(scope.$index, 1, scope.row, item.type, item)"
				circle
			  />
			  <!-- <el-button
				type="danger"
				icon="el-icon-delete"
				size="mini"
				@click="handleEdit(scope.$index, 2, scope.row)"
				circle
			  /> -->
			</template>
		  </el-table-column>
		</el-table>
	  </el-tab-pane>
	</el-tabs>

	<el-dialog
	  :title="titleText"
	  :visible.sync="editDialog"
	  :show-close="false"
	  :close-on-click-modal="false"
	  width="1200px"
	  top="2vh"
	  center
	>
    <el-form :model="editItems" :ref="editItems" :rules="rules">
	  <div class="block-edit" v-if="showGroup == 1 || showGroup == 0">
		<div style="display:flex">
		  <el-card
			:style="editItems.type_parametr == 2||editItems.type == 2 ? 'width:1050px' : 'width:1250px'"
			shadow="hover"
		  >
            <el-form-item label="Название параметра" prop="name">
			    <el-input v-model="editItems.name" />
                <!-- <el-select v-if="editItems.type_parametr == 1||editItems.type == 1" filterable style="width:100%" v-model="editItems.name">
                    <el-option  v-for="(item, ind) in listCaptions" :key="ind" :label="item.name" :value="item.name" />
                </el-select> -->
            </el-form-item>
		  </el-card>

		  <el-card
			v-if="editItems.type_parametr == 2||editItems.type == 2"
			style="width:180px;margin-left:20px"
			shadow="hover"
		  >
			<div slot="header" style="text-align:center" class="clearfix">
			  <span>Спрятать</span>
			</div>
			<center>
			  <!-- <el-checkbox style="width:20px" title="Спрятать параметр" v-model="editItems.hide" /> -->
			  <input
				class="custom-check"
				style="height: 25px;width: 25px;cursor:pointer"
				type="checkbox"
			  />
			</center>
		  </el-card>
		</div>

		<el-card v-if="editItems.type_parametr == 1" style="margin-top:20px" shadow="hover">
		  <el-table :data="editItems.list_ref" style="width: 100%">

			<el-table-column width="55">
			  <template slot-scope="scope">
				<el-button
				  v-if="editItems.list_ref.length-1 == scope.$index"
				  style="top:0px"
				  class="btn-style-plus"
				  @click="addRef(defaultItems)"
				  type="primary"
				  icon="el-icon-plus"
				  circle
				/>
			  </template>
			</el-table-column>

			<el-table-column width="140" align="center" label="Ед. изм." property="unit">
			  <template slot-scope="scope">
				<el-select size="small" v-model="scope.row.units" placeholder="Выбрать ед. изм">
				  <el-option
					v-for="item in listUnit"
					:key="item.id"
					:label="item.name"
					:value="item.id"
				  />
				</el-select>
			  </template>
			</el-table-column>
			<el-table-column align="center" label="Допустимые значения" property="unit">
                <!-- <template slot="header">
                    Допустимые значения
                    <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover">
                            текст
                            <i class="el-icon-question" slot="reference" />
                    </el-popover>
                </template> -->

			  <template slot-scope="scope">
                  <!-- {{console(scope)}} -->
                <el-input slot="reference" size="small" placeholder="От" style="width:25%" v-model="scope.row.vv_min" />
                &#8212;
				<el-input
				  size="small"
				  placeholder="До"
				  style="width:25%"
				  v-model="scope.row.vv_max"
				/>
			  </template>
			</el-table-column>
			<el-table-column align="center" label="Референсные значения" property="unit">
                <template slot="header">
                    Референсные значения
                    <el-popover
                        placement="top-start"
                        width="235"
                        trigger="hover">
                            <p>Норма для здорового человека. Должна быть в пределах <br>допустимых значений</p>
                            <i class="el-icon-question" slot="reference" />
                    </el-popover>
                </template>
			  <template slot-scope="scope">
				<el-color-picker style="top:6px" size="small" v-model="scope.row.color_acces" />
				<div style="display:unset;position:relative;bottom:6px;">
				  <el-input
					size="small"
					placeholder="От"
					style="width:25%"
					v-model="scope.row.ref_min"
				  />&#8212;
				  <el-input
					size="small"
					placeholder="До"
					style="width:25%"
					v-model="scope.row.ref_max"
				  />
				</div>
				<el-color-picker style="top:6px" size="small" v-model="scope.row.color_warning" />
			  </template>
			</el-table-column>
            <el-table-column align="center" label="Возраст" property="unit">
			  <template slot="header">
                    Возраст
                    <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover">
                            Зависимость реф.значений от возраста
                            <i class="el-icon-question" slot="reference" />
                    </el-popover>
                </template>
              <template slot-scope="scope">
				<el-checkbox title="Спрятать" v-model="scope.row.depend" /><br>
				
                <el-input v-if="scope.row.depend" slot="reference" size="small" placeholder="От" style="width:25%" v-model="scope.row.age_min" />
                <span v-if="scope.row.depend">&#8212;</span>
				<el-input
                  v-if="scope.row.depend"
				  size="small"
				  placeholder="До"
				  style="width:25%"
				  v-model="scope.row.age_max"
				/>
                
			  </template>
			</el-table-column>
			<el-table-column width="90px" align="center" label="Спрятать" property="unit">
			  <template slot-scope="scope">
				<el-checkbox title="Спрятать" v-if="!scope.row.newRow" v-model="scope.row.hide" />
				<el-button
				  v-if="scope.row.newRow"
				  class="btn-style-minus"
				  @click="delRef(scope.$index)"
				  type="danger"
				  icon="el-icon-delete"
				  circle
				/>
			  </template>
			</el-table-column>
		  </el-table>
		</el-card>
	  </div>

	  <center class="block-ed-del">
		<el-button v-if="showGroup == 2" type="primary" @click="editDialog = false">Нет</el-button>
		<el-button v-if="showGroup == 2" type="primary" @click="deleteFucn(editItems)">Да</el-button>

		<el-button v-if="showGroup == 1 || showGroup == 0" type="primary" @click="resetForm">Отмена</el-button>
		<el-button
		  v-if="showGroup == 1 || showGroup == 0"
		  type="primary"
		  @click="submitForm(editItems)"
		>Сохранить</el-button>
	  </center>
    </el-form>
	</el-dialog>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  components: {},
  data() {
	return {
      editInd: 1,
      listCaptions: [],
      loading: true,
	  newArr: [],
	  listUnit: [
		{ id: 4, name: "не указана" },
		{ id: 1, name: "Г." },
		{ id: 2, name: "Мг." },
		{ id: 3, name: "Мкг." },
		{ id: 5, name: "шт." },
	  ],
	  listType: [
		{ id: 1, name: "Число" },
		{ id: 2, name: "Список" },
	  ],
	  headers: [
		{
		  name: "Тестовый(ТИП ЧИСЛО)",
		  type: 1,
		  params: [
			{
			  id: 1,
			  name: "Тест123",
			  // type_parametr наследуется у справочника type
			  type_parametr: 1,
			  caption: "",
			  hide: false,
			  list_ref: [
				{
				  ref_min: 1,
				  ref_max: 22,
				  units: 1,
				  vv_min: 0,
				  vv_max: 200,
				  color_acces: "#57E31C",
				  color_warning: "#ED1515",
				  hide: false,
				},
			  ],
			},
		  ],
		},
		{
		  name: "Тестовый(ТИП СПИСОК)",
		  type: 2,
		  params: [{id: 1,type_parametr: 2,name: "Тест 1",caption: "",hide: false,list_ref: []},{id: 2,type_parametr: 2,name: "Тест 2",caption: "",hide: false,list_ref: []},{id: 3,type_parametr: 2,name: "Тест 3",caption: "",hide: false,list_ref: []}]
		},
	  ],
	  showGroup: 0,
	  editItems: {},
	  defaultItems: {
		ref_min: null,
		ref_max: null,
		units: null,
		vv_min: 0,
		vv_max: null,
		color_acces: "#57E31C",
		color_warning: "#ED1515",
		hide: false,
		newRow: true,
	  },
	  multipleSelection: [],
	  search: "",
	  editDialog: false,
	  titleText: "",
	  rules: {
		name: [
		  {
			required: true,
			message: "Пожалуйста введите название параметра...",
			trigger: "blur",
		  },
		],
		dop: [
		  {
			required: true,
			message: "Пожалуйста введите значение...",
			trigger: "blur",
		  },
		],
	  },
	};
  },
  methods: {
	  ...mapActions({
		  getParamsList: 'adminModule/getParamsList',
          saveParams: 'adminModule/saveParams',
          saveNewParams: 'adminModule/saveNewParams',
          authParams: 'adminModule/authParams'
	  }),
	addRef(e) {
	  this.editItems.list_ref.push({
		age_min: null,
		age_max: null,
		depend: false,
		ref_min: null,
		ref_max: null,
		units: null,
		vv_min: 0,
		vv_max: null,
		color_acces: "#57E31C",
		color_warning: "#ED1515",
		hide: false,
		newRow: true,
	  });
	  // console.log('this.editItems.list_ref', this.editItems.list_ref)
	},
	selectEvents() {
	  this.$message({
		message: `Тип справочника изменен на ${this.editItems}`,
		type: "success",
	  });
    },
    S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    },
    guidGener() {
        return (this.S4()+this.S4()+"-"+this.S4()+"-"+this.S4()+"-"+this.S4()+"-"+this.S4()+this.S4()+this.S4());
    },
	delRef(ind) {
	  // console.log(ind)
	  this.editItems.list_ref.splice(ind, 1);
	},
	viewResult(e) {
	  // console.log(e)
	  //return e.ref_active ? e.ref.min&&e.ref.max ? e.ref.min+' - '+e.ref.max : (e.ref.min&&!e.ref.max ? '> '+e.ref.min : (!e.ref.min&&e.ref.max ? '< '+e.ref.max : '')) : 'Нет'
	  return 1;
	},
	handleSelectionChange(val) {
	  this.multipleSelection = val;
	},
	handleEdit(ind, edde, item, type, obj) {
	  this.showGroup = edde;
	  this.editDialog = true;
	  if (edde !== 0) {
        this.editInd = -1
		if(type == 1) {
            this.editItems = {
                id_testator: item.id_testator,
                name: item.name,
                id: item.id,
                type_parametr: type,
                caption: item.caption,
                list_ref: item.list_ref,
                hide: item.hide,
            };
        } else if(type == 2) {
            this.editItems = {
                id_testator: item.id_testator,
                name: item.name,
                id: item.id,
                type_parametr: type,
                caption: item.caption,
                list_ref: item.list_ref,
                hide: item.hide,
            };
        }
	  } else {
        this.editInd = 1
		if (type == 1) {
		  this.editItems = {
			id_testator: obj.id,
            name: "",
            id: this.guidGener(),
			type_parametr: type,
			caption: "",
			list_ref: [
			  {
				ref_min: null,
				ref_max: null,
				units: null,
				vv_min: 0,
				vv_max: null,
				color_acces: "#57E31C",
				color_warning: "#ED1515",
                hide: false,
                depend: false,
                age_min: null,
		        age_max: null,
			  },
			],
		  };
		} else {
		  this.editItems = {
            name: "",
            id_testator: obj.id,
            id: this.guidGener(),
			type: type,
			hide: false,
		  };
		}
	  }

	  if (edde == 1) this.titleText = `Редактировать параметр "${item.name}"`;
	  else if (edde == 2)
		this.titleText = `Вы уверены, что хотите удалить параметр "${item.name}"?`;
	  else this.titleText = `Добавить новый параметр`;

	  //   console.log(this.editItems.type_parametr)
	},
	deleteFucn(e) {
	  console.log("deleteFucn", e);
	},
	submitForm(formName) {
        let flagError = false, type = formName.type || formName.type_parametr;
        if(type == 2) {
            if(!formName.name) {
                this.$message({ message: 'Заполните название параметра!!!', type: "error"})
                flagError = true
            }
        } else {
            for (let i = 0; i < formName.list_ref.length; i++) {
                if(!formName.name) {
                    this.$message({ message: 'Заполните название параметра!!!', type: "error"})
                    flagError = true
                    break;
                } else {
                    if(!formName.list_ref[i].vv_min && !formName.list_ref[i].vv_max){
                        this.$message({ message: 'Заполните допустимое значение', type: "error"})
                        flagError = true
                        break;
                    } else {
                        if(+formName.list_ref[i].vv_max < +formName.list_ref[i].vv_min) {
                            this.$message({ message: 'Допустимое мин. не может быть больше макс. !!!', type: "error"})
                            flagError = true
                            break;
                        } else {
                            if(formName.list_ref[i].ref_min && +formName.list_ref[i].vv_min > +formName.list_ref[i].ref_min){
                                this.$message({ message: 'Референсное мин. не может быть меньше допустимого!!!', type: "error"})
                                flagError = true
                                break;
                            } else {
                                if(formName.list_ref[i].ref_max && +formName.list_ref[i].vv_max < +formName.list_ref[i].ref_max){
                                    this.$message({ message: 'Референсное макс. не может быть больше допустимого!!!', type: "error"})
                                    flagError = true
                                    break;
                                } else {
                                    if(formName.list_ref[i].depend && +formName.list_ref[i].age_max < +formName.list_ref[i].age_min) {
                                        this.$message({ message: 'Возраст мин. не может быть больше максимального!!!', type: "error"})
                                        flagError = true
                                        break;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if(!flagError) {
            // this.$message({ message: 'Успешно сохранено!!!', type: "success"})
            // console.log(formName)
            this.newArr.forEach(el => {
                // console.log(el.params,formName)
                if(el.id == formName.id_testator) {
                    // formName.name = formName.caption
                    if(this.editInd == 1)
                        el.params.push(formName)
                    else
                        el.params.forEach(f => {
                            if(f.id == formName.id) Object.assign(f, formName)
                        })
                    // console.log({caption:el.caption, id:el.id?el.id:undefined, json:el.json.json})
                    this.saveParams({caption:el.caption, id:el.id?el.id:undefined, json:el.json.json})
                }
            })
            this.editDialog = false
        }
    },
    console(e) {
        console.log(e)
    },
	resetForm(formName) {
      //   this.$refs[formName].resetFields();
      this.editItems = {}
	  this.editDialog = false;
	},
  },
  created() {
	  this.getParamsList().then(res => {
          res.data.forEach(el => {
                el.json = JSON.parse(el.json)
                if(typeof el.json.json !== 'string') el.params = el.json.json
                if(el.params) {
                    el.params.forEach(e => { this.listCaptions.push({name:e.name}); e.id_testator = el.id })
                    el.type = el.params[0].type_parametr
                }
                // console.log(el.params)
            })
          this.newArr = res.data.filter(f => f.params)
          if(res.status == 200) this.loading = false
      })

    // ТИП СПИСОК
    //   let dataList = {
    //         caption: "Единицы измерения",
    //         json: [{"id":0,"type_parametr":2,"name":"г/л","caption":"Единицы измерения","hide":false,"list_ref":[],"id_testator":"bcb40d7f-0705-45dc-ae37-5dfa974da929"},{"id":1,"type_parametr":2,"name":"мм/час","caption":"Единицы измерения","hide":false,"list_ref":[],"id_testator":"bcb40d7f-0705-45dc-ae37-5dfa974da929"},{"id":2,"type_parametr":2,"name":"10^9/л","caption":"Единицы измерения","hide":false,"list_ref":[],"id_testator":"bcb40d7f-0705-45dc-ae37-5dfa974da929"},{"id":3,"type_parametr":2,"name":"10^12/л","caption":"Единицы измерения","hide":false,"list_ref":[],"id_testator":"bcb40d7f-0705-45dc-ae37-5dfa974da929"},{"name":"Ед/л","id_testator":"bcb40d7f-0705-45dc-ae37-5dfa974da929","id":"7436cde6-4d3f-05a0-e958-d26ab785de3d","type":2,"hide":false},{"name":"мкмоль/л^2","id_testator":"bcb40d7f-0705-45dc-ae37-5dfa974da929","id":"406d0db8-0d83-5bfd-d73e-6f635c4d2d9a","type":2,"hide":false,"type_parametr":2}]
    //     }
    //     JSON.stringify(dataList.json)
    //   this.saveParams(dataList)
  }
};
</script>

<style>

div.el-popover {
    padding: 25px!important;
}
.el-slider__runway.disabled .el-slider__bar {
    background-color: #6374DD;
}
.block-ed-del {
  margin-top: 20px;
}

.marg-top {
  margin-top: 10px;
}
.btn-style-plus {
  position: relative;
  bottom: 6px;
  left: 6px;
  padding: 5px 5px !important;
}
.btn-style-minus {
  /* position: relative;
	bottom: 1px;
	left: 19px; */
  padding: 5px 5px !important;
}
/* .el-button.is-plain:focus, .el-button.is-plain:hover {
	background: #FFF;
	border-color: #6374DD!important;
	color: #6374DD!important;
} */
/* .el-table td, .el-table th {
	text-align: center!important;
} */
.el-checkbox__inner:hover {
  border-color: #6374dd !important;
}
.el-checkbox__inner:focus {
  border-color: #6374dd !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #6374dd !important;
  border-color: #6374dd !important;
}
.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #6374dd !important;
}
.el-dialog__header {
  background: #6374dd !important;
}
.el-dialog__title {
  color: white !important;
}
.el-button--info {
  color: white !important;
  background-color: #6374dd !important;
  border-color: #6374dd !important;
}
.el-tabs--border-card
  > .el-tabs__header
  .el-tabs__item:not(.is-disabled):hover {
  color: #6374dd !important;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #6374dd !important;
}
</style>