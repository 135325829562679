<template>
    <v-container fluid>
        <v-row justify="center" align="center" class="tabs-wrap">
            <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                <el-tabs :value="mofd" @tab-click="tabClick" id="tabsize" tab-index="activeIndex" class="dropdown-tabs" type="border-card">
                    
                    <el-tab-pane name="1" label="Осмотр">
                        <view-inspection v-if="us.id || patient.id" />
                    </el-tab-pane>

                    <el-tab-pane name="2" label="Фотоизображение">
                        <el-tabs :value="photo || '11'" @tab-click="tabClickPhoto" type="border-card" class="tabs-clear">
                            <el-tab-pane name="11" label="Суставы">
                                <joints :photo="tabClickFlag" />
                            </el-tab-pane>
                            <el-tab-pane name="22" label="Суставной статус">
                                <joints-status />
                            </el-tab-pane>
                            <el-tab-pane name="33" label="Кожный покров">
                                <integument :photo="tabClickFlag"/>
                            </el-tab-pane>
                            <el-tab-pane name="44" label="Общее">
                                <general :photo="tabClickFlag" />
                            </el-tab-pane>
                        </el-tabs>
                    </el-tab-pane>

                    <el-tab-pane name="3" label="Видео">
                        <video-el :video="tabClickFlagV"/>
                    </el-tab-pane>

                </el-tabs>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import ViewInspection from './grid/ViewInspection.vue'


import Joints from './element/Joints.vue'
import Integument from './element/Integument.vue'
import General from './element/General.vue'
import VideoEl from './element/VideoEl.vue'
import JointsStatus from './element/jointsStatus.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        ViewInspection,
        Joints,
        Integument,
        General,
        VideoEl,
		JointsStatus
    },
    data() {
        return {
            activeIndex: '4',
            tabClickFlag: null,
            tabClickFlagV: null,
        }
    },
    computed: {
        ...mapGetters({
            notifications: 'personalDoctorsOffice/notifications',
			us: 'user/user',
			patient: 'user/patient',
			usNoCopie: 'user/copy'
        }),
        numberOfNotifications() {
            var intNot = 0
            for(let i=0;i<this.notifications.length;i++) {
                if(this.notifications[i].new === true) {
                    intNot++
                }
            }
            return intNot
        },
        mofd() {
            return localStorage.mofd
        },
        photo() {
            return localStorage.photo
        },
    },
    methods: {
        ...mapMutations({
            changeFlag: 'manifestationOfDisease/changeFlag'
        }),
        tabClick(e){
            this.tabClickFlagV = e.name
            if(e.name !== 2) this.changeFlag()

            if(e.name == '1') {
                this.getInspection()
                this.getJoints('joints')
                localStorage.setItem('mofd', '1');
                localStorage.removeItem('subtype')
                localStorage.removeItem('subtype_item')
            } else if(e.name == '2'){
                localStorage.setItem('mofd', '2');
                localStorage.removeItem('subtype')
                localStorage.removeItem('subtype_item')
                if(localStorage.photo === '11'){
                    this.resetMass();
                    this.getJoints('joints');
                    this.getAllJointPhoto('joints');
                } else if(localStorage.photo === '22'){
                    this.resetMass();
                    this.getJoints('angles');
                    this.getAllJointPhoto('angles');
                } else if(localStorage.photo === '33'){
                    this.resetMass();
                    this.getJoints('skin');
                    this.getAllJointPhoto('skin');
                } else if(localStorage.photo === '44'){
                    this.resetMass();
                    this.getJoints('general');
                    this.getAllJointPhoto('general');
                }
            } else if(e.name == '3'){
                this.resetMass();
                this.getJoints('videos');
                this.getAllJointPhoto('videos');
                localStorage.setItem('mofd', '3');
                localStorage.removeItem('subtype')
                localStorage.removeItem('subtype_item')
            }
        },
        tabClickPhoto(e){
            this.tabClickFlag = e.name
            if(e.name == 11) {
                this.resetMass();
                this.getJoints('joints');
		        this.getAllJointPhoto('joints');
                localStorage.setItem('photo', '11');
            } else if(e.name == 22){
                this.resetMass();
                this.getJoints('angles');
		        this.getAllJointPhoto('angles');
                localStorage.setItem('photo', '22');
            } else if(e.name == 33){
                this.resetMass();
                this.getJoints('skin');
		        this.getAllJointPhoto('skin');
                localStorage.setItem('photo', '33');
            } else if(e.name == 44){
                localStorage.setItem('photo', '44');
                this.getJoints('general');
		        this.getAllJointPhoto('general');
            }
        },
        ...mapActions({
            getAllJointPhoto: 'manifestationOfDisease/getAllJointPhoto',
            resetMass: 'manifestationOfDisease/resetMass',
            getInspection: 'manifestationOfDisease/getInspection',
            getJoints: 'manifestationOfDisease/getJoints',
            getUser(dispatch) {
                let dataEditCop = JSON.parse(localStorage.statusUserCopie)
                dispatch('auth/getUser', {
                    id: dataEditCop.id,
                    fio: dataEditCop.FullName,
                    role: dataEditCop.role,
                    ssid: dataEditCop.ssid,
                    patients_id: dataEditCop.patients_id,
                    login: dataEditCop.login
                })
            }
		})
    },
    created() {
        
        if(localStorage.photo === '11' && localStorage.mofd === '2'){
            this.resetMass();
            this.getJoints('joints');
            this.getAllJointPhoto('joints');
        } else if(localStorage.photo === '22' && localStorage.mofd === '2'){
            this.resetMass();
            this.getJoints('angles');
            this.getAllJointPhoto('angles');
        } else if(localStorage.photo === '33' && localStorage.mofd === '2'){
            this.resetMass();
            this.getJoints('skin');
            this.getAllJointPhoto('skin');
        } else if(localStorage.photo === '44' && localStorage.mofd === '2'){
            this.resetMass();
            this.getJoints('general');
            this.getAllJointPhoto('general');
        } else if(localStorage.mofd === '3') {
            console.log('3')
            this.getJoints('videos');
            this.getAllJointPhoto('videos');
        }
    },
    mounted(){
        if(localStorage.mofd === '1'){
            // this.getInspection()
            this.getJoints('joints')
        }
    }
}
</script>

