export default {
	namespaced: true,
	state: {
		biochemical_blood_test: [],
		other: [],
		clinical_blood_count: [ ],
		immunology: [],
		edizm: [
			{"ID": 1, "Name": "г/л"},
			{"ID": 2,"Name": "мм/час"},
			{"ID": 3,"Name": "10^9/л"},
			{"ID": 4,"Name": "10^12/л"}
		],
		products: [
			{"ID": 1, "Name": "АлАт (аланинаминотрансфераза)"},
			{"ID": 2,"Name": "АсАт (аспартатаминотрансфераза)"},
			{"ID": 3,"Name": "Альбумин"},
			{"ID": 4,"Name": "Общий белок"},
			{"ID": 5,"Name": "Глюкоза"},
			{"ID": 6,"Name": "Триглицериды"},
			{"ID": 7,"Name": "КФК (креатинкиназа)"},
			{"ID": 8,"Name": "Натрий"},
			{"ID": 9,"Name": "Калий"},
			{"ID": 10,"Name": "Фосфор"},
			{"ID": 11,"Name": "Щелочная фосфатаза общая"},
			{"ID": 12,"Name": "ГГТ (гаммаглютамилтранспептидаза)"},
			{"ID": 13,"Name": "ЛДГ (лактатдегидрогеназа)"},
			{"ID": 14,"Name": "Мочевая кислота"},
			{"ID": 15,"Name": "Железо"},
			{"ID": 16,"Name": "Амилаза"},
			{"ID": 17,"Name": "Биллирубин прямой"},
			{"ID": 18,"Name": "Амилаза панкреатическая"},
			{"ID": 19,"Name": "Холестерин общий"},
			{"ID": 20,"Name": "Кальций общий"},
			{"ID": 21,"Name": "Липаза"},
			{"ID": 22,"Name": "Магний"},
			{"ID": 23,"Name": "Иммуноглобулин IgG"},
			{"ID": 24,"Name": "Иммуноглобулин IgM"},
			{"ID": 25,"Name": "Иммуноглобулин IgA"},
			{"ID": 26,"Name": "Холестерин общий"},
			{"ID": 27,"Name": "Антистрептолизин О"},
			{"ID": 28,"Name": "С3 компонент комплемента"},
			{"ID": 29,"Name": "С4 компонент комплемента"},
			{"ID": 30,"Name": "Иммуноглобулин Е общий"},
			{"ID": 31,"Name": "Прокальцитонин"},
		],

		clinical_blood_countModal: false,
		biochemicalBloodTestModal: false,
		immunologyModal: false,
	},
	getters: {
		biochemicalBloodTest: state => state.biochemical_blood_test,
		other: state => state.other,
		clinicalBloodCount: state => state.clinical_blood_count,
		immunology: state => state.immunology,
		products: state => state.products,
		edIzm: state => state.edizm,
		clinical_blood_countModal: state => state.clinical_blood_countModal,
		biochemicalBloodTestModal: state => state.biochemicalBloodTestModal,
		immunologyModal: state => state.immunologyModal,
	},
	mutations: {		
		clinical_blood_countModal(state, data) {
			state.clinical_blood_countModal = data;
		},

		biochemicalBloodTestModal(state, data) {
			state.biochemicalBloodTestModal = data;
		},

		immunologyModal(state, data) {
			state.immunologyModal = data;
		},

		//Инные анализы
		otherAn(state, data) {
			state.other = data;
		},
		setOtherAn(state, data) {
			state.other.push(data)
		},
		//Клинические анализы
		blobTestItems(state, data) {
			data.forEach(item => {
				item.ley = item.ley == null ? '' : String(item.ley)
				item.eri = item.eri == null ? '' : String(item.eri)
				item.tro = item.tro == null ? '' : String(item.tro)
				item.ney = item.ney == null ? '' : String(item.ney)
				item.gem = item.gem == null ? '' : String(item.gem)
				item.soe = item.soe == null ? '' : String(item.soe)
				item.lim = item.lim == null ? '' : String(item.lim)
				item.baz = item.baz == null ? '' : String(item.baz)
				item.eozin = item.eozin == null ? '' : String(item.eozin)
				item.mon = item.mon == null ? '' : String(item.mon)
			})
			state.clinical_blood_count = data;
		},
		saveClin(state, data){
			data.ley = data.ley == null ? '' : String(data.ley)
			data.eri = data.eri == null ? '' : String(data.eri)
			data.tro = data.tro == null ? '' : String(data.tro)
			data.ney = data.ney == null ? '' : String(data.ney)
			data.gem = data.gem == null ? '' : String(data.gem)
			data.soe = data.soe == null ? '' : String(data.soe)
			data.lim = data.lim == null ? '' : String(data.lim)
			data.baz = data.baz == null ? '' : String(data.baz)
			data.eozin = data.eozin == null ? '' : String(data.eozin)
			data.mon = data.mon == null ? '' : String(data.mon)
			state.clinical_blood_count.push(data)
		},
		setUpdClin(state, data){
			state.clinical_blood_count.forEach(item => {
				if(item.id == data.id){
					item.ley = data.ley == null ? '' : String(data.ley)
					item.eri = data.eri == null ? '' : String(data.eri)
					item.tro = data.tro == null ? '' : String(data.tro)
					item.ney = data.ney == null ? '' : String(data.ney)
					item.gem = data.gem == null ? '' : String(data.gem)
					item.soe = data.soe == null ? '' : String(data.soe)
					item.lim = data.lim == null ? '' : String(data.lim)
					item.baz = data.baz == null ? '' : String(data.baz)
					item.eozin = data.eozin == null ? '' : String(data.eozin)
					item.mon = data.mon == null ? '' : String(data.mon)
					item.date = data.date
				}
			});
		},
		//Биохимические анализы
		bioBlobTestItems(state, data) {
			data.forEach(item => {
				item.act = item.act == null ? '' : String(item.act)
				item.alt = item.alt == null ? '' : String(item.alt)
				item.obshbil = item.obshbil == null ? '' : String(item.obshbil)
				item.moch = item.moch == null ? '' : String(item.moch)
				item.kre = item.kre == null ? '' : String(item.kre)
			})
			state.biochemical_blood_test = data;
		},
		saveBioh(state, data){
			data.act = data.act == null ? '' : String(data.act)
			data.alt = data.alt == null ? '' : String(data.alt)
			data.obshbil = data.obshbil == null ? '' : String(data.obshbil)
			data.moch = data.moch == null ? '' : String(data.moch)
			data.kre = data.kre == null ? '' : String(data.kre)
			state.biochemical_blood_test.push(data)
		},
		setUpdBiohim(state, data){
			state.biochemical_blood_test.forEach(item => {
				if(item.id == data.id){
					item.act = data.act === null ? '' : data.act
					item.alt = data.alt === null ? '' : data.alt
					item.obshbil = data.obshbil === null ? '' : data.obshbil
					item.moch = data.moch === null ? '' : data.moch
					item.kre = data.kre === null ? '' : data.kre
					item.date = data.date
				}
			});
		},
		//Иммунология
		immunologyTestItems(state, data) {
			data.forEach(item => {
				item.c_reak = item.c_reak == null ? '' : String(item.c_reak)
				item.antfac = item.antfac == null ? '' : String(item.antfac)
				item.revfac = item.revfac == null ? '' : String(item.revfac)
				item.antpep = item.antpep == null ? '' : String(item.antpep)
			})
			state.immunology = data;
		},
		saveImmun(state, data){
			data.c_reak = data.c_reak == null ? '' : String(data.c_reak)
			data.antfac = data.antfac == null ? '' : String(data.antfac)
			data.revfac = data.revfac == null ? '' : String(data.revfac)
			data.antpep = data.antpep == null ? '' : String(data.antpep)
			state.immunology.push(data)
		},
		setUpdImm(state, data){
			state.immunology.forEach(item => {
				if(item.id == data.id){
					item.c_reak = data.c_reak === null ? '' : data.c_reak
					item.antfac = data.antfac === null ? '' : data.antfac
					item.revfac = data.revfac === null ? '' : data.revfac
					item.antpep = data.antpep === null ? '' : data.antpep
					item.date = data.date
				}
			});
		},
	},
	actions: {	
		updateclinical_blood_countModal({commit}, data) {
			commit('clinical_blood_countModal', data);
		},

		updatebiochemicalBloodTestModal({commit}, data) {
			commit('biochemicalBloodTestModal', data);
		},

		updateimmunologyModal({commit}, data) {
			commit('immunologyModal', data);
		},

		//Инные анализы
		//Инные анализы
		//Инные анализы
		getOtherAn({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/api/patients/${id}/other_blood_tests`).then(res => {
				commit('otherAn', res.data);
			})
		},
		createOtherAn({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/other_blood_tests`, payload).then(res => {
				commit('setOtherAn', res.data);
			})
		},
		updateOtherAn({commit, rootState}, payload) {
			let idd = payload.__KEY__ || payload.id
			let id = rootState.user.patient.patients_id;
			this.api.put(`${rootState.apiUrl}/api/patients/${id}/other_blood_tests/${idd}`, payload)
		},
		deleteOtherAn({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/other_blood_tests/${payload.id}`, payload)
		},
		//Клинические анализы
		//Клинические анализы
		//Клинические анализы
		getBlobTestItems({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/api/patients/${id}/clinical_blood_tests`).then(res => {
				commit('blobTestItems', res.data);
			})
		},
		createAnalysesBlobTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/clinical_blood_tests`, payload).then(res => {
				commit('saveClin', res.data)
			})
		},
		updateAnalysesBlobTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.put(`${rootState.apiUrl}/api/patients/${id}/clinical_blood_tests/${payload.id}`, payload).then(res => {
				commit('setUpdClin', payload)
			})
		},
		deleteAnalysesBlobTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/clinical_blood_tests/${payload.id}`, payload)
		},
		//Биохимические анализы
		//Биохимические анализы
		//Биохимические анализы
		getBioBlobTestItems({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/api/patients/${id}/biochemical_blood_tests`).then(res => {
				commit('bioBlobTestItems', res.data);
			})
		},
		createBioBlobTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/biochemical_blood_tests`, payload).then(res => {
				commit('saveBioh', res.data)
			})
		},
		updateBioBlobTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.put(`${rootState.apiUrl}/api/patients/${id}/biochemical_blood_tests/${payload.id}`, payload).then(res => {
				commit('setUpdBiohim', payload)
			})
		},
		deleteBioBlobTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/biochemical_blood_tests/${payload.id}`, payload)
		},
		//Иммунология
		//Иммунология
		//Иммунология
		getImmunologyTestItems({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/api/patients/${id}/immunology`).then(res => {
				commit('immunologyTestItems', res.data);
			})
		},
		createImmunologyTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/immunology`, payload).then(res => {
				commit('saveImmun', res.data)
			})
		},
		updateImmunologyTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.put(`${rootState.apiUrl}/api/patients/${id}/immunology/${payload.id}`, payload).then(res => {
				commit('setUpdImm', payload)
			})
		},
		deleteImmunologyTest({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/immunology/${payload.id}`, payload)
		}
	}
}