<template>
<div>
    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        :viewBox="viewBox" style="enable-background:new 0 0 1000 1500;" xml:space="preserve">
    <g id="Слой_2">
    </g>
   <path v-if="syssind"  class="st0" d="M565.7,235.7c-24.6-7.5-48.4-16.7-57.3-22.1c-8.8-5.5-41.4-5.5-50.4,0c-8.8,5.5-40.5,12.1-58.1,22.9
	c-12,7.3,14.7,48.4,20,66.1c0.5-1.5,8.7,55.8,5.3,68.9c-3.3,13.1-4.1,22.5-11.5,46.1c-6.7,21.6-13.5,69.7-10.3,93
	s69.4-9.9,69.4-12.3c0-2.3,6.4-7.7,10.4-7.7c3.9,0,11.9,4,11.9,6.4c0,2.3,65.8,41.8,67,18.4c1.2-25.2-1.3-65.5-5.9-87.8
	c-4.7-22.8-9.5-32.6-12.9-45.7c-3.3-13.1-1.2-42.6-1.2-46.9C542.3,329.1,548.7,289,565.7,235.7z"/>
<path  v-if="syssind"  class="st0" d="M507.8,214.8c-8.8-5.5-4.4-20.4-4.4-20.4c4.3-5.1,10.1-25.2,10.1-25.2c2.9,0.8,3.5,0,3.5,0
	c4.5-2.4,2.8-11.5,2.9-13.5s1.2-1.5,1.6-6s-3.9-3.1-3.9-3.1c4.8-50.5-35-43.8-35-43.8s-39.8-6.5-35,43.8c0,0-4.3-1.5-3.9,3.1
	c0.4,4.5,1.5,4,1.6,6c0.1,2-1.6,11.1,2.9,13.5c0,0,0.7,0.8,3.5,0c0,0,5.9,20.1,10.1,25.2c0,0,6.1,18.7-9.5,22.6
	c0.5,0,0.3,10,0.3,11.6c0,0,59,0,61.7,0c0.8,0,0-1.1,0-11.7C514.4,217,514.4,217,507.8,214.8z"/>
<path v-if="syssind" class="st0" d="M419.3,398.3c-3.3,13.1,1.9-4.3-5.5,19.3c-6.7,21.6-13.5,69.7-10.3,93s69.4-9.9,69.4-12.3
	c0-2.3,6.4-7.7,10.4-7.7c3.9,0,11.9,4,11.9,6.4c0,2.3,65.8,41.8,67,18.4c1.2-25.2-1.3-65.5-5.9-87.8c-4.7-22.8-3.9-16.5-7.3-29.6
	C504.4,398.3,462.6,398.3,419.3,398.3z"/>
<path v-if="syssind"  class="st0" d="M472.8,498.5c-0.3,2.3-2.4,30.1-4.7,37.7c-2.3,7.6-10,44.8-12.5,51.4c-2.7,6.7-4.9,31.4-6.3,37
	c-1.3,5.7-5.1,27.6-10.4,36.9c-5.5,9.3-4.4,51.3-5.3,57.3s-10.9,44.5-12.1,49.4c-1.2,4.9-1.7,7.9-2.7,14.1
	c-1.1,6.3,1.1,29.3-4.5,41.4c-3.1,7.6-3.1,5.3-2.9,5.1c-4.8,8.1-7.3,11.9-15.7,26.1c-7.6,11.3-2.8,4-9.1,12
	c-2.9,2.1-5.5,8.4-8.5,8.1c-5.6-0.5-0.8-8.8-0.8-8.8c-3.2,5.3-6.1,5.5-7.7,4.5c-2.8-1.5,2.1-6.4,2.1-8.8c-6.9,5.7-8.9,2.7-8.8,0.4
	c0.1-2.4,3.9-6,3.9-6c-14.5,6.4-8.5-3.5-5.9-4.9c1.5-0.8-4.3,1.9-4.3,1.9c-5.2-0.9,7.2-17.3,10.5-22c1.5-2,10.5-11.1,13.5-14.3
	c5.3-10.4,8.8-20.1,8.5-32c-0.1-8.3-3.5-19.5-5.6-42.4c-2.1-23,4.3-48.4,9.3-64.7c4.9-16.5,7.5-37.3,8.4-47.4
	c0.8-10-3.7-43.7-5.3-60.2c-1.6-16.5-1.2-34.4,0.4-57.7c1.5-45.7,15.6-83.3,15.6-83.3C433.8,463.9,473.1,496.2,472.8,498.5z"/>
<path v-if="syssind"  class="st0" d="M300.2,250.8c0.5,2.3,3.9,15.2,4,31.6c0,16.4-10.3,13.5-0.3,30c8.1,7.2,25.8,22.4,35.6,32.5
	c11.2,11.7,12-17.1,39-72.2c13.3-25.8,15.7-31.3,21.6-36.1c7.9-4.1,48.6-19.3,57.3-21.8c0,0,0.9,188.9-0.8,191.3
	c-17.2,22.1-38.4-1.1-38.4-1.1c16-50.6-1.5-108.8-1.7-107.9c-28.5,47.6-28.8,49-29.7,50.4c-33.3,48.6-39.7,53.3-50.6,46.4
	c-8.8-8.8-14.9-13.7-29.2-30.6c-3.1-4.5-22.9-30-28.2-41.4c-7.3-6.9-9.3-7.7-15.3-11.9c-6.3-4.4-9.9-9.5-23.6-22.6
	c-15.7-13.9-17.5-16.4-14.4-19.3c0.5-1.3,20.1,11.2,20.1,11.2s6,0.5-13.6-18.4c-8.8-9.3-1.9-10.3,5.3-5.1
	c4.3,3.1,14.9,13.5,14.9,13.5c6.3,1.6-6.9-20.2-8.8-22.8c-6.7-11.3,0.8-11.6,10.3,1.9c2.8,3.1,6.1,8.3,8.1,9.5
	c0.1,0.1,0.4-0.3,0.3-0.1c-0.3,0.1,4.9,4.4,1.9-4.5c-1.7-9.3-3.7-18.4-3.7-18.4c-0.9-4.4,2.4-5.7,4.4-3.5c2,2.3,5.1,8.1,7.6,13.3
	c2.5,6.1,13.3,27.6,13.3,27.6c6.4-0.1,6.3-12,6-13.1c-0.3-1.1-0.3-8.8,2.7-11.6C297,244.4,299.5,248.5,300.2,250.8z"/>
<path v-if="syssind"   class="st0" d="M674.3,248.5c2.9,2.8,2.9,10.5,2.7,11.6c-0.3,1.1-0.5,13.1,6,13.1c0,0,10.8-21.4,13.3-27.6
	c2.5-5.2,5.6-11.1,7.6-13.3c2-2.3,5.3-0.9,4.4,3.5c0,0-2,9.1-3.7,18.4c-3.1,8.9,2.1,4.7,1.9,4.5c-0.1-0.1,0.1,0.3,0.3,0.1
	c2-1.2,5.5-6.4,8.1-9.5c9.5-13.3,16.9-13.2,10.3-1.9c-1.9,2.5-15.1,24.4-8.8,22.8c0,0,10.7-10.4,14.9-13.5c7.3-5.2,14.1-4.3,5.3,5.1
	C717,280.9,723,280.4,723,280.4s19.7-12.5,20.1-11.2c3.1,2.9,1.3,5.3-14.4,19.3c-13.7,13.2-17.3,18.3-23.6,22.6
	c-6,4.1-8,4.9-15.3,11.9c-5.3,11.3-25.2,36.9-28.2,41.4c-14.1,16.9-20.2,21.8-29.2,30.6c-11.1,7.1-17.3,2.4-50.6-46.4
	c-0.9-1.3-1.1-2.7-29.7-50.4c-0.3-0.9-22.6,56-1.7,107.9c0,0-21.2,23.2-38.4,1.1c-1.7-2.3-0.8-191.3-0.8-191.3
	c8.7,2.5,49.4,17.7,57.3,21.8c6,4.8,8.3,10.3,21.6,36.1c27,55.2,27.8,83.9,39,72.2c9.6-10.1,27.3-25.3,35.6-32.5
	c9.9-16.5-0.4-13.6-0.3-30c0.1-16.5,3.5-29.3,4-31.6C669,249.9,671.5,245.7,674.3,248.5z"/>
<path v-if="syssind" class="st0" d="M556.4,429c0,0,14.1,37.6,15.6,83.3c1.6,23.4,2,41.2,0.4,57.7c-1.6,16.5-6.3,50.4-5.3,60.2
	c0.9,10.1,3.3,31,8.4,47.4c4.9,16.5,11.5,41.7,9.3,64.7c-2.1,23-5.5,34.2-5.6,42.4c-0.3,11.9,3.2,21.4,8.5,32
	c2.9,3.3,12,12.3,13.5,14.3c3.3,4.7,15.7,21,10.5,22c0,0-5.6-2.7-4.3-1.9c2.7,1.5,8.8,11.3-5.9,4.9c0,0,3.7,3.5,3.9,6
	c0.1,2.4-1.9,5.3-8.8-0.4c0,2.4,4.9,7.3,2.1,8.8c-1.7,0.9-4.5,0.8-7.7-4.5c0,0,4.8,8.3-0.8,8.8c-3.1,0.3-5.6-6-8.5-8.1
	c-6.1-8-1.5-0.7-9.1-12c-8.3-14.1-10.9-18-15.7-26.1c0.1,0.3,0.1,2.7-2.9-5.1c-5.6-12.1-3.6-35.2-4.5-41.4
	c-0.9-6.3-1.5-9.2-2.7-14.1c-1.2-4.9-11.2-43.4-12.1-49.4s0.1-48-5.3-57.3s-9.1-31.2-10.4-36.9c-1.3-5.6-3.6-30.4-6.3-37
	c-2.5-6.7-10.3-43.8-12.5-51.4c-2.3-7.5-4.4-35.4-4.7-37.7C495.2,496.2,534.5,463.9,556.4,429z"/>
<g id="Слой_3">
</g>
<circle class="st1 st2" cx="502.6" cy="207.2" r="12.5" @click="setActive(0)" v-bind:class="{st3: syssind[0].isActive}"/>
<circle class="st1 st2" cx="462.6" cy="207.2" r="12.5" @click="setActive(1)" v-bind:class="{st3: syssind[1].isActive}"/>
<circle class="st1 st2" cx="546.8" cy="291.7" r="15.7" @click="setActive(2)" v-bind:class="{st3: syssind[2].isActive}"/>
<circle class="st1 st2" cx="418.5" cy="291.7" r="15.7" @click="setActive(3)" v-bind:class="{st3: syssind[3].isActive}"/>
<circle class="st1 st2" cx="612.9" cy="382.3" r="15.7" @click="setActive(4)" v-bind:class="{st3: syssind[4].isActive}"/>
<circle class="st1 st2" cx="355.4" cy="382.3" r="15.7" @click="setActive(5)" v-bind:class="{st3: syssind[5].isActive}"/>
<circle class="st1 st2" cx="441.9" cy="647.7" r="15.7" @click="setActive(9)" v-bind:class="{st3: syssind[9].isActive}"/>
<circle class="st1 st2" cx="531.1" cy="647.7" r="15.7" @click="setActive(8)" v-bind:class="{st3: syssind[8].isActive}"/>
<circle class="st1 st2" cx="511.1" cy="488.4" r="15.7" @click="setActive(6)" v-bind:class="{st3: syssind[6].isActive}"/>
<circle class="st1 st2" cx="457.6" cy="488.1" r="15.7" @click="setActive(7)" v-bind:class="{st3: syssind[7].isActive}"/>
    </svg>
	
    </div>
</template>

<script>
export default {
    props: ['viewBox', 'syssind', 'edit'],

    methods: {
		setActive(i) {
            if (!this.edit) return false;
			if(i || i == 0) this.syssind[i].isActive = !this.syssind[i].isActive
		}

	},
}
</script>


<style scoped>
.st0{fill:#F7AD9A;stroke:#FFFFFF;stroke-miterlimit:10;stroke-dasharray:2;}
.st1{fill:#F7AD9A;cursor:pointer;}
.st2{fill:#FFFFFF;stroke:#A56D65;stroke-miterlimit:10;}
/* .st0:hover {
    fill:rgb(70, 112, 107);
} */
.st3{fill:rgb(202, 70, 70);stroke:#A56D65;stroke-miterlimit:10;}
.st4{fill:#79B729;stroke:#A56D65;stroke-miterlimit:10;}
.st5{fill:#FFFF00;stroke:#A56D65;stroke-miterlimit:10;}
</style>

