<template>
<div id="osn" class="passportPart" :key="keyUpdateComponent">
    <div class="patient-wrapper">
        <el-row class="static-wrap">
            <el-col :md="7" class="patient-data">
                <PatientData />
            </el-col>
            <el-col :md="7">
                <InformationAboutParents/>
            </el-col>
            <el-col :md="9" class="family-history">
                <FamilyHistory />
            </el-col>
        </el-row>
    </div>
    <div>
        <el-row class="static-wrap tabs-wrap">
            <el-tabs :value="pp" @tab-click="tabClick" type="border-card">
                <el-tab-pane name='1' label="Диагноз по заключению врача">
					<table-diad />
                </el-tab-pane>
                <el-tab-pane name='2' label="Анамнез">
                    <Anamnez></Anamnez>
                </el-tab-pane>
                <el-tab-pane name='3' label="Сопутствующие заболевания">
					<table-sop-zab />
                </el-tab-pane>
            </el-tabs>
        </el-row>
    </div>

</div>
</template>
<script>
import PatientData from './element/PatientData.vue'
import InformationAboutParents from './element/InformationAboutParents.vue'
import FamilyHistory from './element/FamilyHistory.vue'
import tableDiad from './grid/tableDiad.vue'
import tableSopZab from './grid/tableSopZab.vue'
import Anamnez from './element/Anamnez.vue'
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        PatientData,
        InformationAboutParents,
        FamilyHistory,
        Anamnez,
		tableSopZab,
		tableDiad
    },
    data() {
        return {
            activeIndex: '1',
            mobileMenu: false,
            visiblePopupNotif: false
        }
    },
    computed: {
        ...mapGetters({
			notifications: 'personalDoctorsOffice/notifications',
			dataEditCop: 'user/copy',
			usNoCopie: 'user/copy',
            us: 'user/user',
            keyUpdateComponent: 'passportPart/keyUpdateComponent'
        }),
        pp(){
            return localStorage.pp || '1'
        }
    },
    methods: {
        tabClick(e){
            if(e.name == 1) {
                localStorage.setItem('pp', '1');
            } else if(e.name == 2){
                localStorage.setItem('pp', '2');
            } else if(e.name == 3){
                localStorage.setItem('pp', '3');
            }
        },
        ...mapActions({
            getKey: 'passportPart/getKey',
            logout(dispatch) {
               return dispatch('user/logout')
            },
		}),
        loggout() {
            this.logout()
            .then(res => {
                if(res.status === 200 && res.data.success === true) {
                    this.$router.replace('/')
                }
            })
            .catch(error => console.log(error));
        },
        handleSelect(key, keyPath) {
            if(key === "2") {
                this.$router.replace('/Treatment')
            } else if(key === "3") {
                this.$router.replace('/Analyses')
            } else if(key === "4") {
                this.$router.replace('/ManifestationOfDisease')
            } else if(key === "5") {
                this.$router.replace('/QualityOfLifeQuestionnaire')
            } else if(key === "6") {
                this.$router.replace('/Scale')
            } else if(key === "7") {
                this.$router.replace('/Data')
            } else if(key === "8") {
                this.$router.replace('/Analytics')
            }
        }
    },
    created() {
        this.getKey();
    }
}
</script>
