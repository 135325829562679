<template>
<div>
    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        :viewBox="viewBox" style="enable-background:new 0 0 1000 1500;" focusable="true" xml:space="preserve">
    <g id="Слой_3">
    </g>
    <path class="st1" d="M778.3,200.1c-15.8-8.5-37.7-15.1-44.4-19.2c-6.6-4.1-31.1-4.1-37.8,0c-6.6,4.1-30.4,9.1-43.6,17.2
        c-9,5.5,11,36.3,15,49.6c0.4-1.1,17.1,37.9,14.6,47.7c-2.5,9.8-7.8,19.6-13.3,37.3c-5,16.2-16,53.6-13.6,71.1s54.4-4.4,54.8-6.2
        c1.5-5.8,5-8.8,8-8.8c2.9,0,6,3,6,4.8c0,1.7,49.4,31.4,50.3,13.8c0.9-18.9-1-49.2-4.4-65.9c-3.5-17.1-7.8-23-11.5-32.8
        c-3.7-10.3-6.3-18.8-6.3-22C752.1,282.3,765.5,240.1,778.3,200.1z"/>
    <path class="st1" d="M734,181.9c-6.6-4.1-3.3-15.3-3.3-15.3c3.2-3.8,7.6-18.9,7.6-18.9c2.2,0.6,2.6,0,2.6,0
        c3.4-1.8,2.1-8.6,2.2-10.1c0.1-1.5,0.9-1.1,1.2-4.5s-2.9-2.3-2.9-2.3c3.6-37.9-26.3-32.9-26.3-32.9s-29.9-4.9-26.3,32.9
        c0,0-3.2-1.1-2.9,2.3c0.3,3.4,1.1,3,1.2,4.5c0.1,1.5-1.2,8.3,2.2,10.1c0,0,0.5,0.6,2.6,0c0,0,4.4,15.1,7.6,18.9
        c0,0,3.3,11.2-3.3,15.3C689.5,186,740.6,186,734,181.9z"/>
    <path class="st1" d="M606.6,386.3c6-7,17.1-28.2,19.2-31.6c7-14.7,11.4-39.3,14.6-48c6.1-13.8,6.8-21.3,21.1-54.6
        c-1.2-0.2,40-50.7-0.6-56.3c-0.7,0.1-7.7-2.7-16.7,16.7c-19.3,51-19.9,89.2-40.5,125.2c-3.9,9.6-8.7,26.2-11,34
        C584.6,383.4,604.2,393.4,606.6,386.3z"/>
    <path class="st1" d="M769.2,338.1c0,0,11.3,32.7,12.4,67c1.2,17.6,1.5,30.9,0.3,43.3s-4.7,37.8-4,45.2c0.7,7.6,2.5,23.3,6.3,35.6
        c3.7,12.4,8.6,31.3,7,48.6s-4.1,25.7-4.2,31.8c-0.2,8.9-13.1,38.9-16.7,32.8c0.1,0.2-4.9-30.2-5.6-34.9s-1.1-6.9-2-10.6
        s-8.4-32.6-9.1-37.1s0.1-36-4-43s-6.8-23.4-7.8-27.7c-1-4.2-12.4-60.7-14.1-66.4c-1.7-5.6-3.3-26.6-3.5-28.3
        C724,393,752.8,364.3,769.2,338.1z"/>
    <path class="st1" d="M511.1,412.8c5.1,2.2,19.8-4.7,24.3-8.3c1.2-0.9,15.1-11.8,15.8,3.6c0,0-17.2,24.8-23.4,31.4
        c-5,6.1-10.6,13.2-12.3,17s1.8,7.3,6.8,3.7c0,0,7.7-5.4,19.2-16.9c39.6-50,12.8-3.6-2.8,23.3c-10.9,20.8-2.7,23.4,7.6,8.7
        c6.9-10.8,47.1-77.1,26.1-25.4c0,0-7.6,18.1-9,25.3c-8.3,22.7,0.5,23.2,7.2,6.9C583.3,446.8,591,436,591,436s-10.4,56.4-8.5,56.2
        c5.4,2.3,8.3-6.8,16.9-34.5c5.3-24.7,12.5-41.8,14.5-52.2c1.3-20.4,0.3-18.4,4.8-31.5c8.3-30.9,25.6-55.3-21.4-13.6
        c-15,21.6-19.8,8.7-40.1,17.9c-20.5,9.2-39.6,24.9-42.1,26.8C512.5,406.7,506.1,410.7,511.1,412.8z"/>
    <path class="st1" d="M787.8,597.6c-0.4,17.2,14.4,33.2,25,46.6c4.3,4.8,17.4,17.8,19.5,20.7c8.7,8.3,53.3,44,23,31.8
        c0,0-11.8-6.1-13-4.6c26.6,14.4,33.3,37.9-9.4,9.2c0,0,3.6,2.9,9.1,7.1c29.6,23.7,12.3,23.2-16.3,1c10.7,6.6,27.1,22.7,20.9,27.3
        c-3.7,2.4-23.4-14.9-29.1-21.1c0,0,21,32.6,13.5,37.1c-9.4,7.3-24.5-18.2-30.1-27.1c-8.9-11.6-28.1-50.9-33-64.4
        c-8.8-31.2-4.3-26.7-5.2-41.2C761.7,594.3,788,585.8,787.8,597.6z"/>
	<path class="st1" d="M834.4,371.9c-2.3-7.8-7.1-24.4-11-34c-20.5-36.1-21.2-74.3-40.5-125.2c-9.1-19.4-16-16.6-16.7-16.7
        c-40.6,5.6,0.6,56-0.6,56.3c14.4,33.3,15,40.8,21.1,54.6c3.2,8.7,7.6,33.3,14.6,48c2.1,3.4,13.2,24.6,19.2,31.6
        C822.9,393.5,842.4,383.5,834.4,371.9z"/>
    <path class="st1" d="M912.1,404.9c-2.5-1.9-21.7-17.6-42.1-26.8c-20.3-9.2-25.1,3.8-40.1-17.9c-47-41.6-29.7-17.3-21.4,13.6
        c4.5,13.1,3.5,11.1,4.8,31.5c1.9,10.4,9.2,27.5,14.5,52.2c8.5,27.7,11.5,36.8,16.9,34.5c1.9,0.2-8.5-56.2-8.5-56.2
        s7.7,10.8,20.4,46.1c6.6,16.3,15.4,15.8,7.2-6.9c-1.4-7.1-9-25.3-9-25.3c-21-51.7,19.3,14.6,26.1,25.4c10.3,14.7,18.5,12.1,7.6-8.7
        c-15.5-26.9-42.4-73.2-2.8-23.3c11.5,11.5,19.2,16.9,19.2,16.9c4.9,3.6,8.5,0.1,6.8-3.7c-1.7-3.8-7.3-10.9-12.3-17
        c-6.2-6.6-23.4-31.4-23.4-31.4c0.6-15.4,14.6-4.5,15.8-3.6c4.5,3.6,19.1,10.5,24.3,8.3C921,410.8,914.6,406.8,912.1,404.9z"/>
	<path class="st1" d="M712.7,391.3c-0.2,1.7-1.8,22.7-3.5,28.3c-1.7,5.7-13.1,62.2-14.1,66.4c-1,4.3-3.7,20.7-7.8,27.7
        c-4.1,7-3.3,38.5-4,43s-8.2,33.4-9.1,37.1c-0.9,3.7-1.3,5.9-2,10.6s-5.7,35.1-5.6,34.9c-3.6,6.1-16.5-23.9-16.7-32.8
        c-0.1-6.1-2.6-14.5-4.2-31.8c-1.6-17.3,3.3-36.2,7-48.6c3.8-12.3,5.6-28,6.3-35.6c0.7-7.4-2.8-32.8-4-45.2s-0.9-25.7,0.3-43.3
        c1.1-34.3,12.4-67,12.4-67C684.1,361.2,712.9,389.9,712.7,391.3z"/>
    <path class="st1" d="M674.1,616.9c-0.9,14.5,3.6,10-5.2,41.2c-4.9,13.5-24.1,52.8-33,64.4c-5.6,8.9-20.7,34.4-30.1,27.1
        c-7.5-4.5,13.5-37.1,13.5-37.1c-5.7,6.2-25.4,23.5-29.1,21.1c-6.2-4.7,10.2-20.7,20.9-27.3c-28.6,22.2-45.9,22.7-16.3-1
        c5.5-4.2,9.1-7.1,9.1-7.1c-42.7,28.7-36,5.2-9.4-9.2c-1.2-1.5-13,4.6-13,4.6c-30.3,12.2,14.3-23.5,23-31.8
        c2.1-2.9,15.2-15.9,19.5-20.7c10.6-13.4,25.4-29.4,25-46.6C648.9,582.7,675.2,591.2,674.1,616.9z"/>
	
	<g v-if="type === 'photo'" >
		<custom-circle v-for="circle in shapes.circles" :key="circle.n" :circle="circle"/>
		<custom-ellipse v-for="ellipse in shapes.ellipses"  :ellipse="ellipse" :key="ellipse.n"/>
	</g>
    <g v-if="sys && sys.length">
		<circle class="st2" v-for="circle in shapes.circles" :key="circle.n" :class="{
			'test': sys.find(i => i.n == circle.n) ? sys.find(i => i.n == circle.n).isA : '', 
			'st4': sys.find(i => i.n == circle.n) ? sys.find(i => i.n == circle.n).check.indexOf('1') > -1 || sys.find(i => i.n == circle.n).check.indexOf('3') > -1 : '',
			'st3': sys.find(i => i.n == circle.n) ? sys.find(i => i.n == circle.n).check.indexOf('2') > -1 || (sys.find(i => i.n == circle.n).check.indexOf('2') > -1 && sys.find(i => i.n == circle.n).check.indexOf('1') > -1) || (sys.find(i => i.n == circle.n).check.indexOf('2') > -1 && sys.find(i => i.n == circle.n).check.indexOf('3') > -1) || (sys.find(i => i.n == circle.n).check.indexOf('1') > -1 && sys.find(i => i.n == circle.n).check.indexOf('2') > -1 && sys.find(i => i.n == circle.n).check.indexOf('3') > -1) || (sys.find(i => i.n == circle.n).check.indexOf('1') > -1 && sys.find(i => i.n == circle.n).check.indexOf('3') > -1) : '',
			'st6':  sys.find(i => i.n == circle.n) ? sys.find(i => i.n == circle.n).selected : ''
			}"
            :cx="circle.cx"
            :cy="circle.cy"
            :r="circle.r"
            @click="click(circle.n, 'circles')"
        />

		<ellipse class="st2" v-for="ellipse in shapes.ellipses" 
			:class="{
			'test': sys.find(i => i.n == ellipse.n) ? sys.find(i => i.n == ellipse.n).isA : '', 
			'st4': sys.find(i => i.n == ellipse.n) ? sys.find(i => i.n == ellipse.n).check.indexOf('1') > -1 || sys.find(i => i.n == ellipse.n).check.indexOf('3') > -1 : '',
			'st3': sys.find(i => i.n == ellipse.n) ? sys.find(i => i.n == ellipse.n).check.indexOf('2') > -1 || (sys.find(i => i.n == ellipse.n).check.indexOf('2') > -1 && sys.find(i => i.n == ellipse.n).check.indexOf('1') > -1) || (sys.find(i => i.n == ellipse.n).check.indexOf('2') > -1 && sys.find(i => i.n == ellipse.n).check.indexOf('3') > -1) || (sys.find(i => i.n == ellipse.n).check.indexOf('1') > -1 && sys.find(i => i.n == ellipse.n).check.indexOf('2') > -1 && sys.find(i => i.n == ellipse.n).check.indexOf('3') > -1) || (sys.find(i => i.n == ellipse.n).check.indexOf('1') > -1 && sys.find(i => i.n == ellipse.n).check.indexOf('3') > -1) : '',
			'st6':  sys.find(i => i.n == ellipse.n) ? sys.find(i => i.n == ellipse.n).selected : ''
			}" 
			:key="ellipse.n"  
			:transform="ellipse.transform" 
			:cx="ellipse.cx" 
			:cy="ellipse.cy" 
			:rx="ellipse.rx" 
			:ry="ellipse.ry" 
			@click="click(ellipse.n, 'ellipses')"
        />

    </g>
    </svg>
    </div>
</template>

<script>
import shapesObj from './humanSys.json'
import customEllipse from './ellipse';
import customCircle from './circle';
export default {
	components: {customEllipse, customCircle},
	data() {
		return {
			shapes: shapesObj
		}
    },
    props: ['viewBox', 'sys', 'type'],
    methods: {
        click: function(n, name) {
			let sys = this.sys.find(i => i.n == n);

            this.sys.forEach(i => {
				i.selected = false
				i.isA = false
                i.isB = true
			});
			
            if(sys.isA === false) {
                sys.isA = true
                sys.isB = false
			}

			sys.selected = true;
        }
	}
}
</script>

<style scoped>
.st1{fill:#F7AD9A;}
.st2{fill:#FFFFFF;stroke:#A56D65;stroke-miterlimit:10;cursor:pointer}
.test {
    fill:rgba(33, 88, 72, 0.562);
}
.st2:hover {
    fill:rgb(128, 156, 153);
    opacity: 0.5;
}
a:focus {
    stroke: rgb(136, 38, 38);
    fill: #79B729;
}
.st3{fill:#DD0A0A !important;stroke:#A56D65;stroke-miterlimit:10;}
.st4{fill:#79B729;stroke:#A56D65;stroke-miterlimit:10;}
.st5{fill:#FFFF00;stroke:#A56D65;stroke-miterlimit:10;}
.st6{stroke-width:2; stroke: #FF0000 !important;stroke-miterlimit:20;}
</style>