<template>
    <cookie-law :buttonText="'Я согласен'">
        <div slot="message">
            Веб-сайт использует файлы cookie. При использовании данного сайта без изменения настроек вашего браузера, вы подтверждаете свое согласие на использование файлов cookie. Для получения дополнительной информации по cookies, пожалуйста, прочтите нашу
            <a href="/static/PrivacyPolicy.docx" style="color: #ffdb70;" >политику конфиденциальности.</a> Для удаления учетной записи прочтите <a style="color: #ffdb70;" href="https://ja.aspirre-russia.ru/static/deleteUser.pdf">инструкцию</a>
        </div>
    </cookie-law>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
export default {
    components: { CookieLaw }
}
</script>
