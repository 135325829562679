import Vue from 'vue';
import Vuex from 'vuex';
import passportPart from './PassportPart.js'
import manifestationOfDisease from './ManifestationOfDisease.js'
import qualityOfLifeQuestionnaire from './QualityOfLifeQuestionnaire.js'
import analyses from './Analyses.js'
import user from './user.js'
import adminModule from './adminModule.js'
import personalDoctorsOffice from './PersonalDoctorsOffice.js'
import scale from './Scale.js'
import data from './Data.js'
import treatment from './Treatment.js'
import testUrl from './testUrl.js'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        apiUrl: testUrl.state.url,
        apiUrlCheckValue: 'https://api.aspirre-russia.ru/CheckValueService/api',
        apiStatic: 'https://api.aspirre-russia.ru/ja/static/help/',
        apiStaticFile: 'https://api.aspirre-russia.ru/ja/',
    },
    getters: {
        apiStatic: state => state.apiStatic,
        apiStaticFile: state => state.apiStaticFile,
        apiUrl: state => state.apiUrl,
        apiUrlCheckValue: state => state.apiUrlCheckValue
    },
    modules: {
        passportPart,
        manifestationOfDisease,
        qualityOfLifeQuestionnaire,
        analyses,
        adminModule,
        personalDoctorsOffice,
        user,
        scale,
        data,
        treatment,
        testUrl

    },
});
store.subscribe((mutation, state) => {
    localStorage.setItem('user', JSON.stringify(state.user));
    localStorage.setItem('passportPart', JSON.stringify(state.passportPart));
});

export default store;
