export default {
    namespaced: true,
    state: {
        visibleQuest: false,
        saveStartEnd: false,
        save_put_btn: false,
        datagrig: [],
        active: 0,
        newData: {
            q1: null,
            q2: null,
            q3: null,
            q4: null,
            q5: null,
            q6: null,
            q7: null,
            q8: null,
            q9: null,
            q10: null,
            q11: null,
            rod: false,
            hod: false,
            kos: false,
            inv: false,
            odev: false,
            pencil: false,
            chair: false,
            other: false,
            otherDevices: '',
            hputon: false,
            hgetup: false,
            heat: false,
            hwalk: false,
            sidun: false,
            sidvan: false,
            kons: false,
            per: false,
            longwash: false,
            longget: false,
            gig: false,
            questget: false,
            questopen: false,
            home: false,
            q12: null,
            q13: null,
            q14: null,
            q15: null,
            q16: null,
            q17: null,
            q18: null,
            q19: null,
            q20: null,
            q21: null,
            q22: null,
            q23: null,
            q24: null,
            q25: null,
            q26: null,
            q27: null,
            q28: null,
            q29: null,
            q30: null,
            pain_asses: 0,
            cond_asses: 0,
        }
    },
    getters: {
        grid: state => state.datagrig,
        newData: state => state.newData,
        active: state => state.active,
        saveQ: state=> state.saveStartEnd,
        savePutBtn: state=> state.save_put_btn,
        visQuest: state=> state.visibleQuest
    },
    mutations: {
        setQuestionnarie(state, data) {
            state.datagrig = data
        },
        setCreateQuest(state, data) {
            state.datagrig.push(data)
        },
        setEditQuest(state, payload) {
            state.datagrig.forEach(element => {
                if(element.id === payload.id) {
                    element.q1 = payload.q1,
                    element.q2 = payload.q2,
                    element.q3 = payload.q3,
                    element.q4 = payload.q4,
                    element.q5 = payload.q5,
                    element.q6 = payload.q6,
                    element.q7 = payload.q7,
                    element.q8 = payload.q8,
                    element.q9 = payload.q9,
                    element.q10 = payload.q10,
                    element.q11 = payload.q11,
                    element.rod = payload.rod,
                    element.hod = payload.hod,
                    element.kos = payload.kos,
                    element.inv = payload.inv,
                    element.odev = payload.odev,
                    element.pencil = payload.pencil,
                    element.chair = payload.chair,
                    element.other = payload.other,
                    element.otherDevices = payload.otherDevices,
                    element.hputon = payload.hputon,
                    element.hgetup = payload.hgetup,
                    element.heat = payload.heat,
                    element.hwalk = payload.hwalk,
                    element.sidun = payload.sidun,
                    element.sidvan = payload.sidvan,
                    element.kons = payload.kons,
                    element.per = payload.per,
                    element.longwash = payload.longwash,
                    element.longget = payload.longget,
                    element.gig = payload.gig,
                    element.questget = payload.questget,
                    element.questopen = payload.questopen,
                    element.home = payload.home,
                    element.q12 = payload.q13,
                    element.q13 = payload.q13,
                    element.q14 = payload.q14,
                    element.q15 = payload.q15,
                    element.q16 = payload.q16,
                    element.q17 = payload.q17,
                    element.q18 = payload.q18,
                    element.q19 = payload.q19,
                    element.q20 = payload.q20,
                    element.q21 = payload.q21,
                    element.q22 = payload.q22,
                    element.q23 = payload.q23,
                    element.q24 = payload.q24,
                    element.q25 = payload.q25,
                    element.q26 = payload.q26,
                    element.q27 = payload.q27,
                    element.q28 = payload.q28,
                    element.q29 = payload.q29,
                    element.q30 = payload.q30,
                    element.pain_asses = payload.pain_asses,
                    element.cond_asses = payload.cond_asses,
                    element.result = payload.result
                }
            });
        },
        setNewDataNull(state) {
            state.newData.q1 = null,
            state.newData.q2 = null,
            state.newData.q3 = null,
            state.newData.q4 = null,
            state.newData.q5 = null,
            state.newData.q6 = null,
            state.newData.q7 = null,
            state.newData.q8 = null,
            state.newData.q9 = null,
            state.newData.q10 = null,
            state.newData.q11 = null,
            state.newData.rod = false,
            state.newData.hod = false,
            state.newData.kos = false,
            state.newData.inv = false,
            state.newData.odev = false,
            state.newData.pencil = false,
            state.newData.chair = false,
            state.newData.other = false,
            state.newData.otherDevices = '',
            state.newData.hputon = false,
            state.newData.hgetup = false,
            state.newData.heat = false,
            state.newData.hwalk = false,
            state.newData.sidun = false,
            state.newData.sidvan = false,
            state.newData.kons = false,
            state.newData.per = false,
            state.newData.longwash = false,
            state.newData.longget = false,
            state.newData.gig = false,
            state.newData.questget = false,
            state.newData.questopen = false,
            state.newData.home = false
            state.newData.q12 = null,
            state.newData.q13 = null,
            state.newData.q14 = null,
            state.newData.q15 = null,
            state.newData.q16 = null,
            state.newData.q17 = null,
            state.newData.q18 = null,
            state.newData.q19 = null,
            state.newData.q20 = null,
            state.newData.q21 = null,
            state.newData.q22 = null,
            state.newData.q23 = null,
            state.newData.q24 = null,
            state.newData.q25 = null,
            state.newData.q26 = null,
            state.newData.q27 = null,
            state.newData.q28 = null,
            state.newData.q29 = null,
            state.newData.q30 = null,
            state.newData.pain_asses = 0,
            state.newData.cond_asses = 0
        },
        setActiveOff(state) {
            state.active = 0
        },
        setActiveOn(state) {
            state.active += 1;
        },
        activeBack(state) {
            state.active--
        },
        setSaveaveOn(state) {
            state.saveStartEnd = false
        },
        setSaveaveOff(state) {
            state.saveStartEnd = true
        },
        setSavePutBtnOn(state) {
            state.save_put_btn = true
        },
        setSavePutBtnOff(state) {
            state.save_put_btn = false
        },
        setVisibleOn(state) {
            state.visibleQuest = true
        },
        setVisibleOff(state) {
            state.visibleQuest = false
        }
    },
    actions: {
        getQuest({commit, rootState}) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/patients/${id}/questionnaire`).then(res => {
				commit('setQuestionnarie', res.data);
			})
        },
        createQuest({commit, rootState}, payload) {
            return new Promise((resolve, reject)=>{
                let id = rootState.user.patient.patients_id;
                this.api.post(`${rootState.apiUrl}/patients/${id}/questionnaire`, payload).then(res => {
                    commit('setCreateQuest', res.data)
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },
        editQuest({commit, rootState}, payload) {
            return new Promise((resolve, reject)=>{
                let id = rootState.user.patient.patients_id;
                this.api.put(`${rootState.apiUrl}/patients/${id}/questionnaire/${payload.id}`, payload).then(res => {
                    commit('setEditQuest', res.data)
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },
        deleteQuest({rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/patients/${id}/questionnaire/${payload.id}`, payload);
        },
        summQuest({commit}, payload) {
            commit('setQuest', payload)
        },
        gridQuest ({commit}, payload) {
            commit('setGrid', payload)
        },
        newDataNull({commit}) {
            commit('setNewDataNull')
        },
        activeOff({commit}) {
            commit('setActiveOff')
        },
        activeOn({commit}) {
            commit('setActiveOn')
        },
        activeBack({commit}) {
            commit('activeBack')
        },
        saveOn({commit}) {
            commit('setSaveaveOn')
        },
        saveOff({commit}) {
            commit('setSaveaveOff')
        },
        savePutBtnOn({commit}) {
            commit('setSavePutBtnOn')
        },
        savePutBtnOff({commit}) {
            commit('setSavePutBtnOff')
        },
        visibleOn({commit}) {
            commit('setVisibleOn')
        },
        visibleOff({commit}) {
            commit('setVisibleOff')
        }
    }

}