<template>
<div>
    <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        :viewBox="viewBox" style="enable-background:new 0 0 1000 1500;" xml:space="preserve">
		<g id="Слой_2">
		</g>
			<g v-for="( sk, ind ) in skinsWrapper" :key="ind">
				<path :class="[sk.class, sk.PicturesCount > 0 ? 'st10' : '',  sk.numeric_id == activeJoint.numeric_id ? 'st11': '']" :d="sk.d" @click="setJoint(sk.numeric_id)"/>
			</g>
    </svg>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import skinData from './skin.json';

export default {
	props: ['viewBox', 'syssind', 'edit'],
	data() {
		return {
			skins: JSON.parse(JSON.stringify(skinData.skins)),
		}
	},
	computed: {
		...mapGetters({
			joints: 'manifestationOfDisease/joints',
			activeJoint: 'manifestationOfDisease/activeJoint',
			allJointPhoto: 'manifestationOfDisease/allJointPhoto'
		}),
		skinsWrapper() {
			let data = [];
			this.skins.forEach(item => {
				this.joints.forEach(el => {
					if(el.numeric_id == item.numeric_id){
						data.push({...item, ...el})
					}
				})
			})
			//console.log(data)
			return data;
		}
	},
    methods: {
		...mapActions({
			setActiveJoint: 'manifestationOfDisease/setActiveJoint'
		}),
		setActive(i) {
			if (!this.edit) return false;
			this.syssind[i].isActive = !this.syssind[i].isActive;
		},
		setJoint(n_id) {
			//console.log(n_id)
			if(n_id !== 8 && n_id !== 2 && n_id !== 7 && n_id !== 1 && n_id !== 3) {
				this.setActiveJoint(this.joints.find(i => i.numeric_id == n_id).id);
			}
		}
	},
}
</script>


<style scoped>
.st0{fill:#F7AD9A;stroke:#FFFFFF;stroke-miterlimit:10;stroke-dasharray:2;cursor: pointer;}
.st1{fill:#EA9C94;}
.st2{fill:#C6847B;}
.st3{fill:#6D4F4F;}
.st4{fill:#1A171B;}
.st5{fill:#FFFFFF;}
.st6{fill:#49494B;}
.st7{fill:#BFE5F7;}
.st8{opacity:0.27;fill:#F4CAC3;enable-background:new;cursor: pointer;}
.st9{opacity:0.2;fill:#560B0B;enable-background:new    ;}
.st0.st10{opacity:1;fill:#ca3232;enable-background:new    ;}
.st15{fill:#DD9080;stroke:#FFFFFF;stroke-miterlimit:10;stroke-dasharray:2;cursor: not-allowed}
.st0.st11{opacity:1;stroke:#ca3232;enable-background:new; stroke-width: 3;}
</style>

