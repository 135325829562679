<template>
    <div style="margin-top:20px;margin-bottom:30px; width:45%">
        <el-button id="pp_35" v-if="this.disabledan === false" @click="submit_anamnez('anamnez')" style="float: right; margin-right: 15px;" type="primary">Сохранить изменения</el-button>
        <el-button id="pp_36" v-if="this.disabledan === true && checkRole" @click="disabledanamnez" style="float: right; margin-right: 15px;" type="primary" icon="el-icon-edit" circle></el-button>
        <el-form :label-position="labelPosition" :model="anamnez" :rules="anamnez_val" ref="anamnez">
            <el-form-item label="Дата появления первых симптомов, жалоб:" prop="date_disease">
                <el-date-picker
                 id="pp_37"
                :picker-options="pickerOptdpp"
                :disabled="disbtn"
                v-if="this.disbtn === false"
                clearable
                format="dd.MM.yyyy"
                value-format="timestamp"
                v-model="anamnez.date_disease"
                type="date"
                placeholder="Выбрать дату"
                @change="handleDateDeseaseChanged">
                </el-date-picker>
                <div class="pclass" v-if="this.disbtn === true"> {{ anamnez.date_disease !== '' ? new Date(anamnez.date_disease).toLocaleDateString("ru-RU") : '-' }} </div>
            </el-form-item>
            
            <el-form-item label="Дата постановки диагноза:" prop="date_diagnosis">
                <el-date-picker
                 id="pp_38"
                :disabled="disbtn"
                v-if="this.disbtn === false"
                clearable
                :picker-options="pickerOptdpd"
                format="dd.MM.yyyy"
                value-format="timestamp"
                v-model="anamnez.date_diagnosis"
                type="date"
                placeholder="Выбрать дату">
                </el-date-picker>
                <div class="pclass" v-if="this.disbtn === true"> {{ anamnez.date_diagnosis !== '' ? new Date(anamnez.date_diagnosis).toLocaleDateString("ru-RU") : '-'}} </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    computed: {
		...mapGetters({
			anamnez: 'passportPart/anamnez',
            user: 'user/user',
            patientDate: 'passportPart/patientData'
		}),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
    },
    watch: {
        anamnez: {
            handler: function( val ) {
                if(!this.initialDateDisease && val.date_disease) {
                    this.initialDateDisease = val.date_disease
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapActions({
            getAnamnez: 'passportPart/getAnamnez',
            saveAnamnez(dispatch) {
               dispatch('passportPart/saveAnamnez', this.anamnez)
            }
        }),
        disabledanamnez: function() {
            this.disabledan = false
            this.disbtn = false
        },
        handleDateDeseaseChanged( ev ) {
            if( +ev > +this.anamnez.date_diagnosis) {
                this.$message({
                    message: 'Дата заболевания должна предшествовать дате постановки диагноза!',
                    type: 'error'
                });    
                this.anamnez.date_disease = this.initialDateDisease
            }
        },
        submit_anamnez(form) {
        this.$refs[form].validate((valid) => {
          if (valid) {
            this.saveAnamnez();
            this.disbtn = true
            this.disabledan = true
            this.$message({
                message: 'Данные успешно сохранены!',
                type: 'success'
            });
          } else {
            this.$message.error('Заполните обязательные поля!!!');
            return false;
          }
        });
      },
    },
    data() {
        return {
            disabledan: true,
            disbtn: true,
            labelPosition: 'left',
            pickerOptdpp: {
                disabledDate(time) {
                    let min = time.getTime() < window.ell.patientDate.birth_date;
					let max = time.getTime() > Date.now()
                    
                    return min || max
                }
            },
            pickerOptdpd: {
                disabledDate(time) {
                    let min = time.getTime() < window.ell.anamnez.date_disease;
                    let max = time.getTime() > Date.now()
                    
                    return min || max
                }
            },
            anamnez_val: {
                date_disease: [
                    { type: 'date', required: true, message: 'Пожалуйста выберите дату', trigger: 'change' }
                ],
                date_diagnosis: [
                    { type: 'date', required: true, message: 'Пожалуйста выберите дату', trigger: 'change' }
                ],
            }, 
            initialDateDisease: ''
        }
    },
    created() {
        window.ell = this
        this.getAnamnez();
    }
}
</script>