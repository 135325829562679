export default {
    namespaced: true,
    state: {
        url: 'https://api.aspirre-russia.ru/ja'
        // url: 'https://api.aspirre-russia.ru/ja'
    },
    getters: {},
    mutations: {},
    actions: {}
}
