<template>
	<v-card>
		<v-card-title>
			<v-btn @click="dialog=true" v-if="patient.role !== 2 && list.length <= 0" color="#6374dd" dark class="ml-4">Добавить диагноз</v-btn>
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:items="list"
			:loading="loading"
			no-data-text="Диагноз отсутствует..."
			hide-default-footer
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="checkRole" @click="editItems(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>

				<v-btn v-if="patient.role !== 2 && new Date().getTime() - item.date_created < 86400000" @click="deleteItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>

			<template v-slot:[`item.diagnosis_id`]="{ item }">
				{{ listDiagnoses.find(f=>f.id == item.diagnosis_id) && listDiagnoses.find(f=>f.id == item.diagnosis_id).synonym }}
				({{ 
					listDiagnoses.find(f=>f.id == item.diagnosis_id) && listDiagnoses.find(f=>f.id == item.diagnosis_id).mkb_code
				}}
				-
				{{
					listDiagnoses.find(f=>f.id == item.diagnosis_id) && listDiagnoses.find(f=>f.id == item.diagnosis_id).mkb_name
				}})
			</template>
		</v-data-table>


		<v-dialog persistent v-model="dialog" max-width="1100px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Диагноз</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation >
							<v-row>
								<v-col cols="12" sm="12" md="12">
									<v-select outlined color="#6b59c7" ref="tf0" :items="listDiagnoses" item-text="synonym" item-value="id" v-model="obj.diagnosis_id" :rules="[rules.required]" label="Ваш диагноз *" />
								</v-col>
								<v-col cols="12" sm="12" md="12">
									<v-textarea outlined rows="4" color="#6b59c7" v-model="obj.comment" label="Комментарий" />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			patient: 'user/patient',
			user: 'user/user',
        }),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
	data() {
		return {
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				id: null,
				comment: null,
				diagnosis_id: null,
				date_created: null
			},
			dialog: false,
			list: [],
			listDiagnoses: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.'
			},
			headers: [
				{ text: "Ваш диагноз (Код МКБ-10)", value: "diagnosis_id", align: 'center', sortable: false },
				{ text: "Комментарий", value: "comment", align: 'center', sortable: false },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		close() {
			this.$refs[`tf0`].reset()
			this.obj = { id: null, comment: null, diagnosis_id: null, date_created: null }
			this.dialog = false
		},
		async apiMethods(url, name) {
			this.loading = false
			let res = (await this.api.get(`${url}/${name}`)).data
			if(name == 'api/diagnoses') res.sort((a,b) => (a.synonym > b.synonym) ? 1 : ((b.synonym > a.synonym) ? -1 : 0))
			return res
		},
		editItems(row) {
			Object.assign(this.obj, row)
			this.dialog = true
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/patients/${this.patient.patients_id}/diagnoses/${row.id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				let url = obj.id ? `${this.apiUrl}/api/patients/${this.patient.patients_id}/diagnoses/${obj.id}` : `${this.apiUrl}/api/patients/${this.patient.patients_id}/diagnoses`
				this.overlay = true

				if(!obj.id) delete obj['id']
				obj.date_created = new Date().getTime()

				this.api[obj.id ? 'put' : 'post'](url, obj)
				.then(res => {
					if(!obj.id) {
						
						//Добавление в массив нового пациента
						this.list.unshift(res.data)
						
						this.snackBarFunc(true, 'Диагноз успешно создан!!!', 'success')
					} else {
						this.list.forEach(el => {
							if(el.id == res.data.id) {
								Object.assign(el, res.data)
							}
						})
						this.snackBarFunc(true, 'Диагноз успешно обновлен!!!', 'success')
					}
					this.overlay = false
					this.dialog = false
					this.close()
				})
				.catch(error => {
					if(error) {
						this.snackBarFunc(true, 'Ошибка, обновите страницу и попробуйте снова!!!', 'error')
						this.overlay = false
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/patients/${this.patient.patients_id}/diagnoses`)
		this.listDiagnoses = await this.apiMethods(this.apiUrl, `api/diagnoses`)

		// console.log(this.$route.query.myPatient == 'false' ? (user.CanRead ? true : false) : true)
	}
};
</script>


<style scoped>

.fs-16 {
	font-size: 16px!important;
}

</style>