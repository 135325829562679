<template>
	<div v-if="this.$route.name !== 'ResetPass'">
		<!-- ШАПКА ВРАЧА -->
		<div v-if="(user.id || isAuth) && (user.role == 2||(user.role == 3&&$route.name !== 'PersonalDoctorsOffice'&&$route.name !== 'ListDoc'&&$route.name !== 'ListPatient'&&$route.name !== 'Guide'))" class="header">
			<el-row class="static-wrap" type="flex">
				<el-col :sm="12" :md="8" class="logo"></el-col>
				<el-col :span="3" :sm="3" :md="13" class="menu-wrap">
					<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
						<el-menu-item index="1" disabled class="custom-item">
							<div class="custom-panel">{{ patient.query_fio ? patient.query_fio : user.fio }}</div>
							<div class="role-type">врач-терапевт</div>
						</el-menu-item>
					</el-menu>
				</el-col>
				<el-col :span="3" class="auth-btns doctor">
					<el-popover placement="bottom-end" width="428" trigger="click" ref="popoverNotify">
						<el-button class="def-btn arh-btn" size="small" @click="arhNotif">Архив</el-button>
						<div v-if="this.numberOfNotifications !== 0" class="notifications">
							<div v-for="item in notifications" :key="item.id">
								<div class="notif" v-if="item.seen === false">
									<el-button @click="falseNotific(item.Id)" class="minbtnarh" size="mini" title="Скрыть уведомление" round></el-button>
									<div class="fio">Пациент: <a href="#" @click="goPatient(item)">{{ item.patient_fio }} </a></div><br>
									<div class="patAnaliz">Дата: {{ new Date(item.created).toLocaleDateString("ru-RU") }} </div>
									<div class="patAnaliz">Показатель: <b>{{ item.parameter }}</b> </div>
									<div class="incident">Событие: Выход за допустимое значение </div>
									<div class="patAnaliz">Текущее значение: <b>{{ item.value +" "+ item.unit }}</b>.<br> Норма: {{ item.limits }}</div>
								</div>
							</div>
						</div>
						
						<div style="text-align:center;padding-bottom:15px;padding-top:15px;" v-if="this.numberOfNotifications === 0">
							<span>Новых уведомлений нет</span>
						</div>
						<!-- есть уведомления -->
						<el-badge v-if="this.numberOfNotifications !== 0" slot="reference" is-dot :value="numberOfNotifications" class="item">
							<i size="small" title="Уведомления" style="cursor:pointer" class="el-icon-message"></i>
						</el-badge>
						<!-- нет уведомлений -->
						<el-badge v-if="this.numberOfNotifications === 0" slot="reference" hidden :value="numberOfNotifications" class="item no-notifications">
							<i size="small" title="Уведомления" style="cursor:pointer;position: relative;top: -4px;" class="el-icon-message"></i>
						</el-badge>
					</el-popover>
					<a @click="openHelp">
						<i style="font-size: 24px;color: #ffdb70;cursor: pointer;padding-left:10px" title="Помощь" class="el-icon-info" />
					</a>

					<el-dialog
						title="Помощь"
						:visible.sync="helpPopup"
						width="700px"
						top="25vh"
						center>
							<a 
								href="/static/ManualDoc.pdf" 
								target="_blank"
								style="font-size: 16px; color: #6374dd; text-decoration: none;"
							>
								Руководство врача 
								<i class="view-icon el-icon-view el-icon-right"/>	
							</a>

							<div>
								<p style="font-family:sans-serif;font-size:large;line-height:25px;">
									Служба поддержки:<br>
									8(495)627-29-61 (будни 09:00-18:00)<br>
									support@nitrosbase.com
								</p>
							</div>
					</el-dialog>

					<el-button @click="logoutHandler" type="text" class="sign-in doctor">Выход</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- ШАПКА АДМИНКИ -->
		<div v-if="(user.id || isAuth) && (!patient.patients_id && (user.role == 0 || user.role == 3))" class="header">

			<!-- <v-row class="static-wrap">
				<v-col sm="12" md="8">
					<img class="logo" />
				</v-col>
				
				<v-col sm="2" md="2" v-if="$route.query.id">
					<el-button @click="back(1)" class="btn-back-supervisor" type="text" size="mini" icon="el-icon-back">Вернуться назад</el-button>
				</v-col>
				
				<v-col sm="3" md="auto" class="menu-wrap">
					<el-button @click="mobileMenu = !mobileMenu" class="menu-handle">Меню</el-button>
                    <el-menu :default-active="activeIndex" class="el-menu-demo el-row--flex" mode="horizontal" @select="handleSelect" v-bind:class="{ show: mobileMenu }">
                        <el-menu-item index="0" disabled class="custom-item">
                            <template v-if="!$route.query.id">
                                <div class="custom-panel">Панель администрирования</div>
                                <div class="role-type">права {{user.role == 0 ? 'администратора' : 'супервизора'}}</div>
                            </template>
                            <template v-if="$route.query.id">
                                <div class="custom-panel">Врач: {{$route.query.fio}}</div>
                                <div class="role-type">права {{user.role == 0 ? 'администратора' : 'супервизора'}}</div>
                            </template>
                        </el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="8">Список врачей</el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="9">Список пациентов</el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="10">Справочник</el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="11">Отчет</el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="12">Роль</el-menu-item>
                    </el-menu>
				</v-col>

				<v-col sm="3" class="auth-btns">
					<el-button @click="logoutHandler" class="sign-in">Выход</el-button>
				</v-col>


			</v-row> -->
            
			<el-row class="static-wrap" type="flex">
                <el-col :sm="12" :md="8" class="logo"></el-col>
                <el-col v-if="$route.query.id" :sm="2" :md="2">
                    <el-button @click="back(1)" class="btn-back-supervisor" type="text" size="mini" icon="el-icon-back">Вернуться назад</el-button>
                </el-col>
                <el-col :span="3" :sm="3" :md="24" class="menu-wrap">
                    <el-button @click="mobileMenu = !mobileMenu" class="menu-handle">Меню</el-button>
                    <el-menu :default-active="activeIndex" class="el-menu-demo el-row--flex" mode="horizontal" @select="handleSelect" v-bind:class="{ show: mobileMenu }">
                        <el-menu-item index="0" disabled class="custom-item">
                            <template v-if="!$route.query.id">
                                <div class="custom-panel">Панель администрирования</div>
                                <div class="role-type">права {{user.role == 0 ? 'администратора' : 'супервизора'}}</div>
                            </template>
                            <template v-if="$route.query.id">
                                <div class="custom-panel">Врач: {{$route.query.fio}}</div>
                                <div class="role-type">права {{user.role == 0 ? 'администратора' : 'супервизора'}}</div>
                            </template>
                        </el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="8">Список врачей</el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="9">Список пациентов</el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="10">Справочник</el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="11">Отчет</el-menu-item>
                        <el-menu-item v-if="!$route.query.id" index="12">Роль</el-menu-item>
                    </el-menu>
                </el-col>
                <el-col :span="3" class="auth-btns">
                    <el-button @click="logoutHandler" class="sign-in">Выход</el-button>
                </el-col>
            </el-row>
        </div>
		<!-- ШАПКА ПАЦИЕНТА -->
		<div class="" v-if="this.$route.name !== 'PersonalDoctorsOffice'">
			<div class="header patient" v-if="(user.id && patient.patients_id) || !user.id">
				<el-row class="static-wrap" type="flex">
					<el-col :sm="12" :md="8" class="logo" v-if="!user.id"></el-col>
					<el-col v-if="user.id" :sm="12" :md="12" >
						<el-row type="flex">
							<el-col :span="4" class="iclasss">
								<i @click="back(2)" class="el-icon-back"></i>
							</el-col>
							<el-col :span="22">
								<div class="ppat">
									Пациент
								</div>
								<div class="ppatt">
									{{ patient.fio }}
								</div>
							</el-col>
						</el-row>
					</el-col>
					
					<el-col :span="3" :sm="3" :md="24" class="menu-wrap" v-if="patient.patients_id || (patient.id || ( !patient.id && isAuth ))">
						<el-button @click="mobileMenu = !mobileMenu" class="menu-handle">Меню</el-button>
						<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" v-bind:class="{ 'show': mobileMenu }">
							<el-menu-item index="1" @click="mobileMenu = !mobileMenu">Паспортная часть</el-menu-item>
							<el-menu-item index="4" @click="mobileMenu = !mobileMenu">Проявление болезней</el-menu-item>
							<el-menu-item index="5" @click="mobileMenu = !mobileMenu">Вопросник качества жизни</el-menu-item>
							<el-menu-item index="6" @click="mobileMenu = !mobileMenu">Шкалы</el-menu-item>
							<el-menu-item index="3" @click="mobileMenu = !mobileMenu">Анализы</el-menu-item>
							<el-menu-item index="7" @click="mobileMenu = !mobileMenu">Исследования</el-menu-item>
							<el-menu-item index="2" @click="mobileMenu = !mobileMenu">Лечение</el-menu-item>

							<el-menu-item>
								<a href="https://aspirre-russia.ru/publikatsiya-pro/informacia-pacientam.php" target="_blank" rel="noopener noreferrer" style="text-decoration:none">
									Библиотека
								</a>
							</el-menu-item>
							<!-- <el-menu-item v-if="user.id" index="8">Аналитика</el-menu-item> -->
						</el-menu>
					</el-col>
					<el-col :span="3" :md="1" class="auth-btns" v-if="(user.id || isAuth) && user.role == 2">
					</el-col>
					<el-col :md="12" class="auth-btns">
						<!-- Уведомления пациента -->
						<el-popover  v-if="patient.id" placement="bottom-end" width="428" trigger="click">						
							<div v-if="this.numberOfNotificationsPatient !== 0" class="notifications">
								<div v-for="item in notificationsPatient" :key="item.Id">
									<div class="notif" v-if="item.IsReaded === false">
										<el-button @click="falseNotificationsPatient(item.Id)" class="minbtnarh" size="mini" title="Скрыть уведомление" round></el-button>
										<div class="patAnaliz"><b>{{ item.Msg }}</b> </div>
										<div class="patAnaliz">Дата: {{ new Date(item.CreateDate).toLocaleDateString("ru-RU") }} </div>
									</div>
								</div>
							</div>
							
							<div style="text-align:center;padding-bottom:15px;padding-top:15px;" v-if="this.numberOfNotificationsPatient === 0">
								<span>Новых уведомлений нет</span>
							</div>
							
							<el-badge v-if="this.numberOfNotificationsPatient !== 0" slot="reference" is-dot :value="numberOfNotificationsPatient" class="item">
								<i size="small" title="Уведомления" style="cursor:pointer;position: relative;top: -4px;" class="el-icon-message"></i>
							</el-badge>
							
							<el-badge v-if="this.numberOfNotificationsPatient === 0" slot="reference" hidden :value="numberOfNotificationsPatient" class="item no-notifications">
								<i size="small" title="Уведомления" style="cursor:pointer;position: relative;top: -4px;" class="el-icon-message"></i>
							</el-badge>
						</el-popover>

						<el-button v-if="patient.id" @click="openReport = !openReport" class="help-btn" style="color:rgb(255, 219, 112);padding-left:10px;" type="text">Сформировать отчет</el-button>

																			 
						<el-button @click="openHelp" class="help-btn" style="color:rgb(255, 219, 112);padding-right: 10px;padding-left:10px;" type="text">Помощь</el-button>
						<el-popover
						v-if="patient.id && !user.id"
						placement="top-end"
						width="428"
						trigger="click"
						center>
						<div class="settings-form">
							<span>Смена пароля</span>
							<el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" class="demo-ruleForm">
								<el-form-item prop="pass">
									<el-input type="password" placeholder="Введите новый пароль" v-model="ruleForm2.pass" autocomplete="off"></el-input>
								</el-form-item>
								<el-form-item prop="checkPass">
									<el-input type="password" placeholder="Подтвердите новый пароль" v-model="ruleForm2.checkPass" autocomplete="off"></el-input>
								</el-form-item>
								<el-form-item>
									<el-button round type="primary" @click="submitFormPass('ruleForm2')">Сохранить</el-button>
								</el-form-item>
							</el-form>
						</div>
						<!-- <<div  class="settings-form">
							<span>Привязка к мобильному приложению</span>
							<el-form class="demo-ruleForm">
								<div style="margin: 15px 0px 15px;">Введите код для привязки устройств: <b>{{ keyMobile }}</b></div>
								el-form-item>
									<el-button round type="primary">Отправить код по смс</el-button>
								</el-form-item>
							</el-form>
						</div> -->
						<div class="settings-form">
							<el-form class="demo-ruleForm">
								<el-form-item>
									<el-button @click="exitFromAllDevices" round type="primary">Выход со всех устройств</el-button>
								</el-form-item>
							</el-form>
						</div>
						<i slot="reference" style="cursor: pointer;" class="el-icon-setting ishes" />
						</el-popover>
						<!-- <a href="/static/ManualUsersPdf.pdf" target="_blank">
							<i style="font-size: 24px;color: #ffdb70;cursor: pointer;" title="Руководство пользователя" v-if="patient.id && !user.id" class="el-icon-info" />
						</a> -->
						<el-button @click="dialogFormRegister = true" class="sign-in-register" type="text"  v-if="!patient.id && !user.id">Регистрация</el-button>
						<el-button @click="dialogFormVisible = true" class="sign-in" type="text"  v-if="!patient.id && !user.id">Вход</el-button>
						

						<el-button @click="logoutHandler" class="sign-in" type="text"  v-if="patient.id && !user.id">Выход</el-button>
					</el-col>
				</el-row>
				<!-- Обработка ошибки -->
				<el-dialog
					title="Error"
					:visible.sync="visibleError"
					width="30%"
					top="35vh"
					center>
					<center>
						<span style="font-size: 20px;color: orangered;" v-if="this.error === 401">Ошибка авторизации, авторизуйтесь в системе!!!</span>
					</center>
					<span slot="footer" class="dialog-footer">
						<el-button @click="visibleError = false" class="def-btn">OK</el-button>
					</span>
				</el-dialog>
				
				<el-dialog
					title="Помощь"
					v-if="!user.id"
					:visible.sync="helpPopup"
					width="700px"
					top="25vh"
					center>
						<div v-if="user.role == 2" style="font-size:18px;font-family: sans-serif;">
							Вы можете посмотреть как пациент заполняет разделы
						</div>
						<div v-if="this.$route.name === 'PassportPart'">
							<el-button @click="openVideo(1)" type="text" >Посмотреть видеоурок раздела "Паспортная часть"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
						</div>
						<div v-if="this.$route.name === 'ManifestationOfDisease'">
							<el-button @click="openVideo(2)" type="text" >Посмотреть видеоурок раздела "Осмотр"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(3)" type="text" >Посмотреть видеоурок раздела "Фотоизображения -> Суставы"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(17)" type="text" >Посмотреть видеоурок раздела "Фотоизображения -> Суставной статус"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(15)" type="text" >Посмотреть видеоурок раздела "Фотоизображения -> Кожный покров"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(16)" type="text" >Посмотреть видеоурок раздела "Фотоизображения -> Общее"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(4)" type="text" >Посмотреть видеоурок раздела "Видео"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
						</div>
						<div v-if="this.$route.name === 'QualityOfLifeQuestionnaire'">
							<el-button @click="openVideo(5)" type="text" >Посмотреть видеоурок раздела "Вопросник качества жизни"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
						</div>
						<div v-if="this.$route.name === 'Analyses'">
							<el-button @click="openVideo(6)" type="text" >Посмотреть видеоурок раздела "Клинический анализ крови"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(7)" type="text" >Посмотреть видеоурок раздела "Биохимический анализ крови"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(8)" type="text" >Посмотреть видеоурок раздела "Иммунология"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
						</div>
						<div v-if="this.$route.name === 'Scale'">
							<el-button @click="openVideo(9)" type="text" >Посмотреть видеоурок раздела "Шкалы"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
						</div>
						<div v-if="this.$route.name === 'Data'">
							<el-button @click="openVideo(10)" type="text" >Посмотреть видеоурок раздела "Инструментальные методы исследования"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(11)" type="text" >Посмотреть видеоурок раздела "Выписка из других медицинских учреждений"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
						</div>
						<div v-if="this.$route.name === 'Treatment'">
							<el-button @click="openVideo(12)" type="text" >Посмотреть видеоурок раздела "Лекарственные препараты, которые получал пациент"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(13)" type="text" >Посмотреть видеоурок раздела "Нежелательные явления препаратов"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
							<br>
							<el-button @click="openVideo(14)" type="text" >Посмотреть видеоурок раздела "Реабилитация"
								<i class="view-icon el-icon-view el-icon-right"></i>
							</el-button>
						</div>
						<br>
						<el-button v-if="this.$route.name != 'Authorization'" type="text" @click="openpdf(0)">Скачать/Посмотреть Руководство пользователя
							<i class="view-icon el-icon-download el-icon-right"></i>
						</el-button>
						<br>
						<el-button v-if="this.$route.name === 'Authorization'" type="text" @click="openpdf(1)">Скачать/Посмотреть Руководство по авторизации
							<i class="view-icon el-icon-download el-icon-right"></i>
						</el-button>
						<div>
							<p style="font-family:sans-serif;font-size:large;line-height:25px;">
								Служба поддержки:<br>
								8(495)627-29-61 (будни 09:00-18:00)<br>
								support@nitrosbase.com
							</p>
						</div>
				</el-dialog>

				<el-dialog
					:title="this.$route.name === 'PassportPart' ? 'Раздел Паспортная часть' : '' ||
					this.$route.name === 'ManifestationOfDisease' ? 'Раздел Проявление болезней' : '' ||
					this.$route.name === 'QualityOfLifeQuestionnaire' ? 'Раздел Вопросник качества жизни' : '' ||
					this.$route.name === 'Analyses' ? 'Раздел Анализы' : '' ||
					this.$route.name === 'Scale' ? 'Раздел Шкалы' : '' ||
					this.$route.name === 'Data' ? 'Раздел Исследования' : '' ||
					this.$route.name === 'Treatment' ? 'Раздел Лечения' : ''"
					:visible.sync="popupVideo"
					width="750px"
					top="10vh"
					center>
						<video v-if="this.$route.name === 'PassportPart' && numberVid === 1 && popupVideo" :src="`${apiStatic}Раздел Паспортная часть.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'ManifestationOfDisease' && numberVid === 2 && popupVideo" :src="`${apiStatic}Вкладка осмотр.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'ManifestationOfDisease' && numberVid === 3 && popupVideo" :src="`${apiStatic}Вкладка Суставы.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'ManifestationOfDisease' && numberVid === 15 && popupVideo" :src="`${apiStatic}Вкладка Кожный покров.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'ManifestationOfDisease' && numberVid === 16 && popupVideo" :src="`${apiStatic}Вкладка Общее.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'ManifestationOfDisease' && numberVid === 4 && popupVideo" :src="`${apiStatic}Вкладка Видео.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'ManifestationOfDisease' && numberVid === 17 && popupVideo" :src="`${apiStatic}Вкладка Суставной статус.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'QualityOfLifeQuestionnaire' && numberVid === 5 && popupVideo" :src="`${apiStatic}Раздел Вопросник.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Analyses' && numberVid === 6 && popupVideo" :src="`${apiStatic}Вкладка Клинический анализ крови.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Analyses' && numberVid === 7 && popupVideo" :src="`${apiStatic}Вкладка Биохимический анализ крови.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Analyses' && numberVid === 8 && popupVideo" :src="`${apiStatic}Вкладка Иммунология.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Scale' && numberVid === 9 && popupVideo" :src="`${apiStatic}Раздел шкалы.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Data' && numberVid === 10 && popupVideo" :src="`${apiStatic}Вкладка Инструментальные методы исследования.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Data' && numberVid === 11 && popupVideo" :src="`${apiStatic}Вкладка Выписка из других медицинских учреждений.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Treatment' && numberVid === 12 && popupVideo" :src="`${apiStatic}Вкладка Лекарственные препараты которые получал пациент.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Treatment' && numberVid === 13 && popupVideo" :src="`${apiStatic}Вкладка Нежелательные явления препаратов.mp4`" width="700" controls="controls" />
						<video v-if="this.$route.name === 'Treatment' && numberVid === 14 && popupVideo" :src="`${apiStatic}Вкладка Реабилитация.mp4`" width="700" controls="controls" />
						
				</el-dialog>			
			</div>
		</div>


		<el-dialog :close-on-click-modal="false" :before-close="closePop" width="555px" :title="!formToggle ? 'Авторизация' : 'Восстановление пароля'" :visible.sync="dialogFormVisible">
			<div class="login-page">
				<div class="form">
					<form class="login-form" v-bind:class="{display: formToggle}" @keyup.enter="login('loginForm')">
						<el-form v-loading="loginLoad" v-bind:class="{display: formToggle}"  :model="loginForm" :rules="ruleLoginForm" ref="loginForm">
							<el-form-item label="Логин" prop="username" >
								<el-input clearable v-model="loginForm.username" auto-complete="off" ></el-input>
							</el-form-item>
							<el-form-item label="Пароль" prop="password">
								<el-input name="password" id="myPassword" type="password" v-model="loginForm.password" auto-complete="off">
									<el-button style="font-size: 20px;" id="showme" @click="passview" slot="append" icon="el-icon-view"></el-button>
								</el-input>
							</el-form-item>
							<el-button type="text" @click="register">Забыли пароль?</el-button>
						</el-form>
					</form>
					<form class="register-form" v-bind:class="{display: !formToggle}">
						<el-form :model="validateEmail" ref="validateEmail" v-bind:class="{display: !formToggle}">
							
							<el-form-item v-if="!textCongratilation" prop="email" :rules="[{ required: true, message: 'Пожалуйста, введите свой E-mail адрес', trigger: 'blur' },
      						{ type: 'email', message: 'Пожалуйста введите E-mail адрес корректно', trigger: ['blur', 'change'] }]" label="Введите e-mail">
								<el-input v-model="validateEmail.email" placeholder="Введите свой E-mail..." type="email" clearable auto-complete="off"></el-input>
							</el-form-item>
							
							<el-form-item :rules="[{ required: true, message: 'Пожалуйста введите код', trigger: 'blur' }]" v-if="visCodeChangePass == true" 
							label="Введите код подтверждения, отправленный Вам на email:" prop="phone" class="el-form-item is-required">
								<the-mask style="width:75px;" v-model="validateEmail.code" class="idmask el-input__inner" :mask="['##-##']" />
							</el-form-item>
							
							<div style="color:#f56c6c" v-if="codeBool == true">
								Ваш код неверен, перепроверьте правильность кода в смс!
								</div>
							<div v-if="noEmailSys == true" style="color:#f56c6c">
								Введенный Вами E-mail не зарегистрирован в системе.
							</div>
							<div v-if="textCongratilation == true">
								<el-form :model="ruleForm3" status-icon :rules="rules3" ref="ruleForm3" class="demo-ruleForm">
									<el-form-item prop="pass" class="mb-8">
										<el-input type="password" placeholder="Введите новый пароль" v-model="ruleForm3.pass" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item prop="checkPass">
										<el-input type="password" placeholder="Подтвердите новый пароль" v-model="ruleForm3.checkPass" autocomplete="off"></el-input>
									</el-form-item>
								</el-form>
							</div>
						</el-form>
					</form>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button v-if="!formToggle" @click="dialogFormVisible = false" class="def-btn">Отмена</el-button>
				<el-button v-if="!formToggle" type="primary" @click="login('loginForm')" :loading="loadBtn" class="def-btn">
					<span v-if="loadBtn === false">Войти</span>
					<span v-if="loadBtn === true">Вход...</span>
				</el-button>

				<el-button v-if="formToggle" icon="el-icon-back" type="primary" @click="register">Вернуться назад</el-button>
				<el-button v-if="formToggle && textCongratilation" :loading="loadBtnEmail" :disabled="loadBtnEmail" type="primary" @click="changePassword">Сохранить новый пароль</el-button>

				<el-button v-if="formToggle && !changeInp && !textCongratilation" type="primary" @click="verifyEmail('validateEmail')" :loading="loadBtnEmail" class="def-btn">
					<span v-if="loadBtnEmail === false">Продолжить</span>
					<span v-if="loadBtnEmail === true">Проверка...</span>
				</el-button>

				<el-button v-if="formToggle && changeInp && !textCongratilation" type="primary" @click="nextChangePass(validateEmail.code)" :loading="loadBtnEmail" class="def-btn">
					<span v-if="loadBtnEmail === false">Продолжить</span>
					<span v-if="loadBtnEmail === true">Проверка...</span>
				</el-button>
			</span>
		</el-dialog>

		<el-dialog center :before-close="logoutHandler" :close-on-click-modal="false" title="Пользовательское соглашение" :visible.sync="userAccept">
			<el-checkbox style="white-space: normal; word-break: break-word;" v-model="checked">
				Я принимаю условия 
				<a href="/static/UserAgreement.doc">Пользовательского соглашения</a> и даю своё согласие на обработку моей персональной информации на условиях, определенных 
				<a href="/static/PrivacyPolicy.docx">Политикой конфиденциальности.</a>
			</el-checkbox>
			<span slot="footer" class="dialog-footer">
				<el-button @click="logoutHandler" class="def-btn">Отклонить</el-button>
				<el-button type="primary" :disabled="checked === false" @click="nextAcc" class="def-btn">Продолжить</el-button>
			</span>
		</el-dialog>

		<el-dialog center width="600px" :show-close="false" :close-on-click-modal="false" :visible.sync="easyToSystem">
			<p>Сессия завершена в связи с отсутствием активности на сайте.<br>Авторизуйтесь, чтобы продолжить работу.</p>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="easyToSystem = false" class="def-btn">Закрыть</el-button>
			</span>
		</el-dialog>


		
		<dx-popup
			:visible.sync="visiblePopupNotif"
			:drag-enabled="true"
			:close-on-outside-click="false"
			width="1200"
			height="auto"
			class="popupNotif"
			title="Архив уведомлений"
		>
			<ArhNotific />
		</dx-popup>

		<!-- Окно регистрации -->
		<v-dialog persistent v-model="dialogFormRegister" max-width="1100px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="closeRegister()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Форма регистрации пациента</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="saveRegister(obj)" dark text >Зарегистрироваться</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation class="mt-2">
							<v-row>
								<v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#6b59c7" label="Фамилия *" :rules="[rulesRegister.required]" outlined dense v-model="obj.LastName" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#6b59c7" label="Имя *" :rules="[rulesRegister.required]" outlined dense v-model="obj.FirstName" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#6b59c7" label="Отчество" outlined dense v-model="obj.Patrynomic" />
                                </v-col>
								<v-col cols="12" sm="12" md="5">
                                    <v-select menu-props="auto" color="#6b59c7" dense label="Диагноз пациента *" :rules="[rulesRegister.required]" item-text="synonym" item-value="id" outlined :items="diagnosesItems" v-model="obj.DiagnosisId" />
                                </v-col>
								<v-col class="mt-0 pt-0" cols="12" sm="12" md="3">
                                    <v-radio-group :rules="[rulesRegister.required]" dense v-model="obj.IsMale" row >
										<v-row>
											<v-col cols="12" sm="12" md="5">
												<v-radio color="#6b59c7" label="Муж" value="true" />
											</v-col>
											<v-col cols="12" sm="12" md="5">
												<v-radio color="#6b59c7" label="Жен" value="false" />
											</v-col>
										</v-row>
									</v-radio-group>
                                </v-col>
								<v-col cols="12" sm="12" md="4">
									<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field color="#6b59c7" dense outlined :rules="[rulesRegister.required]" v-model="dateFormatted" label="Дата рождения *" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
										</template>

										<v-date-picker
											:max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
											min="1950-01-01"
											ref="picker"
											color="#6b59c7"
											:first-day-of-week="1"
											v-model="obj.BirthDate"
											@change="saveDate"
										/>
									</v-menu>
								</v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#6b59c7" label="Фамилия законного представителя *" :rules="[rulesRegister.required]" outlined dense v-model="obj.ApplicantLastName" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#6b59c7" label="Имя законного представителя *" :rules="[rulesRegister.required]" outlined dense v-model="obj.ApplicantFirstName" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#6b59c7" label="Отчество законного представителя" outlined dense v-model="obj.ApplicantPatrynomic" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#6b59c7" label="E-mail законного представителя *" :error="errorEmail" :error-messages="errorEmailText" :rules="[rulesRegister.required, rulesRegister.email]" outlined dense v-model="obj.ApplicantEmail" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#6b59c7" v-mask="'+7 (###) ###-####'" placeholder="+7(___)___-____" label="Телефон законного представителя *" :rules="[rulesRegister.required]" outlined dense v-model="obj.ApplicantPhone" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-select menu-props="auto" color="#6b59c7" dense label="Степень родства *" :rules="[rulesRegister.required]" item-text="Name" item-value="Id" outlined :items="relationshipDegrees" v-model="obj.RelationshipDegreeId" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-select menu-props="auto" color="#6b59c7" dense label="Учреждение *" :rules="[rulesRegister.required]" item-text="Name" item-value="id" outlined :items="hospitalsItems" v-model="obj.HospitalId" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-select menu-props="auto" color="#6b59c7" dense @change="changeDoc(obj.DoctorId)" label="Врач *" :rules="[rulesRegister.required]" item-text="Name" item-value="Id" outlined :items="doctorsItems" v-model="obj.DoctorId" />
                                </v-col>
								<v-col cols="12" sm="12" md="4">
                                    <v-select menu-props="auto" color="#6b59c7" dense label="Регион *" :rules="[rulesRegister.required]" item-text="Name" item-value="id" outlined :items="regionsItems" v-model="obj.RegionId" />
                                </v-col>

								<v-col v-if="obj.DoctorId == 1" cols="12" sm="12" md="6">
                                    <v-text-field color="#6b59c7" label="ФИО врача *" :rules="[rulesRegister.required]" outlined dense v-model="obj.UnknownDoctor" />
                                </v-col>
								<v-col v-if="obj.HospitalId == '2a1101a8-0df3-4ac1-9c9e-4e5c15e9b422'" cols="12" sm="12" md="6">
                                    <v-text-field color="#6b59c7" label="Наименование учреждения *" :rules="[rulesRegister.required]" outlined dense v-model="obj.UnknownHospital" />
                                </v-col>

								<v-col cols="12" sm="12" md="6">
									<v-checkbox color="#6b59c7" dense hide-details :rules="[rulesRegister.required]" v-model="obj.CanContainCookies" label="Согласие на обработку персональных данных" />
                                </v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
		
		<!-- Окно формирование отчета -->
		<modal-generate-report
			:openReport="openReport"
			v-on:update:openReport="openReport = $event"
			:user="patient"
			:birthDate="patientData.birth_date"
			:apiUrl="apiUrl"
			:doctor="patientData.doctor"
		/>

		<v-dialog v-model="dialogWarn" width="500" >
			<v-card>
				<v-card-title class="text-h6 orange accent-1">
					Предупреждение
				</v-card-title>

				<v-card-text class="mt-3">
					Внесенный Вами врач в системе не зарегистрирован, вы можете пользоваться приложением в режиме самоконтроля
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
					<v-btn color="warning" text @click="dialogWarn = false" >
						Ok
					</v-btn>
				</v-card-actions>
			</v-card>
			</v-dialog>

		<!-- <v-dialog persistent v-model="openReport" max-width="1100px">

		</v-dialog> -->

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</div>
</template>

<script>
import idleTimer from 'idle-timer'
import { TheMask } from 'vue-the-mask'
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { DxPopup } from "devextreme-vue";
import ArhNotific from '../PersonalDoctorsOffice/grid/ArhNotific.vue'
import $ from 'jquery'
import {mask} from 'vue-the-mask'
import modalGenerateReport from './modalGenerateReport.vue'

export default {
	directives: {mask},
	components: {DxPopup, ArhNotific, TheMask, modalGenerateReport},
	computed: {
		...mapGetters({
			user: 'user/user',
			urlBack: 'user/urlBack',
			patient: 'user/patient',
			notifications: 'personalDoctorsOffice/notifications',
			notificationsPatient: 'passportPart/notificationsPatient',
			keyMobile: 'passportPart/keyMobile',
			patientData: 'passportPart/patientData',
			apiUrl: 'apiUrl',
			apiStatic: 'apiStatic',
			apiStaticFile: 'apiStaticFile'
		}),
        numberOfNotifications() {
            var intNot = 0
            for(let i=0;i<this.notifications.length;i++) {
                if(this.notifications[i].seen === false) {
                    intNot++
                }
            }
            return intNot
		},
		numberOfNotificationsPatient() {
            let intNot1 = 0
            for(let i=0;i<this.notificationsPatient.length;i++) {
                if(this.notificationsPatient[i].IsReaded === false) {
                    intNot1++
                }
            }
            return intNot1
		},
		activeIndex() {
			switch(this.$route.name) {
				case "PassportPart" : 
					return  "1";
				break;
				case "Treatment" : 
					return  "2";
				break;
				case "Analyses" : 
					return  "3";
				break;
				case "ManifestationOfDisease" : 
					return  "4";
				break;
				case "QualityOfLifeQuestionnaire" : 
					return  "5";
				break;
				case "Scale" : 
					return  "6";
				break;
				case "Data" : 
					return  "7";
				break;
				case "ListDoc" :
					return "8"
				break;
				case "ListPatient" :
					return "9"
				break;
				case "Guide" :
					return "10"
				case "Report" :
					return "11"
				break;
				case "Role" :
					return "12"
				break;
				case "PassportPart" :
					return "13"
				break;
			}
		},
		dateFormatted() {
            if(this.obj.BirthDate)
                return new Date(this.obj.BirthDate).toLocaleDateString("ru-RU");
            return null;
        }
	},
    watch: {
		menu(val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
		}
    },
	data() {
		var callbackFn = _ => {
			if(this.isAuth){
				this.logoutHandler()
				this.easyToSystem = true
			}
		}
		var timer = idleTimer({
			callback: callbackFn,
			idleTime: 1800000
		})
		var validatePass = (rule, value, callback) => {
            var s_letters = "qwertyuiopasdfghjklzxcvbnm"; // Буквы в нижнем регистре
            var b_letters = "QWERTYUIOPLKJHGFDSAZXCVBNM"; // Буквы в верхнем регистре
            var digits = "0123456789"; // Цифры
            var specials = "!@#$%^&*()_-+=\|/.,:;[]{}"; // Спецсимволы
            var is_s = false; // Есть ли в пароле буквы в нижнем регистре
            var is_b = false; // Есть ли в пароле буквы в верхнем регистре
            var is_d = false; // Есть ли в пароле цифры
            var is_sp = false; // Есть ли в пароле спецсимволы
            for (var i = 0; i < value.length; i++) {
                if (!is_s && s_letters.indexOf(value[i]) != -1) is_s = true;
                else if (!is_b && b_letters.indexOf(value[i]) != -1) is_b = true;
                else if (!is_d && digits.indexOf(value[i]) != -1) is_d = true;
                else if (!is_sp && specials.indexOf(value[i]) != -1) is_sp = true;
            }
			if (value === '') {
				callback(new Error('Пожалуйста введите пароль'));
			} else if(value.length < 6){
                callback(new Error('Пароль должен состоять из 6 или более символов!'));
			} else if((is_b && is_sp && is_d) || (is_s && is_sp && is_d)){
				if (this.ruleForm2.checkPass !== '') {
					this.$refs.ruleForm2.validateField('checkPass');
				}

				if(this.ruleForm3.checkPass !== '') {
					this.$refs.ruleForm3.validateField('checkPass');
				}
				callback();
            } else {
				callback(new Error('Пароль должен состоять из 6 или более символов, содержать 1 латинскую букву и спецсимвол'));
			}

			// if (value === '') {
			// callback(new Error('Пожалуйста, введите новый пароль'));
			// } else {
			// if (this.ruleForm2.checkPass !== '') {
			// 	this.$refs.ruleForm2.validateField('checkPass');
			// }
			// callback();
			// }
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('Пожалуйста, введите новый пароль еще раз'));

				return;
			}

			if (this.ruleForm2.pass && value !== this.ruleForm2.pass) {
				callback(new Error('Пароли не совпадают!!!'));
				
				return;
			}
			
			if (this.ruleForm3.pass && value !== this.ruleForm3.pass) {
				callback(new Error('Пароли не совпадают!!!'));
				
				return;
			}

			callback();
		};
		return {
			dialogWarn: false,
			openReport: false,
			errorEmailText: '',
			errorEmail: false,		
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			relationshipDegrees: [],
			doctorsItems: [],
			regionsItems: [],
			hospitalsItems: [],
			diagnosesItems: [],
			menu: false,
			obj: {
				FirstName: null,
				LastName: null,
				Patrynomic: null,
				BirthDate: null,
				RegionId: null,
				IsMale: null,
				IsFemale: null,
				DiagnosisId: null,
				DoctorId: null,
				UnknownDoctor: null,
				HospitalId: null,
				UnknownHospital: null,
				CanContainCookies: null,
				RelationshipDegreeId: null,
				ApplicantFirstName: null,
				ApplicantLastName: null,
				ApplicantPatrynomic: null,
				ApplicantEmail: null,
				ApplicantPhone: null
			},
			overlay: false,
			valid: false,
			dialogFormRegister: false,
			easyToSystem: false,
			codeBool: false,
			textCongratilation: false,
			changeInp: false,
			noEmailSys: false,
			visCodeChangePass: false,
			validateEmail: {
				email: '',
				code: ''
			},
			loginForm:{
				username: '',
				password: ''
			},
			loadBtnEmail: false,
			formToggle: false,
			resData: [],
			checked: false,
			userAccept: false,
			numberVid: '',
			activeName: 'first',
			popupVideo: false,
			helpPopup: false,
			loadBtn: false,
			loginLoad: false,
			dialogFormVisible: false,
			visibleError: false,
			error: 0,
			mobileMenu: false,
			isAuth: false,
			visiblePopupNotif: false,
			ruleLoginForm: {
				username: [
					{ required: true, message: 'Введите логин', trigger: ['blur', 'change'] },
				],
				password: [
					{ required: true, message: 'Введите пароль', trigger: ['blur', 'change'] },
				]
			},
			ruleForm2: {
				pass: '',
				checkPass: '',
				old_pass: ''
			},
			ruleForm3: {
				pass: '',
				checkPass: ''
			},
			rules2: {
				old_pass: [
					{ required: true, message: 'Пожалуйста введите свой пароль', trigger: 'blur' },
				],
				pass: [
					{ validator: validatePass, trigger: 'blur' }
				],
				checkPass: [
					{ validator: validatePass2, trigger: 'blur' }
				]
			},
			rules3: {
				pass: [
					{ validator: validatePass, trigger: 'blur' }
				],
				checkPass: [
					{ validator: validatePass2, trigger: 'blur' }
				]
			},
			rulesRegister: {
				required: value => !!value || 'Обязательно для заполнения.',
				email: v => /.+@.+\..+/.test(v) || 'Некорректный E-mail',
			},
		} 
	},
	methods: {
		closeRegister() {
			this.obj = {
				FirstName: null,
				LastName: null,
				Patrynomic: null,
				BirthDate: null,
				RegionId: null,
				IsMale: null,
				IsFemale: null,
				DiagnosisId: null,
				DoctorId: null,
				UnknownDoctor: null,
				HospitalId: null,
				UnknownHospital: null,
				CanContainCookies: null,
				RelationshipDegreeId: null,
				ApplicantFirstName: null,
				ApplicantLastName: null,
				ApplicantPatrynomic: null,
				ApplicantEmail: null,
				ApplicantPhone: null
			}
			this.$refs.form.resetValidation()
			this.dialogFormRegister = false
			this.errorEmail = false
			this.errorEmailText = ''
		},
		saveRegister(obj) {
			if(this.$refs.form.validate()) {
				this.api.post(`${this.apiUrl}/api/user/register`, obj)
				.then(response => {
					if(response.status == 200) {
						this.snackBarFunc(true, 'Регистрация пациента успешно прошла!!!', 'success')
						this.closeRegister()
					}
				})
				.catch(error => {
					if(error.response.status == 400) {
						this.errorEmail = true
						this.errorEmailText = 'Указанный e-mail уже используется!!!'
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		nextChangePass(e){
			this.loadBtnEmail = true
			this.chekCode(e).then(res => {
				if(res.data.Success == true){
					this.codeBool = false
					this.loadBtnEmail = false
					this.noEmailSys = false
					this.visCodeChangePass = false
					this.textCongratilation = true
				} else {
					this.textCongratilation = false
				}
			}).catch(error => {
				if(error.response.status === 404) {
					this.codeBool = true
					this.loadBtnEmail = false
				}
			})
		},
		closePop(done){
			if(this.formToggle)
				this.register();
			this.visCodeChangePass = false

			done()
		},
		verifyEmail(formName){
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.loadBtnEmail = true
					this.checkEmailInSystem(this.validateEmail.email).then(res => {
						if(res.data.Success == true){
							this.changeInp = true
							this.noEmailSys = false
							this.visCodeChangePass = true
							this.loadBtnEmail = false
						}
					}).catch(error => {
						if(error.response.status === 404) {
							this.noEmailSys = true
							this.loadBtnEmail = false
						}
					})
				} else {
					alert('Введите корректно email!!!');
					return false;
				}
			});
		},
		register(e) {
			this.validateEmail.email = ''
			this.validateEmail.code = ''
			this.visCodeChangePass = false
			this.textCongratilation = false
			this.changeInp = false
			this.noEmailSys = false
			this.loadBtnEmail = false
			this.formToggle = !this.formToggle
			$('form').animate({height: "toggle", opacity: "toggle"}, "slow");
		},
		nextAcc(){
			this.dialogFormVisible = false
			this.userAccept = false
			this.isAuth = true;
			this.loginLoad = false
			this.loadBtn = false
			this.changeAgr();
			switch(this.resData.data.role) {
				case 0:
					this.$router.push('/ListDoc')
					break;
				case 1:
					this.$router.push('/PassportPart')
					break;
				case 2:
					this.$router.push('/PersonalDoctorsOffice')
                    break;
                case 3:
                    this.$router.push('/ListDoc')
                    break;
			}
		},
		openpdf(e){
			window.open(`${this.apiStaticFile}static/Manual${e === 0 ? 'UsersPdf' : 'Auth'}.pdf`, "_blank")
		},
		openVideo(e){
			this.numberVid = e
			this.popupVideo = true
		},
		openHelp(){
			this.helpPopup = true
		},
		exitFromAllDevices() {
			this.outDevice().then((res) => {
				if(res.status === 200) {
					this.$notify({
						title: 'Успешно',
						message: 'Выход со всех устройств успешен!',
						type: 'success'
					});
					this.logoutHandler()
				}
            })
            .catch(error => console.log(error));
		},
		passview() {
            $('#myPassword').attr('type','text');
            $('#showme').on('mouseleave',function(){
                $('#myPassword').attr('type','password');
            })
        },
		submitFormPass(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.changePass({
						oldpsw: this.ruleForm2.old_pass, 
						newpsw: this.ruleForm2.checkPass
					}).then(res => {
						//console.log('res',res)
						if(res.status === 200 && res.data.status_code === 1) {
							//console.log('status 200',res)
							this.$notify({
								title: 'Успешно!',
								message: 'Ваш пароль успешно изменен.',
								type: 'success'
							});
							this.$message({
								message: 'Ваш пароль успешно изменен.',
								type: 'success'
							});
							this.ruleForm2.pass = ''
							this.ruleForm2.checkPass = ''
							this.ruleForm2.old_pass = ''
						} else if(res.data.status_code === 0){
							//console.log('else',res)
							this.$message.error('Уупс, Ваш пароль не верен.');
							this.$notify.error({
								title: 'Ошибка',
								message: 'Уупс, Ваш пароль не верен, попробуйте снова',
							});
							this.ruleForm2.pass = ''
							this.ruleForm2.checkPass = ''
							this.ruleForm2.old_pass = ''
						}
					})
					.catch(error => console.log(error));
				} else {
					return false;
				}
			})
		},
		falseNotific(id) {
            this.notifications.forEach(i => {
                if(i.Id === id) {
                    i.seen = true
				}
			});
			this.postNotification(id);
        },
		falseNotificationsPatient(id){
			this.notificationsPatient.forEach(i => {
                if(i.Id === id) {
                    i.IsReaded = true
				}
			});
			
			this.deleteNotificationsPatient(id);
		},

		...mapActions({
			chekCode(dispatch, e){
				return dispatch('user/chekCode', {token: e})
			},
			checkEmailInSystem(dispatch, e){
				return dispatch('user/checkEmailInSystem', {email: e})
			},
			updateComponent: 'passportPart/updateComponent',
			changeAgr: 'user/changeAgr',
			postNotification: 'personalDoctorsOffice/postNotification',
			outDevice(dispatch) {
				return dispatch('passportPart/outDevice')
			},
			changePass(dispatch, e) {
				return dispatch('passportPart/changePass', e)
			},
            logout(dispatch) {
               return dispatch('user/logout')
			},
			author(dispatch) {
               return dispatch('user/authorization', {
                    login: this.loginForm.username,
                    password: this.loginForm.password,
               })
			},
			setPatient: 'user/setPatient',
            getNotification: 'personalDoctorsOffice/getNotification',
			getNotificationsPatient: 'passportPart/getNotificationsPatient',
			deleteNotificationsPatient: 'passportPart/deleteNotificationsPatient',			
        }),
        ...mapMutations({
            setSaveUrlBack: 'user/setSaveUrlBack'
        }),
		login(formName) {
	        this.$refs[formName].validate((valid) => {
	          if (valid) {
	            this.loginLoad = true
				this.loadBtn = true
	            this.author()
	            .then(res => {
					this.resData = res
					if (res.data.success === false) {
						this.loginLoad = false
						this.loadBtn = false
						this.$message.error('Неверный логин или пароль, попробуйте снова!!!.');
						this.loginForm.username = ''
						this.loginForm.password = ''
					} else if(res.data.agreement === false) {
						this.userAccept = true;
					} else if(res.data.agreement === true){
						this.dialogFormVisible = false
						this.isAuth = true;
						this.loginLoad = false
						this.loadBtn = false
						switch(res.data.role) {
							case 0:
								this.$router.push('/ListDoc')
								break;
							case 1:
								this.$router.push('/PassportPart')
								this.getNotificationsPatient();
								break;
							case 2:
								this.$router.push('/PersonalDoctorsOffice')
								break;
							case 3:
								this.$router.push('/ListDoc')
								break;
						}
					}
	            })
	            .catch(error => console.log(error));
	          } else {
	            return false;
	          }
	        });
	    },
        logoutHandler: function() {
			this.userAccept = false
            this.logout()
            .then(res => {
                if(res.status === 200 && res.data.success === true) {
					this.loginLoad = false
					this.loadBtn = false
					this.dialogFormVisible = false
					this.isAuth = false;
					this.checked = false;
					localStorage.clear();
                    this.$router.push('/')
                }
            })
            .catch(error => console.log(error));
        },
        updateLocalStorage() {
            localStorage.setItem('photo', '11');
            localStorage.removeItem('subtype')
            localStorage.removeItem('subtype_item')
        },
		handleSelect(key, keyPath) {
			if(key === "1") {
				this.$router.push(`/PassportPart${this.$route.query.myPatient ? '?myPatient='+this.$route.query.myPatient : ''}`)
				localStorage.setItem('pp', '1')
				this.updateLocalStorage()
			} else if(key === "2") {
				this.$router.push(`/Treatment${this.$route.query.myPatient ? '?myPatient='+this.$route.query.myPatient : ''}`)
				localStorage.setItem('tm', '1');
                this.updateLocalStorage()
			} else if(key === "3") {
				this.$router.push(`/Analyses${this.$route.query.myPatient ? '?myPatient='+this.$route.query.myPatient : ''}`)
				localStorage.setItem('al', '1')
				this.updateLocalStorage()
			} else if(key === "4") {
				localStorage.setItem('mofd', '1');
				this.updateLocalStorage()
				this.$router.push(`/ManifestationOfDisease${this.$route.query.myPatient ? '?myPatient='+this.$route.query.myPatient : ''}`)
			} else if(key === "5") {
				this.$router.push(`/QualityOfLifeQuestionnaire${this.$route.query.myPatient ? '?myPatient='+this.$route.query.myPatient : ''}`)
				this.updateLocalStorage()
			} else if(key === "6") {
				this.$router.push(`/Scale${this.$route.query.myPatient ? '?myPatient='+this.$route.query.myPatient : ''}`)
				this.updateLocalStorage()
			} else if(key === "7") {
				this.$router.push(`/Data${this.$route.query.myPatient ? '?myPatient='+this.$route.query.myPatient : ''}`)
				this.updateLocalStorage()
				localStorage.setItem('dt', '1');
				localStorage.setItem('dtt', '1');
			} else if(key === "8") {
				this.$router.push('/ListDoc')
			} else if(key === "9") {
				this.$router.push('/ListPatient')
			} else if(key === "10") {
				this.$router.push('/Guide')
			} else if(key === "11") {
				this.$router.push('/Report')
			} else if(key === "12") {
				this.$router.push('/Role')
			} else if(key === "13") {
				// this.$router.push(`/${this.$route.name}`)
			}
		},
        arhNotif() {
            this.visiblePopupNotif = true
		},
		back(e) {
            this.setPatient({});
            if(e == 1) {
                this.$router.push('/ListDoc')
            } else {
                if(this.urlBack !== '') {
                    this.$router.push(this.urlBack)
                    setTimeout(() => { this.setSaveUrlBack('') }, 300);
                } else this.$router.push('/PersonalDoctorsOffice')
            }
		},
		goPatient(e){
			let key = e.patient_id
			this.setPatient({ patients_id: key, fio: e.patient_fio});
			if(this.$route.name === 'PassportPart')
				this.updateComponent();
			this.$router.push('/PassportPart');
			this.$refs['popoverNotify'].doClose();
		},
		saveDate(date) {
			this.$refs.menu.save(date)
		},
		changePassword() {
			this.$refs.ruleForm3.validate((valid) => {
				if (valid) {
					this.loadBtnEmail = true

					this.api.put(`${this.apiUrl}/api/user/reset-pswd`, {
						token: this.validateEmail.code,
						pswd: this.ruleForm3.pass,
						pswd_repeat: this.ruleForm3.checkPass
					})
					.then(res => {
						if(res.data.Success) {
							this.register()

							this.$notify({ title: 'Успешно!', message: 'Ваш пароль успешно изменен.', type: 'success' })
							// this.$message({ message: 'Ваш пароль успешно изменен.', type: 'success' })
							
							setTimeout(() => {
								this.loadBtnEmail = false
								this.ruleForm3.pass = ''
								this.ruleForm3.checkPass = ''
								this.codeBool = false
								this.loadBtnEmail = false
								this.noEmailSys = false
								this.visCodeChangePass = false
								this.textCongratilation = false
							}, 1000)
						}
					})
				} else {
					return false
				}
			})
		},
		changeDoc(e) {
			if(e === 1) this.dialogWarn = true
		}
	},
	created(){
		if(this.user.role === 2){
			this.getNotification();
		}
		
		// if (this.user.role == "" &&  this.patient.role == 1){
		// 	console.log('getNotificationsPatient');
		// 	//пациент
		// 	this.getNotificationsPatient();
		// }

		if(this.$route.fullPath == '/') {
			fetch(`${this.apiUrl}/api/lookups/relationship-degrees`)
			.then((resp) => resp.json())
			.then(res => {this.relationshipDegrees = res})
			
			fetch(`${this.apiUrl}/api/lookups/doctors?lookupName=doctors`)
			.then((resp) => resp.json())
			.then(res => {
				res.push({Id:1, Name: 'Другой'},{Id:2, Name: 'Отсутствует'})
				this.doctorsItems = res
			})

			fetch(`${this.apiUrl}/api/diagnoses`)
			.then((resp) => resp.json())
			.then(res => {this.diagnosesItems = res})

			fetch(`${this.apiUrl}/api/lookups/regions?lookupName=regions`)
			.then((resp) => resp.json())
			.then(res => {
				this.regionsItems = res.sort((a, b) => (a.Name < b.Name) ? -1 : ((b.Name > a.Name) ? 1 : 0))
			})

			fetch(`${this.apiUrl}/api/lookups/hospitals?lookupName=hospitals`)
			.then((resp) => resp.json())
			.then(res => {this.hospitalsItems = res})
		}

	}
}
</script>

<style>
.display {
	display: none
}
.btn-back-supervisor {
    margin-top: 8px;
    color: white;
    font-size: 13px;
}
</style>
