<template>
	<v-card>
		<v-card-title>
			<v-btn v-if="user.role !== 2" @click="dialog=true" color="#6374dd" dark class="ml-4">Добавить сопутствующее заболевание</v-btn>
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:items="list"
			:loading="loading"
			no-data-text="Cопутствующее заболевание отсутствует..."
			hide-default-footer
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="checkRole" @click="editItems(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn v-if="user.role !== 2 && (new Date().getTime() - item.creation_date < 86400000)" @click="deleteItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>
			<template v-slot:[`item.date_start`]="{ item }">
				{{new Date(item.date_start).toLocaleDateString()}}
			</template>
		</v-data-table>


		<v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Сопутствующее заболевание</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation >
							<v-row>
								<v-col cols="12" sm="12" md="12">
									<v-text-field outlined ref="tf0" :rules="[rules.required]" color="#6b59c7" v-model="obj.diagnosis" label="Название заболевания *" />
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field outlined ref="tf1" :rules="[rules.required]" v-model="dateFormattedStart" label="Дата начала *" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
										</template>

										<v-date-picker color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.date_start" :max="checkMaxDateStart" @change="saveDate" />
									</v-menu>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<v-menu ref="menuEnd" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field :disabled="obj.end_date.checkbox" outlined v-model="dateFormattedEnd" label="Дата окончания" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
										</template>

										<v-date-picker color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.end_date.date" :min="checkMinDateEnd" :max="maxDate" @change="saveDateEnd" />
									</v-menu>
								</v-col>
								<v-col cols="12" sm="4" md="4">
									<v-checkbox outlined color="#6b59c7" @change="resetDateEnd(obj.end_date.checkbox)" v-model="obj.end_date.checkbox" label="По настоящее время" />
								</v-col>
								<v-col cols="12" sm="12" md="12">
									<v-textarea outlined rows="4" color="#6b59c7" v-model="obj.comment" label="Комментарий" />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			patient: 'user/patient',
			user: 'user/user',
        }),
		dateFormattedStart() {
            if(this.obj.date_start)
                return new Date(this.obj.date_start).toLocaleDateString("ru-RU");
            return null;
        },
		dateFormattedEnd() {
            if(this.obj.end_date.date)
                return new Date(this.obj.end_date.date).toLocaleDateString("ru-RU");
            return null;
        },
		checkMaxDateStart() {
			return this.obj.end_date.date ? new Date(this.obj.end_date.date).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
		},
		checkMinDateEnd() {
			return this.obj.date_start ? new Date(this.obj.date_start).toISOString().substr(0, 10) : null
		},
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
	data() {
		return {
			maxDate: new Date().toISOString().substr(0, 10),
			menu: false,
			menuEnd: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				id: null,
				comment: '',
				creation_date: null,
				date_start: null,
				diagnosis: '',
				end_date: {date: null, checkbox: null},
				id: null,
				patients_id: null
			},
			dialog: false,
			list: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.'
			},
			headers: [
				{ text: "Название заболевания", value: "diagnosis", align: 'center' },
				{ text: "Дата начала", value: "date_start", align: 'center' },
				{ text: "Дата окончания", value: "date_end", align: 'center', sortable: false },
				{ text: "Комментарий", value: "comment", align: 'center', sortable: false },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		close() {
			this.$refs[`tf0`].reset()
			this.$refs[`tf1`].reset()
			this.obj = {
				id: null,
				comment: '',
				creation_date: null,
				date_start: null,
				diagnosis: '',
				end_date: {date: null, checkbox: null},
				id: null,
				patients_id: null
			}
			this.dialog = false
		},
		async apiMethods(url, name) {
			this.loading = false
			let res = (await this.api.get(`${url}/${name}`)).data

			res.forEach(el => {
				if(el.date_start) el.date_start = this.toJSONLocal(el.date_start)
				el.date_end = el.end_date.checkbox ? 'По настоящее время' : new Date(el.end_date.date).toLocaleDateString()
			})
			
			return res
		},
		editItems(row) {
			Object.assign(this.obj, row)
			this.dialog = true
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/patients/${this.patient.patients_id}/concomitants/${row.id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				let ds = new Date(obj.date_start).getTime(), de = obj.end_date.date ? new Date(obj.end_date.date).getTime() : null;

				if((!obj.end_date.checkbox && de && ds <= de) || (obj.end_date.checkbox && ds)) {
					let url = obj.id ? `${this.apiUrl}/api/patients/${this.patient.patients_id}/concomitants/${obj.id}` : `${this.apiUrl}/api/patients/${this.patient.patients_id}/concomitants`
					this.overlay = true

					if(!obj.id) delete obj['id']
					if(obj.end_date.date) obj.end_date.date = new Date(obj.end_date.date).getTime()

					obj.creation_date = new Date().getTime()
					obj.date_start = new Date(obj.date_start).getTime()

					this.api[obj.id ? 'put' : 'post'](url, obj)
					.then(res => {
						if(!obj.id) {
							res.data.date_end = res.data.end_date.checkbox ? 'По настоящее время' : new Date(res.data.end_date.date).toLocaleDateString()

							//Добавление в массив нового пациента
							this.list.unshift(res.data)
							
							this.snackBarFunc(true, 'Сопутствующее заболевание успешно создано!!!', 'success')
						} else {
							this.list.forEach(el => {
								if(el.id == res.data.id) {
									res.data.date_start = this.toJSONLocal(res.data.date_start)
									res.data.date_end = res.data.end_date.checkbox ? 'По настоящее время' : new Date(res.data.end_date.date).toLocaleDateString()

									Object.assign(el, res.data)
								}
							})
							this.snackBarFunc(true, 'Сопутствующее заболевание успешно обновлено!!!', 'success')
						}
						this.overlay = false
						this.dialog = false
						this.close()
					})
					.catch(error => {
						if(error) {
							this.snackBarFunc(true, 'Ошибка, обновите страницу и попробуйте снова!!!', 'error')
							this.overlay = false
						}
					})
				} else {
					this.snackBarFunc(true, 'Дата начала не может быть больше даты окончания!!!', 'warning')
				}
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		saveDate(date) {
			this.$refs.menu.save(date)
		},
		saveDateEnd(date) {
			this.$refs.menuEnd.save(date)
		},
		resetDateEnd(val) {
			if(val) this.obj.end_date.date = null
		},
		toJSONLocal(date) {
			let local = new Date(date);
			local.setMinutes(new Date(date).getMinutes() - new Date(date).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/patients/${this.patient.patients_id}/concomitants`)
	}
};
</script>