<template>
    <div id="table">
        <el-button v-if="user.role !== 2" style="margin-top:10px;margin-bottom:15px;" @click="newpr" type="primary" icon="el-icon-plus" round>Новая реабилитация</el-button>
        <span v-if="false">Загрузка...</span>
            <dx-data-grid
                :ref="'grid'"
                :data-source="dataReability"
                :remote-operations="false"
                :allow-column-reordering="true" 
                :hover-state-enabled="true"
                :wordWrapEnabled="true"
                :row-alternation-enabled="false"
                :showColumnLines="false"
                :showRowLines="true"
                :showBorders="false"
                @editing-start="onEditingStart"
                @init-new-row="initNewRow"
                @row-inserting="onRowInserting"
                @row-updating="onRowUpdating"
                @row-removed="onRowRemoved"
                @content-ready="onContentReady"
                >
            
            <dx-column
            data-field="date_start"
            caption="Дата начала"
            alignment="center"
            sort-order="desc"
            :validationRules="validationRul"
            data-type="date"
            edit-cell-template="date_start_edit"
            />
            <dx-column 
            data-field="date_end"
            caption="Дата окончания"
            alignment="center"
            :validationRules="validationEndDate"
            data-type="date"
            edit-cell-template="editCellTemplate"
            cell-template="date_end_cell"
            cssClass="no_outline_cell"
            :header-filter="{dataSource: headerFilterEndDate}"
            />
            <dx-column 
                data-field="date_end_checkbox"
                edit-cell-template="cell-city_checkbox-edit"
                :visible="false"
            />
            <dx-column
            data-field="type_rehabil"
            caption="Вид реабилитации"
            alignment="center"
            :validationRules="validationRul"
            edit-cell-template="vidRehabilitation"
            cell-template="vidRehabil"
            dataType="string"
            cssClass="no_outline_cell"
            />
            <dx-filter-row 
            :visible="true"
            />
            <dx-header-filter 
            :visible="true"
            />
            <dx-editing
            mode="popup"
            :allowUpdating="user.role !== 2"
            :allowDeleting="true"
            :useIcons="true"
            :form="form"
            >
                <DxPopup
                    :width="getWindowWidth"
                    :showTitle="true"
                    :title="edit == 1 ? 'Добавить реабилитацию' : 'Редактировать реабилитацию'"
                    height='auto'
                />
            </dx-editing>
            <dx-column
                data-field="edit_but"
                :width="130"
                caption=""
                :buttons="editButtons"
                type="buttons"
            />
            <div slot="vidRehabil" slot-scope="data">
                <span v-if="data.data.value.type === 'Физиотерапия'">{{ data.data.value.type +" "+ (data.data.value.fizcomment ? "("+ data.data.value.fizcomment +")" : '') }}</span>
                <span v-if="data.data.value.type !== 'Физиотерапия'">{{ data.data.value.type }}</span>
            </div>
            <div slot="vidRehabilitation" slot-scope="data">
                <dx-select-box
                @selectionChanged="selectionChanged"
                v-if="!data.data.value"
                :items="vr"
                displayExpr="name"
                valueExpr="name"
                :searchEnabled="true"
                :onValueChanged="function(e) {
                data.data.setValue({type:e.value, fizcomment: data.data.fizcomment})}"
                />
                <dx-text-area
                v-if="fizter === 3 && !data.data.value"
                style="margin-top:10px"
                height="70"
                :value="data.data.fizcomment"
                :onValueChanged="function(e) {
                data.data.setValue({fizcomment:e.value, type: data.data.type})}"
                placeholder="Уточните название процедуры (Обязательно для заполнения)"
                />

                <dx-select-box
                @selectionChanged="selectionChanged"
                v-if="data.data.value"
                :items="vr"
                displayExpr="name"
                valueExpr="name"
                :value="data.data.value.type"
                :onValueChanged="function(e) {
                data.data.setValue({type:e.value, fizcomment: data.data.fizcomment})}"
                />
                <dx-text-area
                v-if="fizter === 3 && data.data.value"
                style="margin-top:10px"
                height="70"
                :value="data.data.value.fizcomment"
                :onValueChanged="function(e) {
                data.data.setValue({fizcomment:e.value, type: data.data.type})}"
                placeholder="Уточните название процедуры (Обязательно для заполнения)"
                />

            </div>
            
            <div slot="date_start_edit" slot-scope="data">
                <dx-date-box
                style="width:100%;"
                v-if="!data.data.value"
                display-format="dd.MM.yyyy"
                @valueChanged="valueChanged"
                :min="new Date(patDate.birth_date)"
                :max="new Date()"
                type="date"
                :onValueChanged="function(e) {
                data.data.setValue(e.value)}"
                />
                <dx-date-box
                style="width:100%;"
                v-if="data.data.value"
                @valueChanged="valueChanged"
                :value="data.data.value"
                display-format="dd.MM.yyyy"
                :min="new Date(patDate.birth_date)"
                :max="new Date()"
                type="date"
                :onValueChanged="function(e) {
                data.data.setValue(e.value)}"
                />
            </div>
            <div slot="date_end_cell" slot-scope="data">
				<span v-if="data.data.value === null">По настоящее время</span>
                <span v-else>{{ new Date(data.data.value).toLocaleDateString("ru-RU") }}</span>
			</div>


            <div slot="editCellTemplate" slot-scope="data">
                <dx-date-box
                style="width:100%;"
                :disabled="checkEvent === true"
                v-if="data.data.value"
                type="date"
                dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                display-format="dd.MM.yyyy"
                :value="checkEvent === true ? null : data.data.value"
                :onValueChanged="function(e) {
                if(checkEvent === true){
                    data.data.setValue(null)
                }else{
                    data.data.setValue(e.value)
                }}"
                />

                <dx-date-box
                style="width:100%;"
                :disabled="checkEvent === true"
                v-if="!data.data.value"
                type="date"
                dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                display-format="dd.MM.yyyy"
                :onValueChanged="function(e) {
                if(checkEvent === true){
                    data.data.setValue(null)
                }else{
                    data.data.setValue(e.value)
                }}"
                />
            </div>
            <div slot="cell-city_checkbox-edit" slot-scope="data">
                    <dx-check-box
                    v-if="data.data.value"
                    text="По настоящее время"
                    :value="data.data.value"
                    @valueChanged="valueChangedCheck"
                    :onValueChanged="function(e) {
                    data.data.setValue(e.value)}"
                    />
                    <dx-check-box
                    v-if="!data.data.value"
                    text="По настоящее время"
                    @valueChanged="valueChangedCheck"
                    :onValueChanged="function(e) {
                    data.data.setValue(e.value)}"
                    />
                </div>
            </dx-data-grid>
    </div>
</template>

<script>
import 'devextreme-intl';
import { DxDataGrid, DxColumn, DxPaging, DxPopup, DxEditing, DxHeaderFilter, DxFilterRow } from "devextreme-vue/ui/data-grid";
import { DxDateBox, DxCheckBox, DxSelectBox, DxTextArea } from "devextreme-vue";
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages } from 'devextreme/localization';
import axios from 'axios'
loadMessages(ruMessages);
locale(navigator.language || navigator.browserLanguage);
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import $ from 'jquery';
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        DxDataGrid, 
        DxColumn, 
        DxPaging,
        DxHeaderFilter,
        DxFilterRow,
        DxEditing,
        DxDateBox,
        DxCheckBox,
        DxSelectBox,
        DxTextArea,
        DxPopup
    },
    methods: {
        check(e) {
            console.log(e)
        },
        headerFilterEndDate(data){
            data.dataSource.postProcess = (results) => {
                return results.map(el => {
                    if(el.value === null){
                        el.text = 'По настоящее время'
                    }
                    return el;
                });
            };
        },
        initNewRow(e) {
            this.edit = 1;
        },
        onEditingStart(e) {
            this.edit = 2;
            if(e.data.date_end_checkbox === true) {
                this.checkEvent = true
            } else {
                this.checkEvent = false
            }
        },
        ...mapActions({
            getRehabil:'treatment/getRehabil',
            deleteRehabil: 'treatment/deleteRehabil',
			lineUpdateRehabil(dispatch, e){
                dispatch('treatment/lineUpdateRehabil', e)
            },
			createRehabil(dispatch, e){
				dispatch('treatment/createRehabil', e)
			},
        }),
        valueChangedCheck(e) {
            this.checkEvent = e.value
        },
        valueChanged(e) {
            this.dateEvent = e.value
        },
        selectionChanged(e) {
            if(e.selectedItem) this.fizter = e.selectedItem.id
        },
        newpr() {
            this.fizter = 0
            this.checkEvent = false
            this.$refs["grid"].instance.addRow();
        },
        onRowInserting(e) {
            //console.log(e.data)
            let data;
            if(e.data.date_end_checkbox === true) {
                data = {
                    date_end: {date: null, checkbox: e.data.date_end_checkbox},
                    creation_date: new Date().getTime(),
                    date_start: new Date(e.data.date_start).getTime(),
                    type_rehabil: {fizcomment: e.data.type_rehabil.fizcomment ? e.data.type_rehabil.fizcomment : '', type: e.data.type_rehabil.type}
                }
            } else if(!e.data.date_end_checkbox) {
                data = {
                    date_end: {date: new Date(e.data.date_end).getTime(), checkbox: false},
                    date_start: new Date(e.data.date_start).getTime(),
                    creation_date: new Date().getTime(),
                    type_rehabil: {fizcomment: e.data.type_rehabil.fizcomment ? e.data.type_rehabil.fizcomment : '', type: e.data.type_rehabil.type}
                }
            }
            this.createRehabil(data)
            e.cancel = true
            this.$refs["grid"].instance.cancelEditData();
            this.getRehabil()
        },
        onRowUpdating(e) {
            let data = {
                id: e.key.id,
                date_end: {
                    date: e.newData.date_end !== undefined ? new Date(e.newData.date_end).getTime() : e.key.date_end, 
                    checkbox: e.newData.date_end_checkbox !== undefined ? e.newData.date_end_checkbox : e.key.date_end_checkbox
                } ,
                date_start: e.newData.date_start ? new Date(e.newData.date_start).getTime() : e.key.date_start,
                creation_date: e.newData.creation_date ? e.newData.creation_date : e.key.creation_date,
                type_rehabil: e.newData.type_rehabil !== undefined ? {
                    fizcomment: e.newData.type_rehabil !== undefined ? e.newData.type_rehabil.fizcomment : e.key.type_rehabil.fizcomment, 
                    type: e.newData.type_rehabil.type !== undefined ? e.newData.type_rehabil.type : e.key.type_rehabil.type
                } : e.key.type_rehabil
            }
            this.lineUpdateRehabil(data)
        },
        onContentReady(e) {
			e.component.__itemVisible = false;
		},
        onRowRemoved(e) {
            this.deleteRehabil({
                creation_date: e.data.creation_date,
                date_end: {
                    data: e.data.date_end,
                    checkbox: e.data.date_end_checkbox
                },
                date_start: e.data.date_start,
                id: e.data.id,
                patients_id: e.data.patients_id,
                type_rehabil:  e.data.type_rehabil
            })
        },
        getWindowWidth(event) {
            this.windowWidth = document.documentElement.clientWidth;
            return this.windowWidth - 60
        },
    },
    mounted() {
        this.getRehabil()
        this.$nextTick(function() {
            window.addEventListener('resize', this.getWindowWidth);
            this.getWindowWidth()
        })
    },
	computed: {
		...mapGetters({
            user: 'user/user',
            reability: 'treatment/reability',
            patDate: 'passportPart/patientData',
        }),
        dataReability(){
            let data = [];
            this.reability.forEach(el => {
                data.push({
                    creation_date: el.creation_date,
                    date_end: el.date_end.date ? el.date_end.date : null,
                    date_start: el.date_start,
                    id: el.id,
                    patients_id: el.patients_id,
                    type_rehabil: el.type_rehabil,
                    date_end_checkbox: el.date_end.checkbox
                })
            });
            return data
        }
    },
    data() {
        return {
            edit: 1,
            listReability: [],
            form: {
                customizeItem:(item)=> {
                    if (item.dataField === "edit_but") {
                        item.visible = this.$refs["grid"].instance.__itemVisible;
					}
                    if(item.dataField === "date_end_checkbox") {
                        item.visible = true;
                        item.visibleIndex = 5;
                        item.label = {text: ' ', showColon: false};
                    }
				}
            },
			editButtons: [
                'edit',
                {
                    hint: 'Удалить',
                    icon: 'trash',
                    onClick:(e)=> {
                        this.$refs["grid"].instance.deleteRow(e.row.rowIndex);
                        e.event.preventDefault();
                    },
                    visible:(e)=>{
                        return this.user.role !== 2 && new Date().getTime() - e.row.data.creation_date < 86400000
                    }
                }
            ],
            validationRul: [{
                type: "required",
                message: "Заполните поле!"
            }],
            validationEndDate: [{
                type: "custom",
                message: "Заполните поле!",
                validationCallback: function(e){
                    if(!e.data.date_end)
                        return false
                    if(e.data.date_end === null){
                        this.message = 'Заполните поле!'
                        return false
                    }
                    const currentEndDate = new Date(e.data.date_end).getTime();
                    if(new Date(e.data.date_start).getTime() > currentEndDate){
                        this.message = 'Некорректное значение даты!!!'
                        return false;
                    }else{
                        return true;
                    }
                    if(e.data.date_end_checkbox === true){
                        return true
                    }
                    
                }
            }],
            checkEvent: '',
            dateEvent: '',
            fizter: 0,
            checkboxOff: false,
            vr: [
                {id:1, name:'Массаж'},
                {id:2, name:'ЛФК'},
                {id:3, name:'Физиотерапия'},
            ]
        }
    },
}
</script>

