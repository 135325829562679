<template>
	 <ellipse v-if="joint" class="st2" 
		:class="{'st4': ( joint.HasPictures || ( joint.items && joint.items.length )), 'st6': joint.active }" 
		:transform="ellipse.transform" 
		:cx="ellipse.cx" 
		:cy="ellipse.cy" 
		:rx="ellipse.rx" 
		:ry="ellipse.ry" 
		@click="setActiveJoint(joint.id)">
	 </ellipse>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	computed: {
		...mapGetters({
			joints: 'manifestationOfDisease/joints',
		}),
		joint() { return  this.joints.find(i => i.numeric_id == this.ellipse.n);},
	},
	methods: {
		...mapActions({
			setActiveJoint: 'manifestationOfDisease/setActiveJoint'
		})
	},
	props: ['ellipse']
	
}
</script>
