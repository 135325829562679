<template>
	<v-card class="ml-1 mr-1 mt-1">
		<v-card-title>
			<v-btn v-if="user.role !== 2" @click="dialog = !dialog" color="#6374dd" dark class="ml-4">Добавить анализ</v-btn>
			
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:search="search"
			:items="list"
			:loading="loading"
			no-data-text="Иные анализы отсутствуют..."
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<!-- кнопки редактирование/просмотра/удаления -->
			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="checkRole(item)" @click="onEditingStart(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn v-if="user.role !== 2 && new Date().getTime() - item.creation_date < 86400000" @click="deleteItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>
			
			<template v-slot:[`item.date`]="{ item }">
				{{ item.date_new }}
			</template>

			<template v-slot:[`item.analys`]="{ item }">
				<span :style="item.norma == false ? 'color:red' : ''">
					{{ item.analys }}
				</span>
			</template>
			
			<template v-slot:[`item.zn`]="{ item }">
				<span :style="item.norma == false ? 'color:red' : ''">
					{{ item.zn }}
				</span>
			</template>

		</v-data-table>

		<v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<template slot="progress">
					<v-progress-linear color="red" indeterminate></v-progress-linear>
				</template>
				<v-toolbar dark color="#6374dd">
					<v-btn :disabled="loading" icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title> {{ !editeRow ? "Добавить анализ" : "Редактировать анализ" }}</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="editeRow ? onRowUpdating(obj) : save(obj)" dark text :loading="loading">Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>				
					<v-container>
						<v-form ref="form" v-model="valid" lazy-validation :disabled="loading" >
							<v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field hide-details :rules="[rules.required]" color="#5D4CB8" label="Выбрать дату" dense outlined :value="dateFormatted" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                        </template>

                                        <v-date-picker
                                            min="1950-01-01"
                                            :max="maxDate"
                                            color="#5D4CB8"
                                            :first-day-of-week="1"
                                            v-model="obj.date"
                                            @change="(date) => { $refs.menu.save(date) }"
                                        />
                                        
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-autocomplete
                                        color="#5D4CB8"
                                        @focus="getList('api/analysispatient/getotherbloodtestsanalysis', 'analysesItems', false, 'isLoadingA', true)"
                                        :loading="isLoadingA"
                                        v-model="obj.analys"
                                        item-text="name"
                                        item-value="name"
										@change="getList(`api/analysispatient/getotherbloodtestsunits/${obj.analys.id}`, 'eiItems', true, 'isLoadingEI', false)"
                                        return-object
                                        hide-no-data
                                        hide-selected
                                        clearable
                                        hide-details
                                        dense
                                        :items="analysesItems"
                                        label="Анализ"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" sm="12" md="4">
                                    <v-text-field color="#5D4CB8" prepend-icon="mdi-pencil" label="Значение" type="number" dense outlined v-model="obj.znach.num" />
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-autocomplete
                                        color="#5D4CB8"
                                        :loading="isLoadingEI"
                                        v-model="obj.znach.sel"
                                        item-text="name"
                                        item-value="name"
                                        return-object
                                        hide-no-data
                                        hide-selected
                                        clearable
                                        dense
                                        hint="Выберите анализ для разблокировки поля!!!"
                                        :persistent-hint="obj.analys ? false : true"
                                        :disabled="!obj.analys"
                                        :items="eiItems"
                                        label="Единица измерения"
                                        outlined
                                    />
                                </v-col>
							</v-row>
						</v-form> 
					</v-container>				
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
//остальное
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			user: 'user/user',
			patient: 'user/patient'
        }),
		dateFormatted() {
            if(this.obj.date)
                return new Date(this.obj.date).toLocaleDateString("ru-RU");
            return null;
        }
	},
	data() {
		return {
			analysesItems: [],
            isLoadingA: false,
            eiItems: [],
            isLoadingEI: false,
			obj: {
                date: null,
                analys: null,
                znach: {num: null, sel: null},
                units_id: null,
                parameters_id: null
            },
			dialog: false,
			editeRow: false,
			search: '',
			maxDate: new Date().toISOString().substr(0, 10),
			menu: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			list: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.'
			},
			headers: [
				{ text: "Дата", value: "date", align: 'center', sortable: true },
				{ text: "Анализ", value: "analys", align: 'center', sortable: false },
				{ text: "Значение", value: "zn", align: 'center', sortable: false },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
        checkRole(item) {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : (this.user.role !== 2 && ((new Date().getTime() - item.creation_date) < 86400000)))
        },
		close() {
			this.obj = {
                creation_date: null,
                date: null,
                analys: null,
                znach: {num: null, sel: null},
                units_id: null,
                parameters_id: null
			}
			this.$refs.form.resetValidation()
            this.dialog = false
		},
		async apiMethods(url, name, flag) {
			let res = [];
			this.loading = false
			
			if(flag) {
				res = (await this.api.get(`${url}/${name}`)).data
				await res.forEach(el => {
					if(el.date) el.date_new = new Date(el.date).toLocaleDateString()
					if(el.znach.num && el.znach.sel) el.zn = `${el.znach.num} (${el.znach.sel})`
				})
			} else {
				res = (await this.api.get(`${url}/${name}`)).data.filter(f => !f.IsHidden)
			}

			return (res.sort((a, b) => (a.date < b.date) ? -1 : ((b.date > a.date) ? 1 : 0))).reverse()
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/patients/${this.patient.patients_id}/other_blood_tests/${row.id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)

						this.snackBarFunc(true, 'Запись успешно удалена!!!', 'success')
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				this.api.post(`${this.apiUrl}/api/patients/${this.patient.patients_id}/other_blood_tests`, {
					analys: obj.analys.name,
					date: new Date(obj.date).getTime(),
					znach: {num: +obj.znach.num, sel: obj.znach.sel.name},
					creation_date: new Date(obj.date).getTime(),
					units_id: obj.znach.sel.id,
					parameters_id: obj.analys.id
				}).then(res => {
					if(res.status == 200) {
						if(res.data.date) res.data.date_new = new Date(res.data.date).toLocaleDateString()
						if(res.data.znach.num && res.data.znach.sel) res.data.zn = `${res.data.znach.num} (${res.data.znach.sel})`
						this.list.push(res.data)

						this.list = (this.list.sort((a, b) => (a.date < b.date) ? -1 : ((b.date > a.date) ? 1 : 0))).reverse()

						this.snackBarFunc(true, 'Запись успешно создана!!!', 'success')
						this.close()
					}
				})
				.catch(error => {
					// console.log(error.response.data.Message);
					if(error) this.snackBarFunc(true, error.response.data.Message ? error.response.data.Message : 'Ошибка, обратитесь к Администратору!!!', 'error')
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
        getList(url, name, flag, isLoading, recordFlag) {
            if(this[name].length > 0 && recordFlag) return;

            this[isLoading] = true

            this.api.get(`${this.apiUrl}/${url}`)
            .then(res => {
                this[isLoading] = false
                this[name] = flag ? res.data.filter(f => !f.IsHidden) : res.data
            })
        },
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
		onEditingStart(row) {
            this.getList(`api/analysispatient/getotherbloodtestsunits/${row.parameters_id}`, 'eiItems', true, 'isLoadingEI', false)
            this.getList('api/analysispatient/getotherbloodtestsanalysis', 'analysesItems', false, 'isLoadingA', true)

			row.date = this.toJSONLocal(row.date)
			this.editeRow = true
			this.obj = Object.assign({}, row)
			
			this.dialog = true
		},
		onRowUpdating(obj) {
			this.api.put(`${this.apiUrl}/api/patients/${this.patient.patients_id}/other_blood_tests/${obj.id}`, {
				analys: typeof obj.analys == 'string' ? obj.analys : obj.analys.name,
				date: new Date(obj.date).getTime(),
				znach: {num: +obj.znach.num, sel: typeof obj.znach.sel == 'string' ? obj.znach.sel : obj.znach.sel.name},
				creation_date: obj.creation_date,
				units_id: typeof obj.znach.sel == 'string' ? obj.units_id : obj.znach.sel.id,
				parameters_id: typeof obj.analys == 'string' ? obj.parameters_id : obj.analys.id
			})
			.then(res => {
				if(res.status == 200) {
					if(res.data.date) res.data.date_new = new Date(res.data.date).toLocaleDateString()
					if(res.data.znach.num && res.data.znach.sel) res.data.zn = `${res.data.znach.num} (${res.data.znach.sel})`

					this.list.forEach(el => {
						if(el.id == res.data.id) Object.assign(el, res.data)
					})

					this.close()
				}
			})
			.catch(error => {
				if(error) this.snackBarFunc(true, error.response.data.Message ? error.response.data.Message : 'Ошибка, обратитесь к Администратору!!!', 'error')
			})
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/patients/${this.patient.patients_id}/other_blood_tests`, true)
	}
};
</script>

<style scoped>

.not_reference {
    color: #ff4500;
}
</style>