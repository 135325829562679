<template>
	<v-container fluid>
		<v-row justify="center" align="center" class="tabs-wrap">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<el-tabs :value="dt" @tab-click="tabClick" class="tabs" type="border-card">
					<el-tab-pane name="1" label="Инструментальные методы исследования">
						<instrumental />
					</el-tab-pane>
					<el-tab-pane name="2" label="Выписка из других медицинских учреждений">
						<statements />
					</el-tab-pane>
					<el-tab-pane name="3" label="Туберкулиновые пробы">
						<tuberculin-tests />
					</el-tab-pane>
				</el-tabs>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import instrumental from './instrumental/index.vue';
import statements from './statements/tableEXTRACT.vue';
import tuberculinTests from './tuberculinTests/table.vue';

export default {
	components: { instrumental, statements, tuberculinTests },
	computed: {
        dt(){
            return localStorage.dt || '1'
        }
	},
    methods: {
        tabClick(e){
            if(e.name == 1) {
                localStorage.setItem('dt', '1');
            } else if(e.name == 2){
                localStorage.setItem('dt', '2');
            } else if(e.name == 3){
                localStorage.setItem('dt', '3');
            }
        }
    },
}

</script>
