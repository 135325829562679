<template>
    <v-row>
        <v-col xs="12" sm="12" md="12" lg="12" xl="12">
            <v-row>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <el-popover
                        style="text-align: center;"
                        placement="bottom-end"
                        width="390"
                        trigger="manual"
                        v-model="visible"
                    >
                        <span @click="visible = false" style="font-size: 17px;float: right;color: black;cursor: pointer;">X</span>
                        <div v-loading="uploading_video" class="settings-form add-popup setting-video-form">
                            <span>Выберите дату видео</span>
                            <el-date-picker
                            style="width:50%"
                            v-model="datedownpic"
                            format="dd.MM.yyyy"
                            value-format="timestamp"
                            type="date"
                            placeholder="Выберите дату"
                            :validationRules="{ type: 'required',message: 'Заполните поле!'}"
                            :picker-options="pickerOptions">
                            </el-date-picker>
                            <span :class="{'not-valid-text ': fileNotValid}" style="margin-bottom:0px;margin-top:10px">Загрузите видео в формате</span>
                            <span :class="{'not-valid-text ': fileNotValid}">(.mp4, .webm)</span>
                            
                            <dx-file-uploader
                            :ref="'uploaderFiles'"
                            :allowed-file-extensions="listVideoFormat"
                            style="margin:0"
                            width="70%"
                            selectButtonText="Загрузить"
                            labelText=""
                            accept="video/*"
                            uploadMode="useForm"
                            :validationRules="{ type: 'required',message: 'Заполните поле!'}"
                            />
                            <span class="file-info" style="margin-bottom:0px;margin-top:10px;word-break: break-word">Загрузка больше одного видео невозможна</span>

                            <span>Оставить комментарий</span>
                            <dx-text-area
                            height="100"
                            v-model="commentsys"
                            />
                            <center>
                                <el-button style="margin:15px 15px;" @click="uploadfiles" type="primary" icon="el-icon-check">Сохранить введенные данные</el-button>
                            </center>
                        </div>
                        <el-button v-if="user.role !== 2 || checkRole" slot="reference" type="primary" icon="el-icon-plus" @click="visible = !visible" class="def-btn">Добавить видео</el-button>
                    </el-popover>
                </v-col>
                <v-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <video-table />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { DxFileUploader, DxTextArea } from "devextreme-vue";
import VideoTable from '../grid/VideoTable.vue'
import { mapGetters, mapActions } from 'vuex';
import $ from 'jquery';

export default {
    props: ['video'],
    components: {
        DxFileUploader,
        DxTextArea,
        VideoTable
	},
	computed: {
		...mapGetters({
			patient: 'user/patient',
			user: 'user/user',
			joints: 'manifestationOfDisease/joints',
			apiUrl: 'apiUrl'
        }),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
    watch: {
        video(val) {
            if(val) this.visible = false
        }
    },
	methods: {
		...mapActions({
			getAllJointPhoto: 'manifestationOfDisease/getAllJointPhoto',
			getJoints: 'manifestationOfDisease/getJoints'
		}),
		uploadfiles: function() {
			let filelist = $(".setting-video-form .dx-fileuploader-input");
            let formData = new FormData();
			if (!filelist[0].files[0]) {
				this.fileNotValid = true;
				return;
            }
            this.fileValidFormat = false;
            this.listVideoFormat.forEach(item => {
                if(filelist[0].files[0].name.search(item) > -1){
                    this.fileValidFormat = true;
                }
            })
            if(this.fileValidFormat === false)
                return;
            this.uploading_video = true;
			formData.append('file', filelist[0].files[0]);
			this.api.post(this.apiUrl + '/files/send', formData, {
            headers: {
                'Content-type': 'multipart/form-data',
            }
			}).then(r => { //console.log('res', r)
				let picture = {
					id: r.data.id,
					patients_id: this.patient.patients_id,
					date: this.datedownpic,
					joints_id: this.joints[0].id,
                    comments: this.commentsys,
                    creation_date: new Date().getTime()
				};
				this.api.post(this.apiUrl + '/api/patients/' + this.patient.patients_id + '/pictures', picture).then(r => {
					this.getAllJointPhoto('videos');
                    this.visible = false; 
                    this.uploading_video = false;
                    this.commentsys = ''
                    this.datedownpic = new Date().getTime()
                    this.$refs['uploaderFiles'].instance.reset()
				})
			}).catch(e => {
				this.uploading_video = false;
			});
        },
	},
    data() {
        return {
            visible: false,
            fileNotValid: false,
            fileValidFormat: false,
            datedownpic: new Date().getTime(),
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            commentsys: '',
            ops: {
                bar: {
                    background: '#6374DD',
                }
            },
            listVideoFormat:[
                '.webm','.mp4','.mov'
            ],
            uploading_video: false
        }
	}
}
</script>
