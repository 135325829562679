<template>
	<v-card>
		<v-card-title>
			<v-btn v-if="user.role !== 2" @click="initNewRow(obj)" color="#6374dd" dark class="ml-4">Добавить анализ</v-btn>
			
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:search="search"
			:items="list"
			:loading="loading"
			sort-by="date_new"
        	:sort-desc="true"
			no-data-text="Клинический анализ крови отсутствует..."
			loading-text="Загрузка... Пожалуйста подождите..."
		>	

			<!-- кнопки редактирование/просмотра/удаления -->
			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="isEditEnabled(user, item)" @click="onEditingStart(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn v-if="isDeleteEnabled(user, item)" @click="deleteItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>
			
			<template v-for="v in headers.filter(x=> x.isRedEnable === true)" v-slot:[`item.${v.value}`]="{ item }">
				<div :key="v.value">
					<span v-if="item[`${v.value}norma`] == false" style="color: red">{{ item[v.value] }} {{ item[v.value] == null ? "" : item[`${v.value}Unit`] }}</span>
					<span v-else>{{ item[v.value] }} {{ item[v.value] == null ? "" : item[`${v.value}Unit`] }}</span>
				</div>				
			</template>

			<template v-slot:[`item.date_new`]="{ item }">
				<span>{{ new Date(item.date_new).toLocaleDateString("Ru-ru") }}</span>
			</template>
		</v-data-table>

		<clinicalBloodCountModal v-if="dialog" :isCreate="!editeRow" :itemRow="obj" :list="list" />

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
//остальное
import { mapGetters, mapMutations, mapActions } from 'vuex';
import clinicalBloodCountModal from '../modal/ClinicalBloodCountModal.vue'


export default {
	components: { clinicalBloodCountModal },
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			user: 'user/user',
			patient: 'user/patient',
			dialog: 'analyses/clinical_blood_countModal'
        }),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
	data() {
		return {
			editeRow: false,
			search: '',							
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {},		
			list: [],
			loading: true,			
			headers: [
				{ text: "Дата", value: "date_new", align: 'center', isDate: true },
				{ text: "Эритроциты", value: "Erythrocytes", align: 'center', sortable: false, isRedEnable: true },
				{ text: "Тромбоциты", value: "Thrombocytes", align: 'center', sortable: false, isRedEnable: true },
				{ text: "Гемоглобин", value: "Hemoglobin", align: 'center', sortable: false, isRedEnable: true },
				{ text: "СОЭ", value: "SOE", align: 'center', sortable: false, isRedEnable: true },
				{ text: "Лейкоциты", value: "Leukocytes", align: 'center', sortable: false, isRedEnable: true },
				{ text: "Нейтрофилы", value: "Neutrophils", align: 'center', sortable: false, isRedEnable: true },
				{ text: "Лимфоциты", value: "Lymphocytes", align: 'center', sortable: false, isRedEnable: true },
				{ text: "Моноциты", value: "Monocytes", align: 'center', sortable: false, isRedEnable: true },
				{ text: "Базофилы", value: "Basophils", align: 'center', sortable: false, isRedEnable: true },
				{ text: "Эозинофилы", value: "Eosinophils", align: 'center', sortable: false, isRedEnable: true },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		...mapActions({
            updateclinical_blood_countModal: 'analyses/updateclinical_blood_countModal'
        }),		
		async apiMethods(url, name) {						
			return (await this.api.get(`${url}/${name}`)).data
		},
		isDeleteEnabled(user, item) {
			return user.role !== 2 && ( (new Date().getTime() - item.creation_date) < 86400000 )
		},
		isEditEnabled(user, item) {
			return this.$route.query.myPatient == 'false' ? user.CanRead : (this.$route.query.myPatient == 'true' ? true : (user.role !== 2 && ( (new Date().getTime() - item.creation_date) < 86400000 ) ))
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/analysispatient/DeleteClinicalBloodCount/${this.patient.patients_id}/${row.date_new}`)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)

						this.snackBarFunc(true, 'Запись успешно удалена!!!', 'success')
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},		
		initNewRow(obj) {
			this.editeRow = false
			this.updateclinical_blood_countModal(true)
		},
		onEditingStart(row) {			
			this.editeRow = true
			Object.assign(this.obj, row)
			this.updateclinical_blood_countModal(true)
		},	
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/analysispatient/getClinicalBloodCount?patientId=${this.patient.patients_id}`)		
		this.loading = false
	}
};
</script>

<style scoped>

.not_reference {
    color: #ff4500;
}
</style>