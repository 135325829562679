export default {
    namespaced: true,
    state: {
        reability: [],
        listMedPreparat: [],
        medicines_received_patient: [],
        adverse_effects_drugs: [],
        ish: [
            {id:1, name:'Продолжение'},
            {id:2, name:'Выздоровление'},
            {id:3, name:'Переход в хроническую форму'},
            {id:4, name:'Оперативное вмешательство'},
            {id:5, name:'Госпитализация'}
        ],
        diagnosys_select: [
            {id:1, name:'Аллергическая реакция'},
            {id:2, name:'Местная кожная реакция (в месте введения препарата)'},
            {id:3, name:'Острый ринит'},
            {id:4, name:'Острый гайморит'},
            {id:5, name:'Острый отит'},
            {id:6, name:'Обострение хронического аденоидита'},
            {id:7, name:'Обострение хронического тонзиллита'},
            {id:8, name:'Острая пневмония'},
            {id:9, name:'Острый бронхит'},
            {id:10, name:'Туберкулезная инфекция'},
            {id:11, name:'Герпетическая инфекция'},
            {id:12, name:'Мочевая инфекция'},
            {id:13, name:'Кишечная инфекция'},
            {id:14, name:'Вирусный гепатит'},
            {id:15, name:'Токсический гепатит'},
            {id:16, name:'Тошнота'},
            {id:17, name:'Рвота'},
            {id:18, name:'Боли в животе'},
            {id:19, name:'Боли в мышцах'},
            {id:20, name:'Мышечная слабость'},
            {id:21, name:'Снижение аппетита'},
            {id:22, name:'Снижение массы тела'},
            {id:23, name:'Ожирение'},
            {id:24, name:'Повышение температуры тела'},
            {id:25, name:'Головная боль'},
            {id:26, name:'Головокружение'},
            {id:27, name:'Перелом костей'},
            {id:28, name:'Повышение артериального давления'},
            {id:29, name:'Боль в месте инъекции'},
            {id:30, name:'Другое (укажите в комментарии)'},
        ],
        pv: [
            {id:1, name:'Внутрь'},
            {id:2, name:'Подкожный'},
            {id:3, name:'Внутрикожный'},
            {id:4, name:'Внутримышечный'},
            {id:5, name:'Внутривенный'},
            {id:6, name:'Ректальный (свечи, клизмы)'},
            {id:7, name:'Наружный'},
            {id:8, name:'Глазной'},
        ],
        oblek: [
            {id:1, name:'Получение за счет ОНЛС (по инвалидности)'},
            {id:2, name:'По программе 7 нозологий'},
            {id:3, name:'Самостоятельное приобретение'},
            {id:4, name:'За счет благотворительного фонда'}
        ],
        kr: [
            {id:1, name:'1 раз в день'},
            {id:2, name:'2 раза в день'},
            {id:3, name:'3 раза в день'},
            {id:4, name:'4 раза в день'},
            {id:5, name:'5-6 раз в день'},
            {id:6, name:'1 раз в неделю'},
            {id:7, name:'2 раза в неделю'},
            {id:8, name:'3 раза в неделю'},
            {id:9, name:'1 раз в 2 недели'},
            {id:10, name:'1 раз в 3 недели'},
            {id:11, name:'1 раз в 4 недели'},
            {id:12, name:'1 раз в 5 недель'},
            {id:13, name:'1 раз в 6 недель'},
            {id:14, name:'1 раз в 7 недель'},
            {id:15, name:'1 раз в 8 недель'},
            {id:16, name:'1 раз в 9 недель'},
            {id:17, name:'1 раз в 10 недель'},
            {id:18, name:'1 раз в 11 недель'},
            {id:19, name:'1 раз в 12 недель'},
            {id:20, name:'1 раз в месяц'},
            {id:21, name:'1 раз в 6 месяцев'},
            {id:22, name:'1 раз в год'},
        ],
        tlf : [
            {id:1, name:'Таблетки'},
            {id:2, name:'Капсулы'},
            {id:3, name:'Сироп'},
            {id:4, name:'Суспензия для приема внутрь'},
            {id:5, name:'Раствор для подкожного введения'},
            {id:6, name:'Раствор для внутримышечного введения'},
            {id:7, name:'Раствор для внутривенного введения'}
        ],
        ei: [
            {id:1, name:'Г.'},
            {id:2, name:'Мг.'},
            {id:3, name:'Мкг.'},
            {id:4, name:'не указана'},
            {id:5, name:'шт.'},
        ],
    },
    getters: {
        reability: state => state.reability,
        listMedPreparat: state => state.listMedPreparat,
        medicinesReceivedByThePatient: state => state.medicines_received_patient,
        adverseEffectsOfDrugs: state => state.adverse_effects_drugs,
        ish: state => state.ish,
        diagnosysselect: state => state.diagnosys_select,
        pv: state => state.pv,
        oblek: state => state.oblek,
        kr: state => state.kr,
        tlf: state => state.tlf,
        ei: state => state.ei,
    },
    mutations: {
        //Лекарственные препараты
        //Лекарственные препараты
        //Лекарственные препараты
        setMedRec(state, payload) {
            state.medicines_received_patient = payload
        },
        setCreateMedRec(state, payload) {
            state.medicines_received_patient.push(payload)
        },
        setLineUpdateMedRec(state, payload) {
            state.medicines_received_patient.forEach(el => {
                if(el.id === payload.id){
                    el.dnp = payload.dnp
                    el.dop = {
                        date: payload.dop.date,
                        checkbox: payload.dop.checkbox
                    }
                    el.ei = payload.ei
                    el.krat = payload.krat
                    el.mnn = payload.mnn
                    el.obesplek = payload.obesplek
                    el.pop = payload.pop
                    el.pv = payload.pv
                    el.srd = payload.srd
                    el.tlf = payload.tlf
                    el.tnp = payload.tnp
                    el.creation_date = payload.creation_date
                }
            })
        },
        setListMedPreparat(state, payload){
            state.listMedPreparat = payload.map(el => el.Name)
        },
        //Нежелательные явления
        //Нежелательные явления
        //Нежелательные явления
        setAdvEff(state, payload) {
            state.adverse_effects_drugs = payload
        },
        setCreateAdvEff(state, payload) {
            state.adverse_effects_drugs.push(payload)
        },
        setLineUpdateAdvEff(state, payload) {
            state.adverse_effects_drugs.comment = payload.comment
            state.adverse_effects_drugs.date = payload.date
            state.adverse_effects_drugs.ny = payload.ny
            state.adverse_effects_drugs.treat_out = payload.treat_out
        },
        //Реабилитация
        //Реабилитация
        //Реабилитация
        setRehabil(state, payload) {
            state.reability = payload
        },
        setCreateRehabil(state, payload) {
            state.reability.push(payload)
        },
        setLineUpdateRehabil(state, payload) {
            state.reability.date_start = payload.date_start
            state.reability.date_end = payload.date_end
            state.reability.type_rehabil = payload.type_rehabil
        },
    },
    actions: {
        //Лекарственные препараты
        //Лекарственные препараты
        //Лекарственные препараты
        getMedRec({commit, rootState}) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/api/patients/${id}/treatments`).then(res => {
				commit('setMedRec', res.data);
			})
        },
        createMedRec({commit, rootState}, payload) {
            //console.log(payload)
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/treatments`, payload).then(res => {
                commit('setCreateMedRec', res.data)
			})
        },
        deleteMedRec({rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/treatments/${payload.id}`, payload);
        },
        lineUpdateMedRec({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.put(`${rootState.apiUrl}/api/patients/${id}/treatments/${payload.id}`, payload).then(res => {
                commit('setLineUpdateMedRec', res.data)
			})
        },
        getListMedPreparat({commit, rootState}){
            this.api.get(`${rootState.apiUrl}/api/drugs`).then(res => {
                commit('setListMedPreparat', res.data)
            })
        },
        //Нежелательные явления
        //Нежелательные явления
        //Нежелательные явления
        getAdvEff({commit, rootState}) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/api/patients/${id}/side_effects`).then(res => {
				commit('setAdvEff', res.data);
			})
        },
        createAdvEff({commit, rootState}, payload) {
            //console.log(payload)
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/side_effects`, payload).then(res => {
                commit('setCreateAdvEff', res.data)
			})
        },
        deleteAdvEff({rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/side_effects/${payload.id}`, payload);
        },
        lineUpdateAdvEff({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.put(`${rootState.apiUrl}/api/patients/${id}/side_effects/${payload.id}`, payload).then(res => {
                commit('setLineUpdateAdvEff', res.data)
			})
        },
        //Реабилитация
        //Реабилитация
        //Реабилитация
        getRehabil({commit, rootState}) {
			let id = rootState.user.patient.patients_id;
			this.api.get(`${rootState.apiUrl}/api/patients/${id}/rehabilitations`).then(res => {
				commit('setRehabil', res.data);
			})
        },
        createRehabil({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.post(`${rootState.apiUrl}/api/patients/${id}/rehabilitations`, payload).then(res => {
                commit('setCreateRehabil', res.data)
			})
        },
        deleteRehabil({rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.delete(`${rootState.apiUrl}/api/patients/${id}/rehabilitations/${payload.id}`, payload);
        },
        lineUpdateRehabil({commit, rootState}, payload) {
			let id = rootState.user.patient.patients_id;
			this.api.put(`${rootState.apiUrl}/api/patients/${id}/rehabilitations/${payload.id}`, payload).then(res => {
                commit('setLineUpdateRehabil', res.data)
			})
        },
    }
}