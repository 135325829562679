import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueTheMask from 'vue-the-mask'
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';
import vuescroll from 'vuescroll';
import 'vuescroll/dist/vuescroll.css';
import axios from 'axios';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import VueImg from 'v-img';

// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.directive("filter", {
    bind: (el, binding) => {
        this.inputHandler = (e) => {
            var ch = String.fromCharCode(e.which);
            var re = new RegExp(binding.value);
            if (!ch.match(re)) {
                e.preventDefault();
            }
        };
        el.addEventListener("keypress", this.inputHandler);
    },
    unbind: (el) => {
        el.removeEventListener("keypress", this.inputHandler);
    },
    inputHandler: null
});

Vue.use(VueImg);
Vue.use(ElementUI, { locale })
Vue.use(vuescroll);
Vue.use(VueTheMask)

axios.defaults.withCredentials = true

Vue.prototype.api = store.api = axios.create()

store.api.interceptors.response.use(res => {
	return res
}, (error) => {
	if (error.response.status === 401 || error.response.status === 400) {
		store._actions['user/logout'][0]()
		router.replace('/')
	} else if(error.response.status === 500) {
        console.log('status 500 auth')
    }
	return Promise.reject(error)
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')