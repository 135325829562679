<template>
    <div id="osn">
        <div class="otst">
             <el-row class="preview-header">
                <h3>Данные о пациенте</h3>
                <el-button id="pp_1" v-if="this.disabledbtn === true && checkRole" @click="disabledpatdata" type="primary" icon="el-icon-edit" circle></el-button>
                <el-button id="pp_2" v-if="this.disabledbtn === false" @click="submit_patient_data('patient_data')" type="primary">Сохранить</el-button> 
            </el-row>

            <el-row class="preview" v-if="this.disabledbtn === true && patient_data">
                <el-col class="fio">
                    {{ checkRolePI ? (patient_data.last_name ? hideData(patient_data.last_name) : '') : (patient_data.last_name || '') }}
                    {{ patient_data.first_name || '' }}
                    {{ checkRolePI ? (patient_data.patronymic ? hideData(patient_data.patronymic) : '') : (patient_data.patronymic || '') }}
                </el-col>
                <el-col>{{ new Date(toMilliSeconds(patient_data.birth_date)).toLocaleDateString("ru-RU") }} ({{getFullYear(toMilliSeconds(patient_data.birth_date))}})</el-col>
                <el-col>Пол: {{ patient_data.gender !== null ? patient_data.gender : '-' }}</el-col>
                <el-col>
                    Инвалидность: 
                    {{ patient_data.invalid === 1 ? 'Да' : ''  }}
                    {{ patient_data.invalid === 2 || patient_data.invalid === 0 ? 'Нет' : ''  }}
                </el-col>
                <el-col v-if="patient_data.invalid === 2">
                    Причина: 
                    {{ this.patient_data.not_invalid_reason === 1 ? 'Сняли' : ''}}
                    {{ this.patient_data.not_invalid_reason === 2 ? 'Отказали' : ''}}
                    {{ this.patient_data.not_invalid_reason === 3 ? 'Не подавали документы' : ''}}
                </el-col>

                <el-col>Регион: {{ patient_data.regionName !== null ? patient_data.regionName : '-' }}</el-col>
                <el-col>Организация: {{ patient_data.hospitalName !== null ? patient_data.hospitalName : '-' }}</el-col>

                <el-col class="address" v-if="patient_data.address !== ''">Адрес: {{ patient_data.address !== null ? patient_data.address : '-' }}</el-col>
            </el-row>

            <el-form v-if="this.disabledbtn === false && patient_data" :label-position="labelPosition" :model="patient_data" :rules="patient_data_val" ref="patient_data" class="demo-ruleForm">  
                <el-form-item label="Фамилия" prop="last_name">
                    <el-input id="pp_3" :disabled="disabledbtn" clearable v-model="patient_data.last_name"></el-input>
                </el-form-item>
                <el-form-item label="Имя" prop="first_name">
                    <el-input id="pp_4" :disabled="disabledbtn" clearable v-model="patient_data.first_name"></el-input>
                </el-form-item>
                <el-form-item label="Отчество" prop="patronymic">
                    <el-input id="pp_5" :disabled="disabledbtn" clearable v-model="patient_data.patronymic"></el-input>
                </el-form-item>
                <el-form-item label="Выберите пол" prop="gender">
                    <el-select id="pp_6" :disabled="disabledbtn" v-model="patient_data.gender" placeholder="Пол">
                        <el-option label="Мужчина" value="Мужчина"></el-option>
                        <el-option label="Женщина" value="Женщина"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item prop="birth_date" label="Дата рождения" required>
                    <el-date-picker id="pp_7" :disabled="disabledbtn" :picker-options="pickerOptionsDate" value-format="timestamp" format="dd.MM.yyyy" type="date" placeholder="Выбрать дату" v-model="patient_data.birth_date"></el-date-picker>
                </el-form-item>
                <el-form-item label="Адрес проживания" required>
                    <el-form-item prop="address">
                        <el-autocomplete
                            id="pp_8"
                            :disabled="disabledbtn"
                            clearable
                            class="inline-input"
                            v-model="patient_data.address"
                            :fetch-suggestions="querySearch"
                            placeholder="Введите адрес"
                            :trigger-on-focus="false"
                            />
                    </el-form-item>
                </el-form-item>
                <el-form-item prop="invalid" label="Инвалидность" class="no-margin-bottom">
                    <el-radio-group id="pp_9" :disabled="disabledbtn" v-model="patient_data.invalid">
                        <el-radio :label="1">Да</el-radio>
                        <el-radio :label="2">Нет</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item prop="not_invalid_reason" v-if="patient_data.invalid === 2" label="Укажите причину:" required>
                    <el-radio-group id="pp_10" :disabled="disabledbtn" v-model="patient_data.not_invalid_reason">
                        <el-radio :label="1">Сняли</el-radio>
                        <el-radio :label="2">Отказали</el-radio>
                        <el-radio :label="3">Не подавали документы</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="Регион">
                    <el-input readonly v-model="patient_data.regionName"></el-input>
                </el-form-item>

                <el-form-item label="Организация">
                    <el-input readonly v-model="patient_data.hospitalName"></el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
    },
    data() {
        return {
            pickerOptionsDate: {
                disabledDate(time) {
                    let min = time.getTime() < new Date('1989-12-31').getTime();
					let max = time.getTime() > Date.now()
                    
                    return min || max
                }
            },
            errorList: null,
            labelPosition: 'left',
            disabledbtn: true,
            links: [],
            fullYear: '',
            patient_data_val: {
                last_name: [
                    { required: true, message: 'Пожалуйста заполните Фамилию пациента', trigger: 'blur' },
                    { min: 3, message: 'Длина должна быть не менее 3 символов', trigger: 'blur' }
                ],
                first_name: [
                    { required: true, message: 'Пожалуйста заполните Имя пациента', trigger: 'blur' },
                    { min: 3, message: 'Длина должна быть не менее 3 символов', trigger: 'blur' }
                ],
                patronymic: [
                    { required: true, message: 'Пожалуйста заполните Отчество пациента', trigger: 'blur' },
                    { min: 3, message: 'Длина должна быть не менее 3 символов', trigger: 'blur' }
                ],
                gender: [
                    { required: true, message: 'Пожалуйста выберите пол', trigger: 'change' }
                ],
                address: [
                    { required: true, message: 'Пожалуйста заполните поле Адрес', trigger: 'blur' },
                    { min: 5, message: 'Длина должна быть не менее 5 символов', trigger: 'blur' }
                ],
                birth_date: [
                    { type: 'date', required: true, message: 'Пожалуйста выберите Дату рождения', trigger: 'change' }
                ],
                invalid: [
                    { required: true, message: 'Пожалуйста выберите значение', trigger: 'change' }
                ],
                not_invalid_reason: [
                    { required: true, message: 'Пожалуйста выберите одно значение', trigger: 'change' }
                ],
            },
            patient_id: null,
        }
	},
	computed: {
		...mapGetters({
            patient_data: 'passportPart/patientData',
			uss: 'user/user'
		}),
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.uss.CanRead : (this.$route.query.myPatient == 'true' ? true : this.uss.role !== 2)
        },
        checkRolePI() {
            return this.$route.query.myPatient == 'false' ? !this.uss.PersonalInformation : false
        }
	},
    methods: {
		...mapActions({
            getPatData(dispatch){
                return dispatch('passportPart/getPatData')
            },
            savPatData(dispatch) {
               dispatch('passportPart/savPatData', this.patient_data)
            },
            errorPasport(dispatch) {
                dispatch('user/errorPasport', this.errorList)
            }
        }),
        toMilliSeconds(d)
        {
            if (d.toString().length <= 10)
                return d*1000;
            return d;
        },
        getFullYear(birthData){
            let diff = new Date()-birthData
            let age = Math.floor(diff/31557600000);
            let txt;
            let count = age % 100;
            if (count >= 5 && count <= 20) {
                txt = 'лет';
            } else {
                count = count % 10;
                if (count == 1) {
                    txt = 'год';
                } else if (count >= 2 && count <= 4) {
                    txt = 'года';
                } else {
                    txt = 'лет';
                }
            }
            return age+" "+txt;
        },
        submit_patient_data(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                this.savPatData();
                this.disabledbtn = true
                this.$message({
                    message: 'Данные успешно сохранены!',
                    type: 'success'
                });
            } else {
                this.$message.error('Заполните обязательные поля!!!');
                return false;
            }
            });
        },
        querySearch(queryString, cb) {
            var links = this.links;
            var results = queryString ? links.filter(this.createFilter(queryString)) : links;
            cb(results);
        },
        createFilter(queryString) {
            return (link) => {
            return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        loadAll() {
            return [
            { "value": "Нахимовский проспект" },
            { "value": "Улица Ленина" },
            { "value": "Улица Мира" },
            { "value": "Ленина" },
            { "value": "Мира" },
            ];
        },
        disabledpatdata: function() {
            this.disabledbtn = false
        },
        hideData(str) {
            let res = ''

            for (let i = 0; i < str.length; i++) {
                res += '*'
            }

            return res
        }
    },
    created() {
        this.links = this.loadAll();
        this.getPatData()
        .then((res) => {
            // if(res.data === null) {
            //     this.$router.replace('/')
            //     this.$message({
            //         message: 'Ошибка, произошла системная ошибка.',
            //         type: 'error'
            //     });
            //     this.$notify.error({
            //         title: 'Ошибка соединения',
            //         message: 'Обратитесь в службу технической поддержки!',
            //         duration: 0
            //     });
            // }
        })
        .catch(error => {
            if(error) {
                this.errorList = error.response.status
                this.errorPasport();
            }
            if(error.response.status === 401) {
                this.$router.replace('/')
            }
        })
    }
}
</script>
