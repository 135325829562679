<template>
	<v-container fluid>
		<v-row justify="center" align="center">
			<v-col class="" xs="12" sm="12" md="12" lg="12" xl="12">

                <v-tabs v-model="tab" background-color="#5D4CB8" dark icons-and-text >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab v-for="item in arrayTabs" :key="item.id" :href="'#tab-'+item.id">
                        {{item.name}}
                    </v-tab>
                    <v-tab href="#tab-1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="pb-1 cursor-pointer">mdi-help-rhombus</v-icon>
                            </template>
                            <span>
                                Установив в фильтрах необходимые параметры и кликнув на кнопку "Сформировать отчет",
                                <br>
                                Система отобразит список пациентов, подходящих заданным условиям
                            </span>
                        </v-tooltip>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <!-- КОНСТРУКТОР ОТЧЕТОВ -->
                    <v-tab-item value="tab-1" >
                        <v-card flat class="mt-2 ml-3">
                            <!-- <v-row  align="center"> -->
			                    <!-- <v-col md="4">
                                    <v-select color="#5D4CB8" dense hide-details :items="[]" label="Шаблон" outlined />
                                </v-col> -->
			                    <!-- <v-col md="12"> -->
                                    <v-row  align="center">
                                        <v-col md="12" class="mb-0 pb-0">
                                            <h2 class="font-weight-medium">Данные за какой период необходимо учитывать</h2>
                                        </v-col>
			                            <v-col md="auto">
                                            <v-radio-group @change="changePeriodDate(row)" class="mb-6" hide-details v-model="row" row>
                                                <v-radio color="#5D4CB8" label="За последнюю неделю" :value="1" />
                                                <v-radio color="#5D4CB8" label="За последние три месяца" :value="2" />
                                                <v-radio color="#5D4CB8" label="Дата" :value="3" />
                                            </v-radio-group>
                                        </v-col>
			                            <v-col md="2">
                                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field color="#5D4CB8" :disabled="row !== 3" placeholder="Выбрать дату от - до" hide-details dense outlined v-model="dateFormatted" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                                </template>

                                                <v-date-picker
                                                    min="1950-01-01"
                                                    color="#5D4CB8"
                                                    :first-day-of-week="1"
                                                    range
                                                    v-model="dateRange"
                                                    @change="(date) => { $refs.menu.save(date) }"
                                                />
                                            </v-menu>
                                        </v-col>
                                        <v-col md="auto">
                                            <v-btn @click="getReport(row !== 3 ? [datefrom, dateto] : dateRange, generalInfo, anamnez, familyAnamnez, inspections, analysis, instrumentalResearch, scale)" dark color="#5D4CB8">Построить отчет</v-btn>
                                        </v-col>
                                        <v-col md="auto">
                                            <v-btn dark @click="resetFilter" color="#5D4CB8">Сбросить фильтр</v-btn>
                                        </v-col>
                                        <v-col md="auto">
                                            <v-tooltip top color="warning">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn :loading="loadingExcel" v-on="list.length == 0 ? on : ''" v-bind="attrs" @click="exportToExcel(list)" color="warning">Выгрузить в Excel</v-btn>
                                                </template>
                                                <span>Нужно построить отчет, прежде чем его выгрузить в Excel</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                <!-- </v-col> -->
                            <!-- </v-row> -->
                        </v-card>

                        <v-expansion-panels focusable light v-model="panel" multiple class="pl-1 pr-1 pb-1">
                            <!-- Общая информация -->
                            <v-expansion-panel >
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">Общая информация</v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
			                            <v-col sm="12" md="12" v-if="!doctor">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/doctors', 'itemsDoctors', true, 'isLoadingD')"
                                                :loading="isLoadingD"
                                                v-model="generalInfo.doctors"
                                                item-text="fio"
                                                item-value="Id"
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                small-chips
                                                hide-details
                                                dense
                                                :items="itemsDoctors"
                                                label="Врач"
                                                outlined
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                        small
                                                        v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="remove('generalInfo', 'doctors', data.item, 'Id')"
                                                    >
                                                        {{ data.item.fio }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
			                            <v-col sm="12" md="3">
                                            <v-row>
                                                <v-col md="auto">
                                                    <legend class="v-label theme--light pt-3">Пол:</legend>
                                                </v-col>
			                                    <v-col sm="12" md="auto">
                                                    <v-checkbox color="#5D4CB8" dense hide-details v-model="generalInfo.gender" label="Мужчина" value="Мужчина" />
                                                </v-col>
			                                    <v-col sm="12" md="auto">
                                                    <v-checkbox color="#5D4CB8" dense hide-details v-model="generalInfo.gender" label="Женщина" value="Женщина"  />
                                                </v-col>
                                            </v-row>
                                        </v-col>
			                            <v-col sm="12" md="4" class="pt-1">
                                            <v-radio-group label="Инвалидность:" class="pt-0" dense hide-details v-model="generalInfo.invalid" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" />
                                                <v-radio color="#5D4CB8" label="нет" :value="0" />
                                            </v-radio-group>
                                        </v-col>
                                        <v-col sm="12" md="12">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/hospitals', 'itemsHospitals', null, 'isLoadingH')"
                                                :loading="isLoadingH"
                                                v-model="generalInfo.hospitalids"
                                                item-text="Name"
                                                item-value="id"
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                small-chips
                                                hide-details
                                                dense
                                                :items="itemsHospitals"
                                                label="Организация"
                                                outlined
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                        small
                                                        v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="remove('generalInfo', 'hospitalids', data.item, 'id')"
                                                    >
                                                        {{ data.item.Name }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="12" md="12">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/regions', 'itemsRegions', null, 'isLoadingR')"
                                                :loading="isLoadingR"
                                                v-model="generalInfo.regionids"
                                                item-text="Name"
                                                item-value="id"
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                small-chips
                                                hide-details
                                                dense
                                                :items="itemsRegions"
                                                label="Регион"
                                                outlined
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                        small
                                                        v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="remove('generalInfo', 'regionids', data.item, 'id')"
                                                    >
                                                        {{ data.item.Name }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="12" md="12">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/diagnoses', 'itemsDiagnoses', true, 'isLoadingDiag')"
                                                :loading="isLoadingDiag"
                                                v-model="generalInfo.diagnoseids"
                                                item-text="name"
                                                item-value="id"
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                small-chips
                                                hide-details
                                                dense
                                                :items="itemsDiagnoses"
                                                label="Диагноз"
                                                outlined
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                        small
                                                        v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="remove('generalInfo', 'diagnoseids', data.item, 'id')"
                                                    >
                                                        {{ data.item.name }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            
                            <!-- Анамнез -->
                            <v-expansion-panel>
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">Анамнез</v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
                                        <v-col sm="12" md="12" class="pt-0">
                                            <v-radio-group label="Появление первых симптомов:" class="pt-0" dense hide-details v-model="anamnez.date_disease" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('anamnez', 'date_disease', anamnez.date_disease)" />
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('anamnez', 'date_disease', anamnez.date_disease)" />
                                            </v-radio-group>
                                        </v-col>
                                        <v-col sm="12" md="12" class="pt-0">
                                            <v-radio-group label="Постановка диагноза:" class="pt-0" dense hide-details v-model="anamnez.date_diagnosis" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('anamnez', 'date_diagnosis', anamnez.date_diagnosis)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('anamnez', 'date_diagnosis', anamnez.date_diagnosis)"/>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col sm="12" md="12" class="pt-0">
                                            <v-radio-group label="Сопутствующие заболевания:" class="pt-0" dense hide-details v-model="anamnez.concomitantdiagnos" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('anamnez', 'concomitantdiagnos', anamnez.concomitantdiagnos)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('anamnez', 'concomitantdiagnos', anamnez.concomitantdiagnos)"/>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            
                            <!-- Семейный анамнез -->
                            <v-expansion-panel>
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">
                                    <v-row>
                                        <v-col align-self="center" md="auto" class="pt-0 pb-0">
                                            Семейный анамнез
                                        </v-col>
                                        <v-col md="auto" class="pt-0 pb-0">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-bind="attrs" v-on="on" class="pb-1 cursor-pointer">mdi-help-rhombus</v-icon>
                                                </template>
                                                <span>Фильтр по наличию заболеваний в семейном анамнезе пациента</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-radio-group label="Артрит:" class="pt-0" dense hide-details v-model="familyAnamnez.radioart" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('familyAnamnez', 'radioart', familyAnamnez.radioart)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('familyAnamnez', 'radioart', familyAnamnez.radioart)"/>
                                            </v-radio-group>
                                            
                                            <v-radio-group label="Псориаз:" class="pt-0" dense hide-details v-model="familyAnamnez.radiopsor" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('familyAnamnez', 'radiopsor', familyAnamnez.radiopsor)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('familyAnamnez', 'radiopsor', familyAnamnez.radiopsor)"/>
                                            </v-radio-group>
                                            
                                            <v-radio-group label="Болезнь Крона:" class="pt-0" dense hide-details v-model="familyAnamnez.radiokron" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('familyAnamnez', 'radiokron', familyAnamnez.radiokron)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('familyAnamnez', 'radiokron', familyAnamnez.radiokron)"/>
                                            </v-radio-group>

                                            <v-radio-group label="Язвенный колит" class="pt-0" dense hide-details v-model="familyAnamnez.radioyazkol" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('familyAnamnez', 'radioyazkol', familyAnamnez.radioyazkol)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('familyAnamnez', 'radioyazkol', familyAnamnez.radioyazkol)"/>
                                            </v-radio-group>
                                        </v-col>

                                        <v-col sm="12" md="6" class="pt-0">
                                            
                                            <v-radio-group label="Болезнь Бехтерева:" class="pt-0" dense hide-details v-model="familyAnamnez.radiobolbeh" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('familyAnamnez', 'radiobolbeh', familyAnamnez.radiobolbeh)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('familyAnamnez', 'radiobolbeh', familyAnamnez.radiobolbeh)"/>
                                            </v-radio-group>

                                            <v-radio-group label="Увеит:" class="pt-0" dense hide-details v-model="familyAnamnez.radiobouveit" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('familyAnamnez', 'radiobouveit', familyAnamnez.radiobouveit)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('familyAnamnez', 'radiobouveit', familyAnamnez.radiobouveit)"/>
                                            </v-radio-group>
                                            
                                            <v-radio-group label="Болезнь Рейтера:" class="pt-0" dense hide-details v-model="familyAnamnez.radiobobolrey" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('familyAnamnez', 'radiobobolrey', familyAnamnez.radiobobolrey)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('familyAnamnez', 'radiobobolrey', familyAnamnez.radiobobolrey)"/>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            
                            <!-- Осмотр -->
                            <v-expansion-panel>
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">Осмотр</v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-radio-group label="Температура:" class="pt-0" dense hide-details v-model="inspections.temp" row>
                                                <v-radio color="#5D4CB8" label="все" :value="0" @mouseup="resetRB('inspections', 'temp', inspections.temp)"/>
                                                <v-radio color="#5D4CB8" label="с отклонениями" :value="1" @mouseup="resetRB('inspections', 'temp', inspections.temp)"/>
                                            </v-radio-group>
                                            
                                            <v-radio-group label="Артериальное давление:" class="pt-0" dense hide-details v-model="inspections.ardav" row>
                                                <v-radio color="#5D4CB8" label="все" :value="0" @mouseup="resetRB('inspections', 'ardav', inspections.ardav)"/>
                                                <v-radio color="#5D4CB8" label="с отклонениями" :value="1" @mouseup="resetRB('inspections', 'ardav', inspections.ardav)"/>
                                            </v-radio-group>

                                            <v-radio-group label="ЧСС:" class="pt-0" dense hide-details v-model="inspections.chss" row>
                                                <v-radio color="#5D4CB8" label="все" :value="0" @mouseup="resetRB('inspections', 'chss', inspections.chss)"/>
                                                <v-radio color="#5D4CB8" label="с отклонениями" :value="1" @mouseup="resetRB('inspections', 'chss', inspections.chss)"/>
                                            </v-radio-group>

                                            <v-row class="mt-2">
                                                <span class="v-label theme--light pl-3 pt-3">Утренняя скованность:</span>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field v-model="inspections.utscovfrom" color="#5D4CB8" type="number" dense hide-details label="От" outlined />
                                                </v-col>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field v-model="inspections.utscovto" color="#5D4CB8" type="number" dense hide-details label="До" outlined />
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-radio-group label="Сыпь:" class="pt-0" dense hide-details v-model="inspections.sip" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="0" @mouseup="resetRB('inspections', 'sip', inspections.sip)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="1" @mouseup="resetRB('inspections', 'sip', inspections.sip)"/>
                                            </v-radio-group>

                                            <v-radio-group label="Увеличенные лимфоузлы:" class="pt-0" dense hide-details v-model="inspections.uvellim" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="0" @mouseup="resetRB('inspections', 'uvellim', inspections.uvellim)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="1" @mouseup="resetRB('inspections', 'uvellim', inspections.uvellim)"/>
                                            </v-radio-group>

                                            <v-row class="mt-2">
                                                <span class="v-label theme--light pl-3 pt-3">Суставной синдром. Количество активных суставов:</span>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="inspections.sussactivartfrom" type="number" dense hide-details label="От" outlined />
                                                </v-col>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="inspections.sussactivartto" type="number" dense hide-details label="До" outlined />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            
                            <!-- Анализы -->
                            <v-expansion-panel>
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">Анализы</v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
                                        <!-- Клинические анализы -->
                                        <v-col sm="12" md="4" class="pt-0">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/clinicalanalysis', 'itemsClinicalAnalysis', null, 'isLoadingCA')"
                                                :loading="isLoadingCA"
                                                @input="addAnalyses($event, 'analysis', 'clinicalAnalysis')"
                                                v-model="analysis.clinicalAnalysis.list"
                                                item-text="Name"
                                                item-value="id"
                                                return-object
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                small-chips
                                                hide-details
                                                dense
                                                :items="itemsClinicalAnalysis"
                                                label="Клинические анализы"
                                                outlined
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                        small
                                                        v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="remove('analysis', 'clinicalAnalysis', data.item, 'id', 'list')"
                                                    >
                                                        {{ data.item.Name }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                            <v-radio-group v-for="(item, ind) in  analysis.clinicalAnalysis.list" :key="ind" :label="item.Name" v-model="analysis.clinicalAnalysis[item.KeyName]" class="pt-0" dense hide-details row>
                                                <v-radio color="#5D4CB8" label="все" :value="1" @mouseup="resetRB('analysis', 'clinicalAnalysis', analysis.clinicalAnalysis[item.KeyName], item.KeyName)"/>
                                                <v-radio color="#5D4CB8" label="с отклонениями" :value="0" @mouseup="resetRB('analysis', 'clinicalAnalysis', analysis.clinicalAnalysis[item.KeyName], item.KeyName)"/>
                                            </v-radio-group>
                                        </v-col>

                                        <!-- Биохимические анализы -->
                                        <v-col sm="12" md="4" class="pt-0">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/bichemicalanalysis', 'itemsBichemicalAnalysis', null, 'isLoadingBA')"
                                                :loading="isLoadingBA"
                                                @input="addAnalyses($event, 'analysis', 'bichemicalAnalysis')"
                                                v-model="analysis.bichemicalAnalysis.list"
                                                item-text="Name"
                                                item-value="id"
                                                return-object
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                small-chips
                                                hide-details
                                                dense
                                                :items="itemsBichemicalAnalysis"
                                                label="Биохимические анализы"
                                                outlined
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                        small
                                                        v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="remove('analysis', 'bichemicalAnalysis', data.item, 'id', 'list')"
                                                    >
                                                        {{ data.item.Name }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                            <v-radio-group v-for="(item, ind) in  analysis.bichemicalAnalysis.list" :key="ind" :label="item.Name" class="pt-0" dense hide-details v-model="analysis.bichemicalAnalysis[item.KeyName]" row>
                                                <v-radio color="#5D4CB8" label="все" :value="1" @mouseup="resetRB('analysis', 'bichemicalAnalysis', analysis.bichemicalAnalysis[item.KeyName], item.KeyName)"/>
                                                <v-radio color="#5D4CB8" label="с отклонениями" :value="0" @mouseup="resetRB('analysis', 'bichemicalAnalysis', analysis.bichemicalAnalysis[item.KeyName], item.KeyName)"/>
                                            </v-radio-group>
                                        </v-col>

                                        <!-- Иммунология -->
                                        <v-col sm="12" md="4" class="pt-0">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/immunology', 'itemsImmunologyAnalysis', null, 'isLoadingIA')"
                                                :loading="isLoadingIA"
                                                @input="addAnalyses($event, 'analysis', 'immunologyAnalysis')"
                                                v-model="analysis.immunologyAnalysis.list"
                                                item-text="Name"
                                                item-value="id"
                                                return-object
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                small-chips
                                                hide-details
                                                dense
                                                :items="itemsImmunologyAnalysis"
                                                label="Иммунология"
                                                outlined
                                            >
                                                <template v-slot:selection="data">
                                                    <v-chip
                                                        small
                                                        v-bind="data.attrs"
                                                        :input-value="data.selected"
                                                        close
                                                        @click="data.select"
                                                        @click:close="remove('analysis', 'immunologyAnalysis', data.item, 'id', 'list')"
                                                    >
                                                        {{ data.item.Name }}
                                                    </v-chip>
                                                </template>
                                            </v-autocomplete>
                                            <v-radio-group v-for="(item, ind) in  analysis.immunologyAnalysis.list" :key="ind" :label="item.Name" class="pt-0" dense hide-details v-model="analysis.immunologyAnalysis[item.KeyName]" row>
                                                <v-radio color="#5D4CB8" label="все" :value="1" @mouseup="resetRB('analysis', 'immunologyAnalysis', analysis.immunologyAnalysis[item.KeyName], item.KeyName)"/>
                                                <v-radio color="#5D4CB8" label="с отклонениями" :value="0" @mouseup="resetRB('analysis', 'immunologyAnalysis', analysis.immunologyAnalysis[item.KeyName], item.KeyName)"/>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <!-- Лекарственные препараты -->
                            <!-- <v-expansion-panel>
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">Лекарственные препараты</v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
                                        <v-col sm="12" md="3" class="pt-0">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/doctors', 'itemsDoctors', true, 'isLoadingD')"
                                                :loading="isLoadingD"
                                                v-model="generalInfo.doctors"
                                                item-text="fio"
                                                item-value="Id"
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                hide-details
                                                dense
                                                :items="itemsDoctors"
                                                label="Врач"
                                                outlined
                                            />
                                        </v-col>
                                    </v-row> 
                                </v-expansion-panel-content>
                            </v-expansion-panel>-->

                            <!-- Нежелательные явления препаратов -->
                            <!-- <v-expansion-panel>
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">Нежелательные явления препаратов</v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/doctors', 'itemsUndesirablePhenomenon', true, 'isLoadingUP')"
                                                :loading="isLoadingUP"
                                                v-model="undesirablePhenomenon.listUndesirablePhenomenon"
                                                item-text="Name"
                                                item-value="id"
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                hide-details
                                                dense
                                                :items="itemsUndesirablePhenomenon"
                                                label="Нежелательное явление"
                                                outlined
                                            />
                                        </v-col>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-autocomplete
                                                color="#5D4CB8"
                                                @focus="getList('api/reportconstructor/doctors', 'itemsTreatmentOutcome', true, 'isLoadingTO')"
                                                :loading="isLoadingTO"
                                                v-model="undesirablePhenomenon.listTreatmentOutcome"
                                                item-text="Name"
                                                item-value="id"
                                                hide-no-data
                                                hide-selected
                                                clearable
                                                multiple
                                                hide-details
                                                dense
                                                :items="itemsTreatmentOutcome"
                                                label="Исход лечения"
                                                outlined
                                            />
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel> -->

                            <!-- Инструментальные исследования -->
                            <v-expansion-panel>
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">Инструментальные исследования</v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-radio-group label="Рентген:" class="pt-0" dense hide-details v-model="instrumentalResearch.rentgen" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('instrumentalResearch', 'rentgen', instrumentalResearch.rentgen)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('instrumentalResearch', 'rentgen', instrumentalResearch.rentgen)"/>
                                            </v-radio-group>
                                            <v-radio-group label="УЗИ:" class="pt-0" dense hide-details v-model="instrumentalResearch.uzi" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('instrumentalResearch', 'uzi', instrumentalResearch.uzi)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('instrumentalResearch', 'uzi', instrumentalResearch.uzi)"/>
                                            </v-radio-group>
                                            <v-radio-group label="КТ:" class="pt-0" dense hide-details v-model="instrumentalResearch.kt" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('instrumentalResearch', 'kt', instrumentalResearch.kt)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('instrumentalResearch', 'kt', instrumentalResearch.kt)"/>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-radio-group label="МРТ:" class="pt-0" dense hide-details v-model="instrumentalResearch.mrt" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('instrumentalResearch', 'mrt', instrumentalResearch.mrt)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('instrumentalResearch', 'mrt', instrumentalResearch.mrt)"/>
                                            </v-radio-group>
                                            <v-radio-group label="Туберкулиновые пробы:" class="pt-0" dense hide-details v-model="instrumentalResearch.tuberculintests" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('instrumentalResearch', 'tuberculintests', instrumentalResearch.tuberculintests)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('instrumentalResearch', 'tuberculintests', instrumentalResearch.tuberculintests)"/>
                                            </v-radio-group>
                                            <v-radio-group label="Выписки из других мед. учреждений:" class="pt-0" dense hide-details v-model="instrumentalResearch.institution" row>
                                                <v-radio color="#5D4CB8" label="есть" :value="1" @mouseup="resetRB('instrumentalResearch', 'institution', instrumentalResearch.institution)"/>
                                                <v-radio color="#5D4CB8" label="нет" :value="0" @mouseup="resetRB('instrumentalResearch', 'institution', instrumentalResearch.institution)"/>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <!-- Шкалы -->
                            <v-expansion-panel>
                                <v-expansion-panel-header color="#5D4CB8" style="color:white">Шкалы</v-expansion-panel-header>

                                <v-expansion-panel-content class="mt-6">
                                    <v-row>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-row class="mt-2">
                                                <span class="v-label theme--light pl-3 pt-3">Оценка общего состояния родителем/пациентом во ВАШ:</span>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="scale.vashpacientfrom" type="number" dense hide-details label="От" outlined />
                                                </v-col>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="scale.vashpacientto" type="number" dense hide-details label="До" outlined />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-row class="mt-2">
                                                <span class="v-label theme--light pl-3 pt-3">Оценка общего состояния врачом во ВАШ:</span>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="scale.vashdoctorfrom" type="number" dense hide-details label="От" outlined />
                                                </v-col>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="scale.vashdoctorto" type="number" dense hide-details label="До" outlined />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-row class="mt-2">
                                                <span class="v-label theme--light pl-3 pt-3">Индекс ФН:</span>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="scale.indexFNfrom" type="number" dense hide-details label="От" outlined />
                                                </v-col>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="scale.indexFNto" type="number" dense hide-details label="До" outlined />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col sm="12" md="6" class="pt-0">
                                            <v-row class="mt-2">
                                                <span class="v-label theme--light pl-3 pt-3">Оценка боли:</span>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="scale.pain_assesfrom" type="number" dense hide-details label="От" outlined />
                                                </v-col>
                                                <v-col md="2" class="pt-0">
                                                    <v-text-field color="#5D4CB8" v-model="scale.pain_assesto" type="number" dense hide-details label="До" outlined />
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-row class="mt-6">
                                <v-col md="12">
                                    <v-card>
                                        <v-data-table no-results-text="Поиск не дал результатов"
                                            :headers="headers"
                                            :items="list"
                                            :loading="loadingTable"
                                            loading-text="Данные загружаются..."
                                            no-data-text="Данных нет"
                                        >
                                            <template v-slot:[`item.doctor_lastname`]="{ item }">
                                                {{ `${item.doctor_lastname ? item.doctor_lastname : ''} ${item.doctor_firstname ? item.doctor_firstname : ''} ${item.doctor_patronymic ? item.doctor_patronymic : ''} ` }}
                                            </template>
                                            
                                            <template v-slot:[`item.fioPatient`]="{ item }">
                                                <a title="Переход на страницу с данными..." v-if="doctor" style="cursor:pointer" @click="goPatient(item)">
                                                    {{ item.fioPatient }}
                                                </a>
                                                <span v-else>{{ item.fioPatient }}</span>
                                            </template>
                                            
                                            <template v-slot:[`item.birth_date`]="{ item }">
                                                <!-- {{item}} -->
                                                {{ `${item.birth_date ? new Date(item.birth_date).toLocaleDateString()+ ' '+ '('+ getFullYear(item.birth_date) +')' : ''}` }}
                                            </template>
                                        </v-data-table>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-expansion-panels>
                    </v-tab-item>

                    <!-- ШАБЛОН -->
                    <v-tab-item value="tab-2" >
                        <v-card flat class="mt-10">
                            <v-card-text>2</v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
			</v-col>
		</v-row>

        <v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    props: {
        doctor: { type: Boolean,  default: () => false}
    },
    data: () => ({
        loadingExcel: false,
        headers: [
            { text: "ФИО пациента", value: "fioPatient", align: 'center' },
            { text: "Дата рождения (возраст)", value: "birth_date", align: 'center' },
            { text: "Регион", value: "region", align: 'center' },
            { text: "Врач (ФИО)", value: "doctor_lastname", align: 'center' }
        ],
        list: [],
        loadingTable: false,
        snackbar: false,
        snackbar_text: '',
        snackbarColor: '',
        isLoadingD: false,
        isLoadingH: false,
        isLoadingR: false,
        isLoadingDiag: false,
        isLoadingCA: false,
        isLoadingBA: false,
        isLoadingIA: false,
        isLoadingUP: false,
        isLoadingTO: false,
        generalInfo: {
            doctors: [],
            gender: [], // массив со значениями которые выбраны
            invalid: null, //null && 0 - это нету / 1 - это есть
            hospitalids: [],
            regionids: [],
            diagnoseids: [],
        },
        anamnez: {
            date_disease: null, //только когда - true
            date_diagnosis: null, //только когда - true
            concomitantdiagnos: null, //только когда - true
        },
        familyAnamnez: {
            radioart: null, //null - не отправляю / 0 - это нету / 1 - это есть
            radiopsor: null, //null - не отправляю / 0 - это нету / 1 - это есть
            radiokron: null, //null - не отправляю / 0 - это нету / 1 - это есть
            radioyazkol: null, //null - не отправляю / 0 - это нету / 1 - это есть
            radiobolbeh: null, //null - не отправляю / 0 - это нету / 1 - это есть
            radiobouveit: null, //null - не отправляю / 0 - это нету / 1 - это есть
            radiobobolrey: null //null - не отправляю / 0 - это нету / 1 - это есть
        },
        inspections: {
            temp: null,
            ardav: null,
            chss: null,
            sip: null,
            uvellim: null,
            sussactivartfrom: null,
            sussactivartto: null,
            utscovfrom: null,
            utscovto: null,
        },
        analysis: {
            clinicalAnalysis: {
                list: []
            },
            bichemicalAnalysis: {
                list: []
            },
            immunologyAnalysis: {
                list: []
            }
        },
        undesirablePhenomenon: {
            listUndesirablePhenomenon: [],
            listTreatmentOutcome: []
        },
        instrumentalResearch: {
            rentgen: null, //true / false
            uzi: null, //true / false
            kt: null, //true / false
            mrt: null, //true / false
            tuberculintests: null, //true / false
            institution: null //true / false
        },
        scale: {
            vashpacientfrom: null,
            vashpacientto: null,
            vashdoctorfrom: null,
            vashdoctorto: null,
            indexFNfrom: null,
            indexFNto: null,
            pain_assesfrom: null,
            pain_assesto: null
        },
        itemsDoctors: [],
        itemsHospitals: [],
        itemsRegions: [],
        itemsImmunologyAnalysis: [],
        itemsTreatmentOutcome: [],
        itemsDiagnoses: [],
        itemsUndesirablePhenomenon: [],
        itemsBichemicalAnalysis: [],
        itemsClinicalAnalysis: [],
        panel: [],
        dateRange: null,
        datefrom: null,
        dateto: null,
        menu: false,
        row: 1,
        tab: '#tab-1',
        arrayTabs: [
            {id:1, name: 'Конструктор отчетов'},
            // {id:2, name: 'Шаблоны'}
        ]
    }),
    watch: {
        row(val) {
            if(val !== 3) this.dateRange = null
        }
    },
    computed: {
        ...mapGetters({
            apiUrl: 'apiUrl'
        }),
        dateFormatted() {
            if(this.dateRange?.length !== 2) return null

            return `${new Date(this.dateRange[0]).toLocaleDateString()} - ${new Date(this.dateRange[1]).toLocaleDateString()}`
        }
    },
    methods: {
        ...mapActions({
            setPatient: 'user/setPatient'
        }),
        ...mapMutations({
            setSaveUrlBack: 'user/setSaveUrlBack'
        }),
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
        getList(url, name, flag, isLoading) {
            if(this[name].length > 0) return;

            this[isLoading] = true

            this.api.get(`${this.apiUrl}/${url}`)
            .then(res => {
                this[isLoading] = false
                if(flag && name == 'itemsDoctors') res.data.forEach(el => { el.fio = `${el.first_name} ${el.last_name} ${el.patronymic}` })
                if(flag && name == 'itemsDiagnoses') res.data.forEach(el => { el.name = `${el.mkb_code} - ${el.mkb_name} (${el.synonym})` })
                
                this[name] = name == 'itemsRegions' ? res.data.sort((a, b) => (a.Name < b.Name) ? -1 : ((b.Name > a.Name) ? 1 : 0)) : res.data
            })
            // console.log(url, name)
        },
        changePeriodDate(val) {
            if(val == 1) {
                let dateOffset = (24*60*60*1000) * 7, myDate = new Date();
                this.datefrom = this.toJSONLocal(myDate.setTime(myDate.getTime() - dateOffset));
                this.dateto = this.toJSONLocal()

                return;
            }
            
            if(val == 2) {
                let myDate = new Date();

                this.datefrom = this.toJSONLocal(myDate.setMonth(myDate.getMonth() - 3));
                this.dateto = this.toJSONLocal()

                return;
            }
        },
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
        addAnalyses(val, name1, name2) {
            let objClone = this[name1][name2];

            this[name1][name2] = {list: val}

            for (let i = 0; i < val.length; i++) {
                this.$set(this[name1][name2], val[i].KeyName, objClone[val[i].KeyName] || objClone[val[i].KeyName]==0 ? objClone[val[i].KeyName] : null)
                this.$set(this[name1][name2], val[i].KeyName+'Id', val[i].id)
            }

            // console.log(this[name1][name2])
        },
        filterObj(obj) {
            let objClone = {};

            for (const key in obj) {
                if(key !== 'list') {
                    Object.assign(objClone, {[key]: obj[key]})
                }
            }

            return objClone
        },
        getFullYear(birthData){
            let diff = new Date()-birthData
            let age = Math.floor(diff/31557600000);
            let txt;
            let count = age % 100;
            if (count >= 5 && count <= 20) {
                txt = 'лет';
            } else {
                count = count % 10;
                if (count == 1) {
                    txt = 'год';
                } else if (count >= 2 && count <= 4) {
                    txt = 'года';
                } else {
                    txt = 'лет';
                }
            }
            return age+" "+txt;
        },
        getReport(dateRange, generalInfo, anamnez, familyAnamnez, inspections, analysis, instrumentalResearch, scale) {
            if(this.row == 3 && !dateRange) {
                this.snackBarFunc(true, 'Заполните дату от - до!!!', 'warning')
                
                return;
            }

            if( new Date(dateRange[0]) > new Date(dateRange[1]) ) {
                this.snackBarFunc(true, 'Дата ОТ не должна быть больше даты ДО!!!', 'error')

                return;
            }

            this.loadingTable = true
            
            this.api.post(`${this.apiUrl}/api/reportconstructor/patientsfilter`, {
                datefrom: new Date(dateRange[0]).getTime(),
                dateto: new Date(dateRange[1]).getTime(),
                doctors: generalInfo.doctors.length > 0 ? generalInfo.doctors : undefined,
                gender: generalInfo.gender.length > 0 ? generalInfo.gender : undefined, // массив со значениями которые выбраны
                invalid: generalInfo.invalid ? generalInfo.invalid : undefined, //null && 0 - это нету / 1 - это есть
                hospitalids: generalInfo.hospitalids.length > 0 ? generalInfo.hospitalids : undefined,
                regionids: generalInfo.regionids.length > 0 ? generalInfo.regionids : undefined,
                diagnoseids: generalInfo.diagnoseids.length > 0 ? generalInfo.diagnoseids : undefined,
                date_disease: anamnez.date_disease ? true : undefined, //только когда - true
                date_diagnosis: anamnez.date_diagnosis ? true : undefined, //только когда - true
                concomitantdiagnos: anamnez.concomitantdiagnos ? true : undefined, //только когда - true
                radioart: familyAnamnez.radioart || familyAnamnez.radioart==0 ? familyAnamnez.radioart : undefined, //null - не отправляю / 0 - это нету / 1 - это есть
                radiopsor: familyAnamnez.radiopsor||familyAnamnez.radiopsor==0 ? familyAnamnez.radiopsor : undefined, //null - не отправляю / 0 - это нету / 1 - это есть
                radiokron: familyAnamnez.radiokron||familyAnamnez.radiokron==0 ? familyAnamnez.radiokron : undefined, //null - не отправляю / 0 - это нету / 1 - это есть
                radioyazkol: familyAnamnez.radioyazkol||familyAnamnez.radioyazkol==0 ? familyAnamnez.radioyazkol : undefined, //null - не отправляю / 0 - это нету / 1 - это есть
                radiobolbeh: familyAnamnez.radiobolbeh||familyAnamnez.radiobolbeh==0 ? familyAnamnez.radiobolbeh : undefined, //null - не отправляю / 0 - это нету / 1 - это есть
                radiobouveit: familyAnamnez.radiobouveit||familyAnamnez.radiobouveit==0 ? familyAnamnez.radiobouveit : undefined, //null - не отправляю / 0 - это нету / 1 - это есть
                radiobobolrey: familyAnamnez.radiobobolrey||familyAnamnez.radiobobolrey==0 ? familyAnamnez.radiobobolrey : undefined, //null - не отправляю / 0 - это нету / 1 - это есть
                temp: inspections.temp || inspections.temp == false ? inspections.temp : undefined,
                ardav: inspections.ardav || inspections.ardav == false ? inspections.ardav : undefined,
                chss: inspections.chss || inspections.chss == false ? inspections.chss : undefined,
                sip: inspections.sip || inspections.sip == false ? inspections.sip : undefined,
                uvellim: inspections.uvellim || inspections.uvellim == false ? inspections.uvellim : undefined,
                sussactivartfrom: inspections.sussactivartfrom || inspections.sussactivartfrom==0 ? inspections.sussactivartfrom : undefined,
                sussactivartto: inspections.sussactivartto||inspections.sussactivartto==0 ? inspections.sussactivartto : undefined,
                utscovfrom: inspections.utscovfrom||inspections.utscovfrom==0 ? inspections.utscovfrom : undefined,
                utscovto: inspections.utscovto||inspections.utscovto==0 ? inspections.utscovto : undefined,
                ...this.filterObj(analysis.clinicalAnalysis),
                ...this.filterObj(analysis.bichemicalAnalysis),
                ...this.filterObj(analysis.immunologyAnalysis),
                rentgen: instrumentalResearch.rentgen ? instrumentalResearch.rentgen : undefined, //true / false
                uzi: instrumentalResearch.uzi ? instrumentalResearch.uzi : undefined, //true / false
                kt: instrumentalResearch.kt ? instrumentalResearch.kt : undefined, //true / false
                mrt: instrumentalResearch.mrt ? instrumentalResearch.mrt : undefined, //true / false
                tuberculintests: instrumentalResearch.tuberculintests ? instrumentalResearch.tuberculintests : undefined, //true / false
                institution: instrumentalResearch.institution ? instrumentalResearch.institution : undefined, //true / false
                vashpacientfrom: scale.vashpacientfrom || scale.vashpacientfrom==0 ? scale.vashpacientfrom : undefined,
                vashpacientto: scale.vashpacientto || scale.vashpacientto==0 ? scale.vashpacientto : undefined,
                vashdoctorfrom: scale.vashdoctorfrom || scale.vashdoctorfrom==0 ? scale.vashdoctorfrom : undefined,
                vashdoctorto: scale.vashdoctorto || scale.vashdoctorto==0 ? scale.vashdoctorto : undefined,
                indexFNfrom: scale.indexFNfrom || scale.indexFNfrom==0 ? scale.indexFNfrom : undefined,
                indexFNto: scale.indexFNto || scale.indexFNto==0 ? scale.indexFNto : undefined,
                pain_assesfrom: scale.pain_assesfrom || scale.pain_assesfrom==0 ? scale.pain_assesfrom : undefined,
                pain_assesto: scale.pain_assesto || scale.pain_assesto==0 ? scale.pain_assesto : undefined
            })
            .then(res => {
                if(res.status == 200) {
                    res.data.forEach(el => {
                        el.fioPatient = `${el.last_name ? el.last_name : ''} ${el.first_name ? el.first_name : ''} ${el.patronymic ? el.patronymic : ''}`
                    })
                    this.list = res.data
                    this.loadingTable = false
                }
            })
            .catch(error => {
                console.log(error);
                this.snackBarFunc(true, error.response.data.Message, 'error')
            })
        },
        goPatient(e) {
            // console.log(this.$route.fullPath, e)
            this.setSaveUrlBack(this.$route.fullPath);

            // console.log({ query_fio: this.$route.query.fio ? this.$route.query.fio : undefined, patients_id: e.Id, fio: e.last_name + ' ' +  e.first_name + ' ' +  e.patronymic})
			this.setPatient({ query_fio: this.$route.query.fio ? this.$route.query.fio : undefined, patients_id: e.Id, fio: e.last_name + ' ' +  e.first_name + ' ' +  e.patronymic});
			this.$router.push('/PassportPart');
        },
        resetFilter() {
            this.generalInfo = {
                doctors: [],
                gender: [], // массив со значениями которые выбраны
                invalid: null, //null && 0 - это нету / 1 - это есть
                hospitalids: [],
                regionids: [],
                diagnoseids: [],
            }
            this.anamnez = {
                date_disease: null, //только когда - true
                date_diagnosis: null, //только когда - true
                concomitantdiagnos: null, //только когда - true
            }
            this.familyAnamnez = {
                radioart: null, //null - не отправляю / 0 - это нету / 1 - это есть
                radiopsor: null, //null - не отправляю / 0 - это нету / 1 - это есть
                radiokron: null, //null - не отправляю / 0 - это нету / 1 - это есть
                radioyazkol: null, //null - не отправляю / 0 - это нету / 1 - это есть
                radiobolbeh: null, //null - не отправляю / 0 - это нету / 1 - это есть
                radiobouveit: null, //null - не отправляю / 0 - это нету / 1 - это есть
                radiobobolrey: null //null - не отправляю / 0 - это нету / 1 - это есть
            }
            this.inspections = {
                temp: null,
                ardav: null,
                chss: null,
                sip: null,
                uvellim: null,
                sussactivartfrom: null,
                sussactivartto: null,
                utscovfrom: null,
                utscovto: null,
            }
            this.analysis = {
                clinicalAnalysis: {
                    list: []
                },
                bichemicalAnalysis: {
                    list: []
                },
                immunologyAnalysis: {
                    list: []
                }
            }
            this.undesirablePhenomenon = {
                listUndesirablePhenomenon: [],
                listTreatmentOutcome: []
            }
            this.instrumentalResearch = {
                rentgen: null, //true / false
                uzi: null, //true / false
                kt: null, //true / false
                mrt: null, //true / false
                tuberculintests: null, //true / false
                institution: null //true / false
            }
            this.scale = {
                vashpacientfrom: null,
                vashpacientto: null,
                vashdoctorfrom: null,
                vashdoctorto: null,
                indexFNfrom: null,
                indexFNto: null,
                pain_assesfrom: null,
                pain_assesto: null
            }
            this.list = []

            this.snackBarFunc(true, 'Фильтр сброшен!', 'success')
        },
        exportToExcel(listPatients) {
            if(listPatients.length == 0) {
                return;
            }

            this.loadingExcel = true

            this.api.post(`${this.apiUrl}/api/reportconstructor/reportfile`,
                { ids: listPatients.map(m => m.Id) },
                { responseType: 'arraybuffer' }
            )
            .then(res => {
                if(res.status == 200) {
					const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/octet-binary;charset=utf-8"} ));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Отчет по пациентам.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

                    this.loadingExcel = false
				}
            })

        },
        resetRB(name1, name2, val, name3) {
            setTimeout(() => {
                
                if(name3) {
                    if(this[name1][name2][name3] == val) {
                        this[name1][name2][name3] = null
                    }
                } else {
                    if(this[name1][name2] == val) {
                        this[name1][name2] = null
                    }
                }

            }, 100);
        },
        remove(name1, name2, item, itemReference, name3) {
            if(name3) {
                const index = this[name1][name2][name3].indexOf(item)
                if (index >= 0) this[name1][name2][name3].splice(index, 1)

                return;
            }
            const index = this[name1][name2].indexOf(item[itemReference])
            if (index >= 0) this[name1][name2].splice(index, 1)
        }
    },
    created() {
        this.changePeriodDate(1);
    }
}
</script>

<style>
.v-input--radio-group legend.v-label {
    font-size: 17px!important
}

.v-expansion-panel-header i{
    color: white!important;
}

.v-tooltip__content {
    opacity: 1!important;
}
</style>