<template>
	<v-card>
		<v-card-title>
			<v-btn v-if="user.role !== 2" @click="initNewRow(obj, true)" color="#6374dd" dark class="ml-4">Добавить осмотр</v-btn>
			
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:search="search"
			:items="list"
			:loading="loading"
			no-data-text="Осмотры отсутствуют..."
			loading-text="Загрузка... Пожалуйста подождите..."
		>
			<!-- Температура -->
			<template v-slot:[`item.tem`]="{ item }">
				<span :class="{ not_reference: !isTemperatureReference(item.tem) }"> {{ item.tem }} </span>
			</template>
			
			<!-- ЧСС -->
			<template v-slot:[`item.chss`]="{ item }">
				<span :class="{ not_reference: !isChssReference(item.chss) }"> {{ item.chss }} </span>
			</template>
			
			<!-- Утреняя скованность (в мин) -->
			<template v-slot:[`item.utscov`]="{ item }">
				<span :class="{ not_reference: !isUtscovReference(item.utscov) }"> {{ item.utscov }} </span>
			</template>

			<!-- кнопки редактирование/просмотра/удаления -->
			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="$route.query.myPatient == 'false' ? user.CanRead : ($route.query.myPatient == 'true' ? false : user.role !== 2)" @click="onEditingStart(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn v-if="user.role !== 2 && new Date().getTime() - item.creation_date < 86400000" @click="deleteItems(item, list)" color="error" x-small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>

			<!-- оценка боли -->
			<template v-slot:[`item.ocbol`]="{ item }">
				<v-progress-linear color="#6374dd" :value="item.ocbol" height="25">
					<strong>{{ item.ocbol ? item.ocbol : 0 }}%</strong>
				</v-progress-linear>
			</template>

			<!-- артериальное давление -->
			<template v-slot:[`item.ardav`]="{ item }">
				<span :class="{not_reference: !isPressureReference(item.ardav, item.date)}" v-if="item.ardav.sis && item.ardav.dia">{{ `${item.ardav.sis} / ${item.ardav.dia}` }}</span>
			</template>
			
			<!-- болезненые суставы -->
			<template v-slot:[`item.bolsus`]="{ item }">
				{{item.bolsus}}
				<v-btn v-if="item.bolsus && checkRole" @click="showSysSindrom(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-eye</v-icon>
				</v-btn>
			</template>

			<!-- Ограниченный в движениях сустав -->
			<template v-slot:[`item.sussogrdvij`]="{ item }">
				{{item.sussogrdvij}}
				<v-btn v-if="item.sussogrdvij && checkRole" @click="showSysSindrom(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-eye</v-icon>
				</v-btn>
			</template>

			<!-- Припухшие суставы -->
			<template v-slot:[`item.pripsus`]="{ item }">
				{{item.pripsus}}
				<v-btn v-if="item.pripsus && checkRole" @click="showSysSindrom(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-eye</v-icon>
				</v-btn>
			</template>

			<!-- Суставы с активным артритом -->
			<template v-slot:[`item.sussactivart`]="{ item }">
				{{item.sussactivart}}
				<v-btn v-if="item.sussactivart && checkRole" @click="showSysSindrom(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-eye</v-icon>
				</v-btn>
			</template>

			<!-- Сыпь -->
			<template v-slot:[`item.sip`]="{ item }">
				{{item.sip ? 'Есть' : 'Нет'}}
				<v-btn @click="showSip(item)" v-if="item.sip && checkRole" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-eye</v-icon>
				</v-btn>
			</template>

			<!-- Увеличенные лимфоузлы -->
			<template v-slot:[`item.uvellim`]="{ item }">
				{{item.uvellim ? 'Есть' : 'Нет'}}
				<v-btn @click="showLim(item)" v-if="item.uvellim && checkRole" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-eye</v-icon>
				</v-btn>
			</template>


		</v-data-table>

		<!-- Окно создания/редактирования осмотра -->
		<v-dialog persistent v-model="dialog" max-width="1200px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ !editeRow ? 'Добавить' : 'Редактировать' }} осмотр</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="!editeRow ? save(obj) : onRowUpdating(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>
						<v-row>
							<v-col cols="12" sm="12" md="12" xl="12">
								<v-stepper v-model="e6">
									<v-stepper-header>
										<v-stepper-step :complete="e6 > 1" step="1" >
											Основные анализы
										</v-stepper-step>

										<v-divider></v-divider>

										<v-stepper-step :complete="e6 > 2" step="2" >
											Суставной синдром
										</v-stepper-step>

										<v-divider></v-divider>

										<v-stepper-step :complete="e6 > 3" step="3" >
											Сыпь
										</v-stepper-step>

										<v-divider></v-divider>

										<v-stepper-step :complete="e6 > 4" step="4" >
											Увеличенные лимфоузлы
										</v-stepper-step>
									</v-stepper-header>

									<v-stepper-items>
										<v-stepper-content step="1">
											<v-form ref="form" v-model="valid" lazy-validation class="mt-2">
												<v-row>
													<v-col cols="12" sm="12" md="3" xl="2">
														<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
															<template v-slot:activator="{ on, attrs }">
																<v-text-field outlined ref="tf0" :rules="[rules.required]" v-model="dateFormatted" label="Дата осмотра *" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
															</template>

															<v-date-picker :max="toJSONLocal(null)" :min="toJSONLocal(bday.birth_date)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.date" @change="saveDate" />
														</v-menu>
													</v-col>
													<v-col cols="12" sm="12" md="3" xl="2">
														<v-autocomplete
															color="#5D4CB8"
															v-model="obj.tem"
															item-text="name"
															item-value="name"
															hide-no-data
															hide-selected
															clearable
															height="50px"
															hide-details
															:items="temperature"
															label="Температура тела"
															outlined
														/>
													</v-col>
													<v-col cols="12" sm="12" md="6" xl="5">
														<v-row>
															<v-col cols="12" sm="12" md="6" xl="6">
																<v-autocomplete
																	color="#5D4CB8"
																	v-model="obj.ardav.sis"
																	item-text="name"
																	item-value="name"
																	hide-no-data
																	hide-selected
																	clearable
																	height="50px"
																	hide-details
																	:items="artSisDia"
																	label="Арт. давл. (Систолическое)"
																	outlined
																/>
															</v-col>
															<v-col cols="12" sm="12" md="6" xl="6">
																<v-autocomplete
																	color="#5D4CB8"
																	v-model="obj.ardav.dia"
																	item-text="name"
																	item-value="name"
																	hide-no-data
																	hide-selected
																	clearable
																	height="50px"
																	hide-details
																	:items="artSisDia"
																	label="Арт. давл. (Диастолическое)"
																	outlined
																/>
															</v-col>
														</v-row>
													</v-col>
													<v-col cols="12" sm="12" md="3" xl="2">
														<v-text-field type="number" :rules="[rules.minChss, rules.maxChss]" prepend-icon="mdi-pencil" outlined label="ЧСС" v-model="obj.chss"/>
													</v-col>
													<v-col cols="12" sm="12" md="4" xl="4">
														<v-text-field type="number" :rules="[rules.required, rules.maxUtscov]" outlined label="Утренняя скованность (в мин) *" v-model="obj.utscov"/>
													</v-col>
													<v-col cols="12" sm="12" md="5" xl="5">
														<v-row>
															<v-col cols="12" sm="12" md="4" xl="4">
																<v-subheader class="pl-0">Оценка боли</v-subheader>
															</v-col>
															<v-col class="ml-0 pl-0 pr-6 mt-2" cols="12" sm="12" md="8" xl="8">
																<v-slider v-model="obj.ocbol" thumb-label />
															</v-col>
														</v-row>
													</v-col>
												</v-row>
											</v-form>
											
											<v-btn color="primary" @click="e6 = 2" >
												Продолжить
											</v-btn>
										</v-stepper-content>

										<v-stepper-content step="2">
											<v-row>
												<v-col cols="12" sm="12" md="12" xl="12">
													<v-row>
														<v-col cols="12" sm="12" md="4" xl="4">
															<!-- <span v-if="obj.sys.find(i => i.selected)">
																{{ obj.sys.find(i => i.selected).check }}
															</span> -->
															<el-checkbox-group v-if="obj.sys && obj.sys.find(i => i.selected)" v-model="obj.sys.find(i => i.selected).check">
																<el-checkbox label="1">Болезненный сустав</el-checkbox><br>
																<el-checkbox label="2">Припухший сустав</el-checkbox><br>
																<el-checkbox label="3">Ограниченный в движениях сустав</el-checkbox>
															</el-checkbox-group>
														</v-col>
														<v-col cols="12" sm="12" md="8" xl="8">
															<svg-human-sys v-if="dialog" :sys="obj.sys" viewBox="250 95 900 670"/>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
											
											<v-btn color="primary" @click="e6 = 3" >
												Продолжить
											</v-btn>

											<v-btn text @click="e6 = 1"> Назад </v-btn>
										</v-stepper-content>

										<v-stepper-content step="3">
											<v-row>
												<v-col cols="12" sm="12" md="12" xl="12">
													<view-human-fron-the-front
														v-if="dialog"
														:id="obj.id || 'new_item'"
														:siplist="obj.siplist ? obj.siplist : []"
														edit="true"
														viewBox="-410 90 1320 600"
													/>
												</v-col>
											</v-row>
											
											<v-btn color="primary" @click="e6 = 4" >
												Продолжить
											</v-btn>

											<v-btn text @click="e6 = 2"> Назад </v-btn>
										</v-stepper-content>

										<v-stepper-content step="4">
											<v-row>
												<v-col cols="12" sm="12" md="12" xl="12">
													<svg-human-sip v-if="dialog" edit="true" :syssind="obj.syssind2"  viewBox="-330 90 1630 800" />
												</v-col>
											</v-row>

											<v-btn text @click="e6 = 3"> Назад </v-btn>
										</v-stepper-content>
									</v-stepper-items>
								</v-stepper>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Окно просмотра суставного синдрома -->
		<v-dialog persistent v-model="dialogView" fullscreen v-if="dialogView">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="dialogView = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Суставной синдром</v-toolbar-title>
				</v-toolbar>

				<v-card-text>
					<v-row class="mt-2">
						<v-col cols="12" sm="6" md="6">
							<div class="photo_legend">
								<div class="photo_legend__row">
									<div class="photo_legend__row__circle red"></div>
									<div class="photo_legend__row__label">
										- сустав с активным артритом
									</div>
								</div>
								<div class="photo_legend__row">
									<div class="photo_legend__row__circle green"></div>
									<div class="photo_legend__row__label">
										- сустав без активного артрита
									</div>
								</div>
							</div>

							<newSvgSysPhotoHumanView v-if="dialogView" class="mt-4" :sys="showSysData" viewBox="300 0 920 800" @pointSelected="handlePointSelected" />
						</v-col>
						
						<v-col align-self="center" cols="12" sm="6" md="6">
							<view-human-table v-if="dialogView" :sys="showSysData" :selected="selectedPoint" />
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
		
		<!-- Окно просмотра Сыпи -->
		<v-dialog persistent v-model="dialogViewSip" fullscreen>
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="dialogViewSip = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Кожный покров</v-toolbar-title>
				</v-toolbar>

				<v-card-text>
					<v-row class="mt-2">
						<v-col cols="12" sm="6" md="6">
							<svg-human-pok-view v-if="dialogViewSip" :siplist="activePoc"  viewBox="50 0 920 800"/>
						</v-col>
						
						<v-col align-self="center" cols="12" sm="6" md="6">
							<view-sip-table v-if="dialogViewSip" :siplist="activePoc" />
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Окно просмотра Лимфоузлов -->
		<v-dialog persistent v-model="dialogViewLim" fullscreen>
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="dialogViewLim = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Кожный покров</v-toolbar-title>
				</v-toolbar>

				<v-card-text>
					<v-row class="mt-2">
						<v-col cols="12" sm="6" md="6">
							<svg-human-sip v-if="dialogViewLim" :syssind="activeSip"  viewBox="0 0 940 900" />
						</v-col>
						
						<v-col align-self="center" cols="12" sm="6" md="6">
							<view-lim-table v-if="dialogViewLim" :sys="activeSip" />
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
//суставной синдром
import newSvgSysPhotoHumanView from '../human/newSvgSysPhotoHumanView.vue'
import ViewHumanTable from './ViewHumanTable.vue'
import SvgHumanSys from '../human/SvgHumanSys.vue'
//сыпь
import ViewSipTable from './ViewSipTable.vue'
import SvgHumanPokView from '../human/SvgHumanPokView.vue'
import viewHumanFronTheFront from '../human/viewHumanFronTheFront.vue'
//лимфоузлы
import ViewLimTable from './ViewLimTable.vue'
import SvgHumanSip from '../human/SvgHumanSip.vue'
//остальное
import { mapGetters, mapActions } from 'vuex';

export default {
	components: { newSvgSysPhotoHumanView, ViewHumanTable, ViewSipTable, SvgHumanPokView, ViewLimTable, SvgHumanSip, SvgHumanSys, viewHumanFronTheFront },
	computed: {
		...mapGetters({
			uzi: 'data/dataUzi',
			apiUrl: 'apiUrl',
			bday: 'passportPart/patientData',
			user: 'user/user',
			patient: 'user/patient',
			joints: 'manifestationOfDisease/joints',
			activeSipList: 'manifestationOfDisease/activeSipList'
        }),
		dateFormatted() {
            if(this.obj.date)
                return new Date(this.obj.date).toLocaleDateString("ru-RU");
            return null;
        },
		artSis() {
			let array = [];
			
			for(let i = 30; i < 301; i+=5) {
				if (i > this.obj.ardav.dia) {
					array.push(i);
				}
			}
			return array;
		},
		artDia() {
			let array = [];
			
			for(let i = 15; i < 201; i+=5) {
				if (i < this.obj.ardav.sis) {
					array.push(i);
				}
			} 
			return array;
		},
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
	data() {
		return {
			artSisDia: [],
			temperature: [],
			e6: 1,
			editeRow: false,
			activePoc: {},
			activeSip: {},
			showSysData: [],
            selectedPoint: '',
			file: null,
			search: '',
			maxDate: new Date().toISOString().substr(0, 10),
			menu: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				tem: null,
				creation_date: null,
				date: null,
				ardav: {sis: 0, dia: 0},
				id: null,
				bolsus: 0,
				chss: null,
				ocbol: null,
				pripsus: 0,
				sys: [],
				syssind1: [],
				syssind2: [],
				sip: 0,
				siplist: null,
				sussactiveart: 0,
				sussogrdvij: 0,
				utscov: null,
				uvellim: 0
			},
			dialog: false,
			dialogView: false,
			dialogViewSip: false,
			dialogViewLim: false,
			list: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.',
				minChss: value => !!value ? value >= 40 || 'Меньше 40 не может быть!!!' : true,
				maxChss: value => value <= 300 || 'Больше 300 не может быть!!!',
				maxUtscov: value => value <= 1000 || 'Больше 1000 не может быть!!!',
			},
			headers: [
				{ text: "Дата осмотра", value: "date_new", align: 'center' },
				{ text: "Температура тела", value: "tem", align: 'center', sortable: false },
				{ text: "Артериальное давление", value: "ardav", align: 'center', sortable: false },
				{ text: "ЧСС", value: "chss", align: 'center', sortable: false },
				{ text: "Утренняя скованность (в мин)", value: "utscov", align: 'center', sortable: false },
				{ text: "Оценка боли", value: "ocbol", align: 'center', sortable: false },
				{ text: "Болезненные суставы", value: "bolsus", align: 'center', sortable: false },
				{ text: "Ограниченный в движениях сустав", value: "sussogrdvij", align: 'center', sortable: false },
				{ text: "Припухшие суставы", value: "pripsus", align: 'center', sortable: false },
				{ text: "Суставы с активным артритом", value: "sussactivart", align: 'center', sortable: false },
				{ text: "Сыпь", value: "sip", align: 'center', sortable: false },
				{ text: "Увеличенные лимфоузлы", value: "uvellim", align: 'center', sortable: false },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		...mapActions({
			savePostInspection: 'manifestationOfDisease/savePostInspection',
			updatePutInspection: 'manifestationOfDisease/updatePutInspection'
		}),
        handlePointSelected(ev) {
            this.selectedPoint = ev
        },
		showSysSindrom(row) {
			this.showSysData = row.sys
			this.dialogView = !this.dialogView
		},
        showSip(row){
			this.activePoc = row.siplist;
			this.dialogViewSip = !this.dialogViewSip
        },
        showLim(row){
			this.activeSip = row.syssind2;
			this.dialogViewLim = !this.dialogViewLim
        },
		close() {
			this.obj = {
				tem: null,
				creation_date: null,
				date: null,
				ardav: {sis: 0, dia: 0},
				id: null,
				bolsus: 0,
				chss: null,
				ocbol: null,
				pripsus: 0,
				sys: [],
				syssind1: [],
				syssind2: [],
				sip: 0,
				siplist: null,
				sussactiveart: 0,
				sussogrdvij: 0,
				utscov: null,
				uvellim: 0
			}
			this.editeRow = false
			this.e6 = 1
			this.dialog = false
			this.overlay = false
		},
		async apiMethods(url, name, flag) {
			let res = [];
			this.loading = false
			
			if(flag) {
				res = (await this.api.get(`${url}/${name}`)).data
				await res.forEach(el => { if(el.date) el.date_new = new Date(el.date).toLocaleDateString() })
				await (res.sort((a, b) => (a.date < b.date) ? -1 : ((b.date > a.date) ? 1 : 0))).reverse()
			} else {
				res = (await this.api.get(`${url}/${name}`)).data.filter(f => !f.IsHidden)
			}
			
			return res
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/patients/${this.patient.patients_id}/inspections/${row.id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)

						this.snackBarFunc(true, 'Запись успешно удалена!!!', 'success')
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				this.overlay = true

				obj.date = new Date(obj.date).getTime()
				obj.creation_date = new Date().getTime()
				let data = []
				this.activeSipList('new_item').forEach(i => {
					data.push({numeric_id: i.numeric_id, name: i.name, bol: i.bol})
				})
				obj.siplist = JSON.stringify(data)
				obj.bolsus = 0;
				obj.sussogrdvij = 0;
				obj.pripsus = 0;
				obj.sussactivart = 0;
				obj.sip = 0;
				obj.uvellim = 0;
				this.activeSipList('new_item').forEach(item => {
					if (item.bol == true) obj.sip++
				})
				obj.syssind2.forEach(item => {
					if (item.isActive) obj.uvellim++
				})

				// <el-checkbox label="1">Болезненный сустав</el-checkbox><br>
				// <el-checkbox label="2">Припухший сустав</el-checkbox><br>
				// <el-checkbox label="3">Ограниченный в движениях сустав</el-checkbox>
				
				obj.sys.forEach(item => {
					if (item.check.indexOf('1') > -1) obj.bolsus++
					if (item.check.indexOf('2') > -1 ) obj.pripsus++
					if (item.check.indexOf('3') > -1 ) obj.sussogrdvij++

					if (item.check.indexOf('2') > -1 ) obj.sussactivart++
					else if (item.check.indexOf('2') > -1 && item.check.indexOf('1') > -1 ) obj.sussactivart++
					else if (item.check.indexOf('2') > -1 && item.check.indexOf('3') > -1 ) obj.sussactivart++
					else if (item.check.indexOf('1') > -1 && item.check.indexOf('2') > -1 && item.check.indexOf('3') > -1 ) obj.sussactivart++
					else if (item.check.indexOf('1') > -1 && item.check.indexOf('3') > -1 ) obj.sussactivart++
				})

				// console.log(`Болезненный сустав = ${obj.bolsus}`);
				// console.log(`Припухший сустав = ${obj.pripsus}`);
				// console.log(`Ограниченный в движениях сустав = ${obj.sussogrdvij}`);
				// console.log(`Cустав с активным артритом = ${obj.sussactivart}`);

				this.savePostInspection(obj).then(res => {
					if(res.status == 200) {
						if(res.data.date) res.data.date_new = new Date(res.data.date).toLocaleDateString()
						this.list.push(res.data)

						this.list = (this.list.sort((a, b) => (a.date < b.date) ? -1 : ((b.date > a.date) ? 1 : 0))).reverse()

						this.snackBarFunc(true, 'Осмотр успешно создан!!!', 'success')
						this.close()
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		saveDate(date) {
			this.$refs.menu.save(date)
		},
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
		initNewRow(obj, flag) {
            if(flag) obj.date = this.toJSONLocal()

			obj.sys = []
			obj.syssind1 = []
			obj.syssind2 = []
			obj.ardav = { sis: 0, dia: 0 }

			this.joints.forEach(joint => {
				obj.sys.push(
					{		
						name: joint.name,
						isA: false,
						isB: true,
						selected: false,
						check: [],
						n: joint.numeric_id
					}
				)
			})

			for (let i = 0; i < 10; i++) {
				obj.syssind1.push({isActive: false});
				obj.syssind2.push({isActive: false});
			}

			obj.syssind1[0].name = "Туловище";
			obj.syssind1[1].name = "Голова + шея";
			obj.syssind1[2].name = "Правая нога";
			obj.syssind1[3].name = "Правая рука";
			obj.syssind1[4].name = "Бедро";
			obj.syssind1[5].name = "Левая рука";
            obj.syssind1[6].name = "Левая нога";
			obj.syssind1[7].name = "Бедро(Сзади)";
			obj.syssind1[8].name = "Спина";


			obj.syssind2[0].name = "шейный левый";
			obj.syssind2[1].name = "шейный правый";
			obj.syssind2[2].name = "подмышечный левый";
			obj.syssind2[3].name = "подмышечный правый";
			obj.syssind2[4].name = "локтевой левый";
			obj.syssind2[5].name = "локтевой правый";
			obj.syssind2[6].name = "паховый левый";
			obj.syssind2[7].name = "паховый правый";
			obj.syssind2[8].name = "подколенный левый";
			obj.syssind2[9].name = "подколенный правый";
			
			if(flag) this.dialog = true
		},
		onEditingStart(row) {
			row.date = new Date(row.date).toISOString().substr(0, 10)
			this.editeRow = true
			
			// if(row.sys && row.sys.length > 0) {
			// 	for (let i = 0; i < row.sys.length; i++) {
			// 		if(row.sys[i].check.length > 0) {
			// 			row.sys[i].check.forEach(el => {
			// 				console.log(typeof el == 'number', el)
			// 				// if(typeof el == 'number') el = String(el)
			// 			})
			// 		}
			// 	}
			// }

			this.obj = JSON.parse(JSON.stringify(row))

			// console.log(this.obj);
			
			this.dialog = true
		},
		onRowUpdating(obj) {
			if(this.$refs.form.validate()) {
				this.overlay = true
				
				let data = []
				this.activeSipList(obj.id).forEach(i => {
					data.push({numeric_id: i.numeric_id, name: i.name, bol: i.bol})
				})
				obj.siplist = JSON.stringify(data)
				
				obj.bolsus = 0;
				obj.sussogrdvij = 0;
				obj.pripsus = 0;
				obj.sussactivart = 0;
				obj.sip = 0;
				obj.uvellim = 0;
				this.activeSipList(obj.id).forEach(item => {
					if (item.bol == true) obj.sip++     
				})
				obj.syssind2.forEach(item => {
					if (item.isActive) obj.uvellim++
				})
				obj.sys.forEach(item => {
					if (item.check.indexOf('1') > -1) obj.bolsus++
					if (item.check.indexOf('2') > -1 ) obj.pripsus++
					if (item.check.indexOf('3') > -1 ) obj.sussogrdvij++

					if (item.check.indexOf('2') > -1 ) obj.sussactivart++
					else if (item.check.indexOf('2') > -1 && item.check.indexOf('1') > -1 ) obj.sussactivart++
					else if (item.check.indexOf('2') > -1 && item.check.indexOf('3') > -1 ) obj.sussactivart++
					else if (item.check.indexOf('1') > -1 && item.check.indexOf('2') > -1 && item.check.indexOf('3') > -1 ) obj.sussactivart++
					else if (item.check.indexOf('1') > -1 && item.check.indexOf('3') > -1 ) obj.sussactivart++
				})

				obj.date = new Date(obj.date).getTime()
				
				this.updatePutInspection(obj).then(res => {
					if(res.status == 200) {
						this.list.forEach(el => {
							if(el.id == res.data.id) Object.assign(el, res.data)
						})

						this.close()
					}
				})
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
        isTemperatureReference(val) {
            return (+val >= 35.5) && (+val <= 37.5)
        },
        isPressureReference(val, checkDate) {
            if (!val) return true
            const age = ((+new Date(checkDate)) - +this.bday.birth_date)/(1000*60*60*24*365)

            if(age < 3) {
                return (val.sis >=100 && val.sis <=112) && (val.dia >=60 && val.dia <=74) 
            }
            if(age >= 3 && age <6) {
                return (val.sis >=100 && val.sis <=116 && val.sis !== 0) && (val.dia >=60 && val.dia <=76 && val.dia !== 0)
            }
            if(age >= 6 && age <10) {
                return (val.sis >=100 && val.sis <=122) && (val.dia >=60 && val.dia <=78)
            }
            if(age >= 10 && age <13) {
                return (val.sis >=110 && val.sis <=126) && (val.dia >=70 && val.dia <=82)
            }
            if(age >= 13 && age <16) {
                return (val.sis >=110 && val.sis <=136) && (val.dia >=70 && val.dia <=86)
            }
        },
        isChssReference(val, checkDate) {
            const age = ((+new Date(checkDate)) - +this.bday.birth_date)/(1000*60*60*24*365)

            if(age < 0.5)             return (val>=140 && val<=160) 
            if(age >= 0.5 && age < 1) return (val>=130 && val<=135)
            if(age >= 1 && age < 2)   return (val>=120 && val<=125)
            if(age >= 2 && age < 3)   return (val>=110 && val<=115)
            if(age >= 3 && age < 5)   return (val>=105 && val<=110)
            if(age >= 5 && age < 8)   return (val>=100 && val<=105)
            if(age >= 8 && age < 10)  return (val>=90  && val<=100)
            if(age >= 10 && age < 12) return (val>=80  && val<=85)
            if(age >= 12)             return (val>=70  && val<=75)
        },
        isUtscovReference(val) {
            return (+val >= 0) && (+val <= 15)
        }
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/patients/${this.patient.patients_id}/inspections`, true)
		this.temperature = await this.apiMethods(this.apiUrl, `api/lookups/temperature?lookupName=temperature`, false)
		this.artSisDia = await this.apiMethods(this.apiUrl, `api/lookups/arterial-pressure?lookupName=arterial-pressure`, false)
	}
};
</script>

<style scoped>
.photo_legend {
    margin-bottom: -70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.photo_legend__row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}    

.photo_legend__row__circle {
    min-width: 20px;
    min-height: 20px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.photo_legend__row__label {
    min-width: 250px;
    padding-left: 10px;
    margin-bottom: 5px;
}

.photo_legend__row__circle.red {
    background-color: #dd0a0a;
    border: 1px solid #ff0000;
}

.photo_legend__row__circle.green {
    background-color: #79b729;
    border: 1px solid #a56d65;
}

.not_reference {
    color: #ff4500;
    margin-top: -5px;
    margin-bottom: -5px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}
</style>