<template>
<div>
	<svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		:viewBox="viewBox" style="enable-background:new 0 0 1000 1500;" xml:space="preserve">

		<g id="Слой_2_1_">
		</g>
		<g v-if="changeOf == false">
			<g v-for="( skin, index ) in skins" :key="index">
				<!-- <path :class="[skin.class, skin.numeric_id == (activeJoint.numeric_id && activeJoint.items.length) || skin.hasPictures ? 'st10': '', skin.numeric_id == activeJoint.numeric_id ? 'st11': '']" :d="skin.d" @click="setJoint(skin.numeric_id)"/> -->
				<path :class="[skin.class, skin.bol == true ? 'st10': '']" :d="skin.d" @click="setPokS(skin.numeric_id)"/>
			</g>
		</g>
		<g v-if="changeOf == true">
			<g v-for="( skin, index ) in skinsBack" :key="index">
				<path :class="[skin.class, skin.bol == true ? 'st10': '']" :d="skin.d" @click="setPokZ(skin.numeric_id)"/>
			</g>
		</g>
	</svg>
	<center>
		<el-button :disabled="changeOf === false" icon="el-icon-arrow-left" circle @click="changeOf = false"></el-button>
			<span v-if="changeOf === false">Вид спереди</span>
			<span v-if="changeOf === true">Вид сзади</span>
		<el-button :disabled="changeOf === true" icon="el-icon-arrow-right" circle @click="changeOf = true"></el-button>
	</center>
</div>
</template>
<script>

import { mapActions } from 'vuex'
import skinData from './humanPok.json';
import skinDataBack from './humanPok2.json';

export default {
	props: ['viewBox', 'siplist', 'id'],
	data() {
		return {
			skinTrueBack: [],
			skinTrue: [],
			changeOf: false,
			skins: JSON.parse(JSON.stringify(skinData.skins_pok)),
			skinsBack: JSON.parse(JSON.stringify(skinDataBack.skins_pok_b)), 
		}
	},
	mounted(){
		if(this.siplist.length > 0){
			this.skins.forEach(i => {
				let data = JSON.parse(this.siplist)
				data.forEach(j => {
					if(i.numeric_id == j.numeric_id){
						i.bol = j.bol
					}
				})
			})
			this.skinsBack.forEach(i => {
				let data = JSON.parse(this.siplist)
				data.forEach(j => {
					if(i.numeric_id == j.numeric_id){
						i.bol = j.bol
					}
				})
			})

			this.skinsUpd()
		}
	},
	methods: {
		...mapActions({
			savePokSelected: 'manifestationOfDisease/savePokSelected'
		}),
		skinsUpd() {
			let merge = []

			this.skinTrueBack = []
			this.skinTrue = []
			this.skins.forEach(item => {
				if(item.bol == true && item.name) {
					this.skinTrue.push(item)
				}
			})
			this.skinsBack.forEach(item => {
				if(item.bol == true && item.name) {
					this.skinTrueBack.push(item)
				}
			})
			merge = [...this.skinTrue,...this.skinTrueBack]
			this.savePokSelected({
				id: this.id,
				sipList: merge
			})
		},
		setPokS(n_id) {
			this.skins.forEach( i => {
				if(i.numeric_id == n_id){
					i.bol = !i.bol
				}
			})
			this.skinsUpd()
		},
		setPokZ(n_id) {
			this.skinsBack.forEach( i => {
				if(i.numeric_id == n_id){
					i.bol = !i.bol
				}
			})
			this.skinsUpd()
		},

	},
}
</script>

<style type="text/css" scoped>
.st0{fill:#F7AD9A;stroke:#FFFFFF;stroke-miterlimit:10;stroke-dasharray:2;cursor: pointer;}
.st1{fill:#EA9C94;}
.st2{fill:#C6847B;}
.st3{fill:#6D4F4F;}
.st4{fill:#1A171B;}
.st5{fill:#FFFFFF;cursor: pointer;}
.st6{fill:#49494B;}
.st7{fill:#BFE5F7;}
.st8{opacity:0.27;fill:#F4CAC3;enable-background:new;cursor: pointer;}
.st9{opacity:0.2;fill:#560B0B;enable-background:new    ;}
.st0.st10{opacity:1;fill:#ca3232;enable-background:new    ;}
.st15{fill:#DD9080;stroke:#FFFFFF;stroke-miterlimit:10;stroke-dasharray:2;cursor: not-allowed}
.st0.st11{opacity:1;stroke:#ca3232;enable-background:new; stroke-width: 2;}
</style>

