<template>
	<v-card>
		<v-card-title>			
			<v-btn @click="addItem(true)" color="#6374dd" dark class="ml-4">Добавить уведомление</v-btn>
			<!-- <v-btn @click="addItem(false)" color="#6374dd" dark class="ml-4">Добавить индивидуальное оповещение</v-btn> -->
			<v-spacer></v-spacer>

			<v-text-field v-if="!notificationToPatientId" color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			sort-by="fio"
			:sort-desc="true"
			:headers="headers"
			:items="list"
			:search="search"
			:loading="loading"
			no-data-text="Данных нет"
			loading-text="Загрузка... Пожалуйста подождите..."
		>			
			<template v-slot:[`item.action`]="{ item }">
				<v-btn @click="editItems(item)" color="#6374dd" x-small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>

				<v-btn  v-if="!notificationToPatientId" @click="remove(item)" color="error" x-small icon>
					<v-icon class="w-20">mdi-trash-can-outline</v-icon>
				</v-btn>

				<!-- <v-btn v-if="notificationToPatientId && item.IsOn" @click="changePatientFromNotification(item)" color="error" x-small icon>
					<v-icon class="w-20">mdi-account-remove</v-icon>
				</v-btn>

				<v-btn v-if="notificationToPatientId && !item.IsOn" @click="changePatientFromNotification(item)" color="success" x-small icon>
					<v-icon class="w-20">mdi-account-plus</v-icon>
				</v-btn> -->
			</template>
		</v-data-table>	

		<v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Данные уведомления</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj, isCreate)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>				
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation class="mt-2"> 
							<v-row>
								<v-col cols="12" sm="12" md="12" >
									<v-text-field label="Название уведомления" outlined dense hide-details color="#6b59c7"  v-model="obj.NotificationTask.Name" :rules="[rules.required]" />
								</v-col>
								<v-col cols="4" sm="4" md="7">
                                    <v-select color="#6b59c7" :menu-props="{ bottom: true, offsetY: true }" label="Периодичность" dense hide-details :rules="[rules.required]" item-text="Name" item-value="Id" outlined :items="PeriodTypes" v-model="obj.ScheduleTask.PeriodType" />
                                </v-col>
								<v-col class="mt-2" md="auto">
									Способ получения
								</v-col>
								<v-col md="auto">
									<v-checkbox dense hide-details color="#6b59c7" v-model="obj.NotificationTask.LK" :rules="[rules.requiredLKEMAIL]" label="ЛК" />
								</v-col>
								<v-col md="auto">
									<v-checkbox dense hide-details color="#6b59c7"  v-model="obj.NotificationTask.EMAIL" :rules="[rules.requiredLKEMAIL]" label="E-mail" />
								</v-col>

								<v-col cols="12" sm="12" md="6">
									<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												:hide-details="!obj.StartDate || !obj.EndDate"
												dense
												outlined
												ref="tf5"
												:rules="[
													rules.required,
													obj.StartDate && obj.EndDate ? rules.dateFT(new Date(obj.StartDate).getTime(), new Date(obj.EndDate).getTime()) : true
												]"
												:value="dateFormatted"
												label="Срок действия уведомления ОТ"
												prepend-icon="mdi-calendar"
												readonly
												v-bind="attrs"
												v-on="on"
											/>
										</template>

										<v-date-picker color="#6374dd" ref="picker" :first-day-of-week="1" @change="(date) =>  $refs.menu.save(date)" v-model="obj.StartDate" />
									</v-menu>
								</v-col>

								<v-col cols="12" sm="12" md="6">
									<v-menu ref="menuED" v-model="menuED" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												:hide-details="!obj.StartDate || !obj.EndDate"
												dense
												outlined
												ref="tf5"
												:rules="[
													rules.required,
													obj.StartDate && obj.EndDate ? rules.dateFT(new Date(obj.StartDate).getTime(), new Date(obj.EndDate).getTime()) : true
												]"
												:value="dateFormattedED"
												label="Срок действия уведомления ДО"
												prepend-icon="mdi-calendar"
												readonly
												v-bind="attrs"
												v-on="on"
											/>
										</template>

										<v-date-picker color="#6374dd" ref="pickerED" :first-day-of-week="1" @change="(date) =>  $refs.menuED.save(date)" v-model="obj.EndDate" />
									</v-menu>
								</v-col>

								<v-col cols="12" sm="12" md="6">
									<v-checkbox color="#6b59c7" v-model="obj.ScheduleTask.IsActual" :label="`Статус уведомления: ${obj.ScheduleTask.IsActual ? 'Включен' : 'Выключен'}`" />
								</v-col>

								<v-col cols="12" sm="12" md="12">
									<v-select
										v-model="obj.NotificationAreas"
										:items="NotificationAreaTypes"
										item-text="Name"
										multiple
										outlined
										color="#6b59c7"
										:menu-props="{ bottom: true, offsetY: true }"
										dense
										hide-details
										label="Разделы, по которым будет приходить уведомление о заполнении"
										return-object
										:rules="[rules.required, rules.requiredArray]"
									>
										<template v-slot:selection="{ item }">
											<v-chip class="mt-1">
												<span>{{ item.Name }}</span>
											</v-chip>
										</template>
									</v-select>
								</v-col>
								
								<v-col cols="12" sm="12" md="12">
									<v-select
										v-model="obj.Patients"
										:items="Patients"
										item-text="full_name"
										item-value="id"
										multiple
										outlined
										color="#6b59c7"
										:menu-props="{ bottom: true, offsetY: true }"
										dense
										hide-details
										label="Пациенты, которые будут получать уведомление"
										return-object
										:rules="[rules.required, rules.requiredArray]"
									>
										<template v-slot:selection="{ item }">
											<v-chip class="mt-1">
												<span>{{ item.full_name }}</span>
											</v-chip>
										</template>
									</v-select>
								</v-col>
							</v-row>
						</v-form> 
					</v-container>				
				</v-card-text>
			</v-card>
		</v-dialog>	

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			
			<div v-if="Array.isArray(snackbar_text)">
				<v-icon style="align:center">mdi-information</v-icon>
					{{ snackbar_text[0] }}
				 <div>
					{{ snackbar_text[1] }}
				</div>
			</div>
			<div v-if="!Array.isArray(snackbar_text)">
				<v-icon>mdi-information</v-icon>
				{{ snackbar_text }}
			</div>
		</v-snackbar>	
	</v-card>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import {mask} from 'vue-the-mask'
import modalGenerateReport from '../../Header/modalGenerateReport.vue';

export default {
  components: { modalGenerateReport },
	directives: {mask},	
	props: {
		notificationToPatientId: String,
		randomChangedValue: Date
	},
	computed: {
		...mapGetters({
			apiUrl: 'apiUrl',
			user: 'user/user'
        }),
		dateFormatted() {
            if(this.obj.StartDate)
                return new Date(this.obj.StartDate).toLocaleDateString("ru-RU");
            return null;
        },
		dateFormattedED() {
            if(this.obj.EndDate)
                return new Date(this.obj.EndDate).toLocaleDateString("ru-RU");
            return null;
        }		
	},	
	watch: {
		menu (val) {			
			//val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
		},
		menuED (val) {
			//val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
		},		

		randomChangedValue()
		{
			this.updateList();
		},
    },
	data() {
		return {				
			dialog: false,
			overlay: false,
			list: [],
			loading: true,
			search: "",			
			headers: [
				// { text: "Id", value: "Id", align: 'center' },
				{ text: "Наименование", value: "Name", align: 'center' },
				{ text: "Способ получения", value: "ReceiveTypes", align: 'center' },                
				{ text: "Срок действия", value: "Period", align: 'center' },
				{ text: "Статус", value: "Status", align: 'center' },
				{ text: "", value: "action", sortable: false }
			],
			obj: {
				NotificationTask: { Name: "", LK:false, EMAIL: false, IsAll: true } ,
				ScheduleTask: { StartDate: null, EndDate: null, IsActual: false, PeriodType: null },	
				Patients: [],			
				StartDate : null,
				EndDate : null,				
				NotificationAreas: [],
				SinglePatient: null				
			},
			valid: true,
			menu: false,
			menuED: false,
			PeriodTypes: null,
			NotificationAreaTypes: null,			
			Patients: [],
			isCreate: false,
			allNotificationAreaTypes: true,
			allPatients: true,

			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			rules: {
				required: value => !!value || 'Обязательно для заполнения.',
				requiredArray: value => value && value.length > 0 || 'Обязательно для заполнения.',
				requiredLKEMAIL: value => true, 
				dateFT: (valF, valT)  => valF < valT || 'Дата ДО не должна быть раньше Даты ОТ!!!',
			},
		};
	},
	methods: {
		onAllPatientClicked()
		{			
			if (this.obj.Patients.length === this.Patients.length) { 
				this.obj.NotificationTask.IsAll = true
				return 'mdi-close-box'
			} 
			else if (this.obj.Patients.length > 0) { 
				this.obj.NotificationTask.IsAll = false
				return 'mdi-minus-box'				
			} 
			else {
				this.obj.NotificationTask.IsAll = false
				return  'mdi-checkbox-blank-outline'
			}
		},
		toggle1 () {			
			this.allNotificationAreaTypes = !this.allNotificationAreaTypes
			
			if (this.allNotificationAreaTypes) {
				this.obj.NotificationAreas.length = 0
				this.obj.NotificationAreas.push(...this.NotificationAreaTypes) 
			} else {
				this.obj.NotificationAreas = []					
			}			
		},

		togglePatients () {			
			this.allPatients = !this.allPatients
			
			if (this.allPatients) {
				this.obj.Patients.length = 0
				this.obj.Patients.push(...this.Patients) 
			} else {
				this.obj.Patients = []					
			}			
		},

		async apiMethods(url, name) {			
			return (await this.api.get(`${url}/${name}`)).data			
		},

		recalcField(x)
		{
			x.ReceiveTypes = (x.LK ? "ЛК" : "")+ (x.LK && x.EMAIL  ? ", " : "") + (x.EMAIL ? "E-mail" : "") 
			x.Status = (x.IsActual ? "Активно" : "Выключено")
			x.Period = new Date(x.StartDate).toLocaleDateString("ru-RU") + " - " + new Date(x.EndDate).toLocaleDateString("ru-RU")
			return x
		},
		
		updateList()
		{
			this.loading = true
			this.list.length =0;
			let getlisturl = this.notificationToPatientId ? `api/schedulednotification/GetNotificationTaskByPatientViews/${this.notificationToPatientId}` : `api/schedulednotification/getbydoctor`;  			
			let that = this
			this.apiMethods(this.apiUrl, getlisturl).then(res => {				
				that.list.push(...res)
				that.list.map(x=> { 
					return this.recalcField(x)
				})
				this.loading = false
			}); //список групповых оповещений
		},

		editItems(row) {
			this.isCreate = false;
			this.apiMethods(this.apiUrl, `api/schedulednotification/${row.Id}`)
			.then(data =>{
				this.assignItem(data, false);						
			});				
		},

		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},

		save(obj, isCreate) {
			// console.log(Math.floor((new Date(obj.EndDate).getTime() - new Date(obj.StartDate).getTime())/(1000*60*60*24)));
			
			// Если выбранна переодичность ежемесячно
			if(obj.ScheduleTask.PeriodType == '6593250d-fc17-45a1-94d7-d2c498233d4e' && Math.floor((new Date(obj.EndDate).getTime() - new Date(obj.StartDate).getTime())/(1000*60*60*24)) < 56 ) {
				this.snackBarFunc(true, 'Минимальное значение для периодичности ежемесячно - 2 месяца', 'error')
				return;
			}
			
			//Если выбранна переодичность еженедельно
			if(obj.ScheduleTask.PeriodType == '58b0dbfb-d6b0-47d6-8a7e-bd02adb8b1f4' && Math.floor((new Date(obj.EndDate).getTime() - new Date(obj.StartDate).getTime())/(1000*60*60*24)) < 14 ) {
				this.snackBarFunc(true, 'Минимальное значение для периодичности еженедельно - 2 недели', 'error')
				return;
			}
			
			//Если выбранна переодичность 2 раза в месяц
			if(obj.ScheduleTask.PeriodType == '58babe75-de63-4ae0-893d-7d203292b5be' && Math.floor((new Date(obj.EndDate).getTime() - new Date(obj.StartDate).getTime())/(1000*60*60*24)) < 28 ) {
				this.snackBarFunc(true, 'Минимальное значение для периодичности 2 раза в месяц - 1 месяц', 'error')
				return;
			}
			
			//Если выбранна переодичность ежеквартально
			if(obj.ScheduleTask.PeriodType == '4cda4582-2c15-4a80-984b-137f2c5fe86a' && Math.floor((new Date(obj.EndDate).getTime() - new Date(obj.StartDate).getTime())/(1000*60*60*24)) < 168 ) {
				this.snackBarFunc(true, 'Минимальное значение для периодичности ежеквартально - 6 месяцев', 'error')
				return;
			}

			var okNotifType = this.obj.NotificationTask.LK || this.obj.NotificationTask.EMAIL
			
			if(this.$refs.form.validate() && okNotifType) {
				this.obj.ScheduleTask.StartDate = new Date(this.obj.StartDate).getTime()
				this.obj.ScheduleTask.EndDate = new Date(this.obj.EndDate).getTime() 

				if (!this.obj.NotificationTask.IsGroup) {
					this.obj.Patients = this.obj.Patients.map(m => ({id: m.id}))

					// var patients = this.Patients.filter(x=> x.id === this.obj.SinglePatient)
					// this.obj.Patients = [patients[0]]
				}
				this.overlay = true
				let url = `${this.apiUrl}/api/schedulednotification/update`

				obj.NotificationTask.IsAll = obj.Patients.length === this.Patients.length ? true : false

				this.api[!isCreate ? 'put' : 'post'](url, obj)
					.then(res => {
						this.recalcField(res.data)
						if(isCreate) {
							this.snackBarFunc(true, 'Уведомление успешно создано!!!', 'success')
							//Добавление в массив нового уведомления
							this.list.unshift(res.data)							
						} else {	
							//обновление объекта
							Object.assign(this.list.filter(x=> x.Id === res.data.Id)[0], res.data)					
							this.snackBarFunc(true, 'Уведомление успешно обновлено!!!', 'success')
						}
						this.overlay = false
						this.dialog = false
						this.close()					
					})
					.catch(error => {
						if(error) {
							this.snackBarFunc(true, 'Ошибка при обновлении данных', 'error')
							this.overlay = false
						}
					})
			}
			else {

				this.snackBarFunc(true, [ 'Заполните обязательные поля' , (okNotifType ? '': 'Выберите способ получения')], 'warning')
			}
		},

		assignItem(data, isCreate)
		{			 
			Object.assign(this.obj.ScheduleTask, data.ScheduleTask)
			Object.assign(this.obj.NotificationTask, data.NotificationTask)
			var ids = data.Patients.map(x=> x.id)			
			var patients = this.Patients.filter(x=> ids.indexOf(x.id) >= 0)
			this.obj.Patients.length =0; this.obj.Patients.push(...patients); 
			this.obj.NotificationAreas.length = 0; this.obj.NotificationAreas.push(...data.NotificationAreas);								
			this.obj.StartDate = this.toJSONLocal(this.obj.ScheduleTask.StartDate);
			this.obj.EndDate = this.toJSONLocal(this.obj.ScheduleTask.EndDate);	
			if (!data.NotificationTask.IsGroup && patients.length > 0)
			{
				this.obj.SinglePatient = patients[0].id
			}
			
			if (this.notificationToPatientId)
			{
				if (this.obj.Patients.filter(x=> x.id === this.notificationToPatientId).length == 0) {
					this.obj.Patients.push(...this.Patients.filter(x=> x.id === this.notificationToPatientId))
				}				 
			}
			else if (isCreate)
			{
				this.obj.Patients.push(...this.Patients)
			}

			this.dialog = true
		},

		addItem(isGroup){
			this.dialog = true
			this.isCreate = true;
			// let that = this;
			// this.apiMethods(this.apiUrl, `api/schedulednotification/getnew/${isGroup}`).then(data => {
			// 	that.assignItem(data, true);				
			// });
		},

		remove(row){
			//this.overlay = true
			let url = `${this.apiUrl}/api/schedulednotification/${row.Id}`
			if(confirm('Вы уверены что хотите удалить уведомление ?')) {
					this.api.delete(url)
					.then(res => {
						if(res.status == 200) {
							this.snackbar = true
							this.snackbar_text = 'Уведомление успешно удалено!!!'
							this.snackbarColor = 'success'

							const index = this.list.indexOf(row);
							this.list.splice(index, 1)
						}

						//this.overlay = false
					})
				}		
		},

		changePatientFromNotification(item)
		{
			let that = this;
			let val = item.IsOn ? 'false' : 'true'
			let valMsg = item.IsOn ? 'отключен' : 'включен'
			let valQuest = item.IsOn ? 'отключить' : 'включить'
			let url = `${this.apiUrl}/api/schedulednotification/${item.Id}/changePatientFromNotification/${this.notificationToPatientId}/${val}`
			if(confirm(`Вы уверены что хотите ${valQuest} пациента от уведомления ?`)) {
					this.api.delete(url)
					.then(res => {
						if(res.status == 200) {
							this.snackbar = true
							this.snackbar_text = `Пациент успешно ${valMsg}!!!`
							this.snackbarColor = 'success'
							//обновление объекта
							Object.assign(that.list.filter(x=> x.Id === res.data.Id)[0], res.data)
						}						
					})
				}
		},

		close() {
			this.obj = {
				NotificationTask: { Name: "", LK:false, EMAIL: false, IsAll: true } ,
				ScheduleTask: { StartDate: null, EndDate: null, IsActual: true, PeriodType: null },	
				Patients: [],			
				StartDate : null,
				EndDate : null,				
				NotificationAreas: [],
				SinglePatient: null	
			}
			this.$refs.form.resetValidation()
			this.dialog = false
		},		 

		 toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
			
	},	
	async created() {			
		this.updateList()
		this.PeriodTypes = await this.apiMethods(this.apiUrl, `api/schedulednotification/periodtypes`);
		this.NotificationAreaTypes = await this.apiMethods(this.apiUrl, `api/schedulednotification/notificationareatypes`);
		this.Patients = await this.apiMethods(this.apiUrl, `doctors/${this.user.id}/patients`)
		this.Patients.map(item => {
                    item.full_name = item.last_name + ' ' + item.first_name + ' ' + item.patronymic
                    return item;
                });	
		
		this.loading = false     
	}
};
</script>


<style scoped>

.fs-16 {
	font-size: 16px!important;
}

</style>