<template>
	<v-card>
		<v-data-table no-results-text="Поиск не дал результатов"
            hide-default-footer
            dense
            :headers="headers"
            :items="list"
        >
            <template v-slot:[`item.isActive`]="{ item }">
				{{item.isActive ? 'Да' : 'Нет'}}
			</template>
        </v-data-table>
	</v-card>
</template>


<script>
export default {
	props: ['sys'],
    data: () => ({
        headers: [
            { text: "Название лимфоузла", value: "name", align: 'center', sortable: false },
            { text: "Увеличенный лимфоузел", value: "isActive", align: 'center', sortable: false },
        ]
    }),
	computed: {
        list() {
            return this.sys.filter(i => i.isActive)
        }
    }
}
</script>