<template>
	<circle 
		v-if="joint"
		class="st2" 
		:class="{'st4': ( joint.HasPictures || ( joint.items && joint.items.length )), 'st6': joint.active}" 
		:cx="circle.cx" 
		:cy="circle.cy" 
		:r="circle.r" 
		@click="setActiveJoint(joint.id)"/>

</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	computed: {
		...mapGetters({
			joints: 'manifestationOfDisease/joints',
		}),
		joint() {
			return this.joints.find(i => i.numeric_id == this.circle.n )
		},
	},
	methods: {
		...mapActions({
			setActiveJoint: 'manifestationOfDisease/setActiveJoint'
		})
	},
	props: ['circle']
	
}
</script>
