<template>
	<v-container fluid>
		<v-row justify="center" align="center">
			<v-col xs="12" sm="12" md="12" lg="12" xl="12">
				<v-table headerName="Врачи" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import vTable from '../grid/tableDoc'
export default {
    components: { vTable }
}
</script>