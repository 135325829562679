<template>
<div id="osn" class="Questionnaire">
<center>

<div style="width: 95%;">
    <el-steps :active="active" finish-status="success">
        <el-step title="Одевание и туалет" icon="el-icon-edit" class="medium"></el-step>
        <el-step title="Подъем" icon="el-icon-edit"></el-step>
        <el-step title="Еда" icon="el-icon-edit" class="short"></el-step>
        <el-step title="Ходьба" icon="el-icon-edit"></el-step>
        <el-step title="Приспособления и дополнительная помощь" icon="el-icon-edit" class="long"></el-step>
        <el-step title="Гигиена" icon="el-icon-edit"></el-step>
        <el-step title="Достать что-либо" icon="el-icon-edit" class="medium"></el-step>
        <el-step title="Сжатие" icon="el-icon-edit" class="short"></el-step>
        <el-step title="Функции" icon="el-icon-edit"></el-step>
        <el-step title="Приспособления и дополнительная помощь" icon="el-icon-edit" class="long"></el-step>
        <el-step title="Боль" icon="el-icon-edit" class="short"></el-step>
        <el-step title="Финальная оценка" icon="el-icon-edit"></el-step>
    </el-steps>
</div>

<div v-if="this.active === 0 || this.active === 1 || this.active === 2 || this.active === 3 || this.active === 4 || this.active === 5 || this.active === 6 || this.active === 7 || this.active === 8 || this.active === 9">
    <p class="blockopr">
    Мы хотим получить информацию о том, как болезнь Вашего ребенка влияет на его/ее приспособленность к жизни.
    Отвечая на вопросы, Вы должны отметить один ответ, который наиболее полно отражает физические возможности ребенка (среднее состояние в течение всего дня) ЗА ПОСЛЕДНЮЮ НЕДЕЛЮ.  
    ИМЕЙТЕ В ВИДУ ТОЛЬКО ТЕ ПРОБЛЕМЫ, КОТОРЫЕ ОБУСЛОВЛЕНЫ БОЛЕЗНЬЮ. Если ребенок из-за возраста не может выполнить какую либо из функций, применяется термин “Нельзя оценить”.
    Например, если Ваш ребенок из-за возраста с трудом выполняет некоторые функции или не может вовсе их выполнить, но это не связано с его болезнью, 
    используйте термин “НЕЛЬЗЯ оценить”.
    </p>
</div>

<div v-if="this.active === 10">
    <p class="blockopr">
    Кроме того, мы хотели бы уточнить сопровождается ли болезнь Вашего ребенка болью или нет?<br>Если “да”, оцените, какую боль испытывал Ваш ребенок ЗА ПОСЛЕДНЮЮ НЕДЕЛЮ?<br>Дайте оценку боли Вашего ребенка, отмечая ее на специальной линии.
    </p>
</div>
<div v-if="this.active === 11">
    <p class="blockopr">
    Учитывая все негативные стороны болезни, дайте общую оценку состояния здоровья Вашего ребенка, отмечая это на специальной линии.
    </p>
</div>


<div class="quest">

<div v-if="this.active === 0 && Object.keys(this.dataEdit).length > 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Одеться, включая завязывание шнурков и застёгивание пуговиц?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q1">
        <el-radio style="color:black" :label="0">Без затруднений</el-radio>
        <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
        <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
        <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
        <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Вымыть шампунем свои волосы?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q2">
        <el-radio style="color:black" :label="0">Без затруднений</el-radio>
        <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
        <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
        <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
        <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Снять носки?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q3">
        <el-radio style="color:black" :label="0">Без затруднений</el-radio>
        <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
        <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
        <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
        <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Подстричь ногти?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q4">
        <el-radio style="color:black" :label="0">Без затруднений</el-radio>
        <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
        <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
        <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
        <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>
<div v-if="this.active === 0 && Object.keys(this.dataEdit).length === 0">
<h4>Может ли Ваш ребенок:</h4>
<p>Одеться, включая завязывание шнурков и застёгивание пуговиц?</p>
<el-radio-group v-model="newData.q1">
  <el-radio style="color:black" :label="0">Без затруднений</el-radio>
  <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
  <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
  <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
  <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
</el-radio-group>
<p>Вымыть шампунем свои волосы?</p>
<el-radio-group v-model="newData.q2">
  <el-radio style="color:black" :label="0">Без затруднений</el-radio>
  <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
  <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
  <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
  <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
</el-radio-group>
<p>Снять носки?</p>
<el-radio-group v-model="newData.q3">
  <el-radio style="color:black" :label="0">Без затруднений</el-radio>
  <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
  <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
  <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
  <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
</el-radio-group>
<p>Подстричь ногти?</p>
<el-radio-group v-model="newData.q4">
  <el-radio style="color:black" :label="0">Без затруднений</el-radio>
  <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
  <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
  <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
  <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
</el-radio-group>
</div>


<div v-if="this.active === 1 && Object.keys(this.dataEdit).length > 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Встать с низкого кресла или пола?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q5">
        <el-radio style="color:black" :label="0">Без затруднений</el-radio>
        <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
        <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
        <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
        <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Лечь и встать с постели либо встать в детской кроватке?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q6">
        <el-radio style="color:black" :label="0">Без затруднений</el-radio>
        <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
        <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
        <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
        <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>
<div v-if="this.active === 1 && Object.keys(this.dataEdit).length === 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Встать с низкого кресла или пола?</p>
    <el-radio-group v-model="newData.q5">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Лечь и встать с постели либо встать в детской кроватке?</p>
    <el-radio-group v-model="newData.q6">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>

<div v-if="this.active === 2 && Object.keys(this.dataEdit).length > 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Порезать кусок мяса?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q7">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
  <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
  <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
  <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
  <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Поднести ко рту чашку или стакан?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q8">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Открыть новую коробку с кукурузными хлопьями?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q9">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>
<div v-if="this.active === 2  && Object.keys(this.dataEdit).length === 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Порезать кусок мяса?</p>
    <el-radio-group v-model="newData.q7">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
  <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
  <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
  <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
  <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Поднести ко рту чашку или стакан?</p>
    <el-radio-group v-model="newData.q8">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Открыть новую коробку с кукурузными хлопьями?</p>
    <el-radio-group v-model="newData.q9">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>

<div v-if="this.active === 3 && Object.keys(this.dataEdit).length > 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Ходить вне дома по ровной земле?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q10">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Подняться на пять ступеней?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q11">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>
<div v-if="this.active === 3 && Object.keys(this.dataEdit).length === 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Ходить вне дома по ровной земле?</p>
    <el-radio-group v-model="newData.q10">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Подняться на пять ступеней?</p>
    <el-radio-group v-model="newData.q11">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>


<div v-if="this.active === 4 && Object.keys(this.dataEdit).length > 0">
    <p>Укажите, какие ПРИСПОСОБЛЕНИЯ использует Ваш ребенок, выполняя указанные функции:</p>
    <el-row class="el-checkbox-group">
        <el-col :xs="24" :md="6"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.rod">Трость</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.hod">Ходилки</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.kos">Костыли</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.inv">Инвалидное кресло</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.odev">При одевании (пуговичный крючок для молнии, обувной рожок с длинной ручкой и т.д.)</el-checkbox></el-col><br>
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.pencil">Толстый карандаш или специальные приспособления</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.chair">Специальное или возвышенное кресло</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.other">Другие </el-checkbox></el-col>
    </el-row>
    <el-input
        v-if="this.dataEdit.other === true"
        style="width:60%;margin-top: 10px;"
        type="textarea"
        v-model="dataEdit.otherDevices"
        :rows="3"
        placeholder="Уточните">
    </el-input>
    <p>Укажите, при каких ежедневных действиях ребенка ему требуется ИЗ-ЗА БОЛЕЗНИ дополнительная помощь других лиц:</p>
    <el-row class="el-checkbox-group">
        <el-col :xs="24" :md="6"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.hputon">Одевание и туалет</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.hgetup">Подъем</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.heat">Еда</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.hwalk">Ходьба</el-checkbox></el-col>
    </el-row>
</div>
<div v-if="this.active === 4  && Object.keys(this.dataEdit).length === 0">
    <p>Укажите, какие ПРИСПОСОБЛЕНИЯ использует Ваш ребенок, выполняя указанные функции:</p>
    <el-row class="el-checkbox-group">
        <el-col :xs="24" :md="6"><el-checkbox v-model="newData.rod">Трость</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox v-model="newData.hod">Ходилки</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox v-model="newData.kos">Костыли</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox v-model="newData.inv">Инвалидное кресло</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.odev">При одевании (пуговичный крючок для молнии, обувной рожок с длинной ручкой и т.д.)</el-checkbox></el-col><br>
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.pencil">Толстый карандаш или специальные приспособления</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.chair">Специальное или возвышенное кресло</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.other">Другие </el-checkbox></el-col>
    </el-row>
    <el-input
        v-if="this.newData.other === true"
        style="width:60%;margin-top: 10px;"
        type="textarea"
        v-model="newData.otherDevices"
        :rows="3"
        placeholder="Уточните">
    </el-input>
    <p>Укажите, при каких ежедневных действиях ребенка ему требуется ИЗ-ЗА БОЛЕЗНИ дополнительная помощь других лиц:</p>
    <el-row class="el-checkbox-group">
        <el-col :xs="24" :md="6"><el-checkbox v-model="newData.hputon">Одевание и туалет</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox v-model="newData.hgetup">Подъем</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox v-model="newData.heat">Еда</el-checkbox></el-col>
        <el-col :xs="24" :md="6"><el-checkbox v-model="newData.hwalk">Ходьба</el-checkbox></el-col>
    </el-row>
</div>

<div v-if="this.active === 5 && Object.keys(this.dataEdit).length > 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Вымыть и вытереть всё тело?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q12">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Войти и выйти из ванны?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q13">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Сесть и встать с унитаза или горшка?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q14">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Чистить зубы?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q15">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Причесаться?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q16">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>
<div v-if="this.active === 5  && Object.keys(this.dataEdit).length === 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Вымыть и вытереть всё тело?</p>
    <el-radio-group v-model="newData.q12">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Войти и выйти из ванны?</p>
    <el-radio-group v-model="newData.q13">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Сесть и встать с унитаза или горшка?</p>
    <el-radio-group v-model="newData.q14">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Чистить зубы?</p>
    <el-radio-group v-model="newData.q15">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Причесаться?</p>
    <el-radio-group v-model="newData.q16">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>

<div v-if="this.active === 6 && Object.keys(this.dataEdit).length > 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Взять на уровне головы и опустить вниз тяжелую вещь (большую игру, книги)?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q17">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Нагнуться и поднять с пола одежду или лист бумаги?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q18">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Надеть свитер через голову?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q19">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Повернув шею, посмотреть назад?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q20">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>
<div v-if="this.active === 6  && Object.keys(this.dataEdit).length === 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Взять на уровне головы и опустить вниз тяжелую вещь (большую игру, книги)?</p>
    <el-radio-group v-model="newData.q17">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Нагнуться и поднять с пола одежду или лист бумаги?</p>
    <el-radio-group v-model="newData.q18">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Надеть свитер через голову?</p>
    <el-radio-group v-model="newData.q19">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Повернув шею, посмотреть назад?</p>
    <el-radio-group v-model="newData.q20">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>

<div v-if="this.active === 7 && Object.keys(this.dataEdit).length > 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Писать ручкой или карандашом?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q21">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Открыть дверь автомобиля?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q22">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Открыть ранее вскрытую банку?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q23">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Открыть и закрыть водопроводный кран?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q24">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Отворить дверь, предварительно повернув дверную ручку?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q25">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>
<div v-if="this.active === 7 && Object.keys(this.dataEdit).length === 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Писать ручкой или карандашом?</p>
    <el-radio-group v-model="newData.q21">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Открыть дверь автомобиля?</p>
    <el-radio-group v-model="newData.q22">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Открыть ранее вскрытую банку?</p>
    <el-radio-group v-model="newData.q23">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Открыть и закрыть водопроводный кран?</p>
    <el-radio-group v-model="newData.q24">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Отворить дверь, предварительно повернув дверную ручку?</p>
    <el-radio-group v-model="newData.q25">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>

<div v-if="this.active === 8 && Object.keys(this.dataEdit).length > 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Выполнить поручения вне дома, ходить в магазин?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q26">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Войти и выйти из машины, детской машины, школьного автобуса?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q27">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Ездить на велосипеде?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q28">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Выполнять работу по дому (мыть посуду, выносить мусор, пылесосить, работать во дворе, убирать постель и комнату)?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q29">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Бегать и играть?</p>
    <el-radio-group :disabled="doc.role === 2" v-model="dataEdit.q30">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>
<div v-if="this.active === 8 && Object.keys(this.dataEdit).length === 0">
    <h4>Может ли Ваш ребенок:</h4>
    <p>Выполнить поручения вне дома, ходить в магазин?</p>
    <el-radio-group v-model="newData.q26">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Войти и выйти из машины, детской машины, школьного автобуса?</p>
    <el-radio-group v-model="newData.q27">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Ездить на велосипеде?</p>
    <el-radio-group v-model="newData.q28">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Выполнять работу по дому (мыть посуду, выносить мусор, пылесосить, работать во дворе, убирать постель и комнату)?</p>
    <el-radio-group v-model="newData.q29">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
    <p>Бегать и играть?</p>
    <el-radio-group v-model="newData.q30">
    <el-radio style="color:black" :label="0">Без затруднений</el-radio>
    <el-radio style="color:black" :label="1">Умеренные затруднения</el-radio>
    <el-radio style="color:black" :label="2">Серьезные трудности</el-radio>
    <el-radio style="color:black" :label="3">Не может выполнить</el-radio>
    <el-radio style="color:black" :label="-1">Нельзя оценить</el-radio>
    </el-radio-group>
</div>

<div v-if="this.active === 9 && Object.keys(this.dataEdit).length > 0">
    <p>Укажите ПРИСПОСОБЛЕНИЯ, необходимые ребенку при выполнении следующих функций:</p>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.sidun">Приподнятое сидение унитаза</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.sidvan">Специальное сидение в ванной</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.kons">Консервный нож (если банка уже вскрыта)</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.per">Специальная перекладина в ванной комнате</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.longget">Предметы с длинной ручкой, чтобы что-то достать</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.longwash">Предметы с длинной ручкой для мытья в ванной</el-checkbox></el-col>
    </el-row>
    <p>Укажите повседневные функции, при выполнении которых ребенок ИЗ-ЗА БОЛЕЗНИ нуждается в дополнительной помощи других лиц:</p>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.gig">Гигиена</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.questget">Для того, чтобы что-нибудь достать</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.questopen">Сжатие и открывание предметов</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox :disabled="doc.role === 2" v-model="dataEdit.home">Выполнение поручений и работа по дому</el-checkbox></el-col>
    </el-row>
</div>
<div v-if="this.active === 9 && Object.keys(this.dataEdit).length === 0">
    <p>Укажите ПРИСПОСОБЛЕНИЯ, необходимые ребенку при выполнении следующих функций:</p>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.sidun">Приподнятое сидение унитаза</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.sidvan">Специальное сидение в ванной</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.kons">Консервный нож (если банка уже вскрыта)</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.per">Специальная перекладина в ванной комнате</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.longget">Предметы с длинной ручкой, чтобы что-то достать</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.longwash">Предметы с длинной ручкой для мытья в ванной</el-checkbox></el-col>
    </el-row>
    <p>Укажите повседневные функции, при выполнении которых ребенок ИЗ-ЗА БОЛЕЗНИ нуждается в дополнительной помощи других лиц:</p>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.gig">Гигиена</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.questget">Для того, чтобы что-нибудь достать</el-checkbox></el-col>
    </el-row>
    <el-row class="el-checkbox-group">
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.questopen">Сжатие и открывание предметов</el-checkbox></el-col>
        <el-col :sm="24" :md="12"><el-checkbox v-model="newData.home">Выполнение поручений и работа по дому</el-checkbox></el-col>
    </el-row>
</div>

<div v-if="this.active === 10 && Object.keys(this.dataEdit).length > 0">
    <p style="padding-bottom:30px">Установите оценку боли</p>
    <p class="leftp">Без боли</p>
    <p class="rightp">Очень сильная боль</p>
    <dx-slider
    :min="0"
    :max="100"
    :disabled="doc.role === 2"
    v-model="dataEdit.pain_asses"
    :label="label"
    :tooltip="tooltip"
    />
</div>
<div v-if="this.active === 10 && Object.keys(this.dataEdit).length === 0">
    <p style="padding-bottom:30px">Установите оценку боли</p>
    <p class="leftp">Без боли</p>
    <p class="rightp">Очень сильная боль</p>
    <dx-slider
    :min="0"
    :max="100"
    v-model="newData.pain_asses"
    :label="label"
    :tooltip="tooltip"
    />
</div>

<div v-if="this.active === 11 && Object.keys(this.dataEdit).length > 0">
    <p style="padding-bottom:30px">Установите оценку состояния</p>
    <p class="leftp2">Очень хорошее</p>
    <p class="rightp2">Очень плохое</p>
    <dx-slider
    :min="0"
    :max="100"
    :disabled="doc.role === 2"
    v-model="dataEdit.cond_asses"
    :label="label"
    :tooltip="tooltip"
    />
</div>
<div v-if="this.active === 11 && Object.keys(this.dataEdit).length === 0">
    <p style="padding-bottom:30px">Установите оценку состояния</p>
    <p class="leftp2">Очень хорошее</p>
    <p class="rightp2">Очень плохое</p>
    <dx-slider
    :min="0"
    :max="100"
    v-model="newData.cond_asses"
    :label="label"
    :tooltip="tooltip"
    />
</div>

</div>
<el-row>
<!-- <el-button v-if="this.active > 0" @click="back">Вернутся назад</el-button>
<el-button @click="next">Вперед</el-button> -->
<el-button type="primary" @click="back" v-if="this.active > 0" icon="el-icon-d-arrow-left" circle></el-button>
<el-button type="primary" @click="next" v-if="this.active <= 10" icon="el-icon-d-arrow-right" circle></el-button>
<el-button v-if="this.active === 11 && this.saveQ === false" type="success" @click="savequestchaq" plain>Сохранить пройденный опрос</el-button>
<el-button v-if="this.active === 11 && this.savePutBtn === true && doc.role !== 2" type="success" @click="saveQuestPut" plain>Сохранить изменения</el-button>
<el-button v-if="this.active === 11 && this.savePutBtn === true && doc.role === 2" type="success" @click="visibleEmit" plain>Прекратить просмотр</el-button>

</el-row>

</center>
</div>
</template>
<script>
//import checkbox from './element/checkbox.vue'
import { mapGetters, mapActions } from 'vuex';
import { DxSlider } from 'devextreme-vue'
export default {
    props: ['dataEdit', 'visibleQ', 'idRow'],
    mounted() {
        //console.log(this.$emit)
    },
    components: { DxSlider },
    data() {
        return {
            visibleQuestionnarie: false,
            tooltip: {
                enabled: true,
                format(value) {
                    return value + "%";
                },
                showMode:"always",
                position:"top"
            },
            label: {
                visible: true,
                position: "top"
            },
            activeIndex: '5',
        }
    },
    computed: {
		...mapGetters({
            newData: 'qualityOfLifeQuestionnaire/newData',
            active: 'qualityOfLifeQuestionnaire/active',
            saveQ: 'qualityOfLifeQuestionnaire/saveQ',
            doc: 'user/user',
            savePutBtn: 'qualityOfLifeQuestionnaire/savePutBtn',
            visQuest: 'qualityOfLifeQuestionnaire/visQuest'
        }),
    },
    methods: {
        visibleEmit() {
            this.$emit('visibleEmit', {
                visibleQ: false
            })
        },
        saveQuestPut() {
            this.editQuest().then(res => {
                if(res.status === 200) {
                    this.savePutBtnOff();
                    this.visibleEmit();
                }
            })
            .catch(error => console.log(error));
            
        },
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
        savequestchaq() {
            this.createQuest(this.newData).then(res => {
                if(res.status === 200) {
                    this.newDataNull();
                    this.visibleEmit();
                    this.activeOff();
                }
            })
            .catch(error => console.log(error));
        },
        ...mapActions({
            visibleOff: 'qualityOfLifeQuestionnaire/visibleOff',
            savePutBtnOff: 'qualityOfLifeQuestionnaire/savePutBtnOff',
            editQuest(dispatch, e) {
                let editQ = {
                    creation_date: this.dataEdit.creation_date,
                    id: this.dataEdit.id,
                    questdate: this.dataEdit.questdate,
                    //1
                    q1: this.dataEdit.q1,
                    q2: this.dataEdit.q2,
                    q3: this.dataEdit.q3,
                    q4: this.dataEdit.q4,
                    //2
                    q5: this.dataEdit.q5,
                    q6: this.dataEdit.q6,
                    //3
                    q7: this.dataEdit.q7,
                    q8: this.dataEdit.q8,
                    q9: this.dataEdit.q9,
                    //4
                    q10: this.dataEdit.q10,
                    q11: this.dataEdit.q11,
                    //5
                    rod: this.dataEdit.rod,
                    hod: this.dataEdit.hod,
                    kos: this.dataEdit.kos,
                    inv: this.dataEdit.inv,
                    odev: this.dataEdit.odev,
                    pencil: this.dataEdit.pencil,
                    chair: this.dataEdit.chair,
                    other: this.dataEdit.other,
                    otherDevices: this.dataEdit.otherDevices,
                    //6
                    hputon: this.dataEdit.hputon,
                    hgetup: this.dataEdit.hgetup,
                    heat: this.dataEdit.heat,
                    hwalk: this.dataEdit.hwalk,
                    //7
                    q12: this.dataEdit.q12,
                    q13: this.dataEdit.q13,
                    q14: this.dataEdit.q14,
                    q15: this.dataEdit.q15,
                    q16: this.dataEdit.q16,
                    //8
                    q17: this.dataEdit.q17,
                    q18: this.dataEdit.q18,
                    q19: this.dataEdit.q19,
                    q20: this.dataEdit.q20,
                    //9
                    q21: this.dataEdit.q21,
                    q22: this.dataEdit.q22,
                    q23: this.dataEdit.q23,
                    q24: this.dataEdit.q24,
                    q25: this.dataEdit.q25,
                    //10
                    q26: this.dataEdit.q26,
                    q27: this.dataEdit.q27,
                    q28: this.dataEdit.q28,
                    q29: this.dataEdit.q29,
                    q30: this.dataEdit.q30,
                    //11
                    sidun: this.dataEdit.sidun,
                    sidvan: this.dataEdit.sidvan,
                    kons: this.dataEdit.kons,
                    per: this.dataEdit.per,
                    longget: this.dataEdit.longget,
                    longwash: this.dataEdit.longwash,
                    //12
                    gig: this.dataEdit.gig,
                    questget: this.dataEdit.questget,
                    questopen: this.dataEdit.questopen,
                    home: this.dataEdit.home,
                    pain_asses: this.dataEdit.pain_asses,
                    cond_asses: this.dataEdit.cond_asses
                }
                return dispatch('qualityOfLifeQuestionnaire/editQuest', editQ)
            },
            createQuest(dispatch) {
                let dataQ = {
                    creation_date: new Date().getTime(),
                    questdate: this.toJSONLocal(),
                    //1
                    q1: this.newData.q1,
                    q2: this.newData.q2,
                    q3: this.newData.q3,
                    q4: this.newData.q4,
                    //2
                    q5: this.newData.q5,
                    q6: this.newData.q6,
                    //3
                    q7: this.newData.q7,
                    q8: this.newData.q8,
                    q9: this.newData.q9,
                    //4
                    q10: this.newData.q10,
                    q11: this.newData.q11,
                    //5
                    rod: this.newData.rod,
                    hod: this.newData.hod,
                    kos: this.newData.kos,
                    inv: this.newData.inv,
                    odev: this.newData.odev,
                    pencil: this.newData.pencil,
                    chair: this.newData.chair,
                    other: this.newData.other,
                    otherDevices: this.newData.otherDevices,
                    //6
                    hputon: this.newData.hputon,
                    hgetup: this.newData.hgetup,
                    heat: this.newData.heat,
                    hwalk: this.newData.hwalk,
                    //7
                    q12: this.newData.q12,
                    q13: this.newData.q13,
                    q14: this.newData.q14,
                    q15: this.newData.q15,
                    q16: this.newData.q16,
                    //8
                    q17: this.newData.q17,
                    q18: this.newData.q18,
                    q19: this.newData.q19,
                    q20: this.newData.q20,
                    //9
                    q21: this.newData.q21,
                    q22: this.newData.q22,
                    q23: this.newData.q23,
                    q24: this.newData.q24,
                    q25: this.newData.q25,
                    //10
                    q26: this.newData.q26,
                    q27: this.newData.q27,
                    q28: this.newData.q28,
                    q29: this.newData.q29,
                    q30: this.newData.q30,
                    //11
                    sidun: this.newData.sidun,
                    sidvan: this.newData.sidvan,
                    kons: this.newData.kons,
                    per: this.newData.per,
                    longget: this.newData.longget,
                    longwash: this.newData.longwash,
                    //12
                    gig: this.newData.gig,
                    questget: this.newData.questget,
                    questopen: this.newData.questopen,
                    home: this.newData.home,
                    pain_asses: this.newData.pain_asses,
                    cond_asses: this.newData.cond_asses
                }
               return dispatch('qualityOfLifeQuestionnaire/createQuest', dataQ)
            },
            newDataNull(dispatch) {
               dispatch('qualityOfLifeQuestionnaire/newDataNull')
            },
            activeOff(dispatch) {
                dispatch('qualityOfLifeQuestionnaire/activeOff')
            },
            activeOn(dispatch) {
                dispatch('qualityOfLifeQuestionnaire/activeOn')
            },
            activeBack(dispatch) {
                dispatch('qualityOfLifeQuestionnaire/activeBack')
            }
		}),
      next() {
            if (this.active === 0){
                let d;
                if(Object.keys(this.dataEdit).length > 0) {
                    d = this.dataEdit
                } else if(Object.keys(this.dataEdit).length === 0) {
                    d = this.newData
                }
                if(d.q1 !== null && d.q2 !== null && d.q3 !== null && d.q4 !== null) {
                    this.activeOn();
                } else {
                    this.$message.error('Заполните все поля!!!');
                    return
                }
            }
            else if (this.active === 1){
                let r;
                if(Object.keys(this.dataEdit).length > 0) {
                    r = this.dataEdit
                } else if(Object.keys(this.dataEdit).length === 0) {
                    r = this.newData
                }
                if(this.active === 1 && r.q5 !== null && r.q6 !== null) {
                this.activeOn();
                } else {
                    this.$message.error('Заполните все поля!!!');
                    return
                }
            }
            else if (this.active === 2){
                let t;
                if(Object.keys(this.dataEdit).length > 0) {
                    t = this.dataEdit
                } else if(Object.keys(this.dataEdit).length === 0) {
                    t = this.newData
                }
                if(this.active === 2 && t.q7 !== null && t.q8 !== null && t.q9 !== null) {
                this.activeOn();
                } else {
                    this.$message.error('Заполните все поля!!!');
                    return
                }
            }
            else if (this.active === 3){
                let y;
                if(Object.keys(this.dataEdit).length > 0) {
                    y = this.dataEdit
                } else if(Object.keys(this.dataEdit).length === 0) {
                    y = this.newData
                }
                if(this.active === 3 && y.q10 !== null && y.q11 !== null) {
                this.activeOn();
                } else {
                    this.$message.error('Заполните все поля!!!');
                    return
                }
            }
            else if (this.active === 5){
                let o;
                if(Object.keys(this.dataEdit).length > 0) {
                    o = this.dataEdit
                } else if(Object.keys(this.dataEdit).length === 0) {
                    o = this.newData
                }
                if(this.active === 5 && o.q12 !== null && o.q13 !== null && o.q14 !== null && o.q15 !== null && o.q16 !== null) {
                this.activeOn();
                } else {
                    this.$message.error('Заполните все поля!!!');
                    return
                }
            }
            else if (this.active === 6){
                let j;
                if(Object.keys(this.dataEdit).length > 0) {
                    j = this.dataEdit
                } else if(Object.keys(this.dataEdit).length === 0) {
                    j = this.newData
                }
                if(this.active === 6 && j.q17 !== null && j.q18 !== null && j.q19 !== null && j.q20 !== null) {
                this.activeOn();
                } else {
                    this.$message.error('Заполните все поля!!!');
                    return
                }
            }
            else if (this.active === 7){
                let m;
                if(Object.keys(this.dataEdit).length > 0) {
                    m = this.dataEdit
                } else if(Object.keys(this.dataEdit).length === 0) {
                    m = this.newData
                }
                if(this.active === 7 && m.q21 !== null && m.q22 !== null && m.q23 !== null && m.q24 !== null && m.q25 !== null) {
                this.activeOn();
                } else {
                    this.$message.error('Заполните все поля!!!');
                    return
                }
            } else if (this.active === 8){
                let v;
                if(Object.keys(this.dataEdit).length > 0) {
                    v = this.dataEdit
                } else if(Object.keys(this.dataEdit).length === 0) {
                    v = this.newData
                }
                if(this.active === 8 && v.q26 !== null && v.q27 !== null && v.q28 !== null && v.q29 !== null && v.q30 !== null) {
                this.activeOn();
                } else {
                    this.$message.error('Заполните все поля!!!');
                    return
                }
            } else if (this.active === 4){
                this.activeOn();
            } else if (this.active === 9){
                this.activeOn();
            } else if (this.active === 10){
                this.activeOn();
            }
      },
      back() {
        if(this.active > 0) {
            this.activeBack();
		}
      },
    }
}
</script>