export default {
    namespaced: true,
    state: {
        notification: [],
        patientsList: [],       
    },
    getters: {
        notifications: state => state.notification,
        patientsList: state => state.patientsList,        
    },
    mutations: {       

        setGetListPatients(state, payload) {
            let newList = payload.map(el=>{
                if(!el.full_name)
                    el.full_name = el.last_name+' '+el.first_name+' '+el.patronymic;
                return el;
            })
            state.patientsList = payload
        },
        setGrid(state, payload) {
            //console.log(payload)
            //state.patientsList.push(payload)
        },
        setNotifications(state, payload) {
            state.notification = payload
        },
        updRow(state, payload) {
            state.patientsList.birth_date = payload.birth_date
            state.patientsList.email = payload.email
            state.patientsList.gender = payload.gender
            state.patientsList.first_name = payload.first_name
            state.patientsList.last_name = payload.last_name
            state.patientsList.patronymic = payload.patronymic
            state.patientsList.phone = payload.phone
            state.patientsList.email = payload.email
            state.patientsList.address = payload.address
            state.patientsList.full_name = payload.last_name+' '+payload.first_name+' '+payload.patronymic
        }
    },
    actions: {       

        getListPatients({commit, rootState}, data) {
            let id = data || rootState.user.user.id;
			this.api.get(`${rootState.apiUrl}/doctors/${id}/patients`).then(res => {
				commit('setGetListPatients', res.data);
			})
        },
        getNotification({commit, rootState}) {
            let id = rootState.user.user.id;
			this.api.get(`${rootState.apiUrl}/doctors/${id}/notifications`).then(res => {
				commit('setNotifications', res.data);
			})
        },
        postNotification({commit, rootState}, payload) {
            let id = rootState.user.user.id;
            this.api.post(`${rootState.apiUrl}/doctors/${id}/notifications/${payload}`)
        },
        bindPatient({commit, rootState}, payload) {
            let data = {
                id: payload,
                doctor: rootState.user.user.id
            }
            let id = payload
			this.api.post(`${rootState.apiUrl}/patients/${id}/doctors`, data)
        },
        gridInsert({commit, rootState}, payload) {
            //console.log('gridInsert', payload)
            return new Promise((resolve, reject)=>{
                this.api.post(`${rootState.apiUrl}/patients`, payload).then(res => {
                    commit('setGrid', payload)
                    resolve(res)
                },error => {
                    reject(error)
                })
            })
        },
        updateRow({commit, rootState}, payload) {
            //console.log(payload)
            return new Promise((resolve, reject)=>{
                this.api.put(`${rootState.apiUrl}/doctors/${payload.doctor}/patients/${payload.id}`, payload).then(res => {
                    commit('updRow', res.data)
                    resolve(res)
                },error => {
                    if( error.response.data.Message === 'Указанный e-mail уже используется.') {
                        resolve({status: 'wrong_email'})
                    } else {
                        reject(error)
                    }
                })
            })
        }
    }
}
