<template>
	<v-card>
		<v-card-title>
			<v-btn v-if="user.role !== 2" @click="dialog=true" color="#6374dd" dark class="ml-4">Добавить МРТ</v-btn>
			
			<v-spacer></v-spacer>

			<v-text-field color="#6b59c7" v-model="search" append-icon="mdi-magnify" label="Поиск..." single-line hide-details />
		</v-card-title>

		<v-data-table no-results-text="Поиск не дал результатов"
			:headers="headers"
			:items="list"
			:search="search"
			:loading="loading"
			sort-by="date"
        	:sort-desc="true"
			no-data-text="Нет данных..."
			loading-text="Загрузка... Пожалуйста подождите..."
		>

			<template v-slot:[`item.action`]="{ item }">
				<v-btn v-if="user.role !== 2 || checkRole" @click="editeItems(item)" color="#6374dd" small icon>
					<v-icon class="w-20">mdi-pencil</v-icon>
				</v-btn>
				
				<v-btn v-if="user.role !== 2 && (new Date().getTime() - item.creation_date < 86400000)" @click="deleteItems(item, list)" color="error" small icon>
					<v-icon class="w-20">mdi-delete</v-icon>
				</v-btn>
			</template>

			<template v-slot:[`item.file_ids`]="{ item }">
				<v-btn v-if="item.file_ids && item.file_ids.length > 0" small color="#6374dd" @click="downloadFile(item.file_ids)" dark class="ml-4">Скачать прикрепленный файл</v-btn>
				<span v-else>---</span>
			</template>
		</v-data-table>

		<!-- Окно создания/редактирования -->
		<v-dialog persistent v-model="dialog" max-width="900px">
			<v-card>
				<v-toolbar dark color="#6374dd">
					<v-btn icon dark @click="close()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ editeOn ? 'Редактировать' : 'Добавить' }} исследование (МРТ)</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-toolbar-items>
						<v-btn @click="save(obj)" dark text >Сохранить</v-btn>
					</v-toolbar-items>
				</v-toolbar>

				<v-card-text>
					<v-container>
						<v-overlay absolute :value="overlay">
							<v-progress-circular indeterminate color="#6b59c7" />
						</v-overlay>

						<v-form ref="form" v-model="valid" lazy-validation class="mt-2">
							<v-row>
								<v-col cols="12" sm="4" md="4">
									<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field outlined ref="tf0" :rules="[rules.required]" :value="dateFormatted" label="Дата *" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
										</template>

										<v-date-picker :max="toJSONLocal(null)" :min="toJSONLocal(bday.birth_date)" color="#6b59c7" ref="picker" :first-day-of-week="1" v-model="obj.date" @change="saveDate" />
									</v-menu>
								</v-col>
								<v-col cols="12" sm="8" md="8">
									<v-text-field outlined ref="tf1" :rules="[rules.required]" color="#6b59c7" v-model="obj.name" label="Исследование *" />
								</v-col>

								<v-col cols="12" sm="12" md="12">
									<v-file-input v-if="!editeOn" v-model="file" label="Прикрепить файл" outlined hide-details />
								</v-col>
								<v-col cols="12" sm="12" md="12">
									<v-textarea hide-details outlined rows="4" color="#6b59c7" v-model="obj.comment" label="Комментарий" />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar rounded="pill" elevation="24" :color="snackbarColor" top v-model="snackbar">
			<v-icon>mdi-information</v-icon>
      		{{ snackbar_text }}
		</v-snackbar>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			uzi: 'data/dataUzi',
			apiUrl: 'apiUrl',
			bday: 'passportPart/patientData',
			user: 'user/user',
			patient: 'user/patient',
        }),
		dateFormatted() {
            if(this.obj.date)
                return new Date(this.obj.date).toLocaleDateString("ru-RU");
            return null;
        },
        checkRole() {
            return this.$route.query.myPatient == 'false' ? this.user.CanRead : (this.$route.query.myPatient == 'true' ? true : this.user.role !== 2)
        }
	},
	data() {
		return {
			editeOn: false,
			file: null,
			search: '',
			maxDate: new Date().toISOString().substr(0, 10),
			menu: false,
			overlay: false,
			valid: true,
			snackbar: false,
			snackbar_text: '',
			snackbarColor: '',
			obj: {
				comment: '',
				creation_date: null,
				date: null,
				file_ids: [],
				id: null,
				name: '',
				patient_id: null,
				type_id: null,
				type_name: ''
			},
			dialog: false,
			list: [],
			loading: true,
			rules: {
				required: value => !!value || 'Обязательно для заполнения.'
			},
			headers: [
				{ text: "Дата", value: "date_formatt", align: 'center' },
				{ text: "Исследование", value: "name", align: 'center', sortable: false },
				{ text: "Комментарий", value: "comment", align: 'center', sortable: false },
				{ text: "Файл", value: "file_ids", align: 'center', sortable: false },
                { text: "", value: "action", sortable: false }
			],
		};
	},
	methods: {
		close() {
			if(this.$refs[`tf0`]) this.$refs[`tf0`].reset()
			if(this.$refs[`tf1`]) this.$refs[`tf1`].reset()
			this.file = null
			this.obj = {
				comment: '',
				creation_date: null,
				date: null,
				file_ids: [],
				id: null,
				name: '',
				patient_id: null,
				type_id: null,
				type_name: ''
			}
			this.editeOn = false
			this.dialog = false
			this.dialogView = false
		},
		async apiMethods(url, name) {
			this.loading = false
			
			let res = (await this.api.get(`${url}/${name}`)).data
			res.forEach(el => { if(el.date) el.date_formatt = new Date(el.date).toLocaleDateString() })
			
			return res.filter(f=>f.type_id == 4)
		},
		editeItems(row) {
			row.date = this.toJSONLocal(row.date)
			this.editeOn = true
			this.obj = Object.assign({}, row)
			this.dialog = true
		},
		deleteItems(row) {
			if(confirm('Вы уверены что хотите удалить запись ?')) {
				this.api.delete(`${this.apiUrl}/api/patients/${this.patient.patients_id}/researches/${row.id}`, row)
				.then(res => {
					if(res.status == 200) {
						const index = this.list.indexOf(row);
						this.list.splice(index, 1)
					}
				})
			}
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		save(obj) {
			if(this.$refs.form.validate()) {
				if(this.file || obj.comment) {
					if(this.file) {
						this.overlay = true
						
						let formData = new FormData();
						formData.append('file', this.file)

						this.api.post(`${this.apiUrl}/files/send`, formData)
						.then(res => {
							if(res.status === 200) {
								this.snackBarFunc(true, 'Файл успешно загружен на сервер!!!', 'success')

								this.api.post(`${this.apiUrl}/api/patients/${this.patient.patients_id}/researches`, {
									comment: obj.comment,
									date: new Date(obj.date).getTime(),
									name: obj.name,
									creation_date: new Date().getTime(),
									type_id: 4,
									file_ids: [res.data.id]
								})
								.then(response => {
									if(response.status == 201) {
										response.data.date_formatt = new Date(response.data.date).toLocaleDateString()
										this.list.push(response.data)

										this.overlay = false
										this.dialog = false
										this.close()
									}
								})
							} else {
								this.snackBarFunc(true, 'Ошибка загрузки файла, попробуйте снова!!!', 'error')
								this.overlay = false
							}
						})
					} else {
						this.api[this.editeOn ? 'put' : 'post'](`${this.apiUrl}/api/patients/${this.patient.patients_id}/researches${this.editeOn ? '/'+obj.id : ''}`, {
							comment: obj.comment,
							date: new Date(obj.date).getTime(),
							name: obj.name,
							creation_date: this.editeOn ? obj.creation_date : new Date().getTime(),
							type_id: 4,
							file_ids: obj.file_ids
						})
						.then(response => {
							if(response.status == 201) {
								response.data.date_formatt = new Date(response.data.date).toLocaleDateString()
								this.list.push(response.data)
								
								this.overlay = false
								this.close()

								return;
							}

							this.list.forEach(el => { if(el.id == response.data.id) Object.assign(el, response.data) })
							this.overlay = false
							this.close()
						})
					}
				} else {
					this.snackBarFunc(true, 'Заполните поле "Комментарий" либо загрузите файл с результатами', 'warning')
				}
			} else {
				this.snackBarFunc(true, 'Заполните обязательные поля!!!', 'warning')
			}
		},
		saveDate(date) {
			this.$refs.menu.save(date)
		},
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
		downloadFile(id_array) {
			window.open(`${this.apiUrl}/files/${id_array}/download`, '_blank');
		}
	},
	async created() {
		this.list = await this.apiMethods(this.apiUrl, `api/patients/${this.patient.patients_id}/researches`)
	}
};
</script>