<template>
	<v-card>
		<v-data-table no-results-text="Поиск не дал результатов"
            hide-default-footer
            dense
            :headers="headers"
            :items="listSip"
        >
			<template v-slot:[`item.bol`]="{ item }">
				{{item.bol ? 'Есть' : 'Нет'}}
			</template>
        </v-data-table>
	</v-card>
</template>


<script>
export default {
	props: ['siplist'],
    data: () => ({
        headers: [
            { text: "Часть тела", value: "name", align: 'center', sortable: false },
            { text: "Наличие сыпи", value: "bol", align: 'center', sortable: false },
        ]
    }),
	computed: {
        listSip(){
            return JSON.parse(this.siplist)
        }
    }
}
</script>