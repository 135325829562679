<template>
<div id="osn" class="Authorization">
    <div style="margin: 20px 0 0 20px;">
        <h1>Информационная система "Дистанционный мониторинг для пациентов с Ювенильным артритом"</h1>
        <p>Для доступа к системе нажмите кнопку Вход в верхней правой части страницы</p>
    </div>
</div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    methods: {
        ...mapActions({
            getUserInfo: 'passportPart/getUserInfo',
            resetAuth: 'user/resetAuth'
		}),
    },
    created() {
        if(this.$route.name === 'Authorization'){
            this.resetAuth();
        }
        //this.getUserInfo().then(res=> {
            //console.log(res)
            // if(res.data && res.data.role === 0) {
            //     this.$router.push('/ListDoc')
            // } else if(res.data && res.data.role === 1) {
            //     this.$router.push('/PassportPart')
            // } else if(res.data && res.data.role === 2) {
            //     this.$router.push('/PersonalDoctorsOffice')
            // }
        //})
    }
}
</script>