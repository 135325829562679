<template>
    <v-dialog persistent v-model="openReport" max-width="900px">
        <v-card>
            <v-toolbar dark color="#6374dd">
                <v-btn :disabled="loading" icon dark @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    <span v-if="doctorFlag">
                        {{ objPatient.fio }}
                    </span>
                    Формирование отчета
                </v-toolbar-title>
                <div class="flex-grow-1"></div>
                <v-toolbar-items>
                    <v-btn :loading="loading" @click="generateReport(obj)" dark text >Сформировать отчет</v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation class="mt-2">
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field :disabled="obj.allData" :rules="[!obj.allData ? rules.required : true, obj.dateRange ? rules.minAndMax(obj.dateRange[0], obj.dateRange[1]) : true]" color="#5D4CB8" placeholder="Выбрать дату от - до" dense outlined :value="dateFormatted" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                    </template>

                                    <v-date-picker
                                        min="1950-01-01"
                                        :max="toJSONLocal()"
                                        color="#5D4CB8"
                                        :first-day-of-week="1"
                                        range
                                        v-model="obj.dateRange"
                                        @change="(date) => { $refs.menu.save(date) }"
                                    />
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="12" md="auto">
                                <v-checkbox v-model="obj.allData" @change="() => { obj.dateRange = null }" color="#6b59c7" dense label="За весь период" />
                            </v-col>
                            <v-col v-if="!doctorFlag" cols="12" sm="12" md="12" class="pt-0 pb-0">
                                <h3>Действия с отчетом</h3>
                            </v-col>
                            <v-col v-if="!doctorFlag" cols="12" sm="12" md="auto">
                                <v-checkbox v-model="obj.download" color="#6b59c7" dense label="Скачать отчет" />
                            </v-col>
                            <v-col v-if="!doctorFlag" cols="12" sm="12" md="auto">
                                <v-checkbox v-model="obj.isPatient" color="#6b59c7" dense label="Отправить отчет себе" />
                            </v-col>
                            <v-col v-if="doctor && doctor !== 'Отсутствует' && doctor !== 2 && !doctorFlag" cols="12" sm="12" md="auto">
                                <v-checkbox v-model="obj.isDoctor" color="#6b59c7" dense label="Отправить отчет врачу" />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        openReport: { type: Boolean, default: () => false },
        doctorFlag: { type: Boolean, default: () => false },
        user: {type: Object, default: () => {}},
        birthDate: {type: Number, default: () => 0},
        apiUrl: {type: String, default: () => ''},
        doctor: {default: () => null},
        objPatient: {type: Object, default: () => {}}
    },
    data: () => ({
        loading: false,
        valid: false,
        menu: false,
        snackbar: false,
        snackbar_text: '',
        snackbarColor: '',
        obj: {
            dateRange: null,
            allData: null,
            download: false,
            isPatient: false,
            isDoctor: false
        },
        rules: {
            required: value => !!value || 'Обязательно для заполнения.',
            minAndMax: (dateFrom, dateTo) => dateFrom < dateTo || 'Дата ОТ не может быть меньше даты ДО!!!'
        }
    }),
    computed: {
        dateFormatted() {
            // console.log(this.user)
            if(this.obj.dateRange?.length !== 2) return null

            return `${new Date(this.obj.dateRange[0]).toLocaleDateString()} - ${new Date(this.obj.dateRange[1]).toLocaleDateString()}`
        }
    },
    methods: {
        generateReport(obj) {
            if(this.$refs.form.validate()) {
                this.loading = true

                //если выбрана дата то преобразуем ее в timestmp
                if(obj.dateRange) {
                    obj.dateRange[0] = new Date(obj.dateRange[0]).getTime()
                    obj.dateRange[1] = new Date(obj.dateRange[1]).getTime()
                }

                //если выбран флаг "Все данные" - то ищем от даты рождения до сегодняшнего дня
                if(obj.allData) obj.dateRange = [this.birthDate, new Date().getTime()]


                this.api.post(`${this.apiUrl}/api/patientsreport/SendReportPDF?patientId=${this.doctorFlag ? this.objPatient.id : this.user.patients_id}&startDate=${obj.dateRange[0]}&endDate=${obj.dateRange[1]}&isDoctor=${obj.isDoctor}&isPatient=${obj.isPatient}&download=${this.doctorFlag ? true : obj.download}`, {}, {responseType: 'blob'})
                .then(res => {
                    if(res.status == 200) {
                        if(this.doctorFlag || obj.download) {
                            var blob = new Blob([res.data], {type: "application/pdf"});
                            var fileURL = URL.createObjectURL(blob);
                            var link = document.createElement("a");
                            link.download = `${this.doctorFlag ? this.objPatient.fio : this.user.fio} (c ${new Date(obj.dateRange[0]).toLocaleDateString()} по ${new Date(obj.dateRange[1]).toLocaleDateString()}).pdf`;
                            link.href = fileURL;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }

                        this.loading = false
                        this.close()
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },
		close() {
			this.obj = {
                dateRange: null,
                allData: null,
                download: false,
                isPatient: false,
                isDoctor: false
			}
			this.$refs.form.resetValidation()
            this.$emit('update:openReport', false)
		},
		snackBarFunc(flag, text, color) {
			this.snackbar = flag
			this.snackbar_text = text
			this.snackbarColor = color
		},
		toJSONLocal(date) {
			let local = date ? new Date(date) : new Date();
			local.setMinutes((date ? new Date(date) : new Date()).getMinutes() - (date ? new Date(date) : new Date()).getTimezoneOffset());

			return local.toJSON().slice(0, 10);
		},
    }
}
</script>